import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useIntl } from 'react-intl';

import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { login } from '../actions';
import { getUser } from '../utils';

import MaskablePasswordInput from './MaskablePasswordInput';
import BaseHOC from './BaseHOC';
import FontSelector from './FontSelector';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  progress: {
    margin: theme.spacing(2),
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Login = ({
  classes,
  enqueueSnackbar,
  dispatch,
  authentication,
  location,
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { formatMessage: f, locale } = useIntl();

  if (
    getUser() &&
    getUser().shopMap &&
    getUser().shopMap.length > 0 &&
    location.pathname === `${locale}/app/login`
  ) {
    navigate(`/${locale}/app/summary/`);
    return null;
  }

  return (
    <FontSelector>
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {f({ id: 'appLoginTitle' })}
          </Typography>
          <form
            onSubmit={() =>
              dispatch(login(username, password, enqueueSnackbar, f))
            }
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">
                {f({ id: 'appLoginUsername' })}
              </InputLabel>
              <Input
                disabled={authentication && authentication.loggingIn}
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <MaskablePasswordInput
                disabled={authentication && authentication.loggingIn}
                name="password"
                id="password"
                label={f({ id: 'appLoginPassword' })}
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            {authentication && authentication.loggingIn ? (
              <div className={classes.progressContainer}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() =>
                    dispatch(login(username, password, enqueueSnackbar, f))
                  }
                >
                  {f({ id: 'appLoginLoginButton' })}
                </Button>

                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => navigate(`/${locale}/forgot-password`)}
                >
                  {f({ id: 'appLoginForgotPasswordButton' })}
                </Button>
              </>
            )}
          </form>
        </Paper>
      </main>
    </FontSelector>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

export default BaseHOC(Login, styles, mapStateToProps);
