import React, { useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';

import ReactPlayer from 'react-player';

import { useIntl, FormattedMessage } from 'react-intl';

import * as _ from 'lodash';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';
import SlideWrapper from '../../SlideWrapper';
import PrimaryButton from '../../PrimaryButton';

import DashboardLayout from '../../DashboardLayout';
import { isSalonzone } from '../../../utils';

import schedulesBcHu from '../../../videos/schedules_bc_hu.mp4';
import schedulesBcEn from '../../../videos/schedules_bc_en.mp4';
import schedulesSzHu from '../../../videos/schedules_sz_hu.mp4';
import schedulesSzEn from '../../../videos/schedules_sz_en.mp4';

const ClientSideOnlyLazy = React.lazy(() => import('./AdminSchedulesCalendar'));

const AdminBookingsPage = () => {
  const isSSR = typeof window === 'undefined';
  const { formatMessage: f, locale } = useIntl();

  const urlParams = new URLSearchParams(window.location.search);
  const defaultTooltipOpen = urlParams.get('onboarding') !== null;

  const [helpVideoOpen, setHelpVideoOpen] = useState(defaultTooltipOpen);
  const getVideo = () => {
    if (isSalonzone) {
      if (locale === 'hu') {
        return schedulesSzHu;
      }
      return schedulesSzEn;
    }
    if (locale === 'hu') {
      return schedulesBcHu;
    }
    return schedulesBcEn;
  };

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header
            subtitle={f({ id: 'tooltipViewVideo' })}
            tooltipOnClick={() => setHelpVideoOpen(true)}
          >
            {f({ id: 'appNavAdminBookingSchedules' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ClientSideOnlyLazy />
            </React.Suspense>
          )}
        </Grid>
      </Grid>
      <Dialog open={helpVideoOpen}>
        <DialogTitle>{f({ id: 'appNavAdminBookingSchedules' })}</DialogTitle>
        <DialogContent>
          <SlideWrapper>
            <p>
              <FormattedMessage id="bookingSchedulesTooltip" />
            </p>
            <ReactPlayer
              config={{ file: { attributes: { playsInline: true } } }}
              width="100%"
              height="100%"
              url={getVideo()}
              playing
              muted
              volume={0}
            />
          </SlideWrapper>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setHelpVideoOpen(false)}>
            OK
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminBookingsPage, null, null, null);
