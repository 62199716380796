import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import BaseHOC from '../../BaseHOC';

const StatDisplay = ({ label, value }) => {
  return (
    <Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="h3">{value}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
    </Box>
  );
};

const AdminStatsKPI = ({ bookingStats }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Box
      pt={2}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      flexDirection="row"
      flexWrap="wrap"
    >
      <StatDisplay
        value={bookingStats.kpi.all_count || 0}
        label={f({ id: 'statsAllBooking' })}
      />
      <StatDisplay
        value={bookingStats.kpi.cancelled_count || 0}
        label={f({ id: 'statsKpiCancelledBooking' })}
      />
      <StatDisplay
        value={bookingStats.kpi.no_show_count || 0}
        label={f({ id: 'statsKpiNoShow' })}
      />
      <StatDisplay
        value={`${((bookingStats.kpi.internal_booking_rate || 0) * 100).toFixed(
          0,
        )}%`}
        label={f({ id: 'statsKpiInternalBooking' })}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  bookingStats: state.bookingStats,
});

export default BaseHOC(AdminStatsKPI, null, mapStateToProps);
