import * as _ from 'lodash';
import { percentageToNormal } from '../../../utils';

export const getPaymentMethod = (item, f) => {
  switch (item.payment_method) {
    case 'card':
      return f({ id: 'paymentMethodCard' });
    case 'cash':
      return f({ id: 'paymentMethodCash' });
    case 'giftcard':
      return f({ id: 'paymentMethodGiftcard' });
    default:
      return null;
  }
};

export const getItemKind = (item, f) => {
  if (!_.isEmpty(item.service)) {
    return f({ id: 'kindService' });
  }
  if (!_.isEmpty(item.product)) {
    return f({ id: 'kindProduct' });
  }
  return f({ id: 'kindGiftcard' });
};

export const getDiscountedRowPrice = (item) =>
  Math.round(
    item.quantity *
      parseFloat(item.price) *
      percentageToNormal(item.discountRate || parseFloat(item.discount_rate)) -
      (item.discountAmount || parseFloat(item.discount_amount)) +
      (item.miscSaleAmount || parseFloat(item.misc_sale_amount)),
  );

export const getMiscSaleKind = (item, f) => {
  if (item.manual_price > 0) {
    return f({ id: 'paymentDirectionIn' });
  }
  return f({ id: 'paymentDirectionOut' });
};
