import React, { useEffect, useState } from 'react';
import { Grid, Box, FormLabel, Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import PrimaryButton from '../../PrimaryButton';
import TextField from '../../TextField';

import { getSettings, saveSettings, checkBookingPage } from '../../../actions';
import { getUser } from '../../../utils';

const AdminBookingSettingsBookingWebsitePage = ({
  dispatch,
  settings,
  enqueueSnackbar,
}) => {
  const shopId = getUser().shopMap[0].id;
  const { formatMessage: f } = useIntl();
  const [customUrl, setCustomUrl] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    dispatch(getSettings({ shopId, enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(settings)) {
      if (settings.custom_url) {
        setCustomUrl(settings.custom_url);
      } else if (settings.booking_url) {
        setCustomUrl(settings.booking_url.split('/').pop());
      }
      setMetaDescription(settings.meta_description || '');
      setWarningMessage(settings.warning_message || '');
    }
  }, [settings]);

  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12}>
          <Box pt={4}>
            <Header subtitle={f({ id: 'websiteTooltip' })}>
              {f({ id: 'appNavAdminBookingWebsite' })}
            </Header>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={4}>
            <FormLabel style={{ minWidth: '260px' }} component="legend">
              {`${f({ id: 'website' })}: ${settings && settings.booking_url}`}
            </FormLabel>
            <Box pt={2}>
              <TextField
                margin="dense"
                name="customUrl"
                label={f({ id: 'customUrl' })}
                value={customUrl}
                onChange={(event) => setCustomUrl(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                pr={1}
              >
                <TextField
                  margin="dense"
                  id="metaDescription"
                  name="metaDescription"
                  label={f({ id: 'bookingSettingsMetaDescription' })}
                  value={metaDescription}
                  onChange={(event) => setMetaDescription(event.target.value)}
                  multiline
                  rows="4"
                />
                <Tooltip
                  enterTouchDelay={1}
                  leaveTouchDelay={2000}
                  title={f({ id: 'bookingSettingsMetaDescriptionTooltip' })}
                >
                  <HelpOutlineOutlinedIcon />
                </Tooltip>
              </Box>
            </Box>
            <Box mb={2}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                pr={1}
              >
                <TextField
                  margin="dense"
                  id="warningMessage"
                  name="warningMessage"
                  label={f({ id: 'bookingSettingsWarningMessage' })}
                  value={warningMessage}
                  onChange={(event) => setWarningMessage(event.target.value)}
                  multiline
                  rows="4"
                  style={{ paddingTop: '14px' }}
                />
                <Tooltip
                  enterTouchDelay={1}
                  leaveTouchDelay={2000}
                  title={f({ id: 'bookingSettingsWarningMessageTooltip' })}
                >
                  <HelpOutlineOutlinedIcon />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={4}>
            <PrimaryButton
              onClick={() => {
                if (settings && settings.booking_url) {
                  dispatch(checkBookingPage(settings.booking_url, f));
                }
              }}
            >
              {f({ id: 'open' })}
            </PrimaryButton>
          </Box>
          <Box pt={2}>
            <PrimaryButton
              onClick={() => {
                const data = new FormData();
                data.append('customUrl', customUrl);
                if (metaDescription) {
                  data.append('metaDescription', metaDescription);
                }
                data.append('warningMessage', warningMessage);
                dispatch(
                  saveSettings({
                    f,
                    shopId,
                    settingsData: data,
                    enqueueSnackbar,
                  }),
                ).then(() =>
                  dispatch(getSettings({ shopId, enqueueSnackbar })),
                );
              }}
            >
              {f({ id: 'buttonSave' })}
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default BaseHOC(
  AdminBookingSettingsBookingWebsitePage,
  null,
  mapStateToProps,
);
