import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Input,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import * as _ from 'lodash';
import { useIntl } from 'react-intl';

import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';

import { addPurchaseItem } from '../../../actions';
import { ccyFormat, isPositiveInt, isPositiveIntOrZero } from '../../../utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.label}${option.barcode || ''}`,
});

const SaleItemDialog = ({
  onClose,
  classes,
  items,
  open,
  label,
  kind,
  dispatch,
  barber,
}) => {
  const [selectedItem, setSelectedItem] = useState({ value: null, label: '' });

  const [discountRate, setDiscountRate] = useState('0');
  const [miscSaleAmount, setMiscSaleAmount] = useState('0');
  const [miscSaleAmountError, setMiscSaleAmountError] = useState(false);
  const [miscSaleComment, setMiscSaleComment] = useState('');
  const [discountAmount, setDiscountAmount] = useState('0');
  const [quantity, setQuantity] = useState(1);
  const [selectedItemError, setSelectedItemError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [discountKind, setDiscountKind] = useState('');
  const [discountError, setDiscountError] = useState(false);
  const settings = useSelector((state) => state.settings);

  const { formatMessage: f } = useIntl();

  const onExit = () => {
    setDiscountKind('');
    setSelectedItem({ value: null, label: '' });
    setDiscountRate('0');
    setDiscountAmount('0');
    setQuantity(1);
    setSelectedItemError(false);
    setQuantityError(false);
    setDiscountError(false);
  };

  const handleAdd = () => {
    let error = false;

    if (!selectedItem.value) {
      setSelectedItemError(true);
      error = true;
    } else {
      setSelectedItemError(false);
    }

    if (!isPositiveInt(quantity)) {
      setQuantityError(true);
      error = true;
    } else {
      setQuantityError(false);
    }

    if (
      (discountKind === 'rate' && !isPositiveIntOrZero(discountRate)) ||
      (discountKind === 'amount' && !isPositiveIntOrZero(discountAmount))
    ) {
      setDiscountError(true);
      error = true;
    } else {
      setDiscountError(false);
    }

    if (error) {
      return;
    }
    dispatch(
      addPurchaseItem({
        barber,
        selectedItemId: parseInt(selectedItem.value, 10),
        discountRate:
          (discountKind === 'rate' && parseInt(discountRate, 10)) || 0,
        discountAmount:
          (discountKind === 'amount' && parseInt(discountAmount, 10)) || 0,
        quantity: parseInt(quantity, 10),
        kind,
        miscSaleAmount,
        miscSaleComment,
      }),
    );
    onClose();
  };

  const handlePickerChange = (event, newValue) => {
    if (newValue) {
      setSelectedItem(newValue);
    } else {
      setSelectedItem({ value: null, label: '' });
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      onExit={() => onExit()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        {f({ id: 'saleItemDialogTitle' }, { kind: label })}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                required
                disableClearable
                id="selector"
                name="selector"
                value={selectedItem}
                onChange={(event, newValue) => {
                  handlePickerChange(event, newValue);
                }}
                options={
                  items &&
                  items.map((option) => ({
                    value: option.id,
                    barcode: option.barcode,
                    label: `${option.title} - ${ccyFormat(
                      parseFloat(option.price),
                      settings &&
                        settings?.country &&
                        settings?.country?.currency,
                    )}`,
                  }))
                }
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    error={selectedItemError}
                    className={classes.formControl}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="quantity">
                {f({ id: 'columnTitleQuantity' })}
              </InputLabel>
              <Input
                error={quantityError}
                id="quantity"
                name="quantity"
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <FormLabel component="legend">
                {f({ id: 'columnTitleDiscount' })}
              </FormLabel>
              <RadioGroup
                aria-label={f({ id: 'columnTitleDiscount' })}
                name="discountKind"
                value={discountKind}
                onChange={(event) => setDiscountKind(event.target.value)}
              >
                <FormControlLabel
                  value="rate"
                  control={<Radio color="primary" />}
                  label={f({ id: 'discountPercentage' })}
                />
                <FormControlLabel
                  value="amount"
                  control={<Radio color="primary" />}
                  label={f({ id: 'discountAmount' })}
                />
              </RadioGroup>
              <TextField
                error={discountError}
                id="discount"
                name="discount"
                disabled={!discountKind}
                value={
                  (discountKind === 'rate' && discountRate) ||
                  (discountKind === 'amount' && discountAmount) ||
                  ''
                }
                onChange={(event) => {
                  if (discountKind === 'rate') {
                    setDiscountRate(event.target.value);
                  } else if (discountKind === 'amount') {
                    setDiscountAmount(event.target.value);
                  }
                }}
                type="number"
                InputProps={
                  discountKind === 'amount'
                    ? (settings?.country?.currency === 'Ft' && {
                        endAdornment: (
                          <InputAdornment position="end">Ft</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'GBP' && {
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'EUR' && {
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'CZK' && {
                        startAdornment: (
                          <InputAdornment position="end">Kč</InputAdornment>
                        ),
                      })
                    : {
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }
                }
              />
            </FormControl>
          </Grid>
          {kind === 'service' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <FormLabel component="legend">
                    {f({ id: 'miscSaleAmount' })}
                  </FormLabel>
                  <TextField
                    error={miscSaleAmountError}
                    id="miscSaleAmount"
                    name="miscSaleAmount"
                    value={miscSaleAmount}
                    onChange={(event) =>
                      setMiscSaleAmount(event.target.value || '0')
                    }
                    type="number"
                    InputProps={
                      (settings?.country?.currency === 'Ft' && {
                        endAdornment: (
                          <InputAdornment position="end">Ft</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'GBP' && {
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'EUR' && {
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }) ||
                      (settings?.country?.currency === 'CZK' && {
                        startAdornment: (
                          <InputAdornment position="end">Kč</InputAdornment>
                        ),
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <FormLabel component="legend">
                    {f({ id: 'comment' })}
                  </FormLabel>
                  <TextField
                    id="miscSaleComment"
                    name="miscSaleComment"
                    value={miscSaleComment}
                    onChange={(event) => setMiscSaleComment(event.target.value)}
                    type="text"
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {f({ id: 'buttonCancel' })}
        </Button>
        <PrimaryButton onClick={() => handleAdd()}>
          {f({ id: 'buttonAdd' })}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  purchaseItems: state.purchaseItems,
});

export default BaseHOC(SaleItemDialog, styles, mapStateToProps);
