import React from 'react';
import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';

import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

const OnboardingSuccessDialog = ({ onClose, open }) => {
  const { locale, formatMessage: f } = useIntl();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
      // disableBackdropClick
      // disableEscapeKeyDown
    >
      <DialogTitle id="simple-dialog-title">
        {f({ id: 'onboardingSuccessDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <p>{f({ id: 'onboardingSuccessDialogp1' })}</p>
        <p>{f({ id: 'onboardingSuccessDialogp2' })}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => navigate(`/${locale}/app/admin-schedules/?onboarding`)}
          color="primary"
          variant="contained"
        >
          {f({ id: 'onboardingSuccessCTA' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingSuccessDialog;
