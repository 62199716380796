import React from 'react';
import Moment from 'react-moment';
import { navigate } from 'gatsby';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import Table from '../../Table';
import VoidCell from '../../VoidCell';
import BaseHOC from '../../BaseHOC';

const AdminInventoryInputList = ({ inventoryInputList }) => {
  const { formatMessage: f, locale } = useIntl();
  return (
    <Table title={f({ id: 'inventoryTableInputTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'date' })}</TableCell>
          <TableCell>{f({ id: 'inputTime' })}</TableCell>
          <TableCell>{f({ id: 'createdBy' })}</TableCell>
          <TableCell>{f({ id: 'inventoryProductName' })}</TableCell>
          <TableCell>{f({ id: 'quantity' })}</TableCell>
          <TableCell>{f({ id: 'details' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {inventoryInputList &&
          inventoryInputList.results &&
          inventoryInputList.results.map((row) => (
            <TableRow key={row.id}>
              {row.voided ? (
                <>
                  <VoidCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </VoidCell>
                  <VoidCell>
                    <Moment format="HH:mm">{row.created}</Moment>
                  </VoidCell>
                  <VoidCell>{row.created_by.display_name}</VoidCell>
                  <VoidCell>{row.product.title}</VoidCell>
                  <VoidCell>{row.quantity}</VoidCell>
                  <VoidCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        navigate(`/${locale}/app/inventory-input-list/edit`, {
                          state: { inventoryInput: row },
                        })
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </VoidCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="HH:mm">{row.created}</Moment>
                  </TableCell>
                  <TableCell>{row.created_by.display_name}</TableCell>
                  <TableCell>{row.product.title}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        navigate(`/${locale}/app/inventory-input-list/edit`, {
                          state: { inventoryInput: row },
                        })
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  inventoryInputList: state.inventoryInputList,
});

export default BaseHOC(AdminInventoryInputList, null, mapStateToProps);
