import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import {
  getProductInventory,
  listInventoryInputs,
  getProducts,
} from '../../../actions';

import DashboardLayout from '../../DashboardLayout';
import AdminProductInventoryList from './AdminProductInventoryList';
import AdminProductInventoryAddForm from './AdminProductInventoryAddForm';
import AdminInventoryInputList from './AdminInventoryInputList';

const AdminProductInventoryPage = ({ enqueueSnackbar, uri }) => {
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredProductInventory, setFilteredProductInventory] = useState([]);
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const productInventory = useSelector((state) => state.productInventory);
  const products = useSelector((state) => state.products);

  useEffect(() => {
    // Only load products if not already loaded
    if (products.length === 0) dispatch(getProducts());
  }, []);

  useEffect(() => {
    const filteredProductBarcodeBaseIds = products
      .filter(
        (x) => x.barcode?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1,
      )
      .map((x) => x.base_id);

    setFilteredProductInventory(
      productInventory?.filter(
        (row) =>
          !searchText ||
          filteredProductBarcodeBaseIds.indexOf(row.base_id) > -1 ||
          row.title?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1,
      ),
    );
  }, [productInventory, searchText]);

  const handleProductInventoryListChange = ({ search }) =>
    setSearchText(search);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'inventoryTooltip' })}>
            {f({ id: 'appNavAdminShopProductInventory' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDateParam, endDateParam, shopIdList) => {
              dispatch(
                getProductInventory({
                  startDate: startDateParam,
                  endDate: endDateParam,
                  shopIdList,
                  enqueueSnackbar,
                }),
              );
              dispatch(
                listInventoryInputs({
                  startDate: startDateParam,
                  endDate: endDateParam,
                  shopIdList,
                  enqueueSnackbar,
                }),
              );
              setFilterStartDate(startDateParam);
              setFilterEndDate(endDateParam);
            }}
            rememberDates
          />
        </Grid>
        <Grid item xs={12}>
          <AdminProductInventoryAddForm
            startDate={filterStartDate}
            endDate={filterEndDate}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminProductInventoryList
            onChange={handleProductInventoryListChange}
            productInventory={filteredProductInventory}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminInventoryInputList />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminProductInventoryPage);
