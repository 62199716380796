import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Moment from 'react-moment';

import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  TextField,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import { ccyFormat } from '../../../utils';
import {
  getEmployees,
  voidSalaryDeduction,
  listSalaryDeductions,
} from '../../../actions';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';
import VoidCell from '../../VoidCell';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import CurrencyFormat from '../../CurrencyFormat';

const SalaryCalculationRulesList = ({
  filterStartDate,
  filterEndDate,
  filterShopIdList,
  dispatch,
  enqueueSnackbar,
}) => {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedDeduction, setSelectedDeduction] = useState(null);
  const [voidedBy, setVoidedBy] = useState('');
  const { formatMessage: f } = useIntl();

  const salaryDeductions = useSelector((state) => state.salaryDeductions);
  const employees = useSelector((state) => state.employees);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    if (employees.length === 0) dispatch(getEmployees());
  }, [employees]);

  return (
    <>
      <Table title={f({ id: 'salaryDeductionList' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'createdBy' })}</TableCell>
            <TableCell>{f({ id: 'date' })}</TableCell>
            <TableCell>{f({ id: 'type' })}</TableCell>
            <TableCell>{f({ id: 'comment' })}</TableCell>
            <TableCell>{f({ id: 'amount' })}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {_.isEmpty(salaryDeductions) ? null : (
          <TableBody>
            {salaryDeductions.results.map((deduction) => (
              <TableRow key={deduction.id}>
                {deduction.voided ? (
                  <>
                    <VoidCell>
                      {(employees.length &&
                        employees.find((x) => x.id === deduction.deduct_from) &&
                        employees.find((x) => x.id === deduction.deduct_from)
                          .display_name) ||
                        '-'}
                    </VoidCell>
                    <VoidCell>
                      <Moment format="YYYY-MM-DD">
                        {deduction.deduction_date}
                      </Moment>
                    </VoidCell>
                    <VoidCell>{deduction.kind_display}</VoidCell>
                    <VoidCell>{deduction.notes}</VoidCell>
                    <VoidCell>
                      <CurrencyFormat amount={deduction.amount} />
                    </VoidCell>
                    <VoidCell>
                      <IconButton
                        aria-label={f({ id: 'details' })}
                        onClick={() => {
                          setSelectedDeduction(deduction);
                          setShowDetailsDialog(true);
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </VoidCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      {(employees.length &&
                        employees.find((x) => x.id === deduction.deduct_from) &&
                        employees.find((x) => x.id === deduction.deduct_from)
                          .display_name) ||
                        '-'}
                    </TableCell>
                    <TableCell>
                      <Moment format="YYYY-MM-DD">
                        {deduction.deduction_date}
                      </Moment>
                    </TableCell>
                    <TableCell>{deduction.kind_display}</TableCell>
                    <TableCell>{deduction.notes}</TableCell>
                    <TableCell>
                      <CurrencyFormat amount={deduction.amount} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label={f({ id: 'details' })}
                        onClick={() => {
                          setSelectedDeduction(deduction);
                          setShowDetailsDialog(true);
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {selectedDeduction ? (
        <>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={selectedDeduction && showDetailsDialog}
          >
            <DialogTitle>{f({ id: 'salaryDeductionDetails' })}</DialogTitle>
            <DialogContent>
              <Box>
                <FormControl required>
                  <TextField
                    id="deductedBy"
                    helperText={f({ id: 'salaryDeductionDetailsDeductedBy' })}
                    fullWidth
                    value={
                      employees.length &&
                      employees.find(
                        (x) => x.id === selectedDeduction.deducted_by,
                      ).display_name
                    }
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="created"
                    helperText={f({ id: 'inputDatetime' })}
                    fullWidth
                    value={moment(selectedDeduction.created).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="deductFrom"
                    helperText={f({ id: 'salaryDeductionDeductionDeductFrom' })}
                    fullWidth
                    value={
                      employees.length &&
                      employees.find(
                        (x) => x.id === selectedDeduction.deduct_from,
                      ).display_name
                    }
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="deductionDate"
                    helperText={f({ id: 'date' })}
                    fullWidth
                    value={moment(selectedDeduction.deduction_date).format(
                      moment.HTML5_FMT.DATE,
                    )}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="kind"
                    helperText={f({ id: 'type' })}
                    fullWidth
                    value={selectedDeduction.kind_display}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="notes"
                    helperText={f({ id: 'comment' })}
                    fullWidth
                    value={selectedDeduction.notes}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl required>
                  <TextField
                    id="amount"
                    helperText={f({ id: 'amount' })}
                    fullWidth
                    value={ccyFormat(
                      selectedDeduction.amount,
                      settings && settings.country && settings.country.currency,
                    )}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              {selectedDeduction.voided ? (
                <>
                  <Box>
                    <FormControl required>
                      <TextField
                        id="voided"
                        helperText={f({ id: 'deletionDate' })}
                        fullWidth
                        value={moment(selectedDeduction.voided).format(
                          moment.HTML5_FMT.DATE,
                        )}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl required>
                      <TextField
                        id="voidedBy"
                        helperText={f({ id: 'deletionBy' })}
                        fullWidth
                        value={
                          employees.length &&
                          employees.find(
                            (x) => x.id === selectedDeduction.voided_by,
                          ).display_name
                        }
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                </>
              ) : null}
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                withmargin="true"
                danger
                disabled={!!selectedDeduction.voided}
                onClick={() => {
                  setShowConfirmationDialog(true);
                }}
              >
                {f({ id: 'buttonDelete' })}
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  setShowDetailsDialog(false);
                  setSelectedDeduction(null);
                }}
                color="primary"
              >
                {f({ id: 'buttonOk' })}
              </SecondaryButton>
            </DialogActions>
          </Dialog>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={showConfirmationDialog}
          >
            <DialogTitle>{f({ id: 'confirmDeleteTitle' })}</DialogTitle>
            <DialogContent>
              <Typography variant="h6">
                {f({ id: 'confirmDeleteConfirmDeduction' })}
              </Typography>
              <FormControl required>
                <InputLabel htmlFor="">{f({ id: 'createdBy' })}</InputLabel>
                <Select
                  value={voidedBy}
                  onChange={(event) => setVoidedBy(event.target.value)}
                  input={<Input name="deductFrom" id="deductFrom" />}
                >
                  {employees.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                withmargin="true"
                danger
                disabled={!!selectedDeduction.voided}
                onClick={() => {
                  if (voidedBy) {
                    dispatch(
                      voidSalaryDeduction({
                        f,
                        deductionId: selectedDeduction.id,
                        voidData: {
                          voidedBy,
                        },
                        enqueueSnackbar,
                      }),
                    ).then(() => {
                      dispatch(
                        listSalaryDeductions({
                          startDate: filterStartDate,
                          endDate: filterEndDate,
                          shopIdList: filterShopIdList,
                          enqueueSnackbar,
                        }),
                      );
                      setVoidedBy('');
                      setShowConfirmationDialog(false);
                      setShowDetailsDialog(false);
                      setSelectedDeduction(null);
                    });
                  }
                }}
              >
                {f({ id: 'buttonYes' })}
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  setShowConfirmationDialog(false);
                }}
                color="primary"
              >
                {f({ id: 'buttonCancel' })}
              </SecondaryButton>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

export default BaseHOC(SalaryCalculationRulesList);
