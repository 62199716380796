import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export const Header = styled(Typography)`
  && {
    padding-bottom: 0.5rem;
    @media (max-width: 500px) {
      font-size: 1.5rem;
      margin-bottom: -0.5rem;
      padding-bottom: 0rem;
    }
  }
`;

export const SubHeader = styled(Typography)`
  && {
    padding-bottom: 0.5rem;
    @media (max-width: 500px) {
      font-size: 1rem;
      margin-bottom: -0.5rem;
      padding-bottom: 0rem;
    }
  }
`;

export const SizedHelpOutlineOutlinedIcon = styled(HelpOutlineOutlinedIcon)`
  && {
    font-size: 3rem;
    @media (max-width: 500px) {
      font-size: 1.75rem;
      padding-bottom: 0rem;
    }
  }
`;
