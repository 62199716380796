import React, { useState, useEffect } from 'react';
import {
  Input,
  MenuItem,
  Select,
  InputLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';

import PaperWithTitle from '../../PaperWithTitle';
import FormControl from '../../FormControl';
import PaddedGrid from '../../PaddedGrid';
import PrimaryButton from '../../PrimaryButton';
import BaseHOC from '../../BaseHOC';

import { useProduct, getEmployees, getProducts } from '../../../actions';
import { isPositiveInt, getUser } from '../../../utils';

const InputProductUseForm = ({
  enqueueSnackbar,
  dispatch,
  employees,
  products,
}) => {
  const [createdBy, setcReatedBy] = useState('');
  const [product, setProduct] = useState('');
  const [quantity, setQuantity] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [productError, setProductError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getProducts());
  }, []);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.label}${option.barcode || ''}`,
  });

  const handleSubmit = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (!product.value) {
      setProductError(true);
      error = true;
    } else {
      setProductError(false);
    }

    if (!isPositiveInt(quantity)) {
      setQuantityError(true);
      error = true;
    } else {
      setQuantityError(false);
    }

    if (error) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      return;
    }

    const productUseData = {
      createdBy,
      productId: product.value,
      quantity,
      shopId: getUser().shopId,
    };

    dispatch(useProduct(productUseData, enqueueSnackbar, f));
  };

  return (
    <PaperWithTitle>
      <Grid container spacing={0}>
        <PaddedGrid item xs={12}>
          <FormControl required error={createdByError}>
            <InputLabel htmlFor="createdBy">
              {f({ id: 'createdBy' })}
            </InputLabel>
            <Select
              value={createdBy}
              onChange={(event) => setcReatedBy(event.target.value)}
              input={<Input name="createdBy" id="createdBy" />}
            >
              {employees.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required>
            <Autocomplete
              required
              disableClearable
              id="selector"
              name="selector"
              value={product}
              onChange={(event, newValue) => {
                if (newValue) {
                  setProduct(newValue);
                } else {
                  setProduct({ value: null, label: '' });
                }
              }}
              options={
                products &&
                products.map((option) => ({
                  value: option.id,
                  barcode: option.barcode,
                  label: option.title,
                }))
              }
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  name="product"
                  id="product"
                  label={f({ id: 'kindProduct' })}
                  error={productError}
                />
              )}
            />
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required>
            <InputLabel htmlFor="quantity">
              {f({ id: 'columnTitleQuantity' })}
            </InputLabel>
            <Input
              error={quantityError}
              id="quantity"
              name="quantity"
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
              type="number"
            />
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <PrimaryButton type="button" onClick={(event) => handleSubmit(event)}>
            {f({ id: 'buttonInput' })}
          </PrimaryButton>
        </PaddedGrid>
      </Grid>
    </PaperWithTitle>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  products: state.products,
});

export default BaseHOC(InputProductUseForm, null, mapStateToProps);
