import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getUser } from '../../../utils';
import { deleteSalaryRule } from '../../../actions';

import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import TextField from '../../TextField';
import Chips from '../../Chips';
import Chip from '../../Chip';
import BaseHOC from '../../BaseHOC';

const LargeChip = styled(Chip)`
  && {
    max-width: 100%;
    width: auto;
  }
`;

const LargeChips = styled(Chips)`
  && {
    max-width: 250px;
  }
`;

const DeductCardPaymentFromSalaryRuleDialog = ({
  dialogOpen,
  onSubmit,
  onCancel,
  params,
  deleteSalaryRule,
  enqueueSnackbar,
}) => {
  const { shopMap } = getUser();
  const [ruleName, setRuleName] = useState('');
  const [items, setItems] = useState([]);
  const [shopIdList, setShopIdList] = useState(shopMap.map((x) => x.id));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { formatMessage: f } = useIntl();

  const ITEM_OPTIONS = {
    card_service_net: f({ id: 'salaryCardServiceNet' }),
    card_service_gross: f({ id: 'salaryCardServiceGross' }),
    card_product_net: f({ id: 'salaryCardProductNet' }),
    card_product_gross: f({ id: 'salaryCardProductGross' }),
  };

  const isReadOnly = params !== undefined;

  const validate = () => {
    // TODO: FIX ME!
    console.log('FIX ME!');
    return true;
  };

  useEffect(() => {
    setRuleName((params && params.name) || '');
    setItems((params && params.items) || []);
    setShopIdList((params && params.shops) || shopMap.map((x) => x.id));
  }, [params]);

  const reset = () => {
    setItems([]);
    setRuleName('');
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialogOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>{f({ id: 'deductcardpaymentfromsalaryrule' })}</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              {f({ id: 'deductcardpaymentfromsalaryruleTooltip' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="ruleName"
              name="ruleName"
              label={f({ id: 'salaryName' })}
              margin="normal"
              value={ruleName}
              onChange={(event) => setRuleName(event.target.value)}
            />
          </Grid>
          {shopMap.length > 1 ? (
            <Grid item xs={12}>
              <MultiShopSelector
                disabled={isReadOnly}
                shopIdList={shopIdList}
                setShopIdList={setShopIdList}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="select-multiple-chip">
                {f({ id: 'salaryDeductionBase' })}
              </InputLabel>
              <Select
                multiple
                disabled={isReadOnly}
                value={items}
                onChange={(event) => {
                  setItems(event.target.value);
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <LargeChips>
                    {selected.map((value) => (
                      <LargeChip key={value} label={ITEM_OPTIONS[value]} />
                    ))}
                  </LargeChips>
                )}
              >
                {Object.keys(ITEM_OPTIONS).map((key) => (
                  <MenuItem key={key} value={key}>
                    {ITEM_OPTIONS[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => {
            reset();
            onCancel();
          }}
        >
          {f({ id: 'buttonCancel' })}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={isReadOnly}
          onClick={() => {
            if (validate()) {
              onSubmit({
                ruleType: 'deductcardpaymentfromsalaryrule',
                data: {
                  name: ruleName,
                  items,
                  shopIdList,
                },
              });
              reset();
            }
          }}
        >
          {f({ id: 'buttonConfirm' })}
        </PrimaryButton>
        {isReadOnly ? (
          <PrimaryButton
            withmargin="true"
            danger
            onClick={() => {
              deleteSalaryRule(params.id, enqueueSnackbar, f);
              reset();
              onCancel();
            }}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  deleteSalaryRule: (...args) => dispatch(deleteSalaryRule(...args)),
});

export default BaseHOC(
  DeductCardPaymentFromSalaryRuleDialog,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
