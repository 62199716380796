import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const PaperHeader = styled(Typography)`
  && {
    margin-top: 1rem;
    margin-left: 1rem;
    @media (max-width: 500px) {
      font-size: 1rem;
      margin-bottom: -0.5rem;
      padding-bottom: 0rem;
    }
  }
`;
