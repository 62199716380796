import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminProductEditForm from './AdminProductEditForm';

const AdminProductEditPage = () => {
  const [mode, setMode] = useState('add');
  const { product } = window.history.state;
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (product) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [product]);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>
            {mode === 'edit'
              ? f({ id: 'productEdit' })
              : f({ id: 'productAdd' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminProductEditForm product={product} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminProductEditPage);
