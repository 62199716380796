import React, { useState } from 'react';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import MoreHoriz from '@material-ui/icons/MoreHoriz';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';
import StarRating from '../../StarRating';

import ReviewDetailsDialog from './ReviewDetailsDialog';

const AdminReviewList = ({ reviews, title, onReload, onChange }) => {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Table title={title} onChange={onChange} count={reviews && reviews.count}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'barberName' })}</TableCell>
            <TableCell>{f({ id: 'review' })}</TableCell>
            <TableCell>{f({ id: 'clientsClientName' })}</TableCell>
            <TableCell>{f({ id: 'details' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviews &&
            reviews.results &&
            reviews.results.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.employee.display_name}</TableCell>
                <TableCell>
                  <StarRating rating={row.review_stars} />
                </TableCell>
                <TableCell>{row.customer_name}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label={f({ id: 'details' })}
                    onClick={() => {
                      setSelectedBooking(row);
                      setShowDetailsDialog(true);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ReviewDetailsDialog
        open={showDetailsDialog}
        booking={selectedBooking}
        onCancel={() => {
          setShowDetailsDialog(false);
        }}
        onApprove={() => {
          setShowDetailsDialog(false);
          onReload();
        }}
        onDeny={() => {
          setShowDetailsDialog(false);
          onReload();
        }}
      />
    </>
  );
};

export default BaseHOC(AdminReviewList);
