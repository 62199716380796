import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const SubHeader = styled(Typography).attrs(() => ({
  variant: 'h5',
  noWrap: true,
}))`
  && {
    padding-bottom: 0.5rem;
  }
`;

export default SubHeader;
