import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextField,
  Box,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { getUser } from '../utils';
import { saveSettings, getOnboarding, getSettings } from '../actions';

const OnboardingBarberDialog = ({ open, onClose }) => {
  const shopId = getUser().shopMap[0].id;
  const { formatMessage: f, locale } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState('');
  const [taxReference, setTaxReference] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressTown, setAddressTown] = useState('');
  const [addressPostalCode, setAddressPostalCode] = useState('');
  const [addressCountry, setAddressCountry] = useState('England');

  useEffect(() => {
    if (settings) {
      setCompanyName(settings.company_name);
      setTaxReference(settings.tax_reference);

      if (settings.address) {
        setAddressLine1(settings.address.address_line_1 || '');
        setAddressLine2(settings.address.address_line_2 || '');
        setAddressTown(settings.address.town || '');
        setAddressPostalCode(settings.address.postal_code || '');
        setAddressCountry(
          settings.address.country ||
            (locale === 'hu' && 'Hungary') ||
            (locale === 'sk' && 'Slovakia') ||
            (locale === 'cs' && 'Czech Republic') ||
            'England',
        );
      }
    }
  }, [settings]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {f({ id: 'onboardingShopDialogDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <FormHelperText margin="dense">
          {f({ id: 'onboardingShopDialogTitle' })}
        </FormHelperText>
        <TextField
          margin="dense"
          id="name"
          label={f({ id: 'onboardingShopDialogOwnerName' })}
          inputProps={{
            style: {
              height: '2em',
              paddingTop: '1em',
            },
          }}
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />

        <TextField
          margin="dense"
          id="name"
          label={f({ id: 'onboardingShopDialogCompanyNumber' })}
          fullWidth
          value={taxReference}
          onChange={(e) => setTaxReference(e.target.value)}
        />
        <Box pt={2}>
          <FormHelperText margin="dense">
            {f({ id: 'onboardingShopDialogAddress' })}
          </FormHelperText>
          <TextField
            margin="dense"
            id="name"
            label={f({ id: 'onboardingShopDialogAddressLine1' })}
            fullWidth
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label={f({ id: 'onboardingShopDialogAddressLine2' })}
            fullWidth
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label={f({ id: 'onboardingShopDialogAddressCity' })}
            fullWidth
            value={addressTown}
            onChange={(e) => setAddressTown(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label={f({ id: 'onboardingShopDialogAddressPostcode' })}
            fullWidth
            value={addressPostalCode}
            onChange={(e) => setAddressPostalCode(e.target.value)}
          />
          <FormControl style={{ minWidth: 120, marginTop: '8px' }}>
            <InputLabel id="country-label">
              {f({ id: 'onboardingShopDialogAddressCountry' })}
            </InputLabel>
            <Select
              labelId="country-label"
              id="country-label"
              value={addressCountry}
              margin="dense"
              fullWidth
              onChange={(e) => setAddressCountry(e.target.value)}
            >
              <MenuItem value="Czech Republic">
                {f({ id: 'countryCzechRepublic' })}
              </MenuItem>
              <MenuItem value="England">{f({ id: 'countryEngland' })}</MenuItem>
              <MenuItem value="Hungary">{f({ id: 'countryHungary' })}</MenuItem>
              <MenuItem value="Northern Ireland">
                {f({ id: 'countryNorthernIreland' })}
              </MenuItem>
              <MenuItem value="Scotland">
                {f({ id: 'countryScotland' })}
              </MenuItem>
              <MenuItem value="Slovakia">
                {f({ id: 'countrySlovakia' })}
              </MenuItem>
              <MenuItem value="Wales">{f({ id: 'countryWales' })}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {f({ id: 'buttonCancel' })}
        </Button>
        <Button
          onClick={() => {
            const data = new FormData();
            data.append('companyName', companyName);
            data.append('taxReference', taxReference);
            data.append('address.addressLine1', addressLine1);
            data.append('address.addressLine2', addressLine2);
            data.append('address.town', addressTown);
            data.append('address.postalCode', addressPostalCode);
            data.append('address.country', addressCountry);
            data.append('postalAddressIsSame', true);

            dispatch(
              saveSettings({ f, shopId, settingsData: data, enqueueSnackbar }),
            ).then(() => {
              Promise.all([
                dispatch(getOnboarding()),
                dispatch(getSettings({ shopId, enqueueSnackbar })),
              ]);
              onClose();
            });
          }}
          color="primary"
          variant="contained"
        >
          {f({ id: 'buttonInput' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingBarberDialog;
