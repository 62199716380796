import React from 'react';

import { Paper, Grid } from '@material-ui/core';

import { StyledGrid } from './styles';
import PaperHeader from '../PaperHeader';

const PaperWithTitle = ({ title = '', children, helpText }) => {
  return (
    <Paper>
      <StyledGrid container spacing={0}>
        {title ? (
          <Grid item xs={12}>
            <PaperHeader title={title} helpText={helpText} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {children}
        </Grid>
      </StyledGrid>
    </Paper>
  );
};

export default PaperWithTitle;
