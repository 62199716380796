import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import PrimaryButton from '../../PrimaryButton';

const DeleteClientDialog = ({ open, onCancel, onConfirm }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogTitle>{f({ id: 'confirmDeleteTitle' })}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">
            {f({ id: 'clientsDeleteConfirmation' })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={onCancel}>
          {f({ id: 'buttonCancel' })}
        </PrimaryButton>
        <PrimaryButton danger onClick={onConfirm}>
          {f({ id: 'buttonDelete' })}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteClientDialog;
