import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  listTillClosings,
  getTillClosing,
  resetTillClosingList,
} from '../../../actions';
import { getDateFilterFromUrl } from '../../../utils';

import Header from '../../Header';
import SubHeader from '../../SubHeader';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminTillClosingsList from './AdminTillClosingsList';
import TillCloseNotesLeftInTillTable from '../Shared/TillCloseNotesLeftInTillTable';
import TillCloseDetailsTable from '../Shared/TillCloseDetailsTable';
import TillCloseSplitMissingTable from '../Shared/TillCloseSplitMissingTable';

import styles from '../styles';

const AdminTillClosingsDetailsPage = ({ enqueueSnackbar, closeDate }) => {
  const parsedDate = moment(closeDate, 'YYYY-MM-DD');
  const { formatMessage: f, locale } = useIntl();
  const dispatch = useDispatch();
  const tillClosings = useSelector((state) => state.tillClosings);
  const dailyTillClose = useSelector((state) => state.dailyTillClose);

  useEffect(() => {
    if (!dailyTillClose) {
      dispatch(
        getTillClosing({
          closeDate,
          shopId: getDateFilterFromUrl().shopIdList[0],
          enqueueSnackbar,
        }),
      );
    }
  }, [dailyTillClose, closeDate]);

  useEffect(() => {
    if (tillClosings?.length < 1) {
      dispatch(
        listTillClosings({
          startDate: parsedDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          endDate: parsedDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          shopId: getDateFilterFromUrl().shopIdList[0],
          onlyLatest: false,
          enqueueSnackbar,
        }),
      );
    }
  }, [tillClosings, closeDate]);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>{f({ id: 'appNavInputCloseTill' })}</Header>
          <SubHeader>
            {`${parsedDate
              .locale(locale)
              .format('YYYY-MM-DD')} (${parsedDate
              .locale(locale)
              .format('dddd')})`}
          </SubHeader>
        </Grid>
        <Grid item xs={12}>
          {/* Hiány kalkuláció */}
          <TillCloseSplitMissingTable dailyTillClose={dailyTillClose} />
        </Grid>
        <Grid item xs={12}>
          {/* Kassza forgalom */}
          <TillCloseDetailsTable dailyTillClose={dailyTillClose} />
        </Grid>
        <Grid item xs={12}>
          {/* Kasszában hagyott címletek */}
          <TillCloseNotesLeftInTillTable dailyTillClose={dailyTillClose} />
        </Grid>
        <Grid item xs={12}>
          {/* Napi zárások */}
          <AdminTillClosingsList tillClosings={tillClosings} singleDay />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(resetTillClosingList());
              window.history.back();
            }}
          >
            {f({ id: 'buttonBack' })}
          </Button>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminTillClosingsDetailsPage, styles);
