import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformanceTillShortage = () => {
  const reducedShortage = {};
  const tillShortage = useSelector((state) => state.tillShortage);
  tillShortage.map((row) => {
    if (Object.keys(reducedShortage).includes(row.employee.id.toString())) {
      reducedShortage[row.employee.id].amount += parseFloat(row.amount);
    } else {
      reducedShortage[row.employee.id] = {
        display_name: row.employee.display_name,
        amount: parseFloat(row.amount),
      };
    }
  });
  const { formatMessage: f } = useIntl();

  return (
    <Table title={f({ id: 'performanceShortages' })}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'createdBy' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'columnTitleBalance' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(reducedShortage).map((key) => (
          <TableRow key={key}>
            <TableCell>{reducedShortage[key].display_name}</TableCell>
            <TableCell>
              {parseFloat(reducedShortage[key].amount) > 0 ? null : '+'}
              <CurrencyFormat
                amount={-parseFloat(reducedShortage[key].amount)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformanceTillShortage);
