import React from 'react';
import { Tooltip, IconButton, Box } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { PaperHeader } from './styles';

const StyledPaperHeader = ({ title = '', helpText = '' }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <PaperHeader variant="h5" gutterBottom noWrap>
        {title}
      </PaperHeader>
      {helpText ? (
        <Tooltip
          title={helpText}
          PopperProps={{ style: { whiteSpace: 'pre-line' } }}
          enterTouchDelay={1}
          leaveTouchDelay={2000}
        >
          <IconButton aria-label={helpText} size="small">
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
};
export default StyledPaperHeader;
