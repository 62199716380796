import React from 'react';
import { PaperHeader } from './styles';

const StyledPaperSubHeader = ({ title = '' }) => {
  return (
    <PaperHeader variant="h6" gutterBottom noWrap>
      {title}
    </PaperHeader>
  );
};
export default StyledPaperSubHeader;
