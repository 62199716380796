import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import Header from '../../Header';
import Loader from '../../Loader';
import BaseHOC from '../../BaseHOC';

import { calculateSalaries } from '../../../actions';
import { getDateFilterFromUrl } from '../../../utils';

import DashboardLayout from '../../DashboardLayout';
import SalaryCalculationSalariesList from './SalaryCalculationSalariesList';

const SalaryCalculationAssignmentsPage = ({
  enqueueSnackbar,
  dispatch,
  uri,
}) => {
  const initShopIdList = getDateFilterFromUrl().shopIdList;
  const [resultsVisible, setResultsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage: f } = useIntl();

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'salarySalariesTooltip' })}>
            {f({ id: 'appNavSalarySalaries' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              setIsLoading(true);
              dispatch(
                calculateSalaries({
                  startDate,
                  endDate,
                  shopIdList,
                  enqueueSnackbar,
                }),
              )
                .then(() => {
                  setIsLoading(false);
                  setResultsVisible(true);
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }}
            initShopIdList={initShopIdList}
            skipInitCall
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : (
            resultsVisible && <SalaryCalculationSalariesList />
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  tipSummary: state.tipSummary,
});

export default BaseHOC(SalaryCalculationAssignmentsPage, null, mapStateToProps);
