import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { getNet, getEmploymentLabel, toPercentage } from '../../../utils';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformanceProductServicePerformance = () => {
  const getProductServiceSum = (item) =>
    parseFloat(item.service_sum_gift_card) +
    parseFloat(item.service_sum_card) +
    parseFloat(item.service_sum_cash) +
    parseFloat(item.product_sum_gift_card) +
    parseFloat(item.product_sum_card) +
    parseFloat(item.product_sum_cash);
  const productServicePerformance = useSelector(
    (state) => state.productServicePerformance,
  );
  const productServiceSum = productServicePerformance.reduce(
    (a, b) => a + getProductServiceSum(b),
    0,
  );
  const settings = useSelector((state) => state.settings);
  const { formatMessage: f } = useIntl();

  return (
    <Table title={f({ id: 'performanceProductServiceTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'createdBy' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'employmentKind' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodGiftcard' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodCard' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodCash' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'total' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceProductServicePersonalRatio' })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productServicePerformance.map((row) => (
          <TableRow key={row.name}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{getEmploymentLabel(row, f)}</TableCell>
            <TableCell>
              <CurrencyFormat
                amount={getNet(
                  parseFloat(row.product_sum_gift_card) +
                    parseFloat(row.service_sum_gift_card),
                  settings.country.currency,
                )}
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={
                  parseFloat(row.product_sum_gift_card) +
                  parseFloat(row.service_sum_gift_card)
                }
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={getNet(
                  parseFloat(row.product_sum_card) +
                    parseFloat(row.service_sum_card),
                  settings.country.currency,
                )}
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={
                  parseFloat(row.product_sum_card) +
                  parseFloat(row.service_sum_card)
                }
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={getNet(
                  parseFloat(row.product_sum_cash) +
                    parseFloat(row.service_sum_cash),
                  settings.country.currency,
                )}
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={
                  parseFloat(row.product_sum_cash) +
                  parseFloat(row.service_sum_cash)
                }
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat
                amount={getNet(
                  getProductServiceSum(row),
                  settings.country.currency,
                )}
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat amount={getProductServiceSum(row)} />
            </TableCell>
            <TableCell>
              {toPercentage(getProductServiceSum(row) / productServiceSum)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformanceProductServicePerformance);
