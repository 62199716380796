import { useIntl } from 'react-intl';
import { logout } from '../actions';

import BaseHOC from './BaseHOC';

const Logout = ({ dispatch, enqueueSnackbar }) => {
  const { formatMessage: f, locale } = useIntl();
  dispatch(logout(enqueueSnackbar, f, locale));
  return null;
};

export default BaseHOC(Logout);
