import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import FormControl from '../../FormControl';
import PrimaryButton from '../../PrimaryButton';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminServicesList from './AdminServicesList';

const AdminServicesPage = () => {
  const [searchText, setSearchText] = useState('');
  const { formatMessage: f, locale } = useIntl();

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'tooltipAdminServices' })}>
            {f({ id: 'appNavAdminShopServices' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <FormControl required>
            <TextField
              id="searchText"
              name="searchText"
              placeholder={f({ id: 'search' })}
              fullWidth
              value={searchText}
              margin="normal"
              onChange={(event) => setSearchText(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(`/${locale}/app/services/edit`, {
                state: { service: null },
              })
            }
          >
            {f({ id: 'buttonAdd' })}
          </PrimaryButton>
        </Grid>
        <Grid item xs={12}>
          <AdminServicesList searchText={searchText} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminServicesPage);
