import React from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

const ReportsSummaryCustomer = ({ customerSummary }) => {
  const sumGiftCard = customerSummary.reduce(
    (acc, value) => acc + value.gift_card,
    0,
  );
  const sumCard = customerSummary.reduce((acc, value) => acc + value.card, 0);
  const sumCash = customerSummary.reduce((acc, value) => acc + value.cash, 0);
  const { formatMessage: f } = useIntl();
  return (
    <Table title={f({ id: 'summaryCustomerTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'summaryCustomerSum' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerGifcard' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerCard' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerCash' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{sumGiftCard + sumCard + sumCash}</TableCell>
          <TableCell>{sumGiftCard}</TableCell>
          <TableCell>{sumCard}</TableCell>
          <TableCell>{sumCash}</TableCell>
        </TableRow>
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'summaryCustomerPerColleague' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerGifcard' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerCard' })}</TableCell>
          <TableCell>{f({ id: 'summaryCustomerCash' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customerSummary.map((row) => (
          <TableRow key={row.name}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.gift_card}</TableCell>
            <TableCell>{row.card}</TableCell>
            <TableCell>{row.cash}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  customerSummary: state.customerSummary,
});

export default BaseHOC(ReportsSummaryCustomer, null, mapStateToProps, null);
