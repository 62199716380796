import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import CirclePie from '../../CirclePie';

import BaseHOC from '../../BaseHOC';

const ChartDisplay = ({ label, value }) => {
  return (
    <Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
      <Box pt={2} px={4}>
        <CirclePie
          width={250}
          height={250}
          strokeWidth={28}
          percent={(value * 100).toFixed(0)}
          strokeColor="#408ae5"
        />
      </Box>
    </Box>
  );
};

const AdminStatsRatios = ({ bookingStats }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      flexDirection="row"
      flexWrap="wrap"
    >
      <ChartDisplay
        label={f({ id: 'statsRatioReturningCustomers' })}
        value={bookingStats.kpi.returning_customer_rate || 0}
      />
      <ChartDisplay
        label={f({ id: 'statsRatioOnlineBookings' })}
        value={bookingStats.kpi.online_booking_rate || 0}
      />
      <ChartDisplay
        label={f({ id: 'statsRatioBarberSelected' })}
        value={bookingStats.kpi.barber_selected_rate || 0}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  bookingStats: state.bookingStats,
});

export default BaseHOC(AdminStatsRatios, null, mapStateToProps);
