import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';

import { addSalaryRule } from '../../../actions';

import WideButton from '../../WideButton';
import PaperHeader from '../../PaperHeader';
import BaseHOC from '../../BaseHOC';

import { Paper } from './styles';

import FixSalaryRuleDialog from '../Shared/FixSalaryRuleDialog';
import PerformanceBasedSalaryRuleDialog from '../Shared/PerformanceBasedSalaryRuleDialog';
import TieredSalaryRuleDialog from '../Shared/TieredSalaryRuleDialog';
import TieredProgressiveSalaryRuleDialog from '../Shared/TieredProgressiveSalaryRuleDialog';
import DeductCardPaymentFromSalaryRuleDialog from '../Shared/DeductCardPaymentFromSalaryRuleDialog';

const SalaryCalculationRulesButtons = ({ addSalaryRule, enqueueSnackbar }) => {
  const [fixSalaryRuleDialogVisible, setFixSalaryRuleDialogVisible] = useState(
    false,
  );
  const [
    performanceBasedSalaryRuleDialogVisible,
    setPerformanceBasedSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredSalaryRuleDialogVisible,
    setTieredSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredProgressiveSalaryRuleDialog,
    setTieredProgressiveSalaryRuleDialog,
  ] = useState(false);
  const [
    deductCardPaymentFromSalaryRuleDialog,
    setDeductCardPaymentFromSalaryRuleDialog,
  ] = useState(false);

  const { formatMessage: f } = useIntl();

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaperHeader title={f({ id: 'salaryRulesAddNew' })} />
        </Grid>
        <Grid container item justify="center" alignItems="center" spacing={2}>
          <Grid item xs={9}>
            <WideButton onClick={() => setFixSalaryRuleDialogVisible(true)}>
              {f({ id: 'fixsalaryrule' })}
            </WideButton>
          </Grid>
          <Grid item xs={9}>
            <WideButton
              onClick={() => setPerformanceBasedSalaryRuleDialogVisible(true)}
            >
              {f({ id: 'performancebasedsalaryrule' })}
            </WideButton>
          </Grid>
          <Grid item xs={9}>
            <WideButton onClick={() => setTieredSalaryRuleDialogVisible(true)}>
              {f({ id: 'tieredsalaryrule' })}
            </WideButton>
          </Grid>
          <Grid item xs={9}>
            <WideButton
              onClick={() => setTieredProgressiveSalaryRuleDialog(true)}
            >
              {f({ id: 'tieredprogressivesalaryrule' })}
            </WideButton>
          </Grid>
          <Grid item xs={9}>
            <WideButton
              onClick={() => setDeductCardPaymentFromSalaryRuleDialog(true)}
            >
              {f({ id: 'deductcardpaymentfromsalaryrule' })}
            </WideButton>
          </Grid>
        </Grid>
      </Grid>
      <FixSalaryRuleDialog
        onCancel={() => {
          setFixSalaryRuleDialogVisible(false);
        }}
        onSubmit={(ruleParams) => {
          addSalaryRule(ruleParams, enqueueSnackbar, f);
          setFixSalaryRuleDialogVisible(false);
        }}
        dialogOpen={fixSalaryRuleDialogVisible}
      />
      <PerformanceBasedSalaryRuleDialog
        onCancel={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
        }}
        onSubmit={(ruleParams) => {
          addSalaryRule(ruleParams, enqueueSnackbar, f);
          setPerformanceBasedSalaryRuleDialogVisible(false);
        }}
        dialogOpen={performanceBasedSalaryRuleDialogVisible}
      />
      <TieredSalaryRuleDialog
        onCancel={() => {
          setTieredSalaryRuleDialogVisible(false);
        }}
        onSubmit={(ruleParams) => {
          addSalaryRule(ruleParams, enqueueSnackbar, f);
          setTieredSalaryRuleDialogVisible(false);
        }}
        dialogOpen={tieredSalaryRuleDialogVisible}
      />
      <TieredProgressiveSalaryRuleDialog
        onCancel={() => {
          setTieredProgressiveSalaryRuleDialog(false);
        }}
        onSubmit={(ruleParams) => {
          addSalaryRule(ruleParams, enqueueSnackbar, f);
          setTieredProgressiveSalaryRuleDialog(false);
        }}
        dialogOpen={tieredProgressiveSalaryRuleDialog}
      />
      <DeductCardPaymentFromSalaryRuleDialog
        onCancel={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
        }}
        onSubmit={(ruleParams) => {
          addSalaryRule(ruleParams, enqueueSnackbar, f);
          setDeductCardPaymentFromSalaryRuleDialog(false);
        }}
        dialogOpen={deductCardPaymentFromSalaryRuleDialog}
      />
    </Paper>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  addSalaryRule: (...args) => dispatch(addSalaryRule(...args)),
});

export default BaseHOC(
  SalaryCalculationRulesButtons,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
