import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const aggregateTips = (tipSummary) => {
  const reducedSummary = {};
  tipSummary.map((row) => {
    if (Object.keys(reducedSummary).includes(row.name)) {
      reducedSummary[row.name] +=
        parseFloat(row.card_tip_sum) + parseFloat(row.cash_tip_sum);
    } else {
      reducedSummary[row.name] =
        parseFloat(row.card_tip_sum) + parseFloat(row.cash_tip_sum);
    }
  });
  return reducedSummary;
};

const ReportsTipSummarySum = () => {
  const { formatMessage: f } = useIntl();
  const tipSummary = useSelector((state) => state.tipSummary);
  const [reducedSummary, setReducedSummary] = useState(
    aggregateTips(tipSummary),
  );

  useEffect(() => setReducedSummary(aggregateTips(tipSummary)), [tipSummary]);

  return (
    <Table title={f({ id: 'tipsAllTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell align="left">{f({ id: 'createdBy' })}</TableCell>
          <TableCell align="left">{f({ id: 'tip' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reducedSummary &&
          Object.keys(reducedSummary).map((key) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>
                <CurrencyFormat amount={parseFloat(reducedSummary[key])} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsTipSummarySum);
