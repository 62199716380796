import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminEmployeeEditForm from './AdminEmployeeEditForm';

const AdminEmployeeEditPage = () => {
  const [mode, setMode] = useState('add');
  const { employee } = window.history.state;
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (employee) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [employee]);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>
            {mode === 'edit'
              ? f({ id: 'employeeEdit' })
              : f({ id: 'employeeAdd' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminEmployeeEditForm employee={employee} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminEmployeeEditPage);
