import React, { useState, useEffect } from 'react';

import {
  Grid,
  Paper,
  InputLabel,
  Select,
  Input,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import {
  listSalaryRules,
  assignSalaryRule,
  getEmployees,
} from '../../../actions';
import { getUser } from '../../../utils';

import PaperHeader from '../../PaperHeader';
import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import FormControl from '../../FormControl';
import PaddedGrid from '../../PaddedGrid';
import BaseHOC from '../../BaseHOC';

const AssignSalaryCalculation = ({
  listSalaryRules,
  getEmployees,
  enqueueSnackbar,
  employees,
  salaryRules,
  assignSalaryRule,
}) => {
  const { shopMap } = getUser();
  const [employeeId, setEmployeeId] = useState('');
  const [salaryRuleId, setSalaryRuleId] = useState('');
  const [shopIdList, setShopIdList] = useState(shopMap.map((x) => x.id));

  const { formatMessage: f } = useIntl();

  const validate = () => {
    // TODO: FIX ME!
    console.log('FIX ME!');
    return true;
  };

  const resetForm = () => {
    setEmployeeId('');
    setSalaryRuleId('');
  };

  // Pass an empty aray to ensure it only runs once
  useEffect(() => {
    getEmployees();
    listSalaryRules({ enqueueSnackbar });
  }, []);

  return (
    <Paper>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <PaperHeader title={f({ id: 'salaryAssignmentsNew' })} />
        </Grid>
        <Grid item xs={12}>
          <PaddedGrid
            container
            spacing={2}
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <FormControl required>
                <InputLabel htmlFor="employeeId">
                  {f({ id: 'createdBy' })}
                </InputLabel>
                <Select
                  value={employeeId}
                  onChange={(event) => setEmployeeId(event.target.value)}
                  input={<Input name="employeeId" id="employeeId" />}
                >
                  {employees.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {shopMap.length > 1 ? (
              <Grid item xs={12}>
                <MultiShopSelector
                  shopIdList={shopIdList}
                  setShopIdList={setShopIdList}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {_.isEmpty(salaryRules) ? (
                <CircularProgress />
              ) : (
                <FormControl required>
                  <InputLabel htmlFor="salaryRuleId">
                    {f({ id: 'salaryRule' })}
                  </InputLabel>
                  <Select
                    value={salaryRuleId}
                    onChange={(event) => setSalaryRuleId(event.target.value)}
                    input={<Input name="salaryRuleId" id="salaryRuleId" />}
                  >
                    {salaryRules.results.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                onClick={() => {
                  if (validate()) {
                    assignSalaryRule(
                      {
                        shopIdList,
                        employeeId,
                        salaryRuleId,
                      },
                      enqueueSnackbar,
                      f,
                    );
                    resetForm();
                  }
                }}
              >
                {f({ id: 'salaryAssignmentsAssign' })}
              </PrimaryButton>
            </Grid>
          </PaddedGrid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  salaryRules: state.salaryRules,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployees: () => dispatch(getEmployees()),
  listSalaryRules: (...args) => dispatch(listSalaryRules(...args)),
  assignSalaryRule: (...args) => dispatch(assignSalaryRule(...args)),
});

export default BaseHOC(
  AssignSalaryCalculation,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
