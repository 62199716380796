import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import { getMiscTransactionStats, getMiscCategories } from '../../../actions';

import DashboardLayout from '../../DashboardLayout';
import AdminMiscTransactionsSummaryList from './AdminMiscTransactionsSummaryList';
import AdminMiscTransactionsCategoriesForm from './AdminMiscTransactionsCategoriesForm';

const AdminMiscTransactionsPage = ({ enqueueSnackbar, dispatch, uri }) => {
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    dispatch(getMiscCategories());
  }, []);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'miscTransactionTooptip' })}>
            {f({ id: 'appNavAdminShopMiscTransactions' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDateParam, endDateParam, shopIdList) => {
              dispatch(
                getMiscTransactionStats({
                  startDate: startDateParam,
                  endDate: endDateParam,
                  shopIdList,
                  enqueueSnackbar,
                }),
              );
              setFilterStartDate(startDateParam);
              setFilterEndDate(endDateParam);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminMiscTransactionsSummaryList />
        </Grid>
        <Grid item xs={12}>
          <AdminMiscTransactionsCategoriesForm
            startDate={filterStartDate}
            endDate={filterEndDate}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminMiscTransactionsPage);
