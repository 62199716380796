import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { useStaticQuery, graphql, navigate } from 'gatsby';

import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { huHU, enUS } from '@material-ui/core/locale';
import { getCurrentLangKey } from 'ptz-i18n';
import thunk from 'redux-thunk';
import { Router } from '@reach/router';

import { IntlProvider, useIntl } from 'react-intl';
import messagesHu from '../data/messages/hu';
import messagesEn from '../data/messages/en';
import messagesCs from '../data/messages/cs';
import messagesSk from '../data/messages/sk';
import messagesXx from '../data/messages/xx';

import salonzoneMessagesHu from '../data/messages/salonzone/hu';
import salonzoneMessagesEn from '../data/messages/salonzone/en';
import salonzoneMessagesCs from '../data/messages/salonzone/cs';
import salonzoneMessagesSk from '../data/messages/salonzone/sk';
import salonzoneMessagesXx from '../data/messages/salonzone/xx';

import rootReducer from '../reducers';
import PrivateRoute from './PrivateRoute';
import ManagerRoute from './ManagerRoute';
import AdminRoute from './AdminRoute';

import SEO from './seo';

import TransactionDetails from './Pages/TransactionDetails';
import UsageDetails from './Pages/UsageDetails';

import ReportsSummaryPage from './Pages/ReportsSummary/ReportsSummaryPage';
import ReportsTransactionsPage from './Pages/ReportsTransactions/ReportsTransactionsPage';
import CloseTillPage from './Pages/ReportsCloseTill/CloseTillPage';
import ReportsPerformancePage from './Pages/ReportsPerformance/ReportsPerformancePage';
import ReportsProductUsagePage from './Pages/ReportsProductUsage/ReportsProductUsagePage';
import ReportsTipSummaryPage from './Pages/ReportsTipSummary/ReportsTipSummaryPage';
import CalendarPage from './Pages/Calendar/CalendarPage';

import InputPurchasePage from './Pages/InputPurchase/InputPurchasePage';
import InputMiscTransactionPage from './Pages/InputMiscTransaction/InputMiscTransactionPage';
import InputProductUsePage from './Pages/InputProductUse/InputProductUsePage';

import AdminSettingsPage from './Pages/AdminSettings/AdminSettingsPage';
import AdminEmployeesPage from './Pages/AdminEmployees/AdminEmployeesPage';
import AdminEmployeeEditPage from './Pages/AdminEmployees/AdminEmployeeEditPage';
import AdminServicesPage from './Pages/AdminServices/AdminServicesPage';
import AdminServiceEditPage from './Pages/AdminServices/AdminServiceEditPage';
import AdminProductsPage from './Pages/AdminProducts/AdminProductsPage';
import AdminProductEditPage from './Pages/AdminProducts/AdminProductEditPage';
import AdminTillClosingsPage from './Pages/AdminTillClosings/AdminTillClosingsPage';
import AdminTillClosingsDetailsPage from './Pages/AdminTillClosings/AdminTillClosingsDetailsPage';
import AdminProductInventoryPage from './Pages/AdminProductInventory/AdminProductInventoryPage';
import AdminMiscTransactionsPage from './Pages/AdminMiscTransactions/AdminMiscTransactionsPage';
import AdminProductInventoryMultiInputPage from './Pages/AdminProductInventory/AdminProductInventoryMultiInputPage';
import AdminProductInventoryCheckInputPage from './Pages/AdminProductInventory/AdminProductInventoryCheckInputPage';
import AdminInventoryInputDetailsPage from './Pages/AdminProductInventory/AdminInventoryInputDetailsPage';
import AdminSchedulesPage from './Pages/AdminSchedules/AdminSchedulesPage';

import AdminBookingSettingsPage from './Pages/AdminBookingSettings/AdminBookingSettingsPage';
import AdminBookingSettingsBookingWebsitePage from './Pages/AdminBookingSettings/AdminBookingSettingsBookingWebsitePage';
import AdminClientsPage from './Pages/AdminClients/AdminClientsPage';
import AdminReviewsPage from './Pages/AdminReviews/AdminReviewsPage';
import AdminStatsPage from './Pages/AdminStats/AdminStatsPage';

import SalaryCalculationRulesPage from './Pages/SalaryCalculationRules/SalaryCalculationRulesPage';
import SalaryCalculationDeductionsPage from './Pages/SalaryCalculationDeductions/SalaryCalculationDeductionsPage';
import SalaryCalculationAssignmentsPage from './Pages/SalaryCalculationAssignments/SalaryCalculationAssignmentsPage';
import SalaryCalculationSalariesPage from './Pages/SalaryCalculationSalaries/SalaryCalculationSalariesPage';

import Login from './Login';
import Logout from './Logout';

import mixpanel from '../mixpanel';
import { getUser, isBrowser, isSalonzone } from '../utils';

import palette from '../palette';

const configureStore = (preloadedState = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window;

  let composeEnhancers = compose;

  if (windowGlobal) {
    const reduxDevTools =
      // eslint-disable-next-line
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

    if (reduxDevTools) {
      composeEnhancers = reduxDevTools;
    }
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

const store = configureStore();

const NotFound = ({ location }) => {
  if (!isBrowser) return null;

  const { locale } = useIntl();
  const user = getUser();

  if (!user && location.pathname !== `${locale}/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/${locale}/app/login`);
    return null;
  }

  if (location.pathname.startsWith('/app/')) {
    navigate(`/${locale}${location.pathname}`);
    return null;
  }

  if (
    user.shopMap.filter((x) => x.management).length > 0 &&
    ((user &&
      user.shopMap &&
      user.shopMap.filter((x) => x.booking).length < 1) ||
      user.role === 'manager' ||
      user.role === 'admin')
  ) {
    navigate(`/${locale}/app/summary`);
    return null;
  }

  if (user.shopMap.filter((x) => x.booking).length > 0) {
    navigate(`/${locale}/app/calendar`);
    return null;
  }

  navigate(`/${locale}/app/logout`);
  return null;
};

const Root = ({ location }) => {
  const url = location.pathname;

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);

  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const user = getUser();

  // // TODO: Move this to login / token refresh
  // if (user) {
  //   mixpanel.identify(user.userId);
  //   mixpanel.people.set({
  //     $name: user.username || '',
  //     $email: user.email || '',
  //     shopId: user.shopId || '',
  //     shopName: user.shopName || '',
  //     role: user.role || '',
  //   });
  // }

  React.useEffect(() => {
    if (user) {
      mixpanel.track('PageView', {
        path: location.pathname,
        shop: user.shopName,
      });
    }
  }, [location, user]);

  const shopDefaultLang =
    (user && user.shopMap[0].language_key) || defaultLangKey;

  const langKey = getCurrentLangKey(langs, shopDefaultLang, url);

  const messages = {
    hu: isSalonzone ? salonzoneMessagesHu : messagesHu,
    en: isSalonzone ? salonzoneMessagesEn : messagesEn,
    sk: isSalonzone ? salonzoneMessagesSk : messagesSk,
    cs: isSalonzone ? salonzoneMessagesCs : messagesCs,
    xx: isSalonzone ? salonzoneMessagesXx : messagesXx,
  }[langKey];

  const theme = createTheme(
    {
      palette,
      typography: {
        useNextVariants: true,
      },
    },
    langKey === 'hu' ? huHU : enUS,
  );

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
          <IntlProvider locale={langKey} messages={messages}>
            <SEO title="Dashboard" />
            <Router basepath={`/${langKey}/app`}>
              {/* Report routes */}
              <PrivateRoute
                path="/summary"
                component={ReportsSummaryPage}
                managementOnly
              />
              <PrivateRoute
                path="/transactions"
                component={ReportsTransactionsPage}
                managementOnly
              />
              <PrivateRoute
                path="/transactions/:transactionId"
                component={TransactionDetails}
                managementOnly
              />
              <PrivateRoute
                path="/close-till"
                component={CloseTillPage}
                managementOnly
              />
              <PrivateRoute
                path="/performance"
                component={ReportsPerformancePage}
                managementOnly
              />
              <PrivateRoute
                path="/usages"
                component={ReportsProductUsagePage}
                managementOnly
              />
              <PrivateRoute
                path="/usages/:usageId"
                component={UsageDetails}
                managementOnly
              />
              <PrivateRoute
                path="/tips"
                component={ReportsTipSummaryPage}
                managementOnly
              />
              <PrivateRoute
                path="/calendar"
                component={CalendarPage}
                bookingOnly
              />
              {/* Input routes */}
              <PrivateRoute
                path="/purchase"
                component={InputPurchasePage}
                managementOnly
              />
              <PrivateRoute
                path="/other-transaction"
                component={InputMiscTransactionPage}
                managementOnly
              />
              <PrivateRoute
                path="/product-use"
                component={InputProductUsePage}
                managementOnly
              />
              {/* Admin routes */}
              <ManagerRoute path="/settings" component={AdminSettingsPage} />
              <ManagerRoute path="/employees" component={AdminEmployeesPage} />
              <ManagerRoute
                path="/employees/edit"
                component={AdminEmployeeEditPage}
              />
              <ManagerRoute path="/services" component={AdminServicesPage} />
              <ManagerRoute
                path="/services/edit"
                component={AdminServiceEditPage}
              />
              <ManagerRoute
                path="/products"
                component={AdminProductsPage}
                managementOnly
              />
              <ManagerRoute
                path="/products/edit"
                component={AdminProductEditPage}
                managementOnly
              />
              <ManagerRoute
                path="/till-closings"
                component={AdminTillClosingsPage}
                managementOnly
              />
              <ManagerRoute
                path="/till-closings/:closeDate"
                component={AdminTillClosingsDetailsPage}
                managementOnly
              />
              <ManagerRoute
                path="/product-inventory"
                component={AdminProductInventoryPage}
                managementOnly
              />
              <ManagerRoute
                path="/misc-transactions"
                component={AdminMiscTransactionsPage}
                managementOnly
              />
              <ManagerRoute
                path="/product-inventory-multi-input"
                component={AdminProductInventoryMultiInputPage}
                managementOnly
              />
              <ManagerRoute
                path="/product-inventory-check-input"
                component={AdminProductInventoryCheckInputPage}
                managementOnly
              />
              <ManagerRoute
                path="/inventory-input-list/edit"
                component={AdminInventoryInputDetailsPage}
                managementOnly
              />
              {/* Admin booking settings */}
              <ManagerRoute
                path="/admin-schedules"
                component={AdminSchedulesPage}
                bookingOnly
              />
              <ManagerRoute
                path="/booking-settings"
                component={AdminBookingSettingsPage}
                bookingOnly
              />
              <ManagerRoute
                path="/clients"
                component={AdminClientsPage}
                bookingOnly
              />
              <ManagerRoute
                path="/reviews"
                component={AdminReviewsPage}
                bookingOnly
              />
              <ManagerRoute
                path="/stats"
                component={AdminStatsPage}
                bookingOnly
              />
              <ManagerRoute
                path="/booking-website"
                component={AdminBookingSettingsBookingWebsitePage}
                bookingOnly
              />
              {/* Salary routes */}
              <AdminRoute
                path="/salary-calculation-rules"
                component={SalaryCalculationRulesPage}
                managementOnly
              />
              <AdminRoute
                path="/salary-calculation-assignments"
                component={SalaryCalculationAssignmentsPage}
                managementOnly
              />
              <AdminRoute
                path="/salary-calculation-salaries"
                component={SalaryCalculationSalariesPage}
                managementOnly
              />
              <AdminRoute
                path="/salary-calculation-deductions"
                component={SalaryCalculationDeductionsPage}
                managementOnly
              />
              {/* Other routes */}
              <Login path="/login" />
              <Logout path="/logout" />
              <NotFound default />
            </Router>
          </IntlProvider>
        </Provider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default Root;
