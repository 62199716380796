import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

const BoldCell = styled(TableCell)`
  && {
    font-weight: 800;
  }
`;

export default BoldCell;
