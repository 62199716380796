import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import ReportsSummaryRevenue from './ReportsSummaryRevenue';
import ReportsSummaryCustomer from './ReportsSummaryCustomer';
import ReportsSummaryMiscCash from './ReportsSummaryMiscCash';
import ReportsSummaryUsage from './ReportsSummaryUsage';
import ReportsSummarySales from './ReportsSummarySales';

import {
  getRevenueSummary,
  getCustomerSummary,
  getMiscSalesSummary,
  getUsageSummary,
  getSalesSummary,
} from '../../../actions';

const ReportsSummaryPage = ({ enqueueSnackbar, uri }) => {
  const paginationLimit = 10;
  const dispatch = useDispatch();
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterShopIdList, setFilterShopIdList] = useState(null);
  const { formatMessage: f } = useIntl();

  const handleMiscSalesSummaryChange = ({ offset, limit, search }) => {
    dispatch(
      getMiscSalesSummary({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  const handleUsageSummaryChange = ({ offset, limit, search }) => {
    dispatch(
      getUsageSummary({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  const handleSummarySalesChange = ({
    offset,
    limit,
    search,
    discountFilter,
  }) => {
    dispatch(
      getSalesSummary({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        discountFilter,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>{f({ id: 'appNavReportSummary' })}</Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              const callArgs = {
                startDate,
                endDate,
                shopIdList,
                enqueueSnackbar,
                pagination: {
                  limit: paginationLimit,
                },
              };
              dispatch(getRevenueSummary(callArgs));
              dispatch(getCustomerSummary(callArgs));
              dispatch(getMiscSalesSummary(callArgs));
              dispatch(getUsageSummary(callArgs));
              dispatch(getSalesSummary(callArgs));

              setFilterStartDate(startDate);
              setFilterEndDate(endDate);
              setFilterShopIdList(shopIdList);
            }}
            initSameDay
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsSummaryRevenue />
        </Grid>
        <Grid item xs={12}>
          <ReportsSummaryCustomer />
        </Grid>
        <Grid item xs={12}>
          <ReportsSummaryMiscCash onChange={handleMiscSalesSummaryChange} />
        </Grid>
        <Grid item xs={12}>
          <ReportsSummaryUsage onChange={handleUsageSummaryChange} />
        </Grid>
        <Grid item xs={12}>
          <ReportsSummarySales onChange={handleSummarySalesChange} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(ReportsSummaryPage);
