import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminSettingsForm from './AdminSettingsForm';

const InputMiscTransactionPage = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>{f({ id: 'appNavAdminShopSettings' })}</Header>
        </Grid>
        <Grid item xs={12}>
          <AdminSettingsForm />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(InputMiscTransactionPage);
