import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';

import { getNet, getEmploymentLabel } from '../../../utils';

import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformancePerformanceStats = () => {
  const { formatMessage: f } = useIntl();
  const performanceStats = useSelector((state) => state.performanceStats);
  const settings = useSelector((state) => state.settings);
  return (
    <Table title={f({ id: 'performanceStatsTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'createdBy' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'employmentKind' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceStatsDaysWorked' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceStatsCustomerCount' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceStatsProductsSold' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceStatsDailyAvgCustomerCount' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceStatsDailyAvgProductSoldCount' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'performanceStatsDailyAvgCustomerValue' })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">{f({ id: 'net' })}</TableCell>
          <TableCell align="center">{f({ id: 'gross' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>{f({ id: 'mainAvg' })}</TableCell>
          <TableCell>
            {(
              performanceStats.reduce((a, b) => a + b.working_days_count, 0) /
              performanceStats.length
            ).toFixed(2)}
          </TableCell>
          <TableCell>
            {(
              performanceStats.reduce((a, b) => a + b.customers_count, 0) /
              performanceStats.length
            ).toFixed(2)}
          </TableCell>
          <TableCell>
            {(
              performanceStats.reduce((a, b) => a + b.sold_products_count, 0) /
              performanceStats.length
            ).toFixed(2)}
          </TableCell>
          <TableCell>
            {(
              performanceStats.reduce((a, b) => a + b.avg_daily_customers, 0) /
              performanceStats.length
            ).toFixed(2)}
          </TableCell>
          <TableCell>
            {(
              performanceStats.reduce(
                (a, b) => a + b.avg_daily_sold_products,
                0,
              ) / performanceStats.length
            ).toFixed(2)}
          </TableCell>
          <TableCell>
            <CurrencyFormat
              amount={getNet(
                performanceStats.reduce(
                  (a, b) => a + parseFloat(b.avg_customer_value),
                  0,
                ) / performanceStats.length,
                settings.country.currency,
              ).toFixed(2)}
            />
          </TableCell>
          <TableCell>
            <CurrencyFormat
              amount={(
                performanceStats.reduce(
                  (a, b) => a + parseFloat(b.avg_customer_value),
                  0,
                ) / performanceStats.length
              ).toFixed(2)}
            />
          </TableCell>
        </TableRow>
        {performanceStats.map((row) => (
          <TableRow key={row.name}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{getEmploymentLabel(row, f)}</TableCell>
            <TableCell>{row.working_days_count}</TableCell>
            <TableCell>{row.customers_count}</TableCell>
            <TableCell>{row.sold_products_count}</TableCell>
            <TableCell>{row.avg_daily_customers}</TableCell>
            <TableCell>{row.avg_daily_sold_products}</TableCell>
            <TableCell>
              <CurrencyFormat
                amount={getNet(
                  row.avg_customer_value,
                  settings.country.currency,
                )}
              />
            </TableCell>
            <TableCell>
              <CurrencyFormat amount={row.avg_customer_value} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformancePerformanceStats);
