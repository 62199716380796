import React from 'react';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import { Link as RouterLink } from 'gatsby';
import { ListItem } from './styles';

function NavListItem(props) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  return (
    <ListItem button component={renderLink} onClick={() => onClick()}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText inset primary={primary} />
    </ListItem>
  );
}

export default NavListItem;
