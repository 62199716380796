import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useIntl } from 'react-intl';

import {
  saveProduct,
  deleteProduct,
  addProduct,
  getProducts,
} from '../../../actions';
import { isPositiveInt, getUser } from '../../../utils';

import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

const AdminProductEditForm = ({
  enqueueSnackbar,
  saveProduct,
  addProduct,
  deleteProduct,
  getProducts,
  products,
  product,
}) => {
  const { shopMap } = getUser();
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = useState('');
  const [price, setPrice] = useState(0);
  const [priceError, setPriceError] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [mode, setMode] = useState('add');
  const [shopIdList, setShopIdList] = useState(
    (product && [product.shop_id]) || shopMap.map((x) => x.id),
  );
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (product) {
      setId(product.id);
      setTitle(product.title);
      setShopIdList([product.shop_id]);
      setBarcode(product.barcode);
      setPrice(parseInt(product.price, 10));
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [product]);

  const handleSave = () => {
    let error = false;

    if (title === '') {
      setTitleError(true);
      error = true;
    } else {
      setTitleError(false);
    }

    if (!isPositiveInt(price)) {
      setPriceError(true);
      error = true;
    } else {
      setPriceError(false);
    }

    if (
      !id &&
      products.filter((x) => x.title === title && x.shop_id === shopIdList[0])
        .length > 0
    ) {
      setTitleError(true);
      setTitleErrorMessage(f({ id: 'productAlreadyExists' }));
      error = true;
    } else {
      setTitleError(false);
    }

    if (error) {
      return;
    }

    const productData = {
      title,
      price,
      barcode,
      shopId: shopIdList[0],
    };

    if (mode === 'edit') {
      saveProduct(id, productData, enqueueSnackbar, f);
    } else {
      addProduct(productData, enqueueSnackbar, f);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <TextField
            helperText={titleErrorMessage}
            error={titleError}
            required
            id="title"
            name="title"
            label={f({ id: 'name' })}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Box>
      </Grid>
      {shopMap.length > 1 ? (
        <Grid item xs={12}>
          <Box pt={8}>
            <MultiShopSelector
              disabled={mode === 'edit'}
              shopIdList={shopIdList}
              setShopIdList={setShopIdList}
              singleShopSelect
            />
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box pt={8}>
          <TextField
            error={priceError}
            required
            id="price"
            name="price"
            label={f({ id: 'price' })}
            value={price}
            onChange={(event) => setPrice(event.target.value)}
            type="number"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
          <TextField
            id="barcode"
            name="barcode"
            label={f({ id: 'barcode' })}
            value={barcode}
            onChange={(event) => setBarcode(event.target.value)}
          />
          <Tooltip
            title={f({ id: 'barcodeTooltip' })}
            enterTouchDelay={1}
            leaveTouchDelay={2000}
          >
            <HelpOutlineOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt={8}>
          <PrimaryButton
            withmargin="true"
            onClick={() => window.history.back()}
          >
            {f({ id: 'buttonBack' })}
          </PrimaryButton>
          <PrimaryButton withmargin="true" onClick={() => handleSave()}>
            {f({ id: 'buttonSave' })}
          </PrimaryButton>
          {mode === 'edit' ? (
            <PrimaryButton
              withmargin="true"
              danger
              onClick={() => setDeleteConfirmationOpen(true)}
            >
              {f({ id: 'buttonDelete' })}
            </PrimaryButton>
          ) : (
            ''
          )}
        </Box>
        <Dialog
          open={deleteConfirmationOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {f({ id: 'confirmDeleteTitle' })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {f({ id: 'confirmDeleteConfirmProduct' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={() => setDeleteConfirmationOpen(false)}>
              {f({ id: 'buttonCancel' })}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => deleteProduct(id, enqueueSnackbar, f)}
              danger
            >
              {f({ id: 'buttonDelete' })}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
});

const mapDispatchToProps = (dispatch) => ({
  addProduct: (...args) => dispatch(addProduct(...args)),
  saveProduct: (...args) => dispatch(saveProduct(...args)),
  deleteProduct: (...args) => dispatch(deleteProduct(...args)),
  getProducts: (...args) => dispatch(getProducts(...args)),
});

export default BaseHOC(
  AdminProductEditForm,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
