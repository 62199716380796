import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getUser } from '../../../utils';
import { deleteSalaryRule } from '../../../actions';

import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

const FixSalaryRuleDialog = ({
  dialogOpen,
  onSubmit,
  onCancel,
  params,
  deleteSalaryRule,
  enqueueSnackbar,
}) => {
  const { shopMap } = getUser();
  const [ruleName, setRuleName] = useState('');
  const [periodUnit, setPeriodUnit] = useState('');
  const [amount, setAmount] = useState('');
  const [shopIdList, setShopIdList] = useState(shopMap.map((x) => x.id));
  const settings = useSelector((state) => state.settings);
  const isReadOnly = params !== undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { formatMessage: f } = useIntl();

  const DAILY_OR_MONTHLY = {
    day: f({ id: 'daily' }),
    month: f({ id: 'monthly' }),
  };

  const validate = () => {
    // TODO: FIX ME!
    console.log('FIX ME!');
    return true;
  };

  useEffect(() => {
    setRuleName((params && params.name) || '');
    setPeriodUnit((params && params.period_unit) || '');
    setAmount((params && params.amount) || '');
    setShopIdList((params && params.shops) || shopMap.map((x) => x.id));
  }, [params]);

  const reset = () => {
    setPeriodUnit('');
    setAmount('');
    setRuleName('');
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialogOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>{f({ id: 'salaryFixTitle' })}</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="ruleName"
              name="ruleName"
              label={f({ id: 'salaryName' })}
              margin="none"
              value={ruleName}
              onChange={(event) => setRuleName(event.target.value)}
            />
          </Grid>
          {shopMap.length > 1 ? (
            <Grid item xs={12}>
              <MultiShopSelector
                disabled={isReadOnly}
                shopIdList={shopIdList}
                setShopIdList={setShopIdList}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl required>
              <InputLabel htmlFor="periodUnit">
                {f({ id: 'salaryBase' })}
              </InputLabel>
              <Select
                value={periodUnit}
                disabled={isReadOnly}
                onChange={(event) => setPeriodUnit(event.target.value)}
                input={<Input name="periodUnit" id="periodUnit" />}
              >
                {Object.keys(DAILY_OR_MONTHLY).map((key) => (
                  <MenuItem key={key} value={key}>
                    {DAILY_OR_MONTHLY[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="amount"
              name="amount"
              label={f({ id: 'salaryAmount' })}
              margin="none"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
              type="number"
              InputProps={
                (settings.country.currency === 'Ft' && {
                  endAdornment: (
                    <InputAdornment position="end">Ft</InputAdornment>
                  ),
                }) ||
                (settings.country.currency === 'GBP' && {
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }) ||
                (settings.country.currency === 'EUR' && {
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }) ||
                (settings.country.currency === 'CZK' && {
                  startAdornment: (
                    <InputAdornment position="end">Kč</InputAdornment>
                  ),
                })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => {
            reset();
            onCancel();
          }}
        >
          {f({ id: 'buttonCancel' })}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={isReadOnly}
          onClick={() => {
            if (validate()) {
              onSubmit({
                ruleType: 'fixsalaryrule',
                data: {
                  name: ruleName,
                  periodUnit,
                  amount,
                  shopIdList,
                },
              });
              reset();
            }
          }}
        >
          {f({ id: 'buttonConfirm' })}
        </PrimaryButton>
        {isReadOnly ? (
          <PrimaryButton
            withmargin="true"
            danger
            onClick={() => {
              deleteSalaryRule(params.id, enqueueSnackbar, f);
              reset();
              onCancel();
            }}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  deleteSalaryRule: (...args) => dispatch(deleteSalaryRule(...args)),
});

export default BaseHOC(
  FixSalaryRuleDialog,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
