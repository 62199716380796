import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import BaseHOC from './BaseHOC';
import CurrencyFormat from './CurrencyFormat';
import { getServices, getProducts } from '../actions';
import { getRounding, getSign, percentageToNormal } from '../utils';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const getDiscountedRowPrice = (item) =>
  Math.round(
    100 *
      (item.quantity *
        parseFloat(item.price) *
        percentageToNormal(item.discount_rate) -
        parseFloat(item.discount_amount) +
        parseFloat(item.misc_sale_amount)),
  ) / 100;

const PurchasePreviewTable = ({ classes, purchaseDetails }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const subtotal = (items) =>
    items.map(getDiscountedRowPrice.bind(this)).reduce((sum, i) => sum + i, 0);

  const getItemName = (item) =>
    item.gift_card ? `${f({ id: 'kindGiftcard' })}: ${item.title}` : item.title;

  useEffect(() => {
    dispatch(getServices());
    dispatch(getProducts());
  }, []);

  const { items, tip } = purchaseDetails;
  const paymentMethod = purchaseDetails.payment_method;
  const invoiceSubtotal = paymentMethod === 'giftcard' ? 0 : subtotal(items);
  const invoiceRounding =
    paymentMethod === 'cash'
      ? getRounding(invoiceSubtotal, settings && settings.country.currency) -
        invoiceSubtotal
      : 0;
  const invoiceTotal = invoiceSubtotal + invoiceRounding;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'columnTitleGoods' })}</TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleUnitPrice' })}
            </TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleQuantity' })}
            </TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleDiscount' })}
            </TableCell>
            <TableCell align="right">{f({ id: 'miscSaleAmount' })}</TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleRowSum' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{getItemName(row)}</TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={row.price} />
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                {parseInt(row.discount_amount, 0) ? (
                  <CurrencyFormat amount={row.discount_amount} />
                ) : parseFloat(row.discount_rate) ? (
                  `${row.discount_rate} %`
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={row.misc_sale_amount} />
                {row.misc_sale_amount && ` (${row.misc_sale_comment})`}
              </TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={getDiscountedRowPrice(row)} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell>{f({ id: 'total' })}</TableCell>
            <TableCell align="right">
              <CurrencyFormat amount={invoiceSubtotal} />
            </TableCell>
          </TableRow>
          {settings &&
          settings.country.currency &&
          settings.country.currency === 'Ft' ? (
            <>
              <TableRow>
                <TableCell>{f({ id: 'saleItemTableRounding' })}</TableCell>
                <TableCell align="right">
                  {getSign(invoiceRounding)}
                  <CurrencyFormat amount={invoiceRounding} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{f({ id: 'saleItemTableTotal' })}</TableCell>
                <TableCell align="right">
                  <CurrencyFormat amount={invoiceTotal} />
                </TableCell>
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell>{f({ id: 'tip' })}</TableCell>
            <TableCell align="right">
              <CurrencyFormat amount={tip} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{f({ id: 'purchaseTableToPay' })}</TableCell>
            <TableCell align="right">
              <CurrencyFormat
                amount={parseFloat(invoiceTotal) + parseFloat(tip)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BaseHOC(PurchasePreviewTable, styles);
