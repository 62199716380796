import React from 'react';

import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

const AdminProductInventoryList = ({ onChange, productInventory }) => {
  const { formatMessage: f } = useIntl();

  return (
    <Table title={f({ id: 'inventoryTableTitle' })} onChange={onChange}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {f({ id: 'inventoryProductName' })}
          </TableCell>
          <TableCell align="left">
            {f({ id: 'inventoryQuantityUsed' })}
          </TableCell>
          <TableCell align="left">
            {f({ id: 'inventoryQuantitySold' })}
          </TableCell>
          <TableCell align="left">
            {f({ id: 'inventoryQuantityInput' })}
          </TableCell>
          <TableCell align="left">
            {f({ id: 'inventoryQuantityBalance' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productInventory.map((row) => (
          <TableRow key={row.base_id}>
            <TableCell>{row.title}</TableCell>
            <TableCell>{row.usage_sum || 0}</TableCell>
            <TableCell>{row.sales_sum || 0}</TableCell>
            <TableCell>{row.inventory_input_sum || 0}</TableCell>
            <TableCell>{row.inventory_sum || 0}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(AdminProductInventoryList);
