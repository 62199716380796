import React from 'react';
import { useSelector } from 'react-redux';

import { ccyFormat } from '../utils';

function CurrencyFormat({ amount, bankNotesAndCoins }) {
  const settings = useSelector((state) => state.settings);

  return (
    (settings && settings.country && (
      <>{ccyFormat(amount, settings.country.currency, bankNotesAndCoins)}</>
    )) ||
    ''
  );
}

export default CurrencyFormat;
