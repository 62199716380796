import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

import { getEmployees } from '../../../actions';
import { getUser } from '../../../utils';

const AdminEmployeesList = ({ employees, searchText, dispatch }) => {
  const { shopMap } = getUser();
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const { formatMessage: f, locale } = useIntl();

  const employmentKindMap = [
    {
      value: 'employee',
      label: f({ id: 'employmentKindEmployee' }),
    },
    {
      value: 'contractor',
      label: f({ id: 'employmentKindContractor' }),
    },
  ];

  const roleMap = [
    {
      value: 'barber',
      label: f({ id: 'employmentRoleBarber' }),
      active: true,
    },
    {
      value: 'manager',
      label: f({ id: 'employmentRoleManager' }),
      active: true,
    },
    {
      value: 'manager',
      label: f({ id: 'employmentRoleManager' }), // TODO: Fix in database
      active: false,
    },
  ];

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  useEffect(
    () =>
      setFilteredEmployees(
        employees.filter((x) =>
          x.display_name.toUpperCase().includes(searchText.toUpperCase()),
        ) || [],
      ),
    [searchText, employees],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'createdBy' })}</TableCell>
          {shopMap.length > 1 ? (
            <TableCell>{f({ id: 'shop' })}</TableCell>
          ) : null}
          <TableCell>{f({ id: 'employmentKind' })}</TableCell>
          <TableCell>{f({ id: 'employeeRole' })}</TableCell>
          <TableCell>{f({ id: 'settings' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredEmployees.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.display_name}</TableCell>
            {shopMap.length > 1 ? (
              <TableCell>
                {row.shops
                  .filter((shopId) => shopMap.find((x) => x.id === shopId))
                  .map((shopId) => shopMap.find((x) => x.id === shopId).name)
                  .join(', ')}
              </TableCell>
            ) : null}
            <TableCell>
              {
                employmentKindMap.find((x) => x.value === row.employment_kind)
                  .label
              }
            </TableCell>
            <TableCell>
              {roleMap.find((x) => x.value === row.role).label}
            </TableCell>
            <TableCell>
              <IconButton
                aria-label={f({ id: 'settings' })}
                onClick={() =>
                  navigate(`/${locale}/app/employees/edit`, {
                    state: { employee: row },
                  })
                }
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
});

export default BaseHOC(AdminEmployeesList, null, mapStateToProps);
