import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Box,
  Typography,
} from '@material-ui/core';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

import { getUser } from '../utils';
import getTemplateUk from '../policyTemplateUk.html';
import getTemplateHu from '../policyTemplate.html';

import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { saveSettings, getOnboarding, getSettings } from '../actions';

const OnboardingPolicyDialog = ({ open, onClose }) => {
  const shopId = getUser().shopMap[0].id;
  const { formatMessage: f, locale } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const [policyCity, setPolicyCity] = useState('');
  const [policyDate, setPolicyDate] = useState(moment());
  const [
    generatePolicyButtonEnabled,
    setGeneratePolicyButtonEnabled,
  ] = useState(true);
  const [showPolicyWarning, setShowPolicyWarning] = useState(false);

  useEffect(() => {
    if (settings && settings.address && settings.address.town) {
      setPolicyCity(settings.address.town);
    }
  }, [settings]);

  const getTemplate = locale === 'hu' ? getTemplateHu : getTemplateUk;

  const generatePolicy = () => {
    if (settings) {
      const policy = getTemplate({
        ...settings,
        companyName: settings.company_name,
        taxReference: settings.tax_reference,
        address: {
          addressLine1: settings.address.address_line_1,
          addressLine2: settings.address.address_line_2,
          town: settings.address.town,
          postalCode: settings.address.postal_code,
        },
        bookingUrl: settings.booking_url,
        email: settings.email,
        policyDate: policyDate.format('YYYY-MM-DD'),
        contactEmail: settings.contact_email,
      });
      const data = new FormData();
      data.append('policy.body', policy);
      dispatch(
        saveSettings({ f, shopId, settingsData: data, enqueueSnackbar }),
      ).then(() => {
        dispatch(getOnboarding());
        dispatch(getSettings({ shopId, enqueueSnackbar }));
      });
      onClose();
    }
  };

  return (
    <>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
        <DialogTitle>{f({ id: 'onboardingPolicyTitle' })}</DialogTitle>
        <DialogContent>
          <>
            <Box mb={2}>
              <Typography variant="body1">
                {f({ id: 'onboardingPolicyBody' })}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                {`${f({
                  id: 'bookingSettingsPrivacyPolicyPlaceandDate',
                })}:`}
              </Typography>
              <Box mb={2}>
                <TextField
                  id="policyCity"
                  name="policyCity"
                  label={f({ id: 'bookingSettingsTown' })}
                  value={policyCity}
                  onChange={(event) => setPolicyCity(event.target.value)}
                />
              </Box>
              <Box mb={2}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={moment.locale(locale)}
                >
                  <DatePicker
                    cancelLabel={f({ id: 'buttonCancel' })}
                    id="policyDate"
                    name="policyDate"
                    autoOk
                    openTo="date"
                    value={policyDate}
                    onChange={(date) => setPolicyDate(date)}
                    label={f({ id: 'date' })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            withmargin="true"
            danger
            disabled={!generatePolicyButtonEnabled}
            onClick={() => {
              setShowPolicyWarning(true);
            }}
          >
            {f({ id: 'bookingSettingsPrivacyPolicyGenerate' })}
          </PrimaryButton>
          <SecondaryButton onClick={onClose} color="primary">
            {f({ id: 'buttonBack' })}
          </SecondaryButton>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={showPolicyWarning}
      >
        <DialogTitle>{f({ id: 'onboardingPolicyTitle' })}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="body1">
              {f({ id: 'onboardingPolicyDisclaimer' })}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            withmargin="true"
            onClick={() => {
              generatePolicy();
              setShowPolicyWarning(false);
            }}
          >
            {f({ id: 'buttonOk' })}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OnboardingPolicyDialog;
