import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GroupIcon from '@material-ui/icons/Group';
import ListIcon from '@material-ui/icons/List';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import Check from '@material-ui/icons/Check';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import OnboardingBarberDialog from './OnboardingBarberDialog';
import OnboardingServiceDialog from './OnboardingServiceDialog';
import OnboardingOpeningHoursDialog from './OnboardingOpeningHoursDialog';
import OnboardingShopDialog from './OnboardingShopDialog';
import OnboardingPolicyDialog from './OnboardingPolicyDialog';

import { isSalonzone } from '../utils';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    backgroundColor: '#1c3763',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#4caf50',
  },
});

const ColorlibConnector = withStyles({
  active: {
    '& $line': {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      backgroundColor: '#1c3763',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#4caf50',
    },
  },
  line: {
    // height: 3,
    marginLeft: 10,
    marginTop: 4,
    marginBottom: -4,
    width: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const OnboardingDialog = ({
  onClose,
  open,
  barbersSet,
  servicesSet,
  openingHoursSet,
  privacyPolicySet,
  shopSettingsSet,
}) => {
  const [addBarbersOpen, setAddBarbersOpen] = useState(false);
  const [addServicesOpen, setAddServicesOpen] = useState(false);
  const [addOpeningHoursOpen, setAddOpeningHoursOpen] = useState(false);
  const [addShopDetailsOpen, setAddShopDetailsOpen] = useState(false);
  const [generatePolicyDialogOpen, setGeneratePolicyDialogOpen] = useState(
    false,
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { formatMessage: f } = useIntl();

  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return f({ id: 'onboardingDialogStep1' });
      case 1:
        return f({ id: 'onboardingDialogStep2' });
      case 2:
        return f({ id: 'onboardingDialogStep3' });
      case 3:
        return f({ id: 'onboardingDialogStep4' });
      case 4:
        return f({ id: 'onboardingDialogStep5' });
      default:
        return f({ id: 'onboardingDialogStepUnknown' });
    }
  };

  const getSteps = () => {
    return [
      f({ id: 'onboardingDialogStep1Title' }),
      f({ id: 'onboardingDialogStep2Title' }),
      f({ id: 'onboardingDialogStep3Title' }),
      f({ id: 'onboardingDialogStep4Title' }),
      f({ id: 'onboardingDialogStep5Title' }),
    ];
  };

  const steps = getSteps();

  const getNextHandler = () => {
    return [
      () => setAddBarbersOpen(true),
      () => setAddServicesOpen(true),
      () => setAddOpeningHoursOpen(true),
      () => setAddShopDetailsOpen(true),
      () => setGeneratePolicyDialogOpen(true),
    ];
  };

  const ColorlibStepIcon = ({ active, completed, icon }) => {
    const classesIcon = useColorlibStepIconStyles();

    const icons = {
      1: <GroupIcon />,
      2: <ListIcon />,
      3: <QueryBuilderIcon />,
      4: <SettingsIcon />,
      5: <SecurityIcon />,
    };

    return (
      <div
        className={clsx(classesIcon.root, {
          [classesIcon.active]: active,
          [classesIcon.completed]: completed,
        })}
      >
        {completed ? (
          <Check />
        ) : (
          (active && (
            <IconButton
              color="secondary"
              onClick={active && getNextHandler()[icon - 1]}
              style={
                isSalonzone
                  ? { backgroundColor: '#230928', color: 'white' }
                  : {}
              }
            >
              {icons[String(icon)]}
            </IconButton>
          )) ||
          icons[String(icon)]
        )}
      </div>
    );
  };

  useEffect(() => {
    setActiveStep(
      (shopSettingsSet && openingHoursSet && servicesSet && barbersSet && 4) ||
        (openingHoursSet && servicesSet && barbersSet && 3) ||
        (servicesSet && barbersSet && 2) ||
        (barbersSet && 1) ||
        0,
    );
  }, [
    barbersSet,
    servicesSet,
    privacyPolicySet,
    shopSettingsSet,
    openingHoursSet,
  ]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen={fullScreen}
      >
        <DialogTitle id="simple-dialog-title">
          {f({ id: 'onboardingDialogTitle' })}
        </DialogTitle>
        <Box display="flex" flex={1} flexDirection="row">
          <Box>
            <Stepper
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              orientation="vertical"
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box p="24px" style={{ whiteSpace: 'pre-line' }}>
            {getStepContent(activeStep)}
          </Box>
        </Box>
        <DialogActions>
          <Button
            onClick={getNextHandler()[activeStep]}
            color="primary"
            variant="contained"
          >
            {f({ id: 'onboardingDialogNext' })}
          </Button>
        </DialogActions>
      </Dialog>
      <OnboardingBarberDialog
        open={addBarbersOpen}
        onClose={() => setAddBarbersOpen(false)}
        aria-labelledby="form-dialog-title"
      />
      <OnboardingServiceDialog
        open={addServicesOpen}
        onClose={() => setAddServicesOpen(false)}
        aria-labelledby="form-dialog-title"
      />
      <OnboardingShopDialog
        open={addShopDetailsOpen}
        onClose={() => setAddShopDetailsOpen(false)}
        aria-labelledby="form-dialog-title"
      />
      <OnboardingOpeningHoursDialog
        open={addOpeningHoursOpen}
        onClose={() => setAddOpeningHoursOpen(false)}
        aria-labelledby="form-dialog-title"
      />
      <OnboardingPolicyDialog
        open={generatePolicyDialogOpen}
        onClose={() => setGeneratePolicyDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      />
    </>
  );
};

export default OnboardingDialog;
