import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Button,
  TextField,
} from '@material-ui/core';

import { getUser } from '../utils';
import { addEmployee } from '../actions';

const OnboardingBarberDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { shopMap } = getUser();
  const shopIdList = shopMap.map((x) => x.id);
  const [barbers, setBarbers] = useState(['', '', '', '', '']);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {f({ id: 'onboardingBarberDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {f({ id: 'onboardingBarberDialogHelper' })}
        </DialogContentText>
        {barbers.map((barber, i) => (
          <TextField
            key={`barber-${i}`}
            margin="dense"
            id={`barber-${i}`}
            label={f({ id: 'onboardingBarberDialogTeamMember' })}
            fullWidth
            value={barbers[i]}
            onChange={(e) => {
              const newState = [...barbers];
              newState[i] = (e.target || {}).value || '';
              setBarbers(newState);
            }}
          />
        ))}
        <Button
          variant="outlined"
          onClick={() => {
            const newState = [...barbers, ''];
            setBarbers(newState);
          }}
        >
          {f({ id: 'onboardingAddMore' })}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {f({ id: 'buttonCancel' })}
        </Button>
        <Button
          onClick={() => {
            const dataToSend = [];
            let error = false;

            if (
              barbers.filter((x) => x).length !==
              [...new Set(barbers.filter((x) => x))].length
            ) {
              enqueueSnackbar(f({ id: 'errorDuplicateTeamMember' }), {
                variant: 'warning',
              });
              error = true;
            }

            barbers.forEach((barber) => {
              if (barber) {
                const data = new FormData();
                data.append('displayName', barber);
                data.append('role', 'barber');
                data.append('employmentKind', 'employee');
                data.append('exludeServices', []);
                [...Array(shopIdList.length).keys()].forEach((x) => {
                  data.append('shopIdList', shopIdList[x]);
                });
                dataToSend.push(data);
              }
            });

            if (error) {
              return;
            }

            Promise.all(
              dataToSend.map((data) =>
                dispatch(addEmployee(data, enqueueSnackbar, true, f)),
              ),
            );

            onClose();
          }}
          color="primary"
          variant="contained"
        >
          {f({ id: 'buttonInput' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingBarberDialog;
