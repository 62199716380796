import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';
import StartDateEndDateFilter from '../../StartDateEndDateFilter';

import DashboardLayout from '../../DashboardLayout';
import SalaryCalculationDeductionsButtons from './SalaryCalculationDeductionsButtons';
import SalaryCalculationDeductionsList from './SalaryCalculationDeductionsList';

import { listSalaryDeductions } from '../../../actions';

const SalaryCalculationDeductionsPage = ({
  enqueueSnackbar,
  dispatch,
  uri,
}) => {
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterShopIdList, setFilterShopIdList] = useState(undefined);
  const { formatMessage: f } = useIntl();

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'salaryDeductionTooltip' })}>
            {f({ id: 'appNavSalaryDeductions' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              dispatch(
                listSalaryDeductions({
                  startDate,
                  endDate,
                  shopIdList,
                  enqueueSnackbar,
                }),
              );
              setFilterStartDate(startDate);
              setFilterEndDate(endDate);
              setFilterShopIdList(shopIdList);
            }}
            initSameDay
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SalaryCalculationDeductionsButtons
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            filterShopIdList={filterShopIdList}
          />
        </Grid>
        <Grid item xs={12}>
          <SalaryCalculationDeductionsList
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            filterShopIdList={filterShopIdList}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default BaseHOC(
  SalaryCalculationDeductionsPage,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
