import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  MenuItem,
  Select,
  InputLabel,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import * as _ from 'lodash';
import { useIntl } from 'react-intl';

import PaperWithTitle from '../../PaperWithTitle';
import FormControl from '../../FormControl';
import PaddedGrid from '../../PaddedGrid';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import TextField from '../../TextField';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

import { isPositiveInt, getUser } from '../../../utils';
import {
  purchase,
  cancelPurchase,
  getEmployees,
  getServices,
  getProducts,
} from '../../../actions';

import SaleItemAddCardHorizontal from './SaleItemAddCardHorizontal';
import SaleItemTable from './SaleItemTable';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    paddingBottom: theme.spacing(1),
  },
  tipSelectorClass: {
    paddingLeft: theme.spacing(1),
  },
  tipSelectorDisabledClass: {
    paddingLeft: theme.spacing(1),
    color: 'gray',
  },
});

const PurchaseForm = ({
  classes,
  purchaseItems,
  enqueueSnackbar,
  purchaseTotal,
  employees,
  dispatch,
}) => {
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [totalInclTip, setTotalInclTip] = useState(0);
  const [tipOnly, setTipOnly] = useState(0);
  const [createdByError, setCreatedByError] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [tipError, setTipError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [canNavigateAway, setCanNavigateAway] = useState(true);
  const [tipGiven, setTipGiven] = useState('no');
  const { formatMessage: f } = useIntl();
  const settings = useSelector((state) => state.settings);

  // listenerHandler(event) {
  //   console.log('hey');
  //   const currentPath = global.window.location.pathname;
  //   const nextPath = event.detail.pathname;

  //   if (currentPath !== nextPath) console.log('triggered');
  // }

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getServices());
    dispatch(getProducts());

    return () => {
      dispatch(cancelPurchase());
      // unblock();
      // global.window.removeEventListener(
      //   'gatsby-plugin-page-transition::exit',
      //   listenerHandler,
      // );
    };

    // TODO: Prevent users from naigating away

    // global.window.addEventListener(
    //   'gatsby-plugin-page-transition::exit',
    //   listenerHandler,
    // );

    // unblock = history
    //   .block(() => {
    //     const { canNavigateAway } = state;
    //     const { purchaseItems } = props;
    //     if (!canNavigateAway || !_.isEmpty(purchaseItems)) {
    //       return 'A bevitt adatok törlődni fognak. Biztosan elnavigál az oldalról?';
    //     }
    //     return true;
    //   })
    //   .bind(this);
  }, []);

  const paymentMethods = [
    {
      value: 'cash',
      label: f({ id: 'paymentMethodCash' }),
    },
    {
      value: 'card',
      label: f({ id: 'paymentMethodCard' }),
    },
    {
      value: 'giftcard',
      label: f({ id: 'paymentMethodGiftcard' }),
    },
  ];

  const doPurchase = () => {
    setLoading(true);

    const purchaseDetails = {
      createdBy,
      paymentMethod,
      bookingId,
      tip: tipGiven === 'yes' ? totalInclTip - purchaseTotal.total : 0,
      items: purchaseItems,
      shopId: getUser().shopId,
    };

    dispatch(purchase(purchaseDetails, enqueueSnackbar, f));
  };

  const handleSubmit = () => {
    let error = false;
    let snackbarShown = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (paymentMethod === '') {
      setPaymentMethodError(true);
      enqueueSnackbar(f({ id: 'purchaseErrorPaymentMethodMissing' }), {
        variant: 'error',
      });
      snackbarShown = true;
      error = true;
    } else {
      setPaymentMethodError(false);
    }

    if (tipGiven === 'yes') {
      if (!isPositiveInt(totalInclTip)) {
        setTipError(true);
        error = true;
      } else if (totalInclTip < purchaseTotal.total) {
        setTipError(true);
        enqueueSnackbar(f({ id: 'purchaseErrorTipAmountMismatch' }), {
          variant: 'error',
        });
        snackbarShown = true;
        error = true;
      } else {
        setTipError(false);
      }
    }

    if (purchaseItems.length < 1) {
      enqueueSnackbar(f({ id: 'purchaseErrorNoItemAdded' }), {
        variant: 'error',
      });
      snackbarShown = true;
      error = true;
    }

    if (
      paymentMethod === 'giftcard' &&
      !_.isEmpty(purchaseItems.filter((x) => x.kind !== 'service'))
    ) {
      enqueueSnackbar(f({ id: 'purchaseErrorProductWithGiftcard' }), {
        variant: 'error',
      });
      snackbarShown = true;
      error = true;
    }

    if (error) {
      if (!snackbarShown) {
        enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
          variant: 'warning',
        });
      }
      return;
    }

    setDialogOpen(true);
    // setCanNavigateAway(true);
  };

  return (
    <PaperWithTitle>
      <Grid container spacing={0}>
        <PaddedGrid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                required
                error={createdByError}
                className={classes.formControl}
              >
                <InputLabel htmlFor="createdBy">
                  {f({ id: 'createdBy' })}
                </InputLabel>
                <Select
                  value={createdBy}
                  onChange={(event) => {
                    setCreatedBy(event.target.value);
                    dispatch(cancelPurchase());
                  }}
                  input={<Input name="createdBy" id="createdBy" />}
                >
                  {employees.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                error={paymentMethodError}
                className={classes.formControl}
              >
                <InputLabel htmlFor="paymentMethod">
                  {f({ id: 'paymentMethod' })}
                </InputLabel>
                <Select
                  value={paymentMethod}
                  name="paymentMethod"
                  onChange={(event) => setPaymentMethod(event.target.value)}
                  inputProps={{
                    id: 'paymentMethod',
                  }}
                  className={classes.selectEmpty}
                >
                  {paymentMethods.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormLabel component="legend">
            {f({ id: 'purchaseCurrentTransaction' })}
          </FormLabel>
          <SaleItemTable items={purchaseItems} paymentMethod={paymentMethod} />
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <SaleItemAddCardHorizontal
            barber={createdBy}
            setCreatedById={(barberId) => setCreatedBy(barberId)}
            setBookingId={(id) => setBookingId(id)}
          />
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{`${f({ id: 'tip' })}:`}</FormLabel>
            <Grid container>
              <Grid item xs={12} md={6}>
                <RadioGroup
                  aria-label={f({ id: 'tip' })}
                  name="tipGiven"
                  className={classes.RadioGroup}
                  value={tipGiven}
                  onChange={(event) => setTipGiven(event.target.value)}
                >
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label={f({ id: 'purchaseNoTip' })}
                  />
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label={f({ id: 'purchaseYesTip' })}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={tipGiven === 'no'}
                  error={tipError}
                  id="totalInclTip"
                  name="totalInclTip"
                  label={f({ id: 'purchaseAmountWithTip' })}
                  className={classes.textField}
                  margin="normal"
                  value={totalInclTip}
                  onChange={(event) => {
                    setTipOnly(
                      parseFloat(event.target.value) - purchaseTotal.total,
                    );
                    // setCanNavigateAway(false);
                    setTotalInclTip(event.target.value);
                  }}
                  type="number"
                />
                <Typography
                  variant="body1"
                  className={
                    tipGiven === 'no'
                      ? classes.tipSelectorDisabledClass
                      : classes.tipSelectorClass
                  }
                >
                  {f({ id: 'purchaseAmountWithTipOr' })}
                </Typography>
                <TextField
                  disabled={tipGiven === 'no'}
                  id="tipOnly"
                  name="tipOnly"
                  label={f({ id: 'purchaseTipAmount' })}
                  className={classes.textField}
                  margin="normal"
                  value={tipOnly}
                  onChange={(event) => {
                    setTotalInclTip(
                      purchaseTotal.total + parseFloat(event.target.value),
                    );
                    // setCanNavigateAway(false);
                    setTipOnly(event.target.value);
                  }}
                  type="number"
                />
              </Grid>
            </Grid>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <PrimaryButton type="submit" onClick={(event) => handleSubmit(event)}>
            {f({ id: 'buttonInput' })}
          </PrimaryButton>
          <Dialog disableBackdropClick disableEscapeKeyDown open={dialogOpen}>
            <DialogTitle>{f({ id: 'purchaseConfirm' })}</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  {tipGiven === 'yes' ? (
                    <div>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.heading}
                      >
                        {`${f({
                          id: 'purchaseConfirmFullAmount',
                        })}: `}
                        <CurrencyFormat amount={totalInclTip} />
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.heading}
                      >
                        {`${f({ id: 'purchaseConfirmTipAmount' })}: `}
                        <CurrencyFormat
                          amount={totalInclTip - purchaseTotal.total}
                        />
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.heading}
                      >
                        {`${f({
                          id: 'purchaseConfirmFullAmount',
                        })}: `}
                        <CurrencyFormat amount={purchaseTotal.total} />
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SecondaryButton
                onClick={() => {
                  setDialogOpen(false);
                  // setCanNavigateAway(false);
                }}
              >
                {f({ id: 'buttonCancel' })}
              </SecondaryButton>
              <PrimaryButton
                disabled={loading}
                onClick={(event) => doPurchase(event)}
                variant="contained"
                color="primary"
              >
                {f({ id: 'buttonConfirm' })}
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        </PaddedGrid>
      </Grid>
    </PaperWithTitle>
  );
};

const mapStateToProps = (state) => ({
  purchaseItems: state.purchaseItems,
  services: state.services,
  employees: state.employees,
  products: state.products,
  purchaseTotal: state.purchaseTotal,
});

export default BaseHOC(PurchaseForm, styles, mapStateToProps);
