import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getUser } from '../../../utils';
import { deleteSalaryRule } from '../../../actions';

import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import TextField from '../../TextField';
import Chips from '../../Chips';
import Chip from '../../Chip';
import BaseHOC from '../../BaseHOC';

const LargeChip = styled(Chip)`
  && {
    overflow-x: overlay;
    width: auto;
  }
`;

const PerformanceBasedSalaryRuleDialog = ({
  dialogOpen,
  onSubmit,
  onCancel,
  params,
  deleteSalaryRule,
  enqueueSnackbar,
}) => {
  const { shopMap } = getUser();
  const [ruleName, setRuleName] = useState('');
  const [items, setItems] = useState([]);
  const [basePercentage, setBasePercentage] = useState('');
  const [shopIdList, setShopIdList] = useState(shopMap.map((x) => x.id));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isReadOnly = params !== undefined;

  const { formatMessage: f } = useIntl();

  const ITEM_OPTIONS = {
    giftcard_service_net: f({ id: 'salaryGiftcardServiceNet' }),
    giftcard_service_gross: f({ id: 'salaryGiftcardServiceGross' }),
    card_service_net: f({ id: 'salaryCardServiceNet' }),
    card_service_gross: f({ id: 'salaryCardServiceGross' }),
    cash_service_net: f({ id: 'salaryCashServiceNet' }),
    cash_service_gross: f({ id: 'salaryCashServiceGross' }),
    card_product_net: f({ id: 'salaryCardProductNet' }),
    card_product_gross: f({ id: 'salaryCardProductGross' }),
    cash_product_net: f({ id: 'salaryCashProductNet' }),
    cash_product_gross: f({ id: 'salaryCashProductGross' }),
  };

  const validate = () => {
    // TODO: FIX ME!
    console.log('FIX ME!');
    return true;
  };

  useEffect(() => {
    setRuleName((params && params.name) || '');
    setItems((params && params.items) || []);
    setBasePercentage((params && params.base_percentage) || '');
    setShopIdList((params && params.shops) || shopMap.map((x) => x.id));
  }, [params]);

  const reset = () => {
    setBasePercentage('');
    setItems([]);
    setRuleName('');
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialogOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        {f(
          { id: 'salaryPerformanceBasedTitle' },
          {
            linebreak: <br />,
          },
        )}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="ruleName"
              name="ruleName"
              label={f({ id: 'salaryName' })}
              margin="none"
              value={ruleName}
              onChange={(event) => setRuleName(event.target.value)}
            />
          </Grid>
          {shopMap.length > 1 ? (
            <Grid item xs={12}>
              <MultiShopSelector
                disabled={isReadOnly}
                shopIdList={shopIdList}
                setShopIdList={setShopIdList}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="select-multiple-chip">
                {f({ id: 'salaryBase' })}
              </InputLabel>
              <Select
                multiple
                disabled={isReadOnly}
                value={items}
                onChange={(event) => {
                  setItems(event.target.value);
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Chips>
                    {selected.map((value) => (
                      <LargeChip key={value} label={ITEM_OPTIONS[value]} />
                    ))}
                  </Chips>
                )}
              >
                {Object.keys(ITEM_OPTIONS).map((key) => (
                  <MenuItem key={key} value={key}>
                    {ITEM_OPTIONS[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="basePercentage"
              name="basePercentage"
              label={f({ id: 'salaryPerformancePercentage' })}
              margin="none"
              value={basePercentage}
              onChange={(event) => setBasePercentage(event.target.value)}
              type="number"
              InputLabelProps={{
                shrink: true, // Need to set this as the placeholder is too long
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => {
            reset();
            onCancel();
          }}
          color="primary"
        >
          {f({ id: 'buttonCancel' })}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={isReadOnly}
          onClick={() => {
            if (validate()) {
              onSubmit({
                ruleType: 'performancebasedsalaryrule',
                data: {
                  name: ruleName,
                  items,
                  basePercentage,
                  shopIdList,
                },
              });
              reset();
            }
          }}
        >
          {f({ id: 'buttonConfirm' })}
        </PrimaryButton>
        {isReadOnly ? (
          <PrimaryButton
            withmargin="true"
            danger
            onClick={() => {
              deleteSalaryRule(params.id, enqueueSnackbar, f);
              reset();
              onCancel();
            }}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  deleteSalaryRule: (...args) => dispatch(deleteSalaryRule(...args)),
});

export default BaseHOC(
  PerformanceBasedSalaryRuleDialog,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
