import styled from 'styled-components';

const Chips = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default Chips;
