import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import DashboardLayout from '../../DashboardLayout';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import { listSales } from '../../../actions';

import ReportsTransactionsList from './ReportsTransactionsList';

const ReportsTransactioinsPage = ({ enqueueSnackbar, dispatch, uri }) => {
  const paginationLimit = 25;
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterShopIdList, setFilterShopIdList] = useState(null);

  const { formatMessage: f } = useIntl();

  const handleSalesChange = ({ offset, limit, search }) => {
    dispatch(
      listSales({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'tooltipAdminTransactions' })}>
            {f({ id: 'appNavReportTransactions' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              dispatch(
                listSales({
                  startDate,
                  endDate,
                  shopIdList,
                  enqueueSnackbar,
                  pagination: {
                    limit: paginationLimit,
                  },
                }),
              );

              setFilterStartDate(startDate);
              setFilterEndDate(endDate);
              setFilterShopIdList(shopIdList);
            }}
            rememberDates
            initSameDay
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsTransactionsList
            onChange={handleSalesChange}
            initLimit={paginationLimit}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(ReportsTransactioinsPage);
