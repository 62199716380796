import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const PaperHeader = styled(Typography)`
  && {
    margin-top: 1rem;
    margin-left: 1rem;
    overflow: visible;
    @media (max-width: 500px) {
      font-size: 1.25rem;
    }
  }
`;
