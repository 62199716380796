import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Input,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import PaperWithTitle from '../../PaperWithTitle';
import PrimaryButton from '../../PrimaryButton';
import BaseHOC from '../../BaseHOC';
import PaddedGrid from '../../PaddedGrid';
import SecondaryButton from '../../SecondaryButton';

import { getSettings, saveSettings } from '../../../actions';
import { getUser, isSalonzone } from '../../../utils';

const AdminSettingsForm = ({ enqueueSnackbar }) => {
  const user = getUser();
  const shopMap = (user && user.shopMap) || [];
  const shopId = getUser().shopMap[0].id;

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const [allowDateFiltering, setAllowDateFiltering] = useState(false);
  const [rollBalance, setRollBalance] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [logoImageUrl, setLogoImageUrl] = useState('');
  const { formatMessage: f } = useIntl();

  const [addLevelDialogOpen, setAddLevelDialogOpen] = useState(false);

  const [newLevelName, setNewLevelName] = useState('');
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    dispatch(getSettings({ shopId, enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (settings) {
      setAllowDateFiltering(settings.allow_date_filtering);
      setRollBalance(settings.roll_balance);
      setLogoImageUrl(settings.logo_image);
      setLevels(settings.barber_levels || []);
    }
  }, [settings]);

  const handleSave = () => {
    // TODO: validation!!!

    const data = new FormData();
    if (logoImage) {
      data.append('logoImage', logoImage, logoImage.name);
    }

    data.append('allowDateFiltering', allowDateFiltering);
    data.append('rollBalance', rollBalance);
    if (levels.length > 0) {
      levels.forEach((x) => {
        data.append('barberLevels', x.title);
      });
    } else {
      data.append('barberLevels', '');
    }

    dispatch(
      saveSettings({ f, shopId, settingsData: data, enqueueSnackbar }),
    ).then(() => {
      setLogoImage(null);
      dispatch(getSettings({ shopId, enqueueSnackbar }));
    });
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        {/* {shopMap.filter((x) => x.management).length > 0 && (
          <Box m={1} maxWidth={350}>
            <PaperWithTitle title={f({ id: 'settingsTill' })}>
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="rollBalance"
                        name="rollBalance"
                        checked={rollBalance}
                        onChange={(event) =>
                          setRollBalance(event.target.checked)
                        }
                        color="primary"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label={f({ id: 'settingsRollBalance' })}
                  />
                  <Tooltip title={f({ id: 'settingsRollBalanceTooltip' })}>
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
        )} */}
        {shopMap.filter((x) => x.management).length > 0 && (
          <Box m={1} maxWidth={400}>
            <PaperWithTitle title={f({ id: 'settingsPermissions' })}>
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="allowDateFiltering"
                        name="allowDateFiltering"
                        checked={allowDateFiltering}
                        onChange={(event) =>
                          setAllowDateFiltering(event.target.checked)
                        }
                        color="primary"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label={f({ id: 'settingsAllowDateFiltering' })}
                  />
                  <Tooltip
                    title={f({ id: 'settingsAllowDateFilteringTooltip' })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
        )}
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'settingsUploadLogo' })}
            helpText={f({ id: 'settingsUploadLogoTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box>
                {!logoImage && logoImageUrl ? (
                  <img
                    src={`https://agrddpxdbm.cloudimg.io/fit/200x200/cffffff/${logoImageUrl}`}
                    alt="Current logoimage"
                  />
                ) : (
                  logoImage && <ImageOutlinedIcon style={{ fontSize: 200 }} />
                )}
              </Box>
              <Box>
                <Box height="100%" alignItems="center" display="flex" mb={1}>
                  <FormControlLabel
                    control={
                      <Input
                        id="logoImage"
                        name="logoImage"
                        type="file"
                        onChange={(event) =>
                          setLogoImage(event.target.files[0])
                        }
                        style={{
                          width: '0.1px',
                          height: '0.1px',
                          opacity: '0',
                          overflow: 'hidden',
                          position: 'absolute',
                          zIndex: '-1',
                        }}
                      />
                    }
                    label={f({ id: 'settingsUploadLogo' })}
                    labelPlacement="start"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSalonzone ? '#230928' : '#212121',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '6px 16px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'settingsBarberLevels' })}
            helpText={f({ id: 'tooltipSettingsBarberLevels' })}
          >
            <PaddedGrid item xs={12}>
              <Box display="flex" flexDirection="column" mb={2}>
                {levels &&
                  levels.map((level) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      key={level.id}
                      mb={1}
                    >
                      <Box>{level.title}</Box>
                      <Box>
                        <IconButton
                          size="small"
                          aria-label={f({ id: 'buttonDelete' })}
                          onClick={() => {
                            setLevels([
                              ...levels.filter((x) => x.title !== level.title),
                            ]);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
              <PrimaryButton onClick={() => setAddLevelDialogOpen(true)}>
                {f({ id: 'settingsBarberLevelsAdd' })}
              </PrimaryButton>
              <Dialog
                open={addLevelDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {f({ id: 'settingsBarberLevelsAdd' })}
                </DialogTitle>
                <DialogContent>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="newLevelName"
                      name="newLevelName"
                      label={f({ id: 'settingsBarberLevelsAddLevelLabel' })}
                      value={newLevelName}
                      onChange={(e) => setNewLevelName(e.target.value)}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton
                    onClick={() => {
                      setNewLevelName('');
                      setAddLevelDialogOpen(false);
                    }}
                    autoFocus
                  >
                    {f({ id: 'buttonCancel' })}
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      setLevels([...levels, { title: newLevelName }]);
                      setNewLevelName('');
                      setAddLevelDialogOpen(false);
                    }}
                  >
                    {f({ id: 'buttonAdd' })}
                  </PrimaryButton>
                </DialogActions>
              </Dialog>
            </PaddedGrid>
          </PaperWithTitle>
        </Box>
      </Box>
      <Box mt={2}>
        <PrimaryButton withmargin="true" onClick={() => handleSave()}>
          {f({ id: 'buttonSave' })}
        </PrimaryButton>
      </Box>
    </>
  );
};

export default BaseHOC(AdminSettingsForm);
