import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';

import {
  AppBar,
  Divider,
  Hidden,
  Drawer,
  Toolbar,
  Button,
  IconButton,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Snackbar,
} from '@material-ui/core';

import Flag from 'react-world-flags';

import MuiAlert from '@material-ui/lab/Alert';

import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Timeline from '@material-ui/icons/Timeline';
import Settings from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import EventNote from '@material-ui/icons/EventNote';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// import Help from '@material-ui/icons/Help';

import {
  fitImage,
  getUser,
  isBrowser,
  changeLanguage,
  isSalonzone,
} from '../utils';

import NavListItem from './NavListItem';
import BaseHOC from './BaseHOC';
import OnboardingDialog from './OnboardingDialog';
import OnboardingSuccessDialog from './OnboardingSuccessDialog';
import FontSelector from './FontSelector';
import { getSettings, getOnboarding } from '../actions';

import logo from '../images/logo_small.png';
import logoSc from '../images/logo_small_sc.png';

import './style.css';

const salonzoneTheme = createTheme({
  palette: {
    primary: {
      main: '#230928',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

let backgroundColor = 'primary';

if (process.env.GATSBY_ENV.indexOf('dev') > -1 && !isSalonzone) {
  backgroundColor = 'teal';
} else if (isSalonzone) {
  backgroundColor = '#230928';
}

const drawerWidth = 242;
const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f9f9f9',
  },
  growCapitalize: {
    flexGrow: 1,
    textTransform: 'capitalize',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor,
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // appBar: {
  //   marginLeft: drawerWidth,
  //   [theme.breakpoints.up('md')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //   },
  //   backgroundColor: process.env.GATSBY_ENV === 'dev' ? 'teal' : 'primary',
  //   // backgroundColor: 'primary',
  // },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopMenuButton: {
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
  },
  drawerPaper: {
    width: drawerWidth,
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      overflowX: 'hidden',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginLeft: -drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  progress: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  logoImg: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  listItemIconTopLevel: {
    marginRight: '-16px',
  },
});

const DashboardLayout = ({ classes, children }) => {
  const [
    reportsOpen,
    inputsOpen,
    adminOpen,
    salaryOpen,
    adminBookingOpen,
  ] = useSelector((state) => state.layoutNav);
  const settings = useSelector((state) => state.settings);
  const onboarding = useSelector((state) => state.onboarding);

  const user = getUser();
  const shopMap = (user && user.shopMap) || [];
  const { formatMessage: f, locale } = useIntl();
  const dispatch = useDispatch();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [intervalId, setIntervalId] = useState(-1);
  const [currentDate, setCurrentDate] = useState(moment());
  const [onboardingOpen, setOnboardingOpen] = useState(false);
  const [onboardingSuccessOpen, setOnboardingSuccessOpen] = useState(false);

  const timer = () => {
    setCurrentDate(moment());
  };

  useEffect(() => {
    setIntervalId(setInterval(timer, 1000));
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!settings) {
      dispatch(getSettings());
    }
  }, [settings]);

  useEffect(() => {
    if (!onboarding) {
      dispatch(getOnboarding());
    } else if (
      !onboarding.loading &&
      !(
        onboarding.services &&
        onboarding.barbers &&
        onboarding.privacy_policy &&
        onboarding.shop_details &&
        onboarding.opening_hours
      )
    ) {
      setOnboardingOpen(true);
    } else if (
      !onboarding.loading &&
      onboarding.services &&
      onboarding.barbers &&
      onboarding.privacy_policy &&
      onboarding.shop_details &&
      onboarding.opening_hours
    ) {
      if (onboardingOpen) {
        setOnboardingSuccessOpen(true);
      }
      setOnboardingOpen(false);
    }
  }, [onboarding]);

  const inputsMapping = [
    {
      linkTo: `/${locale}/app/purchase`,
      label: f({ id: 'appNavInputPurchase' }),
    },
    {
      linkTo: `/${locale}/app/other-transaction`,
      label: f({ id: 'appNavInputOtherTransaction' }),
    },
    {
      linkTo: `/${locale}/app/product-use`,
      label: f({ id: 'appNavInputProductUse' }),
    },
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/close-till`,
          label: f({ id: 'appNavInputCloseTill' }),
        },
  ];

  const reportsMapping = [
    {
      linkTo: `/${locale}/app/summary`,
      label: f({ id: 'appNavReportSummary' }),
    },
    {
      linkTo: `/${locale}/app/performance`,
      label: f({ id: 'appNavReportPerformance' }),
    },
    {
      linkTo: `/${locale}/app/transactions`,
      label: f({ id: 'appNavReportTransactions' }),
    },
    { linkTo: `/${locale}/app/usages`, label: f({ id: 'appNavReportUsages' }) },
    { linkTo: `/${locale}/app/tips`, label: f({ id: 'appNavReportTips' }) },
  ];

  const adminMapping = [
    {
      linkTo: `/${locale}/app/services`,
      label: f({ id: 'appNavAdminShopServices' }),
    },
    {
      linkTo: `/${locale}/app/employees`,
      label: f({ id: 'appNavAdminShopEmployees' }),
    },
    shopMap && shopMap.filter((x) => x.management).length > 0
      ? {
          linkTo: `/${locale}/app/products`,
          label: f({ id: 'appNavAdminShopProducts' }),
        }
      : null,
    shopMap.length > 1 ||
    (shopMap && shopMap.filter((x) => x.management).length === 0)
      ? null
      : {
          linkTo: `/${locale}/app/product-inventory`,
          label: f({ id: 'appNavAdminShopProductInventory' }),
        },
    shopMap.length > 1 ||
    (shopMap && shopMap.filter((x) => x.management).length === 0)
      ? null
      : {
          linkTo: `/${locale}/app/misc-transactions`,
          label: f({ id: 'appNavAdminShopMiscTransactions' }),
        },
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/settings`,
          label: f({ id: 'appNavAdminShopSettings' }),
        },
    shopMap.length > 1 ||
    (shopMap && shopMap.filter((x) => x.management).length === 0)
      ? null
      : {
          linkTo: `/${locale}/app/till-closings/?shopIdList[]=${shopMap
            ?.map((x) => x.id)
            ?.join('&shopIdList[]=')}`,
          label: f({ id: 'appNavAdminShopTillClosings' }),
        },
  ];

  const adminBookingMapping = [
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/booking-settings`,
          label: f({ id: 'appNavAdminBookingSettings' }),
        },
    user && user.shopMap && user.shopMap.filter((x) => x.booking).length === 1 // Only support single shop use case
      ? {
          linkTo: `/${locale}/app/admin-schedules`,
          label: f({ id: 'appNavAdminBookingSchedules' }),
        }
      : null,
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/stats`,
          label: f({ id: 'appNavAdminBookingStats' }),
        },
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/clients`,
          label: f({ id: 'appNavAdminBookingClients' }),
        },
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/reviews`,
          label: f({ id: 'appNavAdminBookingReviews' }),
        },
    shopMap.length > 1
      ? null
      : {
          linkTo: `/${locale}/app/booking-website`,
          label: f({ id: 'appNavAdminBookingWebsite' }),
        },
  ];

  const salaryMapping = [
    {
      linkTo: `/${locale}/app/salary-calculation-rules`,
      label: f({ id: 'appNavSalaryRules' }),
    },
    {
      linkTo: `/${locale}/app/salary-calculation-assignments`,
      label: f({ id: 'appNavSalaryAssignments' }),
    },
    {
      linkTo: `/${locale}/app/salary-calculation-deductions`,
      label: f({ id: 'appNavSalaryDeductions' }),
    },
    {
      linkTo: `/${locale}/app/salary-calculation-salaries`,
      label: f({ id: 'appNavSalarySalaries' }),
    },
  ];

  const reportsMenu = (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({
            type: 'NAVBAR_EXPAND_REPORTS',
          });
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <Timeline />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavReport' })} />
        {reportsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
        <ListItem>
          <List component="div" disablePadding>
            {reportsMapping &&
              reportsMapping
                .filter((x) => x !== null)
                .map(({ linkTo, label }) => (
                  <NavListItem
                    key={linkTo}
                    to={linkTo}
                    primary={label}
                    onClick={() => setMobileOpen(false)}
                  />
                ))}
          </List>
        </ListItem>
      </Collapse>
    </>
  );

  const bookingsMenu = (
    <>
      <ListItem
        button
        onClick={() => {
          setMobileOpen(false);
          dispatch({
            type: 'NAVBAR_EXPAND_CALENDAR',
          });
          navigate(`/${locale}/app/calendar`);
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <EventNote />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavBookings' })} />
      </ListItem>
    </>
  );

  const inputsMenu = (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({
            type: 'NAVBAR_EXPAND_INPUTS',
          });
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <AddShoppingCart />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavInput' })} />
        {inputsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={inputsOpen} timeout="auto" unmountOnExit>
        <ListItem>
          <List component="div" disablePadding>
            {inputsMapping &&
              inputsMapping
                .filter((x) => x !== null)
                .map(({ linkTo, label }) => (
                  <NavListItem
                    key={linkTo}
                    to={linkTo}
                    primary={label}
                    onClick={() => setMobileOpen(false)}
                  />
                ))}
          </List>
        </ListItem>
      </Collapse>
    </>
  );

  const adminMenu = (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({
            type: 'NAVBAR_EXPAND_ADMIN',
          });
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <Settings />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavAdminShop' })} />
        {adminOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={adminOpen} timeout="auto" unmountOnExit>
        <ListItem>
          <List component="div" disablePadding>
            {adminMapping &&
              adminMapping
                .filter((x) => x !== null)
                .map(({ linkTo, label }) => (
                  <NavListItem
                    key={linkTo}
                    to={linkTo}
                    primary={label}
                    onClick={() => setMobileOpen(false)}
                  />
                ))}
          </List>
        </ListItem>
      </Collapse>
    </>
  );

  const adminBooking = (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({
            type: 'NAVBAR_EXPAND_ADMIN_BOOKING',
          });
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <Settings />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavAdminBooking' })} />
        {adminBookingOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={adminBookingOpen} timeout="auto" unmountOnExit>
        <ListItem>
          <List component="div" disablePadding>
            {adminBookingMapping &&
              adminBookingMapping
                .filter((x) => x !== null)
                .map(({ linkTo, label }) => (
                  <NavListItem
                    key={linkTo}
                    to={linkTo}
                    primary={label}
                    onClick={() => setMobileOpen(false)}
                  />
                ))}
          </List>
        </ListItem>
      </Collapse>
    </>
  );

  const salaryMenu = (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({
            type: 'NAVBAR_EXPAND_SALARY',
          });
        }}
      >
        <ListItemIcon className={classes.listItemIconTopLevel}>
          <Settings />
        </ListItemIcon>
        <ListItemText primary={f({ id: 'appNavSalary' })} />
        {salaryOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={salaryOpen} timeout="auto" unmountOnExit>
        <ListItem>
          <List component="div" disablePadding>
            {salaryMapping &&
              salaryMapping
                .filter((x) => x !== null)
                .map(({ linkTo, label }) => (
                  <NavListItem
                    key={linkTo}
                    to={linkTo}
                    primary={label}
                    onClick={() => setMobileOpen(false)}
                  />
                ))}
          </List>
        </ListItem>
      </Collapse>
    </>
  );

  const helpMenu = (
    <ListItem
      button
      onClick={() => {
        setMobileOpen(false);
        dispatch({
          type: 'NAVBAR_EXPAND_CALENDAR',
        });
        if (window.$crisp) {
          window.$crisp.push([
            'on',
            'chat:closed',
            () => {
              window.$crisp.push(['do', 'chat:hide']);
              window.$crisp.push(['off', 'chat:closed']);
            },
          ]);
          window.$crisp.push(['set', 'user:company', [shopMap[0].name]]);
          window.$crisp.push([
            'set',
            'session:data',
            [
              [
                ['Shop', shopMap[0].name],
                ['URL', window.location.href],
              ],
            ],
          ]);
          window.$crisp.push(['do', 'chat:show']);
          window.$crisp.push(['do', 'chat:open']);
        }
      }}
    >
      <ListItemIcon className={classes.listItemIconTopLevel}>
        <Settings />
      </ListItemIcon>
      <ListItemText primary={f({ id: 'appNavHelp' })} />
    </ListItem>
  );

  const drawer = (
    <>
      <div className={classes.toolbar} asd>
        {shopMap && (shopMap.find((x) => x.logo) || {}).logo ? (
          <img
            src={fitImage(shopMap.find((x) => x.logo).logo, 239, 64, 'FFFFFF')}
            alt="Shop logo"
            className={classes.logoImg}
          />
        ) : (
          <img
            src={isSalonzone ? logoSc : logo}
            alt="Shop logo"
            className={classes.logoImg}
          />
        )}
      </div>
      <Divider />
      <List>
        {shopMap && shopMap.filter((x) => x.management).length > 0
          ? inputsMenu
          : null}
        {shopMap && shopMap.filter((x) => x.booking).length === 1
          ? bookingsMenu
          : null}
        {shopMap && shopMap.filter((x) => x.management).length > 0
          ? reportsMenu
          : null}
        {user.role === 'manager' || user.role === 'admin' ? adminMenu : null}
        {(user.role === 'manager' || user.role === 'admin') &&
        shopMap &&
        shopMap.filter((x) => x.booking).length === 1
          ? adminBooking
          : null}
        {user.role === 'admin' &&
        shopMap &&
        shopMap.filter((x) => x.management).length > 0
          ? salaryMenu
          : null}
        {process.env.GATSBY_ENV.indexOf('dev') > -1 ? helpMenu : null}
      </List>
      {process.env.GATSBY_ENV.indexOf('dev') > -1 ? (
        <>
          <Divider />
          <List>
            <ListItem>
              <IconButton
                onClick={() => {
                  changeLanguage('hu');
                  setMobileOpen(false);
                }}
              >
                <Flag code="hu" height="12" />
              </IconButton>
              <IconButton
                onClick={() => {
                  changeLanguage('en');
                  setMobileOpen(false);
                }}
              >
                <Flag code="gb" height="12" />
              </IconButton>
              <IconButton
                onClick={() => {
                  changeLanguage('sk');
                  setMobileOpen(false);
                }}
              >
                <Flag code="sk" height="12" />
              </IconButton>
              <IconButton
                onClick={() => {
                  changeLanguage('cs');
                  setMobileOpen(false);
                }}
              >
                <Flag code="cz" height="12" />
              </IconButton>
              <IconButton
                onClick={() => {
                  changeLanguage('xx');
                  setMobileOpen(false);
                }}
              >
                <Flag code="jm" height="12" />
              </IconButton>
            </ListItem>
          </List>
          <Divider />
          <Button
            onClick={() => setOnboardingOpen(true)}
            variant="contained"
            color="primary"
          >
            Onboarding
          </Button>
        </>
      ) : null}
    </>
  );

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  // TODO: notify if shop deactivated (to notidfy admin usrers logging in as)
  // TODO: Update error message if deactvation is negative: Hamarosan meg fog szunni

  const displayFirstWarning =
    user.shopMap
      .map((x) => x.days_until_deactivation)
      .filter((x) => x > 3 && x < 8).length > 0;

  const displayFirstWarningDays =
    displayFirstWarning &&
    Math.min(
      user.shopMap
        .map((x) => x.days_until_deactivation)
        .filter((x) => x > 3 && x < 8),
    );

  const displaySecondWarning =
    user.shopMap.map((x) => x.days_until_deactivation).filter((x) => x <= 3)
      .length > 0;

  const displaySecondWarningDays =
    displaySecondWarning &&
    Math.min(
      user.shopMap.map((x) => x.days_until_deactivation).filter((x) => x <= 3),
    );

  const isTrialPeriod =
    user.shopMap.map((x) => x.trial_period).filter((x) => x).length > 0;

  const [openAlert, setOpenAlert] = useState(
    isBrowser &&
      (displayFirstWarning || displaySecondWarning) &&
      (!localStorage.getItem('alertDismissed') ||
        moment(localStorage.getItem('alertDismissed')).add(
          1,
          displaySecondWarning ? 'hour' : 'day',
        ) < moment()),
  );

  const dismissAlert = (event, reason) => {
    if (reason && reason === 'clickaway') return;
    localStorage.setItem('alertDismissed', moment());
    setOpenAlert(false);
  };

  const page = (
    <FontSelector>
      <div className={classes.root}>
        <CssBaseline />
        <Snackbar
          open={!!openAlert}
          onClose={(event, reason) => dismissAlert(event, reason)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={() => dismissAlert()}
            severity={displaySecondWarning ? 'error' : 'warning'}
          >
            {isTrialPeriod
              ? f(
                  { id: 'appNavAlertFreePeriodToExpire' },
                  { days: displaySecondWarningDays || displayFirstWarningDays },
                )
              : f(
                  { id: 'appNavAlertCooldownToExpire' },
                  { days: displaySecondWarningDays || displayFirstWarningDays },
                )}
          </Alert>
        </Snackbar>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: desktopOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => setDesktopOpen(!desktopOpen)}
              className={classes.desktopMenuButton}
            >
              {desktopOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.grow}
            >
              {shopMap.length > 1
                ? `${user.shopName} + ${user.shopMap.length - 1}`
                : user.shopName}
            </Typography>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.growCapitalize}
            >
              {moment(currentDate)
                .locale(locale)
                .format(f({ id: 'layoutDatetimeFormat' }))}
            </Typography>
            <IconButton
              color="inherit"
              aria-label={f({ id: 'appNavLogout' })}
              onClick={() => setLogoutDialogOpen(true)}
            >
              <ExitToApp />
            </IconButton>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              open={logoutDialogOpen}
            >
              <DialogTitle>{f({ id: 'appNavLogout' })}</DialogTitle>
              <DialogContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.heading}
                >
                  {f({ id: 'appNavLogoutConfirmation' })}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setLogoutDialogOpen(false)}
                  color="primary"
                >
                  {f({ id: 'buttonCancel' })}
                </Button>
                <Button
                  onClick={() => navigate(`/${locale}/app/logout`)}
                  variant="contained"
                  color="primary"
                >
                  {f({ id: 'buttonYes' })}
                </Button>
              </DialogActions>
            </Dialog>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(!mobileOpen)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              // open
              variant="persistent"
              open={desktopOpen}
              onClose={() => setDesktopOpen(!desktopOpen)}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: desktopOpen,
          })}
        >
          {children}
        </main>
        {settings && (
          <>
            <OnboardingDialog
              open={onboardingOpen}
              onClose={() => setOnboardingOpen(false)}
              barbersSet={onboarding?.barbers}
              servicesSet={onboarding?.services}
              privacyPolicySet={onboarding?.privacy_policy}
              shopSettingsSet={onboarding?.shop_details}
              openingHoursSet={onboarding?.opening_hours}
            />
            <OnboardingSuccessDialog
              open={onboardingSuccessOpen}
              onClose={() => setOnboardingSuccessOpen(false)}
            />
          </>
        )}
      </div>
    </FontSelector>
  );

  if (isSalonzone) {
    return <MuiThemeProvider theme={salonzoneTheme}>{page}</MuiThemeProvider>;
  }
  return page;
};

export default BaseHOC(DashboardLayout, styles);
