import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { getTipSummary } from '../../../actions';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import ReportsTipSummarySum from './ReportsTipSummarySum';
import ReportsTipSummaryCash from './ReportsTipSummaryCash';
import ReportsTipSummaryCard from './ReportsTipSummaryCard';

const ReportsTipSummaryPage = ({ dispatch, enqueueSnackbar, uri }) => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>{f({ id: 'appNavReportTips' })}</Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              dispatch(
                getTipSummary({
                  startDate,
                  endDate,
                  shopIdList,
                  enqueueSnackbar,
                }),
              );
            }}
            initSameDay
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsTipSummarySum />
        </Grid>
        <Grid item xs={12}>
          <ReportsTipSummaryCash />
        </Grid>
        <Grid item xs={12}>
          <ReportsTipSummaryCard />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(ReportsTipSummaryPage);
