import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Moment from 'react-moment';

import { getPaymentMethod, getMiscSaleKind } from './utils';

import Table from '../../Table';
import Loader from '../../Loader';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsSummaryMiscCash = ({ onChange }) => {
  const { formatMessage: f } = useIntl();
  const miscSalesSummary = useSelector((state) => state.miscSalesSummary);

  return (
    <Table
      title={f({ id: 'summaryMiscCashTitle' })}
      onChange={onChange}
      count={miscSalesSummary && miscSalesSummary.count}
    >
      {(miscSalesSummary && (
        <>
          <TableHead>
            <TableRow>
              <TableCell>{f({ id: 'date' })}</TableCell>
              <TableCell>{f({ id: 'inputTime' })}</TableCell>
              <TableCell>{f({ id: 'createdBy' })}</TableCell>
              <TableCell>{f({ id: 'summaryMiscCashKind' })}</TableCell>
              <TableCell>{f({ id: 'comment' })}</TableCell>
              <TableCell>{f({ id: 'category' })}</TableCell>
              <TableCell>{f({ id: 'amount' })}</TableCell>
              <TableCell>{f({ id: 'paymentMethod' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {miscSalesSummary.results &&
              miscSalesSummary.results.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="HH:mm:ss">{row.created}</Moment>
                  </TableCell>
                  <TableCell>{row.created_by.display_name}</TableCell>
                  <TableCell>{getMiscSaleKind(row, f)}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>
                    {(row.misc_category && row.misc_category.name) || '-'}
                  </TableCell>
                  <TableCell>
                    <CurrencyFormat amount={row.manual_price} />
                  </TableCell>
                  <TableCell>{getPaymentMethod(row, f)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </>
      )) || <Loader />}
    </Table>
  );
};

export default BaseHOC(ReportsSummaryMiscCash);
