import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Box,
} from '@material-ui/core';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import DashboardLayout from '../DashboardLayout';
import PurchasePreviewTable from '../PurchasePreviewTable';
import CurrencyFormat from '../CurrencyFormat';
import BaseHOC from '../BaseHOC';
import { getSale, voidTransaction, getEmployees } from '../../actions';
import { Header, SubHeader } from '../Header/styles';

const dangerTheme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  voidHeading: {
    marginTop: theme.spacing(2),
    color: 'red',
  },
});

const TransactionDetails = ({ classes, enqueueSnackbar, transactionId }) => {
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const employees = useSelector((state) => state.employees);
  const purchaseDetails = useSelector((state) => state.purchaseDetails);
  const dispatch = useDispatch();

  const { formatMessage: f } = useIntl();

  const paymentMethodMap = {
    card: f({ id: 'paymentMethodCard' }),
    cash: f({ id: 'paymentMethodCash' }),
    giftcard: f({ id: 'paymentMethodGiftcard' }),
  };

  useEffect(() => {
    dispatch(getSale({ saleId: parseInt(transactionId, 10), enqueueSnackbar }));
    dispatch(getEmployees());
  }, []);

  const getDetails = () => {
    if (
      purchaseDetails.items &&
      purchaseDetails.items.length === 1 &&
      purchaseDetails.items[0].notes
    ) {
      const directionLabel =
        purchaseDetails.total > 0
          ? f({ id: 'paymentDirectionIn' })
          : f({ id: 'paymentDirectionOut' });
      return (
        <div>
          <Typography variant="body1" gutterBottom>
            {directionLabel}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {purchaseDetails.items[0].notes}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {purchaseDetails.items[0].misc_category &&
              purchaseDetails.items[0].misc_category.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <CurrencyFormat amount={purchaseDetails.total} />
          </Typography>
        </div>
      );
    }
    return purchaseDetails.items && purchaseDetails.items.length > 0 ? (
      <PurchasePreviewTable purchaseDetails={purchaseDetails} />
    ) : null;
  };

  const getVoidDetails = () => {
    if (!purchaseDetails.voided) {
      return '';
    }
    return (
      <div>
        <Typography variant="h5" gutterBottom className={classes.voidHeading}>
          {`${f({ id: 'transactionDetailsVoided' })} - ${
            purchaseDetails.voided_by.display_name
          } - ${moment(purchaseDetails.voided).format('YYYY-MM-DD HH:mm')}`}
        </Typography>
      </div>
    );
  };

  const doVoidTransaction = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (error) {
      return;
    }

    const voidData = {
      transaction: purchaseDetails.id,
      createdBy,
    };

    dispatch(voidTransaction(voidData, enqueueSnackbar, f));
  };

  return (
    <DashboardLayout>
      <Box pb={4}>
        <Header variant="h2" noWrap>
          {f({ id: 'transactionDetailsTitle' })}
        </Header>
      </Box>
      <Box pb={2}>
        <SubHeader variant="h5">
          {paymentMethodMap[purchaseDetails.payment_method]}
        </SubHeader>
      </Box>
      <Box pb={2}>
        <SubHeader variant="h5">
          {purchaseDetails.created_by?.display_name}
        </SubHeader>
      </Box>
      {getDetails()}
      {getVoidDetails()}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => window.history.back()}
      >
        {f({ id: 'buttonBack' })}
      </Button>
      <MuiThemeProvider theme={dangerTheme}>
        <Button
          disabled={purchaseDetails.voided !== null}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setDialogOpen(true)}
        >
          {f({ id: 'buttonVoid' })}
        </Button>
      </MuiThemeProvider>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={dialogOpen}
        onExit={() => {
          setCreatedBy(null);
          setDialogOpen(false);
          setCreatedByError(false);
        }}
      >
        <DialogTitle>{f({ id: 'selectColleague' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormControl
                required
                error={createdByError}
                className={classes.formControl}
              >
                <InputLabel htmlFor="createdBy">
                  {f({ id: 'createdBy' })}
                </InputLabel>
                <Select
                  value={createdBy}
                  onChange={(event) => setCreatedBy(event.target.value)}
                  error={createdByError}
                  input={<Input name="createdBy" id="createdBy" />}
                >
                  {employees.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreatedBy(null);
              setDialogOpen(false);
              setCreatedByError(false);
            }}
            color="primary"
          >
            {f({ id: 'buttonCancel' })}
          </Button>
          <MuiThemeProvider theme={dangerTheme}>
            <Button onClick={() => doVoidTransaction()} color="primary">
              {f({ id: 'buttonVoid' })}
            </Button>
          </MuiThemeProvider>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default BaseHOC(TransactionDetails, styles);
