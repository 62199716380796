import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import { getSalaryRule, resetSalaryRule } from '../../../actions';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

import FixSalaryRuleDialog from '../Shared/FixSalaryRuleDialog';
import PerformanceBasedSalaryRuleDialog from '../Shared/PerformanceBasedSalaryRuleDialog';
import TieredSalaryRuleDialog from '../Shared/TieredSalaryRuleDialog';
import TieredProgressiveSalaryRuleDialog from '../Shared/TieredProgressiveSalaryRuleDialog';
import DeductCardPaymentFromSalaryRuleDialog from '../Shared/DeductCardPaymentFromSalaryRuleDialog';

const SalaryCalculationSalariesList = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const salaryRule = useSelector((state) => state.salaryRule);
  const salaryCalculations = useSelector((state) => state.salaryCalculations);

  const [fixSalaryRuleDialogVisible, setFixSalaryRuleDialogVisible] = useState(
    false,
  );
  const [
    performanceBasedSalaryRuleDialogVisible,
    setPerformanceBasedSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredSalaryRuleDialogVisible,
    setTieredSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredProgressiveSalaryRuleDialog,
    setTieredProgressiveSalaryRuleDialog,
  ] = useState(false);
  const [
    deductCardPaymentFromSalaryRuleDialog,
    setDeductCardPaymentFromSalaryRuleDialog,
  ] = useState(false);

  if (salaryRule && salaryRule !== {}) {
    if (
      salaryRule.rule_class === 'fixsalaryrule' &&
      !fixSalaryRuleDialogVisible
    ) {
      setFixSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'performancebasedsalaryrule' &&
      !performanceBasedSalaryRuleDialogVisible
    ) {
      setPerformanceBasedSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredsalaryrule' &&
      !tieredSalaryRuleDialogVisible
    ) {
      setTieredSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
      !tieredProgressiveSalaryRuleDialog
    ) {
      setTieredProgressiveSalaryRuleDialog(true);
    } else if (
      salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
      !deductCardPaymentFromSalaryRuleDialog
    ) {
      setDeductCardPaymentFromSalaryRuleDialog(true);
    }
  }

  const { formatMessage: f } = useIntl();

  return (
    <>
      <Table title={f({ id: 'salarySalariesTableTitle' })}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>{f({ id: 'createdBy' })}</TableCell>
            <TableCell colSpan={3} align="center">
              {f({ id: 'salaryRules' })}
            </TableCell>
            <TableCell rowSpan={2}>{f({ id: 'tip' })}</TableCell>
            <TableCell rowSpan={2}>{f({ id: 'purchaseTableToPay' })}</TableCell>
            <TableCell rowSpan={2}>
              {f({ id: 'salarySalariesTotalSalary' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{f({ id: 'name' })}</TableCell>
            <TableCell>{f({ id: 'salarySalariesPartialSalary' })}</TableCell>
            <TableCell>{f({ id: 'details' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salaryCalculations.results &&
            salaryCalculations.results.map((employee) =>
              employee.salary_parts.map((salaryPart) => (
                <TableRow key={salaryPart.id}>
                  {employee.salary_parts.indexOf(salaryPart) === 0 ? (
                    <TableCell rowSpan={employee.salary_parts.length}>
                      {employee.display_name}
                    </TableCell>
                  ) : null}
                  <TableCell>{salaryPart.name}</TableCell>
                  <TableCell>
                    <CurrencyFormat amount={salaryPart.sum} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={salaryPart.id === null}
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        dispatch(getSalaryRule(salaryPart.id, enqueueSnackbar))
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                  {employee.salary_parts.indexOf(salaryPart) === 0 ? (
                    <TableCell rowSpan={employee.salary_parts.length}>
                      <CurrencyFormat amount={employee.tip} />
                    </TableCell>
                  ) : null}
                  {employee.salary_parts.indexOf(salaryPart) === 0 ? (
                    <TableCell rowSpan={employee.salary_parts.length}>
                      <CurrencyFormat amount={employee.salary} />
                    </TableCell>
                  ) : null}
                  {employee.salary_parts.indexOf(salaryPart) === 0 ? (
                    <TableCell rowSpan={employee.salary_parts.length}>
                      <CurrencyFormat amount={employee.full_salary} />
                    </TableCell>
                  ) : null}
                </TableRow>
              )),
            )}
        </TableBody>
      </Table>
      <FixSalaryRuleDialog
        onCancel={() => {
          setFixSalaryRuleDialogVisible(false);
          dispatch(resetSalaryRule());
        }}
        onSubmit={() => {
          setFixSalaryRuleDialogVisible(false);
        }}
        dialogOpen={fixSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'fixsalaryrule' &&
          salaryRule.params
        }
      />
      <PerformanceBasedSalaryRuleDialog
        onCancel={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
          dispatch(resetSalaryRule());
        }}
        onSubmit={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
        }}
        dialogOpen={performanceBasedSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'performancebasedsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredSalaryRuleDialog
        onCancel={() => {
          setTieredSalaryRuleDialogVisible(false);
          dispatch(resetSalaryRule());
        }}
        onSubmit={() => {
          setTieredSalaryRuleDialogVisible(false);
        }}
        dialogOpen={tieredSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredProgressiveSalaryRuleDialog
        onCancel={() => {
          setTieredProgressiveSalaryRuleDialog(false);
          dispatch(resetSalaryRule());
        }}
        onSubmit={() => {
          setTieredProgressiveSalaryRuleDialog(false);
        }}
        dialogOpen={tieredProgressiveSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
          salaryRule.params
        }
      />
      <DeductCardPaymentFromSalaryRuleDialog
        onCancel={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
          dispatch(resetSalaryRule());
        }}
        onSubmit={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
        }}
        dialogOpen={deductCardPaymentFromSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
          salaryRule.params
        }
      />
    </>
  );
};

export default BaseHOC(SalaryCalculationSalariesList);
