import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformanceProductSalesPerformance = ({ onChange }) => {
  const productSalesPerformance = useSelector(
    (state) => state.productSalesPerformance,
  );

  const getDiscount = (row) => {
    if (parseInt(row.discount_amount, 10)) {
      return <CurrencyFormat amount={row.discount_amount} />;
    }
    if (parseFloat(row.discount_rate)) return `${row.discount_rate}%`;
    return '-';
  };

  const { formatMessage: f } = useIntl();

  return (
    <Table
      title={f({ id: 'performanceProductSaleTitle' })}
      onChange={onChange}
      count={productSalesPerformance && productSalesPerformance.count}
    >
      <TableHead>
        <TableRow>
          <TableCell align="center">{f({ id: 'createdBy' })}</TableCell>
          <TableCell align="center">{f({ id: 'kindProduct' })}</TableCell>
          <TableCell align="center">{f({ id: 'quantity' })}</TableCell>
          <TableCell align="center">{f({ id: 'discount' })}</TableCell>
          <TableCell align="center">
            {f({ id: 'columnTitleUnitPrice' })}
          </TableCell>
          <TableCell align="center">{f({ id: 'total' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productSalesPerformance &&
          productSalesPerformance.results &&
          productSalesPerformance.results.map((row) => (
            <TableRow key={`product-perf-${row.name}-${row.title}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.count}</TableCell>
              <TableCell>{getDiscount(row)}</TableCell>
              <TableCell>
                <CurrencyFormat amount={row.price} />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.sum} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformanceProductSalesPerformance);
