import React, { useState, useEffect } from 'react';

import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

import {
  Grid,
  InputLabel,
  Select,
  TextField,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { getUser } from '../../../utils';
import {
  listSalaryDeductions,
  addSalaryDeduction,
  getEmployees,
} from '../../../actions';

import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import PaddedGrid from '../../PaddedGrid';
import FormControl from '../../FormControl';
import BaseHOC from '../../BaseHOC';

const SalaryCalculationDeductionsAddDeductionDialog = ({
  enqueueSnackbar,
  employees,
  dispatch,
  filterStartDate,
  filterEndDate,
  filterShopIdList,
  showDialog,
  setShowDialog,
}) => {
  const shopId = getUser().shopMap[0].id;
  const [deductedBy, setDeductedBy] = useState('');
  const [deductFrom, setDeductFrom] = useState('');
  const [deductionDate, setDeductionDate] = useState(
    moment().format(moment.HTML5_FMT.DATE),
  );
  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState('');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const validate = () => true;
  const resetForm = () => {
    setDeductedBy('');
    setDeductFrom('');
    setDeductionDate(moment().format(moment.HTML5_FMT.DATE));
    setNotes('');
    setNotesError('');
    setAmount('');
    setAmountError('');
  };
  const { formatMessage: f, locale } = useIntl();

  useEffect(() => {
    if (employees.length === 0) dispatch(getEmployees());
  }, []);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={showDialog}>
      <DialogTitle>{f({ id: 'salaryDeductionDeductionNew' })}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <PaddedGrid item xs={12}>
                <FormControl required>
                  <InputLabel htmlFor="deductedBy">
                    {f({ id: 'salaryDeductionDeductionDeductedBy' })}
                  </InputLabel>
                  <Select
                    value={deductedBy}
                    onChange={(event) => setDeductedBy(event.target.value)}
                    input={<Input name="deductedBy" id="deductedBy" />}
                  >
                    {employees.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </PaddedGrid>
              <PaddedGrid item xs={12}>
                <FormControl required>
                  <InputLabel htmlFor="deductFrom">
                    {f({ id: 'salaryDeductionDeductionDeductFrom' })}
                  </InputLabel>
                  <Select
                    value={deductFrom}
                    onChange={(event) => setDeductFrom(event.target.value)}
                    input={<Input name="deductFrom" id="deductFrom" />}
                  >
                    {employees.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </PaddedGrid>
              <PaddedGrid item xs={12}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={moment.locale(locale)}
                >
                  <KeyboardDatePicker
                    cancelLabel={f({ id: 'buttonCancel' })}
                    disableFuture
                    label={f({ id: 'salaryDeductionDeductionDate' })}
                    value={deductionDate}
                    onChange={(date) =>
                      setDeductionDate(
                        moment(date).format(moment.HTML5_FMT.DATE),
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </PaddedGrid>
              <PaddedGrid item xs={12}>
                <FormControl required>
                  <TextField
                    id="notes"
                    name="notes"
                    error={!!notesError}
                    helperText={notesError}
                    // multiline
                    // rows="4"
                    placeholder={f({ id: 'comment' })}
                    fullWidth
                    value={notes}
                    margin="normal"
                    onChange={(event) => setNotes(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </PaddedGrid>
              <PaddedGrid item xs={12}>
                <TextField
                  error={!!amountError}
                  helperText={amountError}
                  required
                  id="amount"
                  name="amount"
                  label={f({ id: 'amount' })}
                  margin="normal"
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                  type="number"
                />
              </PaddedGrid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => {
            resetForm();
            setShowDialog(false);
          }}
        >
          {f({ id: 'buttonCancel' })}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (validate()) {
              dispatch(
                addSalaryDeduction({
                  f,
                  deductionData: {
                    shopId,
                    deductFrom,
                    deductedBy,
                    deductionDate,
                    notes,
                    amount,
                    kind: 'deduction',
                  },
                  enqueueSnackbar,
                }),
              ).then(() => {
                resetForm();
                setShowDialog(false);
                dispatch(
                  listSalaryDeductions({
                    startDate: filterStartDate,
                    endDate: filterEndDate,
                    shopIdList: filterShopIdList,
                    enqueueSnackbar,
                  }),
                );
              });
            }
          }}
        >
          {f({ id: 'buttonInput' })}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
});

export default BaseHOC(
  SalaryCalculationDeductionsAddDeductionDialog,
  null,
  mapStateToProps,
);
