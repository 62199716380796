import React, { useState, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import moment from 'moment';
import * as _ from 'lodash';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import { deleteClient, listClients, downloadClients } from '../../../actions';

import DashboardLayout from '../../DashboardLayout';

import AdminClientsList from './AdminClientsList';

const AdminClientsPage = ({ dispatch, enqueueSnackbar }) => {
  const paginationInitLimit = 25;
  const [filterSearch, setFilterSearch] = useState('');
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(paginationInitLimit);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    dispatch(
      listClients({
        enqueueSnackbar,
        filterSearch,
        pagination: {
          offset: paginationOffset,
          limit: paginationLimit,
        },
      }),
    );
  }, []);

  const handleClientsChange = useCallback(({ offset, limit, search }) => {
    dispatch(
      listClients({
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
    setFilterSearch(search);
    setPaginationLimit(limit);
    setPaginationOffset(offset);
  }, []);

  const handleDelete = (emailToDelete) => {
    dispatch(deleteClient({ f, enqueueSnackbar, email: emailToDelete })).then(
      () =>
        dispatch(
          listClients({
            enqueueSnackbar,
            search: filterSearch,
            pagination: {
              offset: paginationOffset,
              limit: paginationLimit,
            },
          }),
        ),
    );
  };

  const handleDownload = () => {
    dispatch(
      downloadClients({
        enqueueSnackbar,
        search: filterSearch,
      }),
    ).then(({ payload }) => {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:text/csv;charset=utf-8,${encodeURIComponent(payload)}`,
      );
      element.setAttribute(
        'download',
        `barbercloud-client-data-${moment().format('YYYYMMDDHHmmss')}.csv`,
      );
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  };

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'clientsTooltip' })}>
            {f({ id: 'appNavAdminBookingClients' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminClientsList
            onChange={handleClientsChange}
            onDelete={handleDelete}
            onDownload={handleDownload}
            initLimit={paginationInitLimit}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
});

export default BaseHOC(AdminClientsPage, null, mapStateToProps);
