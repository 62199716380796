import React from 'react';
import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { RemoveRedEye } from '@material-ui/icons';

const styles = (theme) => ({
  eye: {
    cursor: 'pointer',
  },
});

class MaskablePasswordInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
  }

  togglePasswordMask() {
    this.setState((prevState) => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  }

  render() {
    const { classes, ...other } = this.props;
    const { passwordIsMasked } = this.state;

    return (
      <TextField
        type={passwordIsMasked ? 'password' : 'text'}
        {...other}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RemoveRedEye
                className={classes.eye}
                onClick={() => this.togglePasswordMask()}
              />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default withStyles(styles)(MaskablePasswordInput);
