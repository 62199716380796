import React from 'react';
import { Tooltip, IconButton, Box } from '@material-ui/core';

import { Header, SizedHelpOutlineOutlinedIcon } from './styles';

const styledHeader = ({ children, subtitle, tooltipOnClick }) => {
  return (
    <Header variant="h2" noWrap>
      <Box display="flex" justifyContent="space-between">
        <Box>{children}</Box>
        <Box>
          {subtitle ? (
            <Tooltip
              title={subtitle}
              PopperProps={{ style: { whiteSpace: 'pre-line' } }}
              onClick={tooltipOnClick}
              enterTouchDelay={1}
              leaveTouchDelay={2000}
            >
              <IconButton aria-label={subtitle} size="small">
                <SizedHelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
    </Header>
  );
};
export default styledHeader;
