import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminServiceEditForm from './AdminServiceEditForm';

const ServiceEditPage = () => {
  const [mode, setMode] = useState('add');
  const { service } = window.history.state;
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (service) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [service]);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>
            {mode === 'edit'
              ? f({ id: 'serviceEdit' })
              : f({ id: 'serviceAdd' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminServiceEditForm service={service} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(ServiceEditPage);
