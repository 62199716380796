import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import PaddedGrid from '../../PaddedGrid';
import WideButton from '../../WideButton';
import BaseHOC from '../../BaseHOC';
import SaleItemDialog from './SaleItemDialog';

import {
  getServices,
  getProducts,
  listBookings,
  addPurchaseItem,
} from '../../../actions';

import { getUser } from '../../../utils';

const SaleItemAddCardHorizontal = ({
  barber,
  setCreatedById,
  setBookingId,
  dispatch,
  enqueueSnackbar,
}) => {
  const [servicesDialogOpen, setServicesDialogOpen] = useState(false);
  const [productsDialogOpen, setProductsDialogOpen] = useState(false);
  const [giftcardsDialogOpen, setGiftcardsDialogOpen] = useState(false);
  const employees = useSelector((state) => state.employees);
  const bookings = useSelector((state) => state.bookings);
  const purchaseItems = useSelector((state) => state.purchaseItems);
  const services = useSelector((state) => state.services);
  const products = useSelector((state) => state.products);

  const employee = barber && employees.find((x) => x.id === barber);

  const user = getUser();

  const { formatMessage: f } = useIntl();

  const closeAll = () => {
    setServicesDialogOpen(false);
    setProductsDialogOpen(false);
    setGiftcardsDialogOpen(false);
  };

  const attachBooking = (bookingId, barberId, serviceId) => {
    dispatch(
      addPurchaseItem({
        barber: employees.find((x) => x.id === barberId),
        selectedItemId: serviceId,
        discountRate: 0,
        discountAmount: 0,
        quantity: 1,
        kind: 'service',
        miscSaleAmount: 0,
        miscSaleComment: '',
      }),
    );
    setCreatedById(barberId);
    setBookingId(bookingId);
  };

  useEffect(() => {
    dispatch(getServices());
    dispatch(getProducts());
    if (
      user &&
      user.shopMap &&
      user.shopMap.filter((x) => x.booking).length === 1
    ) {
      dispatch(
        listBookings({
          startDate: moment()
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          endDate: moment()
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          enqueueSnackbar,
        }),
      );
    }
  }, []);

  const getServicePriceForBarber = (serviceBaseId) => {
    if (employee && services && serviceBaseId) {
      const levelId = (
        (
          employee.service_levels.find(
            (x) => x.service_base_id === serviceBaseId,
          ) || {}
        ).level || {}
      ).id;

      const serviceInfo = services.find((x) => x.base_id === serviceBaseId);

      if (serviceInfo && levelId) {
        const levelInfo =
          serviceInfo.service_levels.find((x) => x.level.id === levelId) || {};

        return levelInfo.price;
      }
      if (serviceInfo) {
        return serviceInfo.price;
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <WideButton
                disabled={!barber}
                onClick={() => setServicesDialogOpen(true)}
                style={{ height: '60px' }}
              >
                {f({ id: 'purchaseAddItemsService' })}
              </WideButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <WideButton
                disabled={!barber}
                onClick={() => setProductsDialogOpen(true)}
                style={{ height: '60px' }}
              >
                {f({ id: 'purchaseAddItemsProduct' })}
              </WideButton>
            </Grid>
            <Grid item xs={12} md={4}>
              <WideButton
                disabled={!barber}
                onClick={() => setGiftcardsDialogOpen(true)}
                style={{ height: '60px' }}
              >
                {f({ id: 'purchaseAddItemsGiftcard' })}
              </WideButton>
            </Grid>
          </Grid>
        </Grid>
        {bookings && bookings.results && bookings.results.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{`${f({
                id: 'purchaseAddItemsBookings',
              })}:`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* TODO: Refactor these to helper functions */}
                {bookings.results
                  .filter((booking) => !!booking.service)
                  .filter((booking) => !booking.cancelled)
                  .filter((booking) =>
                    // Do not render bookings where service could
                    // break preview table
                    services.find((item) => item.id === booking.service.id),
                  )
                  .filter((booking) => {
                    return (
                      !booking.processed &&
                      (!barber || barber === booking.employee.id) &&
                      !purchaseItems.find(
                        (x) => x.itemId === booking.service.id,
                      ) &&
                      moment().isAfter(moment(booking.start)) &&
                      moment().isBefore(moment(booking.end).add(30, 'minutes'))
                    );
                  })
                  .map((booking) => (
                    <PaddedGrid key={booking.id} item xs={12} md={3}>
                      <WideButton
                        auto
                        onClick={() =>
                          attachBooking(
                            booking.id,
                            booking.employee.id,
                            booking.service.id,
                          )
                        }
                      >
                        {`${booking.employee.display_name} - ${moment(
                          booking.start,
                        ).format('H:mm')} - ${booking.service.title}`}
                      </WideButton>
                    </PaddedGrid>
                  ))}
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>

      <SaleItemDialog
        barber={employee}
        kind="service"
        label={f({ id: 'kindService' })}
        open={servicesDialogOpen}
        onClose={() => closeAll()}
        // TODO: Filter for only services supported by barber if selected
        items={(services || []).map((x) => ({
          ...x,
          price: (barber && getServicePriceForBarber(x.base_id)) || x.price,
        }))}
      />
      <SaleItemDialog
        barber={employee}
        kind="product"
        label={f({ id: 'kindProduct' })}
        open={productsDialogOpen}
        onClose={() => closeAll()}
        items={products}
      />
      <SaleItemDialog
        barber={employee}
        kind="giftcard"
        label={f({ id: 'kindGiftcard' })}
        open={giftcardsDialogOpen}
        onClose={() => closeAll()}
        items={services}
      />
    </>
  );
};

export default BaseHOC(SaleItemAddCardHorizontal);
