import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import {
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import BaseHOC from '../../BaseHOC';
import SecondaryButton from '../../SecondaryButton';
import Table from '../../Table';

import { getEmployees } from '../../../actions';

const CalendarCancelledBookings = ({ bookings, dispatch }) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [booking, setBooking] = useState(null);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  return (
    <>
      <Table title={f({ id: 'calendarCancelledBookingTitle' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'calendarCancelledBookingPerson' })}</TableCell>
            <TableCell>
              {f({ id: 'calendarCancelledBookingCancelTime' })}
            </TableCell>
            <TableCell>
              {f({ id: 'calendarCancelledBookingBookedTime' })}
            </TableCell>
            <TableCell>
              {f({ id: 'calendarCancelledBookingCancelReason' })}
            </TableCell>
            <TableCell>
              {f({ id: 'calendarCancelledBookingBookedToBarber' })}
            </TableCell>
            <TableCell>
              {f({ id: 'calendarCancelledBookingBookedService' })}
            </TableCell>
            <TableCell>{f({ id: 'details' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings &&
            bookings.results &&
            bookings.results
              .filter((x) => x.cancelled)
              .sort((a, b) => new Date(b.cancelled) - new Date(a.cancelled))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.cancelled_by_customer
                      ? f({ id: 'calendarCancelledBookingClient' })
                      : row.cancelled_by.display_name}
                  </TableCell>
                  <TableCell>
                    <Moment format="YYYY-MM-DD HH:mm">{row.cancelled}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="YYYY-MM-DD HH:mm">{row.start}</Moment>
                  </TableCell>
                  <TableCell>{row.cancel_reason}</TableCell>
                  <TableCell>
                    {(row.barber_selected && row.employee.display_name) ||
                      f({ id: 'bookingBarberInitialsUnassigned' })}
                  </TableCell>
                  <TableCell>
                    {(row.service && row.service.title) || row.title_override}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() => {
                        setBooking(row);
                        setDetailsDialogOpen(true);
                      }}
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={detailsDialogOpen}
      >
        <DialogTitle>
          {f({ id: 'calendarCancelledBookingDetailsDialogTitle' })}
        </DialogTitle>
        <DialogContent>
          {booking ? (
            <>
              <Typography variant="body1">
                {`${f({
                  id: 'bookingBarber',
                })}: ${booking.employee.display_name} (${
                  booking.barber_selected
                    ? f({
                        id:
                          'calendarCancelledBookingDetailsDialogBarberSelected',
                      })
                    : f({
                        id:
                          'calendarCancelledBookingDetailsDialogBarberNotSelected',
                      })
                })`}
              </Typography>
              <Typography variant="body1">
                {`${f({ id: 'bookingBookingTime' })}: ${moment(
                  booking.start,
                ).format(f({ id: 'prettyDateFormat' }))}`}
              </Typography>
              <Typography variant="body1">
                {`${f({ id: 'kindService' })}: ${booking.service &&
                  booking.service.title}`}
              </Typography>
              <Typography variant="body1">
                {`${f({
                  id: 'calendarCancelledBookingDetailsDialogCustomer',
                })}: ${booking.customer_name}`}
              </Typography>
              <Typography variant="body1">
                {`${f({ id: 'mobile' })}: ${booking.customer_mobile}`}
              </Typography>
              <Typography variant="body1">
                {`${f({ id: 'email' })}: ${booking.customer_email}`}
              </Typography>
              {booking.no_show ? (
                <>
                  <br />
                  <Typography variant="body1">
                    {f({ id: 'bookingClientNotArrived' })}
                  </Typography>
                </>
              ) : null}
              {booking.cancelled ? (
                <>
                  <br />
                  <Typography variant="body1">
                    {`${f({ id: 'comment' })}: ${booking.cancel_reason}`}
                  </Typography>
                </>
              ) : null}
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={() => {
              setDetailsDialogOpen(false);
            }}
            color="primary"
          >
            {f({ id: 'buttonBack' })}
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
});

export default BaseHOC(CalendarCancelledBookings, null, mapStateToProps);
