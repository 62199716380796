import React from 'react';
import * as _ from 'lodash';

import {
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { getUser } from '../../utils';
import FormControl from '../FormControl';

const MultiShopSelector = ({
  shopIdList,
  setShopIdList,
  disabled,
  singleShopSelect = false,
}) => {
  const { shopMap } = getUser();
  const { formatMessage: f } = useIntl();
  return (
    <FormControl required>
      <InputLabel htmlFor="shopId">{f({ id: 'shop' })}</InputLabel>
      {singleShopSelect ? (
        <Select
          disabled={disabled}
          value={shopIdList[0]}
          onChange={(event) => setShopIdList([event.target.value])}
          input={<Input name="shopId" id="shopId" />}
        >
          {shopMap.map((shop) => (
            <MenuItem value={shop.id}>{shop.name}</MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          disabled={disabled}
          multiple
          value={shopIdList}
          onChange={(event) => setShopIdList(event.target.value)}
          input={<Input name="shopId" id="shopId" />}
          renderValue={(selected) =>
            _.truncate(
              selected
                .map((y) => shopMap.find((x) => x.id === y).name)
                .join(', '),
              {
                length: 24,
              },
            )
          }
        >
          {shopMap.map((shop) => (
            <MenuItem key={shop.name} value={shop.id}>
              <Checkbox checked={shopIdList.indexOf(shop.id) > -1} />
              <ListItemText primary={shop.name} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default MultiShopSelector;
