import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export default styled(Grid)`
  && {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
`;
