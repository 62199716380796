import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Input, MenuItem, Select, InputLabel, Grid } from '@material-ui/core';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

import PaperWithTitle from '../../PaperWithTitle';
import TextField from '../../TextField';
import FormControl from '../../FormControl';
import PaddedGrid from '../../PaddedGrid';
import PaperSubHeader from '../../PaperSubHeader';
import PrimaryButton from '../../PrimaryButton';
import BaseHOC from '../../BaseHOC';

import { isPositiveIntOrZero, ccyFormat, getUser } from '../../../utils';
import { closeTill, getEmployees } from '../../../actions';

const CloseTillNewCloseForm = ({ enqueueSnackbar }) => {
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [cashOut, setCashOut] = useState('');
  const [cashOutError, setCashOutError] = useState(false);
  const [closeDate, setCloseDate] = useState(
    moment().format(moment.HTML5_FMT.DATE),
  );

  const dispatch = useDispatch();
  const { formatMessage: f, locale } = useIntl();
  const settings = useSelector((state) => state.settings);
  const employees = useSelector((state) => state.employees);

  const INIT_NOTES = {};
  const INIT_NOTES_ERRORS = {};

  const tillNotes =
    (settings && settings.country && settings.country.notes.split(',')) || [];

  if (settings && settings.country) {
    tillNotes.map((option) => {
      INIT_NOTES[`field${option}`] = '';
      INIT_NOTES_ERRORS[`field${option}`] = false;
      return null;
    });
  }

  const [notes, setNotes] = useState({ ...INIT_NOTES });
  const [notesErrors, setNotesErrors] = useState({ ...INIT_NOTES_ERRORS });

  const handleSubmit = () => {
    let error = false;

    const newNotesErrors = { ...notesErrors };

    tillNotes.map((option) => {
      if (
        notes[`field${option}`] === '' ||
        !isPositiveIntOrZero(notes[`field${option}`])
      ) {
        newNotesErrors[`field${option}`] = true;
        error = true;
      } else {
        newNotesErrors[`field${option}`] = false;
      }
      return null;
    });

    setNotesErrors(newNotesErrors);

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (parseFloat(cashOut) < 0 || cashOut === '') {
      setCashOutError(true);
      error = true;
    } else {
      setCashOutError(false);
    }

    if (error) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      return;
    }

    const closeTillData = {
      notes: Object.keys(notes).map((key) => ({
        note: parseInt(key.replace('field', ''), 10),
        count: notes[key],
      })),
      shopId: getUser().shopId,
      cashOut,
      createdBy,
      closeDate,
    };

    dispatch(closeTill(closeTillData, enqueueSnackbar, f));

    setCreatedBy('');
    setCreatedByError(false);
    setCashOut('');
    setCashOutError(false);
    setCloseDate(moment().format(moment.HTML5_FMT.DATE));
    setNotes({ ...INIT_NOTES });
    setNotesErrors({ ...INIT_NOTES_ERRORS });

    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const handleNotesChange = (event) => {
    const newNotes = { ...notes };
    newNotes[event.target.name] =
      parseInt(event.target.value, 10) || event.target.value;
    setNotes(newNotes);
  };

  useEffect(() => dispatch(getEmployees()), []);

  const user = getUser();

  return (
    <PaperWithTitle title={f({ id: 'tillCloseInputTitle' })}>
      <Grid container spacing={0}>
        <PaddedGrid item xs={12} md={4} lg={2}>
          <FormControl required error={createdByError}>
            <InputLabel htmlFor="createdBy">
              {f({ id: 'createdBy' })}
            </InputLabel>
            <Select
              value={createdBy}
              onChange={(event) => setCreatedBy(event.target.value)}
              input={<Input name="createdBy" id="createdBy" />}
            >
              {employees.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        {user.role === 'manager' || user.role === 'admin' ? (
          <PaddedGrid item xs={12} md={4} lg={2}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              libInstance={moment}
              locale={moment.locale(locale)}
            >
              <DatePicker
                cancelLabel={f({ id: 'buttonCancel' })}
                disableFuture
                label={f({ id: 'tillCloseInputCloseDate' })}
                value={closeDate}
                onChange={(date) =>
                  setCloseDate(moment(date).format(moment.HTML5_FMT.DATE))
                }
              />
            </MuiPickersUtilsProvider>
          </PaddedGrid>
        ) : null}
        <PaddedGrid item xs={12} md={4} lg={2}>
          <TextField
            error={cashOutError}
            required
            id="cashOut"
            name="cashOut"
            label={f({ id: 'tillCloseInputTakenOut' })}
            value={cashOut}
            onChange={(event) => setCashOut(event.target.value)}
            type="number"
          />
        </PaddedGrid>
        <Grid item xs={12}>
          <PaperSubHeader
            title={`${f({ id: 'tillCloseInputNotesLeftInTill' })}:`}
          />
        </Grid>
        <PaddedGrid container item xs={12}>
          {tillNotes.map((option) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={option}>
              <TextField
                error={notesErrors[`field${option}`]}
                required
                id={`field${option}`}
                name={`field${option}`}
                label={ccyFormat(
                  parseInt(option, 10) /
                    (((settings || {}).country || {}).currency_multiplier || 1),
                  settings && settings.country && settings.country.currency,
                  true,
                )}
                value={notes[`field${option}`]}
                onChange={(event) => handleNotesChange(event)}
                type="number"
              />
            </Grid>
          ))}
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <PrimaryButton onClick={handleSubmit}>
            {f({ id: 'buttonInput' })}
          </PrimaryButton>
        </PaddedGrid>
      </Grid>
    </PaperWithTitle>
  );
};

export default BaseHOC(CloseTillNewCloseForm);
