import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import SalaryCalculationRuleButtons from './SalaryCalculationRulesButtons';
import SalaryCalculationRulesList from './SalaryCalculationRulesList';

const SalaryCalculationPage = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'salaryRulesTooltip' })}>
            {f({ id: 'appNavSalaryRules' })}
          </Header>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SalaryCalculationRuleButtons />
        </Grid>
        <Grid item xs={12}>
          <SalaryCalculationRulesList />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(SalaryCalculationPage);
