import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@material-ui/core';

import { Area } from 'salad-ui.chart';

import BaseHOC from '../../BaseHOC';

// Factor out to component
function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

const calculateWidth = (width) => {
  // Do proper calculation:
  const maxSize = 1400;
  const mdSize = 960;
  const leftPanelSize = 240;
  const paperPadding = 16 * 2;
  const responsiveWidth = 0.8;

  const sideBarOffset =
    width > mdSize ? leftPanelSize + paperPadding : paperPadding;
  const expectedSchedulerSize = (width - sideBarOffset) * responsiveWidth;

  if (expectedSchedulerSize > maxSize) {
    return maxSize;
  }

  return expectedSchedulerSize;
};

const AdminStatsTimeseries = ({ bookingStats }) => {
  const { formatMessage: f } = useIntl();
  const width = useWindowWidth();

  return (
    <Box pt={2} display="flex" alignItems="center" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">
          {f({ id: 'statsAllBooking' })}
        </Typography>
      </Box>
      <Box px={4} display="block">
        <Area
          height={300}
          width={calculateWidth(width)}
          data={bookingStats.timeseries.map((x) => ({
            time: x.start_date,
            value: x.daily_count,
          }))}
          yLabelsOutside
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  bookingStats: state.bookingStats,
});

export default BaseHOC(AdminStatsTimeseries, null, mapStateToProps);
