import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';
import DashboardLayout from '../../DashboardLayout';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import {
  getProductServicePerformance,
  getServiceSalesPerformance,
  getProductSalesPerformance,
  getPerformanceStats,
  getTillShortage,
} from '../../../actions';

import ReportsPerformanceServicePerformance from './ReportsPerformanceServicePerformance';
import ReportsPerformanceProductPerformance from './ReportsPerformanceProductPerformance';
import ReportsPerformanceProductServicePerformance from './ReportsPerformanceProductServicePerformance';
import ReportsPerformanceServiceSalesPerformance from './ReportsPerformanceServiceSalesPerformance';
import ReportsPerformanceProductSalesPerformance from './ReportsPerformanceProductSalesPerformance';
import ReportsPerformancePerformanceStats from './ReportsPerformancePerformanceStats';
import ReportsPerformanceTillShortage from './ReportsPerformanceTillShortage';

const ReportsPerformancePage = ({ enqueueSnackbar, dispatch, uri }) => {
  const paginationLimit = 10;
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterShopIdList, setFilterShopIdList] = useState(null);
  const { formatMessage: f } = useIntl();

  const handleServiceSalesPerformance = ({ offset, limit, search }) => {
    dispatch(
      getServiceSalesPerformance({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  const handleProductSalesPerformance = ({ offset, limit, search }) => {
    dispatch(
      getProductSalesPerformance({
        startDate: filterStartDate,
        endDate: filterEndDate,
        shopIdList: filterShopIdList,
        enqueueSnackbar,
        search,
        pagination: {
          offset,
          limit,
        },
      }),
    );
  };

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>{f({ id: 'appNavReportPerformance' })}</Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            onFilterResults={(startDate, endDate, shopIdList) => {
              const callArgs = {
                startDate,
                endDate,
                shopIdList,
                enqueueSnackbar,
                pagination: {
                  limit: paginationLimit,
                },
              };
              dispatch(getProductServicePerformance(callArgs));
              dispatch(getServiceSalesPerformance(callArgs));
              dispatch(getProductSalesPerformance(callArgs));
              dispatch(getPerformanceStats(callArgs));
              dispatch(getTillShortage(callArgs));

              setFilterStartDate(startDate);
              setFilterEndDate(endDate);
              setFilterShopIdList(shopIdList);
            }}
            initSameDay
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceServicePerformance />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceProductPerformance />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceProductServicePerformance />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformancePerformanceStats />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceServiceSalesPerformance
            onChange={handleServiceSalesPerformance}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceProductSalesPerformance
            onChange={handleProductSalesPerformance}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportsPerformanceTillShortage />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(ReportsPerformancePage);
