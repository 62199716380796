import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import InputProductUsageForm from './InputProductUseForm';

const InputProductUseForm = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'tooltipProductUse' })}>
            {f({ id: 'appNavInputProductUse' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <InputProductUsageForm />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(InputProductUseForm);
