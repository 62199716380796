import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import InputPurchaseForm from './InputPurchaseForm';
import DashboardLayout from '../../DashboardLayout';

const InputPurchasePage = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'tooltipInputPurchase' })}>
            {f({ id: 'appNavInputPurchase' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <InputPurchaseForm />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(InputPurchasePage);
