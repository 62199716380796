import React, { useState, useEffect } from 'react';
import { Box, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useIntl } from 'react-intl';

import { Table } from './styles';

import TextField from '../TextField';
import PaperWithTitle from '../PaperWithTitle';

const styledTable = ({
  title = '',
  filters,
  onChange,
  onDownload,
  count,
  children,
  initLimit = 10,
  filtersValues = {},
}) => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initLimit);
  const { formatMessage: f, locale } = useIntl();

  // Debounce logic
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        onChange &&
        search.length > 2 &&
        search.toLowerCase() !== filter.toLowerCase()
      ) {
        onChange({
          offset: 0,
          limit: rowsPerPage,
          search: search.toLowerCase(),
          ...filtersValues,
        });
        setPage(0);
        setFilter(search.toLowerCase());
      } else if (onChange && filter.toLowerCase() !== '') {
        onChange({
          offset: 0,
          limit: rowsPerPage,
          search: '',
          ...filtersValues,
        });
        setPage(0);
        setFilter('');
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  return (
    <>
      <PaperWithTitle title={title}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          {onChange && (
            <Box ml={2} display="flex" flexDirection="row" alignItems="center">
              <TextField
                id="filter"
                name="filter"
                label={f({ id: 'tableFilterLabel' })}
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
              {filters &&
                filters({
                  offset: page * rowsPerPage,
                  limit: rowsPerPage,
                  search,
                  ...filtersValues,
                })}
            </Box>
          )}
          {onDownload && (
            <Box mr={2}>
              <Tooltip title={f({ id: 'download' })}>
                <IconButton
                  size="small"
                  aria-label={f({ id: 'download' })}
                  onClick={onDownload}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        <Table>{children}</Table>
        {Number.isInteger(count) && onChange && (
          <TablePagination
            labelRowsPerPage={
              locale === 'hu' ? 'Sorok száma:' : 'Rows per page:'
            }
            labelDisplayedRows={({ from, to, count }) => {
              if (locale === 'hu') {
                return `${from}–${to} a ${
                  count !== -1 ? count : `több mint ${to}`
                } elemből`;
              } else {
                return `${from}–${to} of ${
                  count !== -1 ? count : `more than ${to}`
                }`;
              }
            }}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, newPage) => {
              setPage(newPage);
              onChange({
                offset: newPage * rowsPerPage,
                limit: rowsPerPage,
                search: filter,
                ...filtersValues,
              });
            }}
            onChangeRowsPerPage={(event) => {
              const newRowsPerPage = parseInt(event.target.value, 10);
              setRowsPerPage(newRowsPerPage);
              setPage(0);
              onChange({
                offset: 0,
                limit: newRowsPerPage,
                search: filter,
                ...filtersValues,
              });
            }}
          />
        )}
      </PaperWithTitle>
    </>
  );
};
export default styledTable;
