import React from 'react';
import styled from 'styled-components';
import { Avatar, Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { serviceColors } from '../utils';

const ColoredAvatar = styled(Avatar)`
  && {
    cursor: pointer;
    background-color: ${(props) =>
      props.backgroundcolor ? `${props.backgroundcolor}` : `black`};
    ${(props) =>
      props.value === props.backgroundcolor ? `border: 3px solid black;` : ``};
  }
`;

const ColorPicker = ({ value, onChange }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Box maxWidth="300px">
      <Box>
        <Typography variant="body2">
          {f({ id: 'colorPickerAssignedColour' })}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" pt={2}>
        {serviceColors.map((color) => (
          <Box m={1} key={color}>
            <ColoredAvatar
              value={value}
              backgroundcolor={color}
              onClick={() => onChange(color)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ColorPicker;
