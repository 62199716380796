import React, { useState, useEffect } from 'react';
import { Input, MenuItem, Select, InputLabel, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import PaperWithTitle from '../../PaperWithTitle';
import FormControl from '../../FormControl';
import PaddedGrid from '../../PaddedGrid';
import PrimaryButton from '../../PrimaryButton';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

import {
  miscTransaction,
  getEmployees,
  getProducts,
  getMiscCategories,
} from '../../../actions';
import { isPositiveInt, getUser } from '../../../utils';

const InputMiscTransactionForm = ({
  employees,
  miscCategories,
  enqueueSnackbar,
  dispatch,
}) => {
  const [createdBy, setCreatedBy] = useState('');
  const [notes, setNotes] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentDirection, setPaymentDirection] = useState('');
  const [miscCategory, setMiscCategory] = useState('');
  const [miscCategoryError, setMiscCategoryError] = useState('');
  const [amount, setAmount] = useState(0);
  const [notesError, setNotesError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [createdByError, setCreatedByError] = useState(false);
  const [paymentDirectionError, setPaymentDirectionError] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const { formatMessage: f } = useIntl();

  const paymentMethods = [
    {
      value: 'cash',
      label: f({ id: 'paymentMethodCash' }),
    },
    {
      value: 'card',
      label: f({ id: 'paymentMethodCard' }),
    },
  ];

  const paymentDirections = [
    {
      value: 'out',
      label: f({ id: 'paymentDirectionOut' }),
    },
    {
      value: 'in',
      label: f({ id: 'paymentDirectionIn' }),
    },
  ];

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getProducts());
    dispatch(getMiscCategories());
  }, []);

  const handleSubmit = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (paymentMethod === '') {
      setPaymentMethodError(true);
      error = true;
    } else {
      setPaymentMethodError(false);
    }

    if (paymentDirection === '') {
      setPaymentDirectionError(true);
      error = true;
    } else {
      setPaymentDirectionError(false);
    }

    if (miscCategory === '') {
      setMiscCategoryError(true);
      error = true;
    } else {
      setMiscCategoryError(false);
    }

    if (notes === '') {
      setNotesError(true);
      error = true;
    } else {
      setNotesError(false);
    }

    if (!isPositiveInt(amount)) {
      setAmountError(true);
      error = true;
    } else {
      setAmountError(false);
    }

    if (error) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      return;
    }

    setSubmitEnabled(false);

    const calculatedAmount = paymentDirection === 'in' ? amount : amount * -1;

    const transactionData = {
      createdBy,
      miscCategory,
      shopId: getUser().shopId,
      paymentMethod,
      item: {
        amount: calculatedAmount,
        notes,
      },
    };

    dispatch(miscTransaction(transactionData, enqueueSnackbar, f));
  };

  return (
    <PaperWithTitle>
      <Grid container spacing={0}>
        <PaddedGrid item xs={12}>
          <FormControl required error={createdByError}>
            <InputLabel htmlFor="createdBy">
              {f({ id: 'createdBy' })}
            </InputLabel>
            <Select
              value={createdBy}
              onChange={(event) => setCreatedBy(event.target.value)}
              input={<Input name="createdBy" id="createdBy" />}
            >
              {employees.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required error={paymentDirectionError}>
            <InputLabel htmlFor="paymentDirection">
              {f({ id: 'operation' })}
            </InputLabel>
            <Select
              value={paymentDirection}
              onChange={(event) => setPaymentDirection(event.target.value)}
              input={<Input name="paymentDirection" id="paymentDirection" />}
            >
              {paymentDirections.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required error={paymentMethodError}>
            <InputLabel htmlFor="paymentMethod">
              {f({ id: 'paymentMethod' })}
            </InputLabel>
            <Select
              value={paymentMethod}
              name="paymentMethod"
              onChange={(event) => setPaymentMethod(event.target.value)}
              inputProps={{
                id: 'paymentMethod',
              }}
            >
              {paymentMethods.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <TextField
            error={amountError}
            required
            id="amount"
            name="amount"
            label={f({ id: 'amount' })}
            margin="normal"
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
            type="number"
          />
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required error={miscCategoryError}>
            <InputLabel htmlFor="miscCategory">
              {f({ id: 'category' })}
            </InputLabel>
            <Select
              value={miscCategory}
              name="miscCategory"
              onChange={(event) => setMiscCategory(event.target.value)}
              inputProps={{
                id: 'miscCategory',
              }}
            >
              {miscCategories &&
                miscCategories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <FormControl required>
            <TextField
              id="notes"
              name="notes"
              error={notesError}
              // multiline
              // rows="4"
              placeholder={`${f({ id: 'comment' })}*`}
              fullWidth
              value={notes}
              margin="normal"
              onChange={(event) => setNotes(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <PrimaryButton
            type="submit"
            onClick={(event) => handleSubmit(event)}
            disabled={!submitEnabled}
          >
            {f({ id: 'buttonInput' })}
          </PrimaryButton>
        </PaddedGrid>
      </Grid>
    </PaperWithTitle>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  products: state.products,
  miscCategories: state.miscCategories,
});

export default BaseHOC(InputMiscTransactionForm, null, mapStateToProps);
