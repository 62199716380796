import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import Table from '../../Table';
import BoldCell from '../../BoldCell';
import IndentedCell from '../../IndentedCell';
import CurrencyFormat from '../../CurrencyFormat';
import Loader from '../../Loader';
import BaseHOC from '../../BaseHOC';

const TillCloseDetailsTable = () => {
  const { formatMessage: f } = useIntl();
  const dailyTillClose = useSelector((state) => state.dailyTillClose);

  const getBalanceTitle = (obj) => {
    const balance = parseFloat(obj.balance);

    if (!obj.created) {
      return f({ id: 'tillCloseNoTillCloseYet' });
    }

    if (balance > 0) {
      return f({ id: 'tillCloseTooMuchInTill' });
    }

    if (balance < 0) {
      return f({ id: 'tillCloseNotEnoughInTill' });
    }

    return f({ id: 'tillCloseMatches' });
  };

  return (
    (dailyTillClose && (
      <Table title={f({ id: 'tillCloseTitle' })}>
        <TableBody>
          <TableRow>
            <BoldCell>{f({ id: 'tillCloseOpeningBalance' })}</BoldCell>
            <BoldCell>
              <CurrencyFormat amount={dailyTillClose.opening_balance} />
            </BoldCell>
          </TableRow>
          <TableRow>
            <BoldCell>{f({ id: 'tillCloseDailyCashRevenue' })}</BoldCell>
            <BoldCell>
              <CurrencyFormat
                amount={
                  dailyTillClose.cash_sales_on_day_sum +
                  dailyTillClose.cash_misc_in_on_day
                }
              />
            </BoldCell>
          </TableRow>
          <TableRow>
            <IndentedCell>
              {f({ id: 'tillCloseDailyCashSalesRevenue' })}
            </IndentedCell>
            <TableCell>
              <CurrencyFormat amount={dailyTillClose.cash_sales_on_day_sum} />
            </TableCell>
          </TableRow>
          <TableRow>
            <IndentedCell>
              {f({ id: 'tillCloseDailyCashMiscRevenue' })}
            </IndentedCell>
            <TableCell>
              <CurrencyFormat amount={dailyTillClose.cash_misc_in_on_day} />
            </TableCell>
          </TableRow>
          <TableRow>
            <BoldCell>{f({ id: 'tillCloseDailyCashExpense' })}</BoldCell>
            <BoldCell>
              <CurrencyFormat
                amount={
                  -dailyTillClose.cash_out + dailyTillClose.cash_misc_out_on_day
                }
              />
            </BoldCell>
          </TableRow>
          <TableRow>
            <IndentedCell>
              {f({ id: 'tillCloseDailyCashMiscExpense' })}
            </IndentedCell>
            <TableCell>
              <CurrencyFormat amount={dailyTillClose.cash_misc_out_on_day} />
            </TableCell>
          </TableRow>
          <TableRow>
            <IndentedCell>
              {f({ id: 'tillCloseDailyCashTillTakenOut' })}
            </IndentedCell>
            <TableCell>
              <CurrencyFormat amount={-dailyTillClose.cash_out} />
            </TableCell>
          </TableRow>
          <TableRow>
            <BoldCell>{f({ id: 'tillCloseDailyCashBalance' })}</BoldCell>
            <BoldCell>
              <CurrencyFormat
                amount={
                  dailyTillClose.opening_balance +
                  dailyTillClose.cash_sales_on_day_sum +
                  dailyTillClose.cash_misc_in_on_day -
                  dailyTillClose.cash_out +
                  dailyTillClose.cash_misc_out_on_day
                }
              />
            </BoldCell>
          </TableRow>
          <TableRow>
            <BoldCell>{getBalanceTitle(dailyTillClose)}</BoldCell>
            <BoldCell>
              {dailyTillClose.balance ? (
                <CurrencyFormat amount={dailyTillClose.balance} />
              ) : (
                '-'
              )}
            </BoldCell>
          </TableRow>
        </TableBody>
      </Table>
    )) || <Loader />
  );
};

export default BaseHOC(TillCloseDetailsTable);
