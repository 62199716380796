export default (theme) => ({
  // root: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   flexGrow: 1,
  //   width: '100%',
  //   overflowX: 'auto',
  //   padding: theme.spacing(2),
  //   marginTop: theme.spacing(3),
  // },
  table: {
    minWidth: 200,
  },
  dialogTable: {
    minWidth: 200,
  },
  datePicker: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  margin: {
    margin: theme.spacing(1),
  },
  subHeading: {
    marginTop: theme.spacing(4),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  paperHeading: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cellBold: {
    fontWeight: 800,
  },
  cellIndented: {
    paddingLeft: theme.spacing(4),
  },
  tipSelectorClass: {
    paddingLeft: theme.spacing(1),
  },
  tipSelectorDisabledClass: {
    paddingLeft: theme.spacing(1),
    color: 'gray',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  // paper: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   flexGrow: 1,
  //   padding: theme.spacing(2),
  //   maxWidth: 500,
  // },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});
