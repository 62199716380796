import React from 'react';
import { Grid } from '@material-ui/core';

import DashboardLayout from '../../DashboardLayout';
import CalendarCancelledBookings from './CalendarCancelledBookings';

const ClientSideOnlyLazy = React.lazy(() => import('./Calendar'));

const CalendarPage = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ClientSideOnlyLazy />
            </React.Suspense>
          )}
        </Grid>
        <Grid item xs={12}>
          <CalendarCancelledBookings />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CalendarPage;
