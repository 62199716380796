import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  DialogTitle,
  Input,
  MenuItem,
  FormControl,
  Select,
  Container,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import BaseHOC from '../../BaseHOC';

import { voidInventoryInput, getEmployees } from '../../../actions';

import DashboardLayout from '../../DashboardLayout';
import { Header, SubHeader } from '../../Header/styles';

const dangerTheme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  voidHeading: {
    marginTop: theme.spacing(2),
    color: 'red',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

const AdminInventoryInputDetailsPage = ({
  classes,
  enqueueSnackbar,
  employees,
  dispatch,
}) => {
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { inventoryInput } = window.history.state;
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (employees.length === 0) dispatch(getEmployees());
  }, []);

  const markInventoryInputVoid = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (error) {
      return;
    }

    const voidData = {
      inventoryInput: inventoryInput.id,
      createdBy,
    };

    dispatch(voidInventoryInput(voidData, enqueueSnackbar, f));
  };

  const reset = () => {
    setCreatedBy('');
    setCreatedByError(false);
    setDialogOpen(false);
  };

  return (
    <DashboardLayout>
      <Container>
        <Box pb={4}>
          <Header variant="h2" noWrap>
            {f({ id: 'inventoryInputDetails' })}
          </Header>
        </Box>
        <Box pb={1}>
          <SubHeader variant="body1" gutterBottom>
            {moment(inventoryInput.created).format('YYYY-MM-DD HH:mm')}
          </SubHeader>
        </Box>
        <Box pb={1}>
          <SubHeader variant="body1" gutterBottom>
            {inventoryInput.created_by.display_name}
          </SubHeader>
        </Box>
        <Box pb={1}>
          <SubHeader variant="body1" gutterBottom>
            {inventoryInput.product.title}
          </SubHeader>
        </Box>
        <Box pb={1}>
          <SubHeader variant="body1" gutterBottom>
            {`${inventoryInput.quantity} db`}
          </SubHeader>
        </Box>
        <Box pb={1}>
          <SubHeader variant="body1" gutterBottom>
            {inventoryInput.created_by.name}
          </SubHeader>
        </Box>
        {inventoryInput.voided ? (
          <Box pb={2}>
            <SubHeader
              variant="h5"
              gutterBottom
              className={classes.voidHeading}
            >
              {`${f({ id: 'transactionDetailsVoided' })} - ${
                inventoryInput.voided_by.display_name
              } - ${moment(inventoryInput.voided).format('YYYY-MM-DD HH:mm')}`}
            </SubHeader>
          </Box>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => window.history.back()}
        >
          {f({ id: 'buttonBack' })}
        </Button>
        <MuiThemeProvider theme={dangerTheme}>
          <Button
            disabled={inventoryInput.voided !== null}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setDialogOpen(true)}
          >
            {f({ id: 'buttonVoid' })}
          </Button>
        </MuiThemeProvider>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={dialogOpen}
          onExit={() => reset()}
        >
          <DialogTitle>{f({ id: 'selectColleague' })}</DialogTitle>
          <DialogContent>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={createdByError}
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="createdBy">
                    {f({ id: 'createdBy' })}
                  </InputLabel>
                  <Select
                    value={createdBy}
                    onChange={(event) => setCreatedBy(event.target.value)}
                    error={createdByError}
                    input={<Input name="createdBy" id="createdBy" />}
                  >
                    {employees.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset()} color="primary">
              {f({ id: 'buttonCancel' })}
            </Button>
            <MuiThemeProvider theme={dangerTheme}>
              <Button onClick={() => markInventoryInputVoid()} color="primary">
                {f({ id: 'buttonVoid' })}
              </Button>
            </MuiThemeProvider>
          </DialogActions>
        </Dialog>
      </Container>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
});

export default BaseHOC(AdminInventoryInputDetailsPage, styles, mapStateToProps);
