import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import BoldCell from '../../BoldCell';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';
import IndentedCell from '../../IndentedCell';
import { CellHeading } from './styles';

const ReportsSummaryRevenue = () => {
  const revenueSummary = useSelector((state) => state.revenueSummary);
  const cardSum =
    revenueSummary.card_service_sum +
    revenueSummary.card_product_sum +
    revenueSummary.card_giftcard_sum;
  const cashSum =
    revenueSummary.cash_service_sum +
    revenueSummary.cash_product_sum +
    revenueSummary.cash_giftcard_sum;
  const noMiscSum = cardSum + cashSum;
  const { formatMessage: f } = useIntl();
  return (
    <Table title={f({ id: 'summaryRevenueTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <CellHeading variant="h4" gutterBottom noWrap>
              <CurrencyFormat amount={noMiscSum} />
            </CellHeading>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <BoldCell>{f({ id: 'summaryRevenueCard' })}</BoldCell>
          <BoldCell>
            <CurrencyFormat amount={cardSum} />
          </BoldCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCardService' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.card_service_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCardProduct' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.card_product_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCardGiftcard' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.card_giftcard_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <BoldCell>{f({ id: 'summaryRevenueCash' })}</BoldCell>
          <BoldCell>
            <CurrencyFormat amount={cashSum} />
          </BoldCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCashService' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.cash_service_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCashProduct' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.cash_product_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <IndentedCell>{f({ id: 'summaryRevenueCashGiftcard' })}</IndentedCell>
          <TableCell>
            <CurrencyFormat amount={revenueSummary.cash_giftcard_sum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <BoldCell>{f({ id: 'summaryRevenueMiscRevenue' })}</BoldCell>
          <BoldCell>
            <CurrencyFormat amount={revenueSummary.misc_in_sum} />
          </BoldCell>
        </TableRow>
        <TableRow>
          <BoldCell>{f({ id: 'summaryRevenueMiscExpense' })}</BoldCell>
          <BoldCell>
            <CurrencyFormat amount={revenueSummary.misc_out_sum} />
          </BoldCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsSummaryRevenue);
