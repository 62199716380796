import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import Loader from '../../Loader';
import BaseHOC from '../../BaseHOC';

const TillCloseNotesLeftInTillTable = () => {
  const { formatMessage: f } = useIntl();
  const settings = useSelector((state) => state.settings);
  const dailyTillClose = useSelector((state) => state.dailyTillClose);

  return (
    (dailyTillClose && (
      <Table title={f({ id: 'adminTillNotesInCounter' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'tillCloseBanknote' })}</TableCell>
            <TableCell>{f({ id: 'tillCloseCount' })}</TableCell>
            <TableCell>{f({ id: 'tillCloseValue' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settings &&
            settings.country &&
            settings.country.notes.split(',').map((row) => (
              <TableRow key={row}>
                <TableCell>
                  <CurrencyFormat
                    amount={
                      parseInt(row, 10) /
                      (((settings || {}).country || {}).currency_multiplier ||
                        1)
                    }
                    bankNotesAndCoins
                  />
                </TableCell>
                <TableCell>{dailyTillClose[`note${row}`]}</TableCell>
                <TableCell>
                  <CurrencyFormat
                    amount={
                      (parseInt(row, 10) /
                        (((settings || {}).country || {}).currency_multiplier ||
                          1)) *
                      parseInt(dailyTillClose[`note${row}`], 10)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell />
            <TableCell>{f({ id: 'tillCloseTotal' })}</TableCell>
            <TableCell>
              <CurrencyFormat amount={dailyTillClose.cash_in_till} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )) || <Loader />
  );
};

export default BaseHOC(TillCloseNotesLeftInTillTable);
