import React, { useState } from 'react';
import { Grid, Paper, Box } from '@material-ui/core';
import moment from 'moment';
import { useIntl } from 'react-intl';

import DashboardLayout from '../../DashboardLayout';
import Header from '../../Header';
import BaseHOC from '../../BaseHOC';
import StartDateEndDateFilter from '../../StartDateEndDateFilter';

import {
  getBookingStatsKPI,
  getBookingStatsTimeseries,
  getBookingStatsServices,
  getBookingStatsBarbers,
  getBookingStatsBookings,
} from '../../../actions';

import AdminStatsKPI from './AdminStatsKPI';
import AdminStatsTimeseries from './AdminStatsTimeseries';
import AdminStatsRatios from './AdminStatsRatios';
import AdminStatsEmployees from './AdminStatsEmployees';
import AdminStatsServices from './AdminStatsServices';
import AdminStatsBookings from './AdminStatsBookings';

const AdminStatsPage = ({ enqueueSnackbar, dispatch, uri }) => {
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const { formatMessage: f } = useIntl();

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'statsTooltip' })}>
            {f({ id: 'appNavAdminBookingStats' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            allowFutureFilter
            uri={uri}
            onFilterResults={(newStartDate, newEndDate, shopIdList) => {
              setFilterStartDate(newStartDate);
              setFilterEndDate(newEndDate);
              const args = {
                shopIdList,
                startDate: moment(newStartDate)
                  .startOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss'),
                endDate: moment(newEndDate)
                  .endOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss'),
                enqueueSnackbar,
              };
              dispatch(getBookingStatsKPI(args));
              dispatch(getBookingStatsTimeseries(args));
              dispatch(getBookingStatsServices(args));
              dispatch(getBookingStatsBarbers(args));
              dispatch(getBookingStatsBookings(args));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box pb={2}>
              <AdminStatsKPI />
              <AdminStatsTimeseries />
              <AdminStatsRatios />
              <AdminStatsEmployees />
              <AdminStatsBookings />
              <AdminStatsServices />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminStatsPage);
