import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import 'react-quill/dist/quill.snow.css';

import * as _ from 'lodash';

import BaseHOC from '../../BaseHOC';

const ReactQuill = React.lazy(() => import('react-quill'));

const AdminBookingSettingsPolicyEditor = ({ policy, editorRef }) => {
  const isSSR = typeof window === 'undefined';
  const { formatMessage: f } = useIntl();

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  return (
    <>
      <Box ml={4} mb={2}>
        <Typography variant="body1">
          {f({ id: 'bookingSettingsPrivacyPolicyHeading' })}
        </Typography>
      </Box>
      <Box justifyContent="center" display="flex" mb={2}>
        <Box width="90%">
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ReactQuill
                ref={editorRef}
                modules={modules}
                formats={formats}
                defaultValue={policy}
              />
            </React.Suspense>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BaseHOC(AdminBookingSettingsPolicyEditor);
