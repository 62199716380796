import React from 'react';
import { navigate } from 'gatsby';
import { useIntl } from 'react-intl';
import { getUser } from '../utils';

const AdminRoute = ({
  component: Component,
  location,
  managementOnly,
  bookingOnly,
  ...rest
}) => {
  const { locale } = useIntl();
  const user = getUser();
  const shopMap = (user && user.shopMap) || [];
  if (
    (!user || user.role !== 'admin') &&
    location.pathname !== `${locale}/app/login`
  ) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/${locale}/app/login`);
    return null;
  }

  if (bookingOnly && shopMap.filter((x) => x.booking).length === 0) {
    // If booking permission required but not present => default
    navigate(`/${locale}/app`);
    return null;
  }

  if (managementOnly && shopMap.filter((x) => x.management).length === 0) {
    // If booking permission required but not present => default
    navigate(`/${locale}/app`);
    return null;
  }

  return <Component {...rest} />;
};

export default AdminRoute;
