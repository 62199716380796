import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const StyledGrid = styled(Grid)`
  && {
    overflow-x: auto;
    padding-top: 0.5rem;
  }
`;
