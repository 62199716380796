import React from 'react';
import { navigate } from 'gatsby';

import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import PrimaryButton from '../../PrimaryButton';
import BaseHOC from '../../BaseHOC';

const AdminProductInventoryAddForm = () => {
  const { formatMessage: f, locale } = useIntl();
  return (
    <Box m={1} display="flex" flexDirection="row">
      <Box p={1}>
        <PrimaryButton
          onClick={() =>
            navigate(`/${locale}/app/product-inventory-multi-input`)
          }
        >
          {f({ id: 'inventoryAddProducts' })}
        </PrimaryButton>
      </Box>
      <Box p={1}>
        <PrimaryButton
          onClick={() =>
            navigate(`/${locale}/app/product-inventory-check-input`)
          }
        >
          {f({ id: 'inventoryDoInventory' })}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default BaseHOC(AdminProductInventoryAddForm);
