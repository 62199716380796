import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';

import { getEmployees } from '../../../actions';

const ClientDetailsDialog = ({
  open,
  onCancel,
  onDelete,
  clientDetails,
  employees,
  dispatch,
}) => {
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (employees.length === 0) dispatch(getEmployees());
  }, [employees]);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogTitle>{f({ id: 'clientsClientDetails' })}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">{`${f({ id: 'clientsClientName' })}: ${
            clientDetails.name
          }`}</Typography>
          <Typography variant="body1">{`${f({ id: 'clientsClientEmail' })}: ${
            clientDetails.email
          }`}</Typography>
          <Typography variant="body1">{`${f({ id: 'clientsClientPhone' })}: ${
            clientDetails.mobile
          }`}</Typography>
          <Typography variant="body1">{`${f({
            id: 'clientsFirstBookingDate',
          })}: ${moment(clientDetails.first_booking_date).format(
            'YYYY-MM-DD',
          )} (${moment().diff(
            moment(clientDetails.first_booking_date),
            'days',
          )} ${f({ id: 'daysAgo' })})`}</Typography>
        </Box>
        <Box mb={2}>
          <Table title="">
            <TableHead>
              <TableRow>
                <TableCell>{f({ id: 'clientsBookingDate' })}</TableCell>
                <TableCell>{f({ id: 'clientsBookingTime' })}</TableCell>
                <TableCell>{f({ id: 'kindService' })}</TableCell>
                <TableCell>{f({ id: 'clientsBookingSource' })}</TableCell>
                <TableCell>{f({ id: 'clientsBookingBarber' })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientDetails &&
                clientDetails.bookings &&
                clientDetails.bookings.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {moment(row.start).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell>{moment(row.start).format('HH:mm')}</TableCell>
                    <TableCell>{row.service.title}</TableCell>
                    <TableCell>
                      {row.staff_input
                        ? f({ id: 'clientsBookingSourceInternal' })
                        : f({ id: 'clientsBookingSourceExternal' })}
                    </TableCell>
                    <TableCell>
                      {row.barber_selected && row.employee
                        ? row.employee.display_name
                        : f({ id: 'bookingBarberInitialsUnassigned' })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={onCancel}>
          {f({ id: 'buttonBack' })}
        </PrimaryButton>
        <PrimaryButton danger onClick={onDelete}>
          {f({ id: 'clientsDeleteClient' })}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  employees: state.employees,
});

export default BaseHOC(ClientDetailsDialog, null, mapStateToProps);
