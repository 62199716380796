import styled from 'styled-components';
import { Typography } from '@material-ui/core';

// TODO: Add h4 and other typo props here and update codeto remove those props
export const CellHeading = styled(Typography)`
  && {
    text-align: center;
    padding-top: 1rem;
  }
`;
