import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Button,
  TextField,
  Box,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';

import { getUser, serviceColors, isPositiveInt } from '../utils';
import { addService } from '../actions';

const OnboardingServiceDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { shopMap } = getUser();
  const shopIdList = shopMap.map((x) => x.id);
  const settings = useSelector((state) => state.settings);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([
    {
      name: '',
      price: '',
      duration: '',
      durationError: false,
      durationErrorMessage: '',
      priceError: false,
      priceErrorMessage: '',
    },
  ]);

  const sendServices = async (dataToSend) => {
    setIsLoading(true);

    for (const data of dataToSend) {
      await dispatch(addService(data, enqueueSnackbar, true, f));
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {f({ id: 'onboardingServiceDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {f({ id: 'onboardingServiceDialogHelper' })}
        </DialogContentText>
        {services.map((service, i) => (
          <Box display="flex" key={`service-${i}`}>
            <TextField
              disabled={isLoading}
              margin="dense"
              id={`name-${i}`}
              label={i === 0 && f({ id: 'onboardingServiceDialogServiceCol' })}
              InputLabelProps={{
                shrink: true,
              }}
              value={service.name}
              onChange={(e) => {
                const newState = [...services];
                newState[i].name = (e.target || {}).value || '';
                setServices(newState);
              }}
            />
            <Box pl={2} maxWidth="140px">
              <TextField
                disabled={isLoading}
                helperText={service.priceErrorMessage}
                error={service.priceError}
                id={`price-${i}`}
                margin="dense"
                label={i === 0 && f({ id: 'onboardingServiceDialogPriceCol' })}
                value={service.price}
                onChange={(e) => {
                  const newState = [...services];
                  newState[i].price = (e.target || {}).value;
                  setServices(newState);
                }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={
                  (settings?.country?.currency === 'Ft' && {
                    endAdornment: (
                      <InputAdornment position="end">Ft</InputAdornment>
                    ),
                  }) ||
                  (settings?.country?.currency === 'GBP' && {
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }) ||
                  (settings?.country?.currency === 'EUR' && {
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }) ||
                  (settings?.country?.currency === 'CZK' && {
                    startAdornment: (
                      <InputAdornment position="end">Kč</InputAdornment>
                    ),
                  })
                }
              />
            </Box>
            <Box pl={2} maxWidth="200px">
              <TextField
                disabled={isLoading}
                helperText={service.durationErrorMessage}
                error={service.durationError}
                id={`duration-${i}`}
                margin="dense"
                label={
                  i === 0 && f({ id: 'onboardingServiceDialogDurationCol' })
                }
                value={service.duration}
                onChange={(e) => {
                  const newState = [...services];
                  newState[i].duration = (e.target || {}).value;
                  setServices(newState);
                }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {f({ id: 'minutes' })}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        ))}
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={() => {
            const newState = [
              ...services,
              { name: '', price: '', duration: '' },
            ];
            setServices(newState);
          }}
        >
          {f({ id: 'onboardingAddMore' })}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          {f({ id: 'buttonCancel' })}
        </Button>
        <Box style={{ m: 1, position: 'relative' }}>
          <Button
            onClick={() => {
              const dataToSend = [];
              let error = false;

              // Check for duplicates
              const names = services.map((x) => x.name).filter((x) => x);

              if (names.length !== [...new Set(names)].length) {
                enqueueSnackbar(
                  f({ id: 'onboardingServiceDialogDuplicateError' }),
                  {
                    variant: 'warning',
                  },
                );
                error = true;
              }

              services.forEach((service, index) => {
                if (service.name) {
                  if (
                    !isPositiveInt(service.duration) ||
                    parseInt(service.duration, 10) % 5 !== 0
                  ) {
                    const newState = [...services];
                    newState[index].durationError = true;
                    newState[index].durationErrorMessage = f({
                      id: 'serviceMultipleOf15Error',
                    });
                    setServices(newState);
                    error = true;
                  } else {
                    const newState = [...services];
                    newState[index].durationError = false;
                    newState[index].durationErrorMessage = '';
                    setServices(newState);
                  }

                  if (parseFloat(service.price || 0) <= 0) {
                    const newState = [...services];
                    newState[index].priceError = true;
                    // TODO: Translate
                    newState[index].priceErrorMessage = f({
                      id: 'onboardingServiceDialogNumberError',
                    });
                    setServices(newState);
                    error = true;
                  } else {
                    const newState = [...services];
                    newState[index].priceError = false;
                    newState[index].priceErrorMessage = '';
                    setServices(newState);
                  }

                  const data = {
                    title: service.name,
                    price: service.price,
                    description: '',
                    duration: service.duration,
                    shopId: shopIdList[0],
                    displayOrder: index + 1,
                    color:
                      serviceColors[
                        Math.floor(Math.random() * serviceColors.length)
                      ],
                  };
                  dataToSend.push(data);
                }
              });

              if (error) {
                return;
              }

              sendServices(dataToSend);
            }}
            color="primary"
            disabled={isLoading || services.filter((x) => x.name).length < 1}
            variant="contained"
          >
            {f({ id: 'buttonInput' })}
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingServiceDialog;
