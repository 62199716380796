import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

import { getProducts } from '../../../actions';
import { getUser } from '../../../utils';

const AdminProductsList = ({ products, searchText, dispatch }) => {
  const { shopMap } = getUser();
  const [filteredProducts, setFilteredProducts] = useState(products);
  const { formatMessage: f, locale } = useIntl();

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(
    () =>
      setFilteredProducts(
        products.filter((x) =>
          x.title.toUpperCase().includes(searchText.toUpperCase()),
        ),
      ),
    [searchText, products],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'kindProduct' })}</TableCell>
          {shopMap.length > 1 ? (
            <TableCell>{f({ id: 'shop' })}</TableCell>
          ) : null}
          <TableCell>{f({ id: 'price' })}</TableCell>
          <TableCell>{f({ id: 'edit' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredProducts.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.title}</TableCell>
            {shopMap.length > 1 ? (
              <TableCell>
                {shopMap.find((x) => x.id === row.shop_id).name}
              </TableCell>
            ) : null}
            <TableCell>
              <CurrencyFormat amount={row.price} />
            </TableCell>
            <TableCell>
              <IconButton
                aria-label={f({ id: 'edit' })}
                onClick={() =>
                  navigate(`/${locale}/app/products/edit`, {
                    state: { product: row },
                  })
                }
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
});

export default BaseHOC(AdminProductsList, null, mapStateToProps);
