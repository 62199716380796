import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import StartDateEndDateFilter from '../../StartDateEndDateFilter';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminTillClosingsList from './AdminTillClosingsList';

import { listTillClosings } from '../../../actions';
import { getDateFilterFromUrl } from '../../../utils';

const AdminTillClosingsPage = ({
  enqueueSnackbar,
  tillClosings,
  history,
  uri,
  dispatch,
}) => {
  const initShopId = _.isEmpty(getDateFilterFromUrl().shopIdList)
    ? undefined
    : getDateFilterFromUrl().shopIdList;
  const { formatMessage: f, locale } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'adminTillTooltip' })}>
            {f({ id: 'appNavAdminShopTillClosings' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <StartDateEndDateFilter
            uri={uri}
            singleShopSelect
            onFilterResults={(startDate, endDate, shopIdList) => {
              dispatch(
                listTillClosings({
                  startDate,
                  endDate,
                  shopId: shopIdList[0],
                  onlyLatest: true,
                  enqueueSnackbar,
                }),
              );
            }}
            rememberDates
            initShopIdList={initShopId}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminTillClosingsList
            history={history}
            tillClosings={tillClosings}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  tillClosings: state.tillClosings,
});

export default BaseHOC(AdminTillClosingsPage, null, mapStateToProps);
