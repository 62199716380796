import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { getNet, getEmploymentLabel, toPercentage } from '../../../utils';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformanceProductPerformance = () => {
  const getProductSum = (item) =>
    parseFloat(item.product_sum_gift_card) +
    parseFloat(item.product_sum_card) +
    parseFloat(item.product_sum_cash);
  const { formatMessage: f } = useIntl();
  const productServicePerformance = useSelector(
    (state) => state.productServicePerformance,
  );
  const settings = useSelector((state) => state.settings);

  return (
    <Table title={f({ id: 'performanceProductTitle' })}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'createdBy' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'employmentKind' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodGiftcard' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodCard' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'paymentMethodCash' })}
          </TableCell>
          <TableCell colSpan={2} align="center">
            {f({ id: 'total' })}
          </TableCell>
          <TableCell rowSpan={2} align="center">
            {f({ id: 'performanceProductProductRatio' })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'netTotal' })}</TableCell>
          <TableCell align="center">{f({ id: 'grossTotal' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productServicePerformance
          .filter((x) => x.product_ratio > 0)
          .map((row) => (
            <TableRow key={`product-perf${row.name}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{getEmploymentLabel(row, f)}</TableCell>
              <TableCell>
                <CurrencyFormat
                  amount={getNet(
                    row.product_sum_gift_card,
                    settings.country.currency,
                  )}
                />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.product_sum_gift_card} />
              </TableCell>
              <TableCell>
                <CurrencyFormat
                  amount={getNet(
                    row.product_sum_card,
                    settings.country.currency,
                  )}
                />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.product_sum_card} />
              </TableCell>
              <TableCell>
                <CurrencyFormat
                  amount={getNet(
                    row.product_sum_cash,
                    settings.country.currency,
                  )}
                />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.product_sum_cash} />
              </TableCell>
              <TableCell>
                <CurrencyFormat
                  amount={getNet(getProductSum(row), settings.country.currency)}
                />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={getProductSum(row)} />
              </TableCell>
              <TableCell>{toPercentage(row.product_ratio)}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformanceProductPerformance);
