import React, { useState } from 'react';

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

import PaddedGrid from '../../PaddedGrid';
import SubHeader from '../../SubHeader';
import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import PaperWithTitle from '../../PaperWithTitle';

import FormControl from '../../FormControl';

import {
  getMiscCategories,
  createMiscCategory,
  deleteMiscCategory,
  getMiscTransactionStats,
} from '../../../actions';

const AdminMiscTransactionsCategoriesForm = ({
  miscCategories,
  dispatch,
  enqueueSnackbar,
  startDate,
  endDate,
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [disableAddButton, setDisableAddButton] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToReplace, setCategoryToReplace] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [miscCategoryError, setMiscCategoryError] = useState(false);

  const { formatMessage: f, locale } = useIntl();

  return (
    <Box width="400px">
      <PaperWithTitle>
        <PaddedGrid item xs={12}>
          <SubHeader style={{ fontSize: '1.25rem' }}>
            {f({ id: 'miscTransactionCategories' })}
          </SubHeader>
        </PaddedGrid>
        <PaddedGrid item xs={12}>
          <Box display="flex" flexDirection="column" mb={2}>
            {miscCategories &&
              miscCategories.map((category) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    key={category.id}
                    mb={1}
                  >
                    <Box>{category.name}</Box>
                    <Box>
                      <IconButton
                        size="small"
                        aria-label={f({ id: 'buttonDelete' })}
                        onClick={() => {
                          setDeleteDialogOpen(true);
                          setCategoryToDelete(category);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          <PrimaryButton onClick={() => setAddDialogOpen(true)}>
            {f({ id: 'miscTransactionCategoriesAdd' })}
          </PrimaryButton>
          <Dialog
            open={addDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {f({ id: 'miscTransactionCategoriesAdd' })}
            </DialogTitle>
            <DialogContent>
              <Box mb={3}>
                <TextField
                  fullWidth
                  id="newCategoryName"
                  name="newCategoryName"
                  label={f({ id: 'miscTransactionCategoryName' })}
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton
                onClick={() => {
                  setNewCategoryName('');
                  setAddDialogOpen(false);
                }}
              >
                {f({ id: 'buttonCancel' })}
              </SecondaryButton>
              <PrimaryButton
                disabled={disableAddButton}
                onClick={() => {
                  setDisableAddButton(true);
                  dispatch(
                    createMiscCategory({
                      categoryName: newCategoryName,
                      enqueueSnackbar,
                    }),
                  ).then(() => {
                    dispatch(getMiscCategories());
                    setNewCategoryName('');
                    setAddDialogOpen(false);
                    setDisableAddButton(false);
                  });
                }}
              >
                {f({ id: 'buttonAdd' })}
              </PrimaryButton>
            </DialogActions>
          </Dialog>
          {categoryToDelete === null ? null : (
            <Dialog
              open={deleteDialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {f({ id: 'miscTransactionCategoriesDelete' })}
              </DialogTitle>
              <DialogContent>
                <Box mb={3} minWidth="300px">
                  <InputLabel htmlFor="shopId">
                    {`${f({ id: 'miscTransactionCategoriesMove' })}:`}
                  </InputLabel>
                  <FormControl required error={miscCategoryError}>
                    <InputLabel htmlFor="miscCategory">
                      {f({ id: 'miscTransactionCategoriesNew' })}
                    </InputLabel>
                    <Select
                      value={categoryToReplace}
                      name="categoryToReplace"
                      onChange={(event) =>
                        setCategoryToReplace(event.target.value)
                      }
                      inputProps={{
                        id: 'categoryToReplace',
                      }}
                    >
                      {miscCategories &&
                        miscCategories
                          .filter((x) => x.id !== categoryToDelete.id)
                          .map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <SecondaryButton
                  onClick={() => {
                    setCategoryToDelete(null);
                    setCategoryToReplace(null);
                    setDeleteDialogOpen(false);
                    setMiscCategoryError(false);
                  }}
                >
                  {f({ id: 'buttonCancel' })}
                </SecondaryButton>
                <PrimaryButton
                  danger
                  disabled={disableDeleteButton}
                  onClick={() => {
                    if (!categoryToReplace) {
                      setMiscCategoryError(true);
                      return;
                    }
                    setMiscCategoryError(false);

                    setDisableDeleteButton(true);
                    dispatch(
                      deleteMiscCategory({
                        categoryToDelete: categoryToDelete.id,
                        categoryToReplace,
                        enqueueSnackbar,
                      }),
                    ).then(() => {
                      dispatch(getMiscCategories());
                      setCategoryToDelete(null);
                      setCategoryToReplace(null);
                      setDeleteDialogOpen(false);
                      setDisableDeleteButton(false);
                      dispatch(
                        getMiscTransactionStats({
                          startDate,
                          endDate,
                          enqueueSnackbar,
                        }),
                      );
                    });
                  }}
                >
                  {f({ id: 'buttonDelete' })}
                </PrimaryButton>
              </DialogActions>
            </Dialog>
          )}
        </PaddedGrid>
      </PaperWithTitle>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  miscCategories: state.miscCategories,
  miscTransactionStats: state.miscTransactionStats,
});

export default BaseHOC(
  AdminMiscTransactionsCategoriesForm,
  null,
  mapStateToProps,
);
