import React, { useState, useEffect, createRef } from 'react';
import {
  Box,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useIntl } from 'react-intl';

import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';

import 'react-quill/dist/quill.snow.css';

import * as _ from 'lodash';

import PaperWithTitle from '../../PaperWithTitle';
import FormControl from '../../FormControl';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';
import StyledLink from '../../StyledLink';

import { getSettings, saveSettings } from '../../../actions';
import { getUser, isSalonzone } from '../../../utils';
import getTemplateHu from '../../../policyTemplate.html';
import getTemplateUk from '../../../policyTemplateUk.html';

import { Capitalized } from './styles';

import AdminBookingSettingsPolicyEditor from './AdminBookingSettingsPolicyEditor';

// moment.locale('hu'); // it is required to select default locale manually

// moment.updateLocale('hu', {
//   week: {
//     dow: 1,
//   },
// });

const AdminBookingSettingsForm = ({
  settings,
  getSettings,
  saveSettings,
  getPolicyTemplate,
  enqueueSnackbar,
}) => {
  const { formatMessage: f, locale } = useIntl();
  const weekdayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const getLocalWeekday = (momentInstance, i) =>
    momentInstance
      .weekdays(true)
      .map((day) => weekdayKeys[momentInstance.weekdays().indexOf(day)])[i];

  const emptyOpeningHours = {
    mon: { open: null, close: null },
    tue: { open: null, close: null },
    wed: { open: null, close: null },
    thu: { open: null, close: null },
    fri: { open: null, close: null },
    sat: { open: null, close: null },
    sun: { open: null, close: null },
  };

  // TODO: Keep in sync with backend
  const defaultOpeningHours = {
    mon: { open: '09:00', close: '19:00' },
    tue: { open: '09:00', close: '19:00' },
    wed: { open: '09:00', close: '19:00' },
    thu: { open: '09:00', close: '19:00' },
    fri: { open: '09:00', close: '19:00' },
    sat: { open: '09:00', close: '19:00' },
    sun: { open: '09:00', close: '14:00' },
  };

  const emailSlugs = [
    {
      slug: 'confirmation-email',
      label: f({ id: 'bookingEmailSlugConfirmationEmail' }),
    },
    {
      slug: 'reminder-email',
      label: f({ id: 'bookingEmailSlugReminderEmail' }),
    },
    {
      slug: 'cancel-booking-email',
      label: f({ id: 'bookingEmailSlugCancellationEmail' }),
    },
    {
      slug: 'review-reminder-email',
      label: f({ id: 'bookingEmailSlugReviewReminderEmail' }),
    },
  ];
  const emptyOpeningDays = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };
  const minBookingWaitTimes = [
    {
      value: 1,
      label: f({ id: 'noMinimum' }),
    },
    {
      value: 15 * 60,
      label: `15 ${f({ id: 'minutes' })}`,
    },
    {
      value: 30 * 60,
      label: `30 ${f({ id: 'minutes' })}`,
    },
    {
      value: 1 * 60 * 60,
      label: `1 ${f({ id: 'hour' })}`,
    },
    {
      value: 2 * 60 * 60,
      label: `2 ${f({ id: 'hours' })}`,
    },
    {
      value: 6 * 60 * 60,
      label: `6 ${f({ id: 'hours' })}`,
    },
    {
      value: 12 * 60 * 60,
      label: `12 ${f({ id: 'hours' })}`,
    },
    {
      value: 24 * 60 * 60,
      label: `24 ${f({ id: 'hours' })}`,
    },
  ];
  const minCancellationTimes = [
    {
      value: 0,
      label: f({ id: 'noMinimum' }),
    },
    {
      value: 5,
      label: `5 ${f({ id: 'minutes' })}`,
    },
    {
      value: 10,
      label: `10 ${f({ id: 'minutes' })}`,
    },
    {
      value: 15,
      label: `15 ${f({ id: 'minutes' })}`,
    },
    {
      value: 30,
      label: `30 ${f({ id: 'minutes' })}`,
    },
    {
      value: 45,
      label: `45 ${f({ id: 'minutes' })}`,
    },
    {
      value: 60,
      label: `1 ${f({ id: 'hour' })}`,
    },
    {
      value: 2 * 60,
      label: `2 ${f({ id: 'hours' })}`,
    },
    {
      value: 3 * 60,
      label: `3 ${f({ id: 'hours' })}`,
    },
    {
      value: 6 * 60,
      label: `6 ${f({ id: 'hours' })}`,
    },
    {
      value: 12 * 60,
      label: `12 ${f({ id: 'hours' })}`,
    },
    {
      value: 24 * 60,
      label: `24 ${f({ id: 'hours' })}`,
    },
  ];
  const maxBookingWaitTimes = [
    {
      value: 3,
      label: `3 ${f({ id: 'days' })}`,
    },
    {
      value: 7,
      label: `1 ${f({ id: 'week' })}`,
    },
    {
      value: 2 * 7,
      label: `2 ${f({ id: 'weeks' })}`,
    },
    {
      value: 3 * 7,
      label: `3 ${f({ id: 'weeks' })}`,
    },
    {
      value: 4 * 7,
      label: `4 ${f({ id: 'weeks' })}`,
    },
    {
      value: 6 * 7,
      label: `6 ${f({ id: 'weeks' })}`,
    },
    {
      value: 8 * 7,
      label: `8 ${f({ id: 'weeks' })}`,
    },
    {
      value: 10 * 7,
      label: `10 ${f({ id: 'weeks' })}`,
    },
    {
      value: 12 * 7,
      label: `12 ${f({ id: 'weeks' })}`,
    },
    {
      value: 14 * 7,
      label: `14 ${f({ id: 'weeks' })}`,
    },
    {
      value: 16 * 7,
      label: `16 ${f({ id: 'weeks' })}`,
    },
  ];
  const emailReminderPeriods = [
    {
      value: 15,
      label: `15 ${f({ id: 'minutes' })}`,
    },
    {
      value: 30,
      label: `30 ${f({ id: 'minutes' })}`,
    },
    {
      value: 60,
      label: `1 ${f({ id: 'hour' })}`,
    },
    {
      value: 2 * 60,
      label: `2 ${f({ id: 'hours' })}`,
    },
    {
      value: 3 * 60,
      label: `3 ${f({ id: 'hours' })}`,
    },
    {
      value: 6 * 60,
      label: `6 ${f({ id: 'hours' })}`,
    },
    {
      value: 12 * 60,
      label: `12 ${f({ id: 'hours' })}`,
    },
    {
      value: 24 * 60,
      label: `24 ${f({ id: 'hours' })}`,
    },
  ];
  const emailReviewPeriods = emailReminderPeriods;
  const bookingIntervals = [
    {
      value: 10,
      label: `10 ${f({ id: 'inMinutes' })}`,
    },
    {
      value: 15,
      label: `15 ${f({ id: 'inMinutes' })}`,
    },
    {
      value: 30,
      label: `30 ${f({ id: 'inMinutes' })}`,
    },
    {
      value: 60,
      label: `1 ${f({ id: 'inHour' })}`,
    },
  ];

  const shopId = getUser().shopMap[0].id;
  const [name, setName] = useState('');
  const [allowDateFiltering, setAllowDateFiltering] = useState(false);
  const [rollBalance, setRollBalance] = useState(false);
  const [aboutUs, setAboutUs] = useState('');
  const [openingHours, setOpeningHours] = useState({ ...emptyOpeningHours });
  const [openingDays, setOpeningDays] = useState({ ...emptyOpeningDays });
  const [heroImage, setHeroImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [bannerImageUrl, setBannerImageUrl] = useState('');
  const [logoImageUrl, setLogoImageUrl] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressTown, setAddressTown] = useState('');
  const [addressPostalCode, setAddressPostalCode] = useState('');
  const [postalAddressIsSame, setPostalAddressIsSame] = useState(true);
  const [postalAddressLine1, setPostalAddressLine1] = useState('');
  const [postalAddressTown, setPostalAddressTown] = useState('');
  const [postalAddressPostalCode, setPostalAddressPostalCode] = useState('');
  const [minimumBookingWaitTime, setMinimumBookingWaitTime] = useState('');
  const [minimumCancellationTime, setMinimumCancellationTime] = useState('');
  const [maximumBookingWaitTime, setMaximumBookingWaitTime] = useState('');
  const [bookingInterval, setBookingInterval] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bookingUrl, setBookingUrl] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [taxReference, setTaxReference] = useState('');
  const [email, setEmail] = useState('');
  const [policy, setPolicy] = useState('');
  const [policyCity, setPolicyCity] = useState('');
  const [policyDate, setPolicyDate] = useState('');
  const [
    generatePolicyButtonEnabled,
    setGeneratePolicyButtonEnabled,
  ] = useState(true);
  const [generatePolicyDialogOpen, setGeneratePolicyDialogOpen] = useState(
    false,
  );
  const [showEditPolicy, setShowEditPolicy] = useState(false);
  const [showPolicyWarning, setShowPolicyWarning] = useState(false);
  const [closeDatePickerOpen, setCloseDatePickerOpen] = useState(false);
  const [closeDates, setCloseDates] = useState([]);
  const [emailReminderEnabled, setEmailReminderEnabled] = useState(false);
  const [emailReminderPeriod, setEmailReminderPeriod] = useState(15);
  const [emailNotificationEnabled, setEmailNotificationEnabled] = useState(
    false,
  );
  const [
    emailNotificationEmailAddress,
    setEmailNotificationEmailAddress,
  ] = useState('');
  const [emailReviewEnabled, setEmailReviewEnabled] = useState(false);
  const [emailReviewPeriod, setEmailReviewPeriod] = useState(15);
  const [
    emailBarberNotificationEnabled,
    setEmailBarberNotificationEnabled,
  ] = useState(false);
  const [autoAccept4StarReviews, setAutoAccept4StarReviews] = useState(false);
  const [autoAccept5StarReviews, setAutoAccept5StarReviews] = useState(false);
  const [onlyReviewsWithFeedback, setOnlyReviewsWithFeedback] = useState(false);
  const [bannerSize, setBannerSize] = useState('large');
  const [bannerEmails, setBannerEmails] = useState([]);
  const [bannerLink, setBannerLink] = useState('');
  const [requiredFieldsErrorVidible, setRequiredFieldsErrorVidible] = useState(
    '',
  );

  const editorRef = createRef();

  useEffect(() => {
    moment.locale(locale);
    moment.updateLocale(locale, {
      week: {
        dow: 1, // First day of week is Monday
      },
    });
    getSettings({ shopId, enqueueSnackbar });
  }, []);

  useEffect(() => {
    if (settings) {
      moment.tz.setDefault(settings.timezone);
      moment.updateLocale(locale, {
        week: {
          dow: 1, // First day of week is Monday
        },
      });

      if (settings.address && settings.address.town) {
        setPolicyCity(settings.address.town);
      }
      setPolicyDate(moment());
    }
  }, [settings]);

  const generatePolicy = () => {
    const handler = locale === 'hu' ? getTemplateHu : getTemplateUk;
    setPolicy(
      handler({
        name,
        companyName,
        address: {
          addressLine1,
          town: addressTown,
          postalCode: addressPostalCode,
        },
        postalAddress: postalAddressIsSame
          ? {
              addressLine1,
              town: addressTown,
              postalCode: addressPostalCode,
            }
          : {
              addressLine1: postalAddressLine1,
              town: postalAddressTown,
              postalCode: postalAddressPostalCode,
            },
        taxReference,
        email,
        bookingUrl,
        policyCity,
        contactEmail: settings?.contact_email,
        policyDate:
          locale === 'hu'
            ? policyDate.format('YYYY. MM. DD.')
            : policyDate.format('YYYY-MM-DD'),
      }),
    );
    setShowPolicyWarning(true);
  };

  useEffect(() => {
    if (settings) {
      const openingHoursValue = settings.opening_hours || {
        ...emptyOpeningHours,
      };

      setName(settings.name);
      setCompanyName(settings.company_name);
      setTaxReference(settings.tax_reference);
      setAllowDateFiltering(settings.allow_date_filtering);
      setRollBalance(settings.roll_balance);
      setAboutUs(settings.about_us || '');
      setOpeningHours(openingHoursValue);
      setOpeningDays(
        Object.fromEntries(
          Object.keys(openingHoursValue).map((key) => {
            return [
              key,
              !!(openingHoursValue[key].open && openingHoursValue[key].close),
            ];
          }),
        ),
      );
      setPhoneNumber(settings.phone_number || '');
      setBookingUrl(settings.booking_url || '');
      setFacebook(settings.facebook || '');
      setInstagram(settings.instagram || '');
      setEmail(settings.email || '');
      setPolicy((settings.policy && settings.policy.body) || '');
      setHeroImageUrl(settings.hero_image);
      setBannerImageUrl(settings.banner_image || '');
      setBannerSize(settings.banner_size || 'large');
      setBannerEmails(
        (settings.banner_emails && settings.banner_emails.split()) || [],
      );
      setLogoImageUrl(settings.logo_image);
      setMinimumBookingWaitTime(settings.minimum_booking_wait_time || 0);
      setMaximumBookingWaitTime(settings.maximum_booking_wait_time || 0);
      setMinimumCancellationTime(settings.minimum_cancellation_time || 0);
      setBookingInterval(settings.booking_interval || 15);
      setCompanyName(settings.company_name || '');
      setPostalAddressIsSame(settings.postal_address_is_same);
      if (settings.address) {
        setAddressLine1(settings.address.address_line_1 || '');
        setAddressTown(settings.address.town || '');
        setAddressPostalCode(settings.address.postal_code || '');
      }
      if (settings.postal_address) {
        setPostalAddressLine1(settings.postal_address.address_line_1 || '');
        setPostalAddressTown(settings.postal_address.town || '');
        setPostalAddressPostalCode(settings.postal_address.postal_code || '');
      }
      setCloseDates(settings.close_dates || []);
      setEmailReminderEnabled(settings.email_reminder_enabled || false);
      setEmailReminderPeriod(settings.email_reminder_period || '');
      setEmailNotificationEnabled(settings.email_notification_enabled || false);
      setEmailNotificationEmailAddress(
        settings.email_notification_email_address || '',
      );
      setEmailReviewEnabled(settings.email_review_enabled || false);
      setEmailReviewPeriod(settings.email_review_period || '');
      setEmailBarberNotificationEnabled(
        settings.email_barber_notification_enabled || false,
      );
      setAutoAccept4StarReviews(settings.auto_accept_4_star_reviews || false);
      setAutoAccept5StarReviews(settings.auto_accept_5_star_reviews || false);
      setOnlyReviewsWithFeedback(settings.only_reviews_with_feedback || false);
      setBannerEmails(
        (settings.banner_email_slugs &&
          settings.banner_email_slugs.split(',')) ||
          [],
      );
      setBannerLink(settings.banner_link || '');
      moment.updateLocale(locale, {
        week: {
          dow: 1, // First day of week is Monday
        },
      });
    }
  }, [settings]);

  const updateOpeningHours = (momentInstance, dayIndex, openClose, value) => {
    // value hour is the one user selected, ie. user clicked 14:00, value will
    // be 14:00, but the TZ can change according to the user's browser

    const newOpeningHours = { ...openingHours };
    // TODO: Format to only hours
    newOpeningHours[getLocalWeekday(momentInstance, dayIndex)][
      openClose
    ] = value ? value.format('HH:mm') : null;
    setOpeningHours(newOpeningHours);
  };

  const handleSave = () => {
    const data = new FormData();
    if (logoImage) {
      data.append('logoImage', logoImage, logoImage.name);
    }

    if (heroImage) {
      data.append('heroImage', heroImage, heroImage.name);
    }

    if (bannerImage) {
      data.append('bannerImage', bannerImage, bannerImage.name);
    }

    if (bannerImage || bannerImageUrl) {
      data.append('bannerWidth', 400);
      data.append('bannerHeight', (bannerSize === 'small' && 100) || 150);
      data.append('bannerEmailSlugs', bannerEmails.join());
    }

    let error = false;

    Object.keys(openingHours || {}).forEach((key) => {
      if (
        openingHours[key].open &&
        openingHours[key].close &&
        openingHours[key].open >= openingHours[key].close
      ) {
        enqueueSnackbar(f({ id: 'bookingSettingsErrorOpeningHours' }), {
          variant: 'warning',
        });
        error = true;
      }

      if (
        openingDays[key] &&
        !(openingHours[key].open && openingHours[key].close)
      ) {
        enqueueSnackbar(f({ id: 'bookingSettingsErrorOpeningHours' }), {
          variant: 'warning',
        });
        error = true;
      }
    });

    if (error) return;

    let requiredFieldsError = false;

    if (name === '') {
      requiredFieldsError = true;
    }
    if (companyName === '') {
      requiredFieldsError = true;
    }
    if (phoneNumber === '') {
      requiredFieldsError = true;
    }
    if (email === '') {
      requiredFieldsError = true;
    }

    if (requiredFieldsError) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      setRequiredFieldsErrorVidible(true);
      return;
    }
    setRequiredFieldsErrorVidible(false);

    const openingHoursWithClosings = { ...openingHours };

    Object.keys(openingDays || {}).forEach((key) => {
      openingHoursWithClosings[key] = (openingDays[key] &&
        openingHours[key]) || { open: null, close: null };
    });

    data.append('name', name);
    data.append('taxReference', taxReference);
    data.append('companyName', companyName);
    data.append('phoneNumber', phoneNumber);
    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('email', email);
    data.append('aboutUs', aboutUs);
    data.append('allowDateFiltering', allowDateFiltering);
    data.append('rollBalance', rollBalance);
    data.append('openingHours', JSON.stringify(openingHoursWithClosings));
    data.append('minimumBookingWaitTime', minimumBookingWaitTime);
    data.append('maximumBookingWaitTime', maximumBookingWaitTime);
    data.append('minimumCancellationTime', minimumCancellationTime);
    data.append('bookingInterval', bookingInterval);
    data.append('postalAddressIsSame', postalAddressIsSame);
    data.append('address.addressLine1', addressLine1);
    data.append('address.town', addressTown);
    data.append('address.postalCode', addressPostalCode);
    // TODO: validation!!!
    if (policy) {
      data.append('policy.body', policy);
    }
    if (!postalAddressIsSame) {
      data.append('postalAddress.addressLine1', postalAddressLine1);
      data.append('postalAddress.town', postalAddressTown);
      data.append('postalAddress.postalCode', postalAddressPostalCode);
    }
    data.append('closeDates', closeDates);
    data.append('emailReminderEnabled', emailReminderEnabled);
    data.append('emailReminderPeriod', emailReminderPeriod);
    data.append('emailNotificationEnabled', emailNotificationEnabled);
    data.append('emailNotificationEmailAddress', emailNotificationEmailAddress);
    data.append('emailReviewEnabled', emailReviewEnabled);
    data.append('emailReviewPeriod', emailReviewPeriod);
    data.append(
      'emailBarberNotificationEnabled',
      emailBarberNotificationEnabled,
    );
    data.append('autoAccept4StarReviews', autoAccept4StarReviews);
    data.append('autoAccept5StarReviews', autoAccept5StarReviews);
    data.append('onlyReviewsWithFeedback', onlyReviewsWithFeedback);
    data.append('bannerLink', bannerLink);
    saveSettings({ f, shopId, settingsData: data, enqueueSnackbar });
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'bookingSettingsBasicInfo' })}
            helpText={f({ id: 'bookingSettingsBasicInfoTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <FormControl component="fieldset" style={{ minWidth: '260px' }}>
                  <FormGroup>
                    <TextField
                      required
                      margin="dense"
                      id="name"
                      name="name"
                      label={f({ id: 'bookingSettingsShopName' })}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="companyName"
                      name="companyName"
                      label={f({ id: 'bookingSettingsCompanyName' })}
                      fullWidth
                      value={companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                    />
                    <TextField
                      margin="dense"
                      id="taxReference"
                      name="taxReference"
                      label={f({ id: 'bookingSettingsTaxReference' })}
                      value={taxReference}
                      onChange={(event) => setTaxReference(event.target.value)}
                    />
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="phoneNumber"
                      name="phoneNumber"
                      label={f({ id: 'bookingSettingsPhoneNumber' })}
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="email"
                      name="email"
                      label={f({ id: 'bookingSettingsEmail' })}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl
                  component="fieldset"
                  style={{ minWidth: '260px', paddingTop: 2 }}
                >
                  <FormLabel component="legend">
                    {f({ id: 'bookingSettingsShopAddress' })}
                  </FormLabel>
                  <FormGroup>
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="addressPostalCode"
                      name="addressPostalCode"
                      label={f({ id: 'bookingSettingsPostalCode' })}
                      value={addressPostalCode}
                      onChange={(event) =>
                        setAddressPostalCode(event.target.value)
                      }
                    />
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="addressTown"
                      name="addressTown"
                      label={f({ id: 'bookingSettingsTown' })}
                      value={addressTown}
                      onChange={(event) => setAddressTown(event.target.value)}
                    />
                    <TextField
                      required
                      error={requiredFieldsErrorVidible}
                      margin="dense"
                      id="addressLine1"
                      name="addressLine1"
                      label={f({ id: 'bookingSettingsAddressLine1' })}
                      value={addressLine1}
                      onChange={(event) => setAddressLine1(event.target.value)}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl
                  component="fieldset"
                  style={{ minWidth: '260px', paddingTop: 2 }}
                >
                  <FormLabel component="legend">
                    {f({ id: 'bookingSettingsPostAddress' })}
                  </FormLabel>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={postalAddressIsSame}
                          onChange={(event) =>
                            setPostalAddressIsSame(event.target.checked)
                          }
                          id="postalAddressIsSame"
                          name="postalAddressIsSame"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({ id: 'bookingSettingsAddressSame' })}
                    />
                  </FormControl>
                  <FormGroup>
                    <TextField
                      margin="dense"
                      disabled={postalAddressIsSame}
                      id="postalAddressPostalCode"
                      name="postalAddressPostalCode"
                      label={f({ id: 'bookingSettingsPostalCode' })}
                      value={postalAddressPostalCode}
                      onChange={(event) =>
                        setPostalAddressPostalCode(event.target.value)
                      }
                    />
                    <TextField
                      margin="dense"
                      disabled={postalAddressIsSame}
                      id="postalAddressTown"
                      name="postalAddressTown"
                      label={f({ id: 'bookingSettingsTown' })}
                      value={postalAddressTown}
                      onChange={(event) =>
                        setPostalAddressTown(event.target.value)
                      }
                    />
                    <TextField
                      margin="dense"
                      disabled={postalAddressIsSame}
                      id="postalAddressLine1"
                      name="postalAddressLine1"
                      label={f({ id: 'bookingSettingsAddressLine1' })}
                      value={postalAddressLine1}
                      onChange={(event) =>
                        setPostalAddressLine1(event.target.value)
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextField
                  margin="dense"
                  style={{ minWidth: '260px' }}
                  id="facebook"
                  name="facebook"
                  label={f({ id: 'bookingSettingsFacebook' })}
                  value={facebook}
                  onChange={(event) => setFacebook(event.target.value)}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  margin="dense"
                  style={{ minWidth: '260px' }}
                  id="instagram"
                  name="instagram"
                  label={f({ id: 'bookingSettingsInstagram' })}
                  value={instagram}
                  onChange={(event) => setInstagram(event.target.value)}
                />
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
        <Box m={1} maxWidth={400} flexDirection="row">
          <Box>
            <PaperWithTitle
              title={f({ id: 'bookingSettingsOpening' })}
              helpText={f({ id: 'bookingSettingsOpeningTooltip' })}
            >
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={moment}
                locale={moment.locale(locale)}
              >
                <Box display="flex" flexDirection="column" ml={2} mt={1}>
                  {moment.weekdays(true).map((weekday, i) => (
                    <Box
                      mb={2}
                      key={`weekday-${weekday}`}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={openingDays[getLocalWeekday(moment, i)]}
                            onChange={(event) => {
                              const key = getLocalWeekday(moment, i);
                              const { checked } = event.target;
                              const openingDaysNew = { ...openingDays };
                              openingDaysNew[key] = checked;
                              setOpeningDays(openingDaysNew);
                              if (checked) {
                                const newOpeningHours = { ...openingHours };
                                let changed = false;
                                if (!openingHours[key].open) {
                                  newOpeningHours[key].open =
                                    defaultOpeningHours[key].open;
                                  changed = true;
                                }
                                if (!openingHours[key].close) {
                                  newOpeningHours[key].close =
                                    defaultOpeningHours[key].close;
                                  changed = true;
                                }
                                if (changed) {
                                  setOpeningHours(newOpeningHours);
                                }
                              }
                            }}
                            id={`${weekday}-day-open`}
                            name={`${weekday}-day-open`}
                            color="primary"
                            inputProps={{
                              'aria-label': 'primary checkbox',
                            }}
                          />
                        }
                      />
                      <Box width={100}>
                        <Capitalized>{weekday}:</Capitalized>
                      </Box>
                      <Box m={1} display="flex" flexDirection="row">
                        <Box m={1} width={100}>
                          <KeyboardTimePicker
                            disabled={!openingDays[getLocalWeekday(moment, i)]}
                            clearable="true"
                            autoOk
                            initialFocusedDate={moment(
                              openingHours[getLocalWeekday(moment, i)].open,
                              'HH:mm',
                            )}
                            mask="__:__"
                            ampm={false}
                            minutesStep={15}
                            variant="inline"
                            cancelLabel={f({ id: 'buttonCancel' })}
                            value={
                              openingHours[getLocalWeekday(moment, i)].open
                                ? moment(
                                    openingHours[getLocalWeekday(moment, i)]
                                      .open,
                                    'HH:mm',
                                  )
                                : null
                            }
                            onChange={(selectedDate) =>
                              updateOpeningHours(
                                moment,
                                i,
                                'open',
                                selectedDate,
                              )
                            }
                          />
                        </Box>
                        <Box m={1} width={100}>
                          <KeyboardTimePicker
                            disabled={!openingDays[getLocalWeekday(moment, i)]}
                            clearable="true"
                            cancelLabel={f({ id: 'buttonCancel' })}
                            autoOk
                            initialFocusedDate={moment(
                              openingHours[getLocalWeekday(moment, i)].close,
                              'HH:mm',
                            )}
                            mask="__:__"
                            ampm={false}
                            minutesStep={15}
                            variant="inline"
                            value={
                              openingHours[getLocalWeekday(moment, i)].close
                                ? moment(
                                    openingHours[getLocalWeekday(moment, i)]
                                      .close,
                                    'HH:mm',
                                  )
                                : null
                            }
                            onChange={(selectedDate) =>
                              updateOpeningHours(
                                moment,
                                i,
                                'close',
                                selectedDate,
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </MuiPickersUtilsProvider>
            </PaperWithTitle>
          </Box>
          <Box mt={1}>
            <PaperWithTitle
              title={f({ id: 'bookingSettingsPrivacyPolicy' })}
              helpText={f({ id: 'bookingSettingsPrivacyPolicyTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1} pb={1}>
                <Box ml={2} mb={2}>
                  {policy ? (
                    <>
                      <PrimaryButton
                        withmargin="true"
                        onClick={() => setShowEditPolicy(true)}
                      >
                        {f({ id: 'bookingSettingsPrivacyPolicyEditButton' })}
                      </PrimaryButton>
                      <Dialog fullScreen open={showEditPolicy}>
                        <Box mt={2} ml={4}>
                          <Typography variant="h6">
                            {f({ id: 'bookingSettingsPrivacyPolicyEditTitle' })}
                          </Typography>
                        </Box>
                        <Box mt={4}>
                          <AdminBookingSettingsPolicyEditor
                            policy={policy}
                            setPolicy={setPolicy}
                            generatePolicy={generatePolicy}
                            editorRef={editorRef}
                          />
                        </Box>
                        <Box mt={2} ml={6} mb={2}>
                          <PrimaryButton
                            withmargin="true"
                            onClick={() => setShowEditPolicy(false)}
                          >
                            {f({ id: 'buttonCancel' })}
                          </PrimaryButton>
                          <PrimaryButton
                            withmargin="true"
                            onClick={() => {
                              if (editorRef.current) {
                                const editor = editorRef.current.getEditor();
                                const unprivilegedEditor = editorRef.current.makeUnprivilegedEditor(
                                  editor,
                                );
                                const policyHTML = unprivilegedEditor.getHTML();
                                setPolicy(policyHTML);
                              }
                              setShowEditPolicy(false);
                            }}
                          >
                            {f({ id: 'buttonOk' })}
                          </PrimaryButton>
                          <PrimaryButton
                            withmargin="true"
                            onClick={() => {
                              setGeneratePolicyButtonEnabled(true);
                              setGeneratePolicyDialogOpen(true);
                            }}
                          >
                            {f({
                              id: 'bookingSettingsPrivacyPolicyReGenerate',
                            })}
                          </PrimaryButton>
                        </Box>
                      </Dialog>
                    </>
                  ) : (
                    <PrimaryButton
                      withmargin="true"
                      onClick={() => setGeneratePolicyDialogOpen(true)}
                    >
                      {f({ id: 'bookingSettingsPrivacyPolicyCreate' })}
                    </PrimaryButton>
                  )}
                </Box>

                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  open={generatePolicyDialogOpen}
                >
                  <DialogTitle>
                    {f({ id: 'bookingSettingsPrivacyPolicyCreate' })}
                  </DialogTitle>
                  <DialogContent>
                    <>
                      <Box mb={2}>
                        <Typography variant="body1">
                          {f({ id: 'bookingSettingsPrivacyPolicyHeading' })}
                        </Typography>
                      </Box>
                      <Box mb={2}>
                        <Typography variant="body1">
                          {`${f({
                            id: 'bookingSettingsPrivacyPolicyPlaceandDate',
                          })}:`}
                        </Typography>
                        <Box mb={2}>
                          <TextField
                            id="policyCity"
                            name="policyCity"
                            label={f({ id: 'bookingSettingsTown' })}
                            value={policyCity}
                            onChange={(event) =>
                              setPolicyCity(event.target.value)
                            }
                          />
                        </Box>
                        <Box mb={2}>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            libInstance={moment}
                            locale={
                              (locale && moment.locale(locale)) ||
                              moment.locale('en')
                            }
                          >
                            <DatePicker
                              id="policyDate"
                              name="policyDate"
                              autoOk
                              openTo="date"
                              value={policyDate}
                              onChange={(date) => setPolicyDate(date)}
                              label={f({ id: 'date' })}
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                      </Box>
                    </>
                  </DialogContent>
                  <DialogActions>
                    <PrimaryButton
                      withmargin="true"
                      danger
                      disabled={!generatePolicyButtonEnabled}
                      onClick={() => {
                        setGeneratePolicyButtonEnabled(false);
                        generatePolicy();
                        setGeneratePolicyDialogOpen(false);
                        setShowEditPolicy(false);
                      }}
                    >
                      {f({ id: 'bookingSettingsPrivacyPolicyGenerate' })}
                    </PrimaryButton>
                    <SecondaryButton
                      onClick={() => {
                        setGeneratePolicyDialogOpen(false);
                      }}
                      color="primary"
                    >
                      {f({ id: 'buttonBack' })}
                    </SecondaryButton>
                  </DialogActions>
                </Dialog>
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  open={showPolicyWarning}
                >
                  <DialogTitle>
                    {f({ id: 'bookingSettingsPrivacyPolicyCreate' })}
                  </DialogTitle>
                  <DialogContent>
                    <Box mb={2}>
                      <Typography variant="body1">
                        {/* TODO: Should be shop-local specific */}
                        {f({
                          id: 'bookingSettingsPrivacyPolicyCreationHeading',
                        })}
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <PrimaryButton
                      withmargin="true"
                      onClick={() => {
                        setShowPolicyWarning(false);
                      }}
                    >
                      {f({ id: 'buttonOk' })}
                    </PrimaryButton>
                  </DialogActions>
                </Dialog>
              </Box>
            </PaperWithTitle>
          </Box>
          <Box mt={1}>
            <PaperWithTitle
              title={f({ id: 'bookingSettingsBackground' })}
              helpText={f({ id: 'bookingSettingsBackgroundTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1} pb={1}>
                <Box mb={2}>
                  {heroImageUrl ? (
                    <img
                      src={`https://agrddpxdbm.cloudimg.io/bound/200x200/cffffff/${heroImageUrl}`}
                      alt={f({ id: 'bookingSettingsBackground' })}
                    />
                  ) : null}
                </Box>
                <Box mb={2}>
                  <FormControlLabel
                    control={
                      <Input
                        id="heroImage"
                        name="heroImage"
                        type="file"
                        onChange={(event) =>
                          setHeroImage(event.target.files[0])
                        }
                        style={{ display: 'none' }}
                      />
                    }
                    label={f({ id: 'bookingSettingsBackgroundNew' })}
                    labelPlacement="top"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSalonzone ? '#230928' : '#212121',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '6px 16px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  />
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
        </Box>
        <Box m={1} maxWidth={350}>
          <Box>
            <PaperWithTitle
              title={f({ id: 'bookingSettingsCloseDates' })}
              helpText={f({ id: 'bookingSettingsCloseDatesTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box display="flex" flexDirection="column">
                  {closeDates.map((closeDate) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      key={closeDate}
                      mb={1}
                    >
                      <Box>{moment(closeDate).format('YYYY. MMMM D.')}</Box>
                      <Box>
                        <IconButton
                          size="small"
                          aria-label={f({ id: 'buttonDelete' })}
                          onClick={() =>
                            setCloseDates(
                              [...closeDates].filter(
                                (x) =>
                                  x !==
                                  moment(closeDate).format(
                                    moment.HTML5_FMT.DATE,
                                  ),
                              ),
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box mb={2}>
                  <StyledLink
                    component="button"
                    onClick={() => setCloseDatePickerOpen(true)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSalonzone ? '#230928' : '#212121',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '6px 16px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  >
                    {f({ id: 'buttonAdd' })}
                  </StyledLink>
                </Box>

                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={moment.locale(locale)}
                >
                  <KeyboardDatePicker
                    open={closeDatePickerOpen}
                    autoOk
                    disablePast
                    openTo="year"
                    cancelLabel={f({ id: 'buttonCancel' })}
                    shouldDisableDate={(date) =>
                      closeDates.indexOf(
                        moment(date).format(moment.HTML5_FMT.DATE),
                      ) !== -1
                    }
                    onChange={(selectedDate) => {
                      setCloseDates([
                        ...closeDates,
                        moment(selectedDate).format(moment.HTML5_FMT.DATE),
                      ]);
                    }}
                    onClose={() => setCloseDatePickerOpen(false)}
                    format="yyyy/MM/dd"
                    TextFieldComponent={() => null}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </PaperWithTitle>
          </Box>
        </Box>
        <Box m={1} maxWidth={350}>
          <Box mt={1}>
            <PaperWithTitle title={f({ id: 'bookingSettingsReviewSettings' })}>
              <Box display="flex" flexDirection="column" ml={2} mt={1} pb={1}>
                <Box mb={2}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pr={1}
                  >
                    <FormControl style={{ minWidth: '260px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={emailReviewEnabled}
                            onChange={(event) =>
                              setEmailReviewEnabled(event.target.checked)
                            }
                            id="emailReviewEnabled"
                            name="emailReviewEnabled"
                            color="primary"
                            inputProps={{
                              'aria-label': 'primary checkbox',
                            }}
                          />
                        }
                        label={f({ id: 'bookingSettingsEmailReview' })}
                      />
                    </FormControl>
                    <Tooltip
                      title={f({ id: 'bookingSettingsEmailReviewTooltip' })}
                      enterTouchDelay={1}
                      leaveTouchDelay={2000}
                    >
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </Box>
                </Box>
                <Box mb={2}>
                  <FormControl
                    disabled={!emailReviewEnabled}
                    style={{ minWidth: '260px' }}
                  >
                    <Box pb={3}>
                      <InputLabel htmlFor="emailReviewPeriod" shrink>
                        {f({ id: 'bookingSettingsEmailReviewPeriod' })}
                      </InputLabel>
                    </Box>
                    <Select
                      value={emailReviewPeriod}
                      onChange={(event) =>
                        setEmailReviewPeriod(event.target.value)
                      }
                      input={
                        <Input
                          name="emailReviewPeriod"
                          id="emailReviewPeriod"
                        />
                      }
                    >
                      {emailReviewPeriods.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoAccept4StarReviews}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setAutoAccept5StarReviews(true);
                            }
                            setAutoAccept4StarReviews(event.target.checked);
                          }}
                          id="autoAccept4StarReviews"
                          name="autoAccept4StarReviews"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({ id: 'bookingSettingsAutoAccept4StarReviews' })}
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={autoAccept5StarReviews}
                          onChange={(event) => {
                            setAutoAccept5StarReviews(event.target.checked);
                          }}
                          id="autoAccept5StarReviews"
                          name="autoAccept5StarReviews"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({
                        id: 'bookingSettingsAutoAccept5StarReviews',
                      })}
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pr={1}
                  >
                    <FormControl style={{ minWidth: '260px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={onlyReviewsWithFeedback}
                            onChange={(event) =>
                              setOnlyReviewsWithFeedback(event.target.checked)
                            }
                            id="onlyReviewsWithFeedback"
                            name="onlyReviewsWithFeedback"
                            color="primary"
                            inputProps={{
                              'aria-label': 'primary checkbox',
                            }}
                          />
                        }
                        label={f({
                          id: 'bookingSettingsonlyReviewsWithFeedback',
                        })}
                      />
                    </FormControl>
                    <Tooltip
                      title={f({
                        id: 'bookingSettingsonlyReviewsWithFeedbackTooltip',
                      })}
                      enterTouchDelay={1}
                      leaveTouchDelay={2000}
                    >
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
          <Box mt={1} maxWidth={350}>
            <PaperWithTitle
              title={f({ id: 'bookingSettingsBanner' })}
              helpText={f({ id: 'bookingSettingsBannerTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1} pb={1}>
                <Box mb={2}>
                  {bannerImageUrl && !bannerImage ? (
                    <img
                      src={`https://agrddpxdbm.cloudimg.io/bound/150x50/cffffff/${bannerImageUrl}`}
                      alt={f({ id: 'bookingSettingsBanner' })}
                    />
                  ) : null}
                </Box>
                <Box mb={2} px={1}>
                  {f({ id: 'bookingSettingsBannerText' })}
                </Box>
                <Box mb={2}>
                  <FormControlLabel
                    control={
                      <Input
                        id="bannerImage"
                        name="bannerImage"
                        type="file"
                        onChange={(event) => {
                          setBannerImage(event.target.files[0]);
                          setBannerEmails([]);
                        }}
                        style={{ display: 'none' }}
                      />
                    }
                    label={f({ id: 'bookingSettingsBannerNew' })}
                    labelPlacement="top"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSalonzone ? '#230928' : '#212121',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '6px 16px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  />
                </Box>
                {bannerImage && (
                  <Box mb={2}>
                    <FormControl style={{ width: '260px' }}>
                      <InputLabel>
                        {f({ id: 'bookingSettingsBannerSize' })}
                      </InputLabel>
                      <Select
                        value={bannerSize}
                        onChange={(e) => setBannerSize(e.target.value)}
                      >
                        <MenuItem value="small">
                          {f({ id: 'bookingSettingsBannerSizeSmall' })}
                        </MenuItem>
                        <MenuItem value="large">
                          {f({ id: 'bookingSettingsBannerSizeLarge' })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {(bannerImageUrl || bannerImage) && (
                  <>
                    <Box mb={2}>
                      <FormControl style={{ width: '260px' }}>
                        <InputLabel>
                          {f({ id: 'bookingSettingsBannerSelectEmails' })}
                        </InputLabel>
                        <Select
                          multiple
                          value={bannerEmails}
                          onChange={(e) => setBannerEmails(e.target.value)}
                          input={<Input />}
                          renderValue={(selected) =>
                            selected
                              .map(
                                (selectedItem) =>
                                  (
                                    emailSlugs.find(
                                      (findSlug) =>
                                        findSlug.slug === selectedItem,
                                    ) || {}
                                  ).label,
                              )
                              .join(', ')
                          }
                        >
                          {emailSlugs.map((item) => (
                            <MenuItem key={item.slug} value={item.slug}>
                              <Checkbox
                                checked={bannerEmails.indexOf(item.slug) > -1}
                              />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box mb={2}>
                      <TextField
                        margin="dense"
                        style={{ minWidth: '260px' }}
                        id="bannerLink"
                        name="bannerLink"
                        label={f({ id: 'bookingSettingsBannerLink' })}
                        value={bannerLink}
                        onChange={(event) => setBannerLink(event.target.value)}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </PaperWithTitle>
          </Box>
        </Box>
        <Box m={1} maxWidth={350}>
          <PaperWithTitle title={f({ id: 'bookingSettingsBookingSettings' })}>
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <InputLabel htmlFor="minimumBookingWaitTime">
                      {f({ id: 'bookingSettingsMinimumBookingWaitTime' })}
                    </InputLabel>
                    <Select
                      value={minimumBookingWaitTime}
                      onChange={(event) =>
                        setMinimumBookingWaitTime(event.target.value)
                      }
                      input={
                        <Input
                          name="minimumBookingWaitTime"
                          id="minimumBookingWaitTime"
                        />
                      }
                    >
                      {minBookingWaitTimes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip
                    title={f({
                      id: 'bookingSettingsMinimumBookingWaitTimeTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <InputLabel htmlFor="maximumBookingWaitTime">
                      {f({ id: 'bookingSettingsMaximumBookingWaitTime' })}
                    </InputLabel>
                    <Select
                      value={maximumBookingWaitTime}
                      onChange={(event) =>
                        setMaximumBookingWaitTime(event.target.value)
                      }
                      input={
                        <Input
                          name="maximumBookingWaitTime"
                          id="maximumBookingWaitTime"
                        />
                      }
                    >
                      {maxBookingWaitTimes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip
                    title={f({
                      id: 'bookingSettingsMaximumBookingWaitTimeTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <InputLabel htmlFor="minimumCancellationTime">
                      {f({ id: 'bookingSettingsMinimumCancellationTime' })}
                    </InputLabel>
                    <Select
                      value={minimumCancellationTime}
                      onChange={(event) =>
                        setMinimumCancellationTime(event.target.value)
                      }
                      input={
                        <Input
                          name="minimumCancellationTime"
                          id="minimumCancellationTime"
                        />
                      }
                    >
                      {minCancellationTimes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip
                    title={f({
                      id: 'bookingSettingsMinimumCancellationTimeTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <InputLabel htmlFor="setBookingInterval">
                      {f({ id: 'bookingSettingsBookingInterval' })}
                    </InputLabel>
                    <Select
                      value={bookingInterval}
                      onChange={(event) =>
                        setBookingInterval(event.target.value)
                      }
                      input={
                        <Input name="bookingInterval" id="bookingInterval" />
                      }
                    >
                      {bookingIntervals.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip
                    title={f({ id: 'bookingSettingsBookingIntervalTooltip' })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={emailReminderEnabled}
                          onChange={(event) =>
                            setEmailReminderEnabled(event.target.checked)
                          }
                          id="emailReminderEnabled"
                          name="emailReminderEnabled"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({ id: 'bookingSettingsEmailReminderEnabled' })}
                    />
                  </FormControl>
                  <Tooltip
                    title={f({
                      id: 'bookingSettingsEmailReminderEnabledTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <FormControl
                  disabled={!emailReminderEnabled}
                  style={{ minWidth: '260px' }}
                >
                  <Box pb={3}>
                    <InputLabel htmlFor="emailReminderPeriod">
                      {f({ id: 'bookingSettingsEmailReminderPeriod' })}
                    </InputLabel>
                  </Box>
                  <Select
                    value={emailReminderPeriod}
                    onChange={(event) =>
                      setEmailReminderPeriod(event.target.value)
                    }
                    input={
                      <Input
                        name="emailReminderPeriod"
                        id="emailReminderPeriod"
                      />
                    }
                  >
                    {emailReminderPeriods.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={emailNotificationEnabled}
                          onChange={(event) =>
                            setEmailNotificationEnabled(event.target.checked)
                          }
                          id="emailNotificationEnabled"
                          name="emailNotificationEnabled"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({
                        id: 'bookingSettingsEmailNotificationEnabled',
                      })}
                    />
                  </FormControl>
                  <Tooltip
                    title={f({
                      id: 'bookingSettingsEmailNotificationEnabledTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
              <Box mb={2}>
                <FormControl
                  disabled={!emailNotificationEnabled}
                  style={{ minWidth: '260px' }}
                >
                  <TextField
                    disabled={!emailNotificationEnabled}
                    margin="dense"
                    id="emailNotificationEmailAddress"
                    name="emailNotificationEmailAddress"
                    label={f({
                      id: 'bookingSettingsEmailNotificationEmailAddress',
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={emailNotificationEmailAddress}
                    onChange={(event) =>
                      setEmailNotificationEmailAddress(event.target.value)
                    }
                  />
                </FormControl>
              </Box>
              <Box mb={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={1}
                >
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={emailBarberNotificationEnabled}
                          onChange={(event) =>
                            setEmailBarberNotificationEnabled(
                              event.target.checked,
                            )
                          }
                          id="emailBarberNotificationEnabled"
                          name="emailBarberNotificationEnabled"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({
                        id: 'bookingSettingsEmailBarberNotificationEnabled',
                      })}
                    />
                  </FormControl>
                  <Tooltip
                    title={f({
                      id:
                        'bookingSettingsEmailBarberNotificationEnabledTooltip',
                    })}
                    enterTouchDelay={1}
                    leaveTouchDelay={2000}
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
      </Box>

      <Box mt={2}>
        <PrimaryButton withmargin="true" onClick={() => handleSave()}>
          {f({ id: 'bookingSettingsSaveChanges' })}
        </PrimaryButton>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  getSettings: (...args) => dispatch(getSettings(...args)),
  saveSettings: (...args) => dispatch(saveSettings(...args)),
});

export default BaseHOC(
  AdminBookingSettingsForm,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
