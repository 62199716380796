import styled from 'styled-components';
import {
  Table as unstyledTable,
  Paper as unstyledPaper,
} from '@material-ui/core';

export const Table = styled(unstyledTable)`
  && {
    min-width: 200px;
    overflow-x: auto;
  }
`;

export const Paper = styled(unstyledPaper)`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    overflow-x: auto;
  }
`;
