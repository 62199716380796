import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

const IndentedCell = styled(TableCell)`
  && {
    padding-left: 4rem;
  }
`;

export default IndentedCell;
