import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';

import { getPaymentMethod, getItemKind, getDiscountedRowPrice } from './utils';

import Table from '../../Table';
import Loader from '../../Loader';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsSummarySales = ({ salesSummary, onChange }) => {
  const [discountFilter, setDiscountFilter] = useState(false);
  const { formatMessage: f } = useIntl();
  return (
    <Table
      title={f({ id: 'summarySalesTitle' })}
      onChange={onChange}
      count={salesSummary && salesSummary.count}
      filtersValues={{ discountFilter }}
      filters={(args) => (
        <Box>
          <IconButton
            aria-label={f({ id: 'summarySalesDiscountedSales' })}
            size="small"
            onClick={() => {
              setDiscountFilter(!discountFilter);
              onChange({ ...args, discountFilter: !discountFilter });
            }}
          >
            <Tooltip
              title={f({ id: 'summarySalesDiscountedSales' })}
              enterTouchDelay={1}
              leaveTouchDelay={2000}
            >
              {discountFilter ? (
                <MonetizationOnIcon fontSize="medium" color="primary" />
              ) : (
                <MonetizationOnOutlinedIcon fontSize="medium" color="primary" />
              )}
            </Tooltip>
          </IconButton>
        </Box>
      )}
    >
      {(salesSummary && (
        <>
          <TableHead>
            <TableRow>
              <TableCell>{f({ id: 'date' })}</TableCell>
              <TableCell>{f({ id: 'inputTime' })}</TableCell>
              <TableCell>{f({ id: 'createdBy' })}</TableCell>
              <TableCell>{f({ id: 'summarySalesProductService' })}</TableCell>
              <TableCell>{f({ id: 'title' })}</TableCell>
              <TableCell>{f({ id: 'discount' })}</TableCell>
              <TableCell>{f({ id: 'columnTitleUnitPrice' })}</TableCell>
              <TableCell>{f({ id: 'quantity' })}</TableCell>
              <TableCell>{f({ id: 'amount' })}</TableCell>
              <TableCell>{f({ id: 'paymentMethod' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesSummary.results &&
              salesSummary.results.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="HH:mm:ss">{row.created}</Moment>
                  </TableCell>
                  <TableCell>{row.created_by.display_name}</TableCell>
                  <TableCell>{getItemKind(row, f)}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {parseInt(row.discount_amount, 0) ? (
                      <CurrencyFormat amount={row.discount_amount} />
                    ) : parseFloat(row.discount_rate) ? (
                      `${row.discount_rate}%`
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    <CurrencyFormat amount={row.price} />
                  </TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>
                    <CurrencyFormat amount={getDiscountedRowPrice(row)} />
                  </TableCell>
                  <TableCell>{getPaymentMethod(row, f)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </>
      )) || <Loader />}
    </Table>
  );
};

const mapStateToProps = (state) => ({
  salesSummary: state.salesSummary,
});

export default BaseHOC(ReportsSummarySales, null, mapStateToProps, null);
