import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Typography,
  InputAdornment,
  Grid,
  DialogTitle,
  DialogContent,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getUser } from '../../../utils';
import { deleteSalaryRule } from '../../../actions';

import MultiShopSelector from '../../MultiShopSelector';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

const SALARY_TIERS = [
  { id: 0, limit: 100000, bonus: 10000 },
  { id: 1, limit: 200000, bonus: 10000 },
  { id: 2, limit: 300000, bonus: 10000 },
  { id: 3, limit: 400000, bonus: 10000 },
  { id: 4, limit: 500000, bonus: 20000 },
  { id: 5, limit: 550000, bonus: 10000 },
  { id: 6, limit: 600000, bonus: 10000 },
  { id: 7, limit: 650000, bonus: 10000 },
  { id: 8, limit: 700000, bonus: 10000 },
  { id: 9, limit: 750000, bonus: 10000 },
];

const TieredSalaryRuleDialog = ({
  dialogOpen,
  onSubmit,
  onCancel,
  params,
  deleteSalaryRule,
  enqueueSnackbar,
}) => {
  const { shopMap } = getUser();
  const [ruleName, setRuleName] = useState('');
  const [items, setItems] = useState([]);
  const [tiers, setTiers] = useState([...SALARY_TIERS]);
  const [shopIdList, setShopIdList] = useState(shopMap.map((x) => x.id));
  const settings = useSelector((state) => state.settings);
  const isReadOnly = params !== undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { formatMessage: f } = useIntl();

  const ITEM_OPTIONS = {
    giftcard_service_net: f({ id: 'salaryGiftcardServiceNet' }),
    giftcard_service_gross: f({ id: 'salaryGiftcardServiceGross' }),
    card_service_net: f({ id: 'salaryCardServiceNet' }),
    card_service_gross: f({ id: 'salaryCardServiceGross' }),
    cash_service_net: f({ id: 'salaryCashServiceNet' }),
    cash_service_gross: f({ id: 'salaryCashServiceGross' }),
    card_product_net: f({ id: 'salaryCardProductNet' }),
    card_product_gross: f({ id: 'salaryCardProductGross' }),
    cash_product_net: f({ id: 'salaryCashProductNet' }),
    cash_product_gross: f({ id: 'salaryCashProductGross' }),
  };

  const validate = () => {
    // TODO: FIX ME!
    console.log('FIX ME!');
    return true;
  };

  useEffect(() => {
    setRuleName((params && params.name) || '');
    setItems((params && params.items) || []);
    setTiers((params && params.tiers) || [...SALARY_TIERS]);
    setShopIdList((params && params.shops) || shopMap.map((x) => x.id));
  }, [params]);

  const changeTier = (tierId, limit, bonus) => {
    // try to make deepcopy
    const newTiers = [...tiers];
    // Update array
    newTiers[tierId].limit = limit;
    newTiers[tierId].bonus = bonus;
    // setTiers (doesn't work)
    setTiers(newTiers); // (doesn't work)
  };

  const reset = () => {
    setTiers([...SALARY_TIERS]);
    setItems([]);
    setRuleName('');
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialogOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        {f({ id: 'salaryTieredSalaryRuleDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              required
              disabled={isReadOnly}
              id="ruleName"
              name="ruleName"
              label={f({ id: 'salaryProvideName' })}
              InputLabelProps={{
                shrink: true, // Need to set this as the placeholder is too long
              }}
              margin="normal"
              value={ruleName}
              onChange={(event) => setRuleName(event.target.value)}
            />
          </Grid>
          {shopMap.length > 1 ? (
            <Grid item xs={12}>
              <MultiShopSelector
                disabled={isReadOnly}
                shopIdList={shopIdList}
                setShopIdList={setShopIdList}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="select-multiple-checkbox" shrink>
                {f({ id: 'salarySalaryBase' })}
              </InputLabel>
              <Select
                multiple
                disabled={isReadOnly}
                value={items}
                onChange={(event) => {
                  setItems(event.target.value);
                }}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={(key) =>
                  key.map((value) => ITEM_OPTIONS[value]).join(', ')
                }
              >
                {Object.keys(ITEM_OPTIONS).map((key) => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={items.indexOf(key) > -1} />
                    <ListItemText primary={ITEM_OPTIONS[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid container item xs={12}>
                  <Typography variant="body2" gutterBottom>
                    {f({ id: 'salaryTierHelper' })}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {f({ id: 'salaryTierMax' })}
                  </Grid>
                  <Grid item xs={6}>
                    {f({ id: 'salaryTierBonus' })}
                  </Grid>
                </Grid>
                {tiers.map((x) => (
                  <Grid container item xs={12} key={x.id}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        compact
                        disabled={isReadOnly}
                        id="limit"
                        name="limit"
                        label={f({ id: 'salaryTierLimit' })}
                        margin="normal"
                        value={x.limit}
                        onChange={(event) =>
                          changeTier(x.id, event.target.value, x.bonus)
                        }
                        type="number"
                        InputProps={
                          (settings.country.currency === 'Ft' && {
                            endAdornment: (
                              <InputAdornment position="end">Ft</InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'GBP' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'EUR' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'CZK' && {
                            startAdornment: (
                              <InputAdornment position="end">Kč</InputAdornment>
                            ),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        compact
                        disabled={isReadOnly}
                        id="bonus"
                        name="bonus"
                        label={f({ id: 'salaryTierBonus' })}
                        margin="normal"
                        value={x.bonus}
                        onChange={(event) =>
                          changeTier(x.id, x.limit, event.target.value)
                        }
                        type="number"
                        InputProps={
                          (settings.country.currency === 'Ft' && {
                            endAdornment: (
                              <InputAdornment position="end">Ft</InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'GBP' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'EUR' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'CZK' && {
                            startAdornment: (
                              <InputAdornment position="end">Kč</InputAdornment>
                            ),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={() => {
            reset();
            onCancel();
          }}
          color="primary"
        >
          {f({ id: 'buttonCancel' })}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={isReadOnly}
          onClick={() => {
            if (validate()) {
              onSubmit({
                ruleType: 'tieredsalaryrule',
                data: {
                  name: ruleName,
                  items,
                  tiers,
                  shopIdList,
                },
              });
              reset();
            }
          }}
        >
          {f({ id: 'buttonConfirm' })}
        </PrimaryButton>
        {isReadOnly ? (
          <PrimaryButton
            withmargin="true"
            danger
            onClick={() => {
              deleteSalaryRule(params.id, enqueueSnackbar, f);
              reset();
              onCancel();
            }}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  deleteSalaryRule: (...args) => dispatch(deleteSalaryRule(...args)),
});

export default BaseHOC(
  TieredSalaryRuleDialog,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
