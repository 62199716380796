import React, { useEffect, useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import {
  listReviewsApproved,
  listReviewsDenied,
  listReviewsUnprocessed,
} from '../../../actions';

import DashboardLayout from '../../DashboardLayout';

import AdminReviewsList from './AdminReviewsList';

const AdminReviewsPage = ({
  reviewsUnprocessed,
  reviewsApproved,
  reviewsDenied,
  dispatch,
  enqueueSnackbar,
}) => {
  const paginationInitLimit = 10;

  const [unprocessedFilterSearch, setUnprocessedFilterSearch] = useState('');
  const [
    unprocessedPaginationOffset,
    setUnprocessedPaginationOffset,
  ] = useState(0);
  const [unprocessedPaginationLimit, setUnprocessedPaginationLimit] = useState(
    paginationInitLimit,
  );

  const [approvedFilterSearch, setApprovedFilterSearch] = useState('');
  const [approvedPaginationOffset, setApprovedPaginationOffset] = useState(0);
  const [approvedPaginationLimit, setApprovedPaginationLimit] = useState(
    paginationInitLimit,
  );

  const [deniedFilterSearch, setDeniedFilterSearch] = useState('');
  const [deniedPaginationOffset, setDeniedPaginationOffset] = useState(0);
  const [deniedPaginationLimit, setDeniedPaginationLimit] = useState(
    paginationInitLimit,
  );

  const { formatMessage: f } = useIntl();

  const reloadAll = () => {
    dispatch(
      listReviewsUnprocessed({
        enqueueSnackbar,
        search: unprocessedFilterSearch,
        pagination: {
          offset: unprocessedPaginationOffset,
          limit: unprocessedPaginationLimit,
        },
      }),
    );
    dispatch(
      listReviewsApproved({
        enqueueSnackbar,
        search: approvedFilterSearch,
        pagination: {
          offset: approvedPaginationOffset,
          limit: approvedPaginationLimit,
        },
      }),
    );
    dispatch(
      listReviewsDenied({
        enqueueSnackbar,
        search: deniedFilterSearch,
        pagination: {
          offset: deniedPaginationOffset,
          limit: deniedPaginationLimit,
        },
      }),
    );
  };

  useEffect(() => {
    reloadAll();
  }, []);

  const handleUnprocessed = useCallback(({ offset, limit, search }) => {
    dispatch(
      listReviewsUnprocessed({
        search,
        enqueueSnackbar,
        pagination: { offset, limit },
      }),
    );
    setUnprocessedFilterSearch(search);
    setUnprocessedPaginationOffset(offset);
    setUnprocessedPaginationLimit(limit);
  }, []);
  const handleApproved = useCallback(({ offset, limit, search }) => {
    dispatch(
      listReviewsApproved({
        search,
        enqueueSnackbar,
        pagination: { offset, limit },
      }),
    );
    setApprovedFilterSearch(search);
    setApprovedPaginationOffset(offset);
    setApprovedPaginationLimit(limit);
  }, []);
  const handleDenied = useCallback(({ offset, limit, search }) => {
    dispatch(
      listReviewsDenied({
        search,
        enqueueSnackbar,
        pagination: { offset, limit },
      }),
    );
    setDeniedFilterSearch(search);
    setDeniedPaginationOffset(offset);
    setDeniedPaginationLimit(limit);
  }, []);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'reviewsTooltip' })}>
            {f({ id: 'appNavAdminBookingReviews' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminReviewsList
            reviews={reviewsUnprocessed}
            title={f({ id: 'reviewsUnprocessed' })}
            onChange={handleUnprocessed}
            onReload={reloadAll}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminReviewsList
            reviews={reviewsApproved}
            title={f({ id: 'reviewsApproved' })}
            onChange={handleApproved}
            onReload={reloadAll}
          />
        </Grid>
        <Grid item xs={12}>
          <AdminReviewsList
            reviews={reviewsDenied}
            title={f({ id: 'reviewsDenied' })}
            onChange={handleDenied}
            onReload={reloadAll}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  reviewsUnprocessed: state.reviewsUnprocessed,
  reviewsApproved: state.reviewsApproved,
  reviewsDenied: state.reviewsDenied,
});

export default BaseHOC(AdminReviewsPage, null, mapStateToProps);
