import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Moment from 'react-moment';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import MoreHoriz from '@material-ui/icons/MoreHoriz';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import VoidCell from '../../VoidCell';
import BaseHOC from '../../BaseHOC';

const ReportsTransactionsList = ({ onChange, initLimit }) => {
  const { formatMessage: f, locale } = useIntl();
  const sales = useSelector((state) => state.sales);

  const transactionKindMap = {
    'misc-payin': f({ id: 'transactionKindMiscPayin' }),
    'misc-payout': f({ id: 'transactionKindMiscPayout' }),
    service: f({ id: 'transactionKindService' }),
    product: f({ id: 'transactionKindProduct' }),
    giftcard: f({ id: 'transactionKindGiftcard' }),
    mixed: f({ id: 'transactionKindMixed' }),
    null: f({ id: 'transactionKindNull' }),
  };

  const paymentMethodMap = {
    card: f({ id: 'paymentMethodCard' }),
    cash: f({ id: 'paymentMethodCash' }),
    giftcard: f({ id: 'paymentMethodGiftcard' }),
  };

  return (
    <Table
      title=""
      onChange={onChange}
      count={sales && sales.count}
      initLimit={initLimit}
    >
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'date' })}</TableCell>
          <TableCell>{f({ id: 'inputTime' })}</TableCell>
          <TableCell>{f({ id: 'createdBy' })}</TableCell>
          <TableCell>{f({ id: 'transactionsInputKind' })}</TableCell>
          <TableCell>{f({ id: 'paymentMethod' })}</TableCell>
          <TableCell>{f({ id: 'amount' })}</TableCell>
          <TableCell>{f({ id: 'details' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sales &&
          sales.results &&
          sales.results.map((row) => (
            <TableRow key={`${row.id}-${row.created}`}>
              {row.voided ? (
                <>
                  <VoidCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </VoidCell>
                  <VoidCell>
                    <Moment format="HH:mm">{row.created}</Moment>
                  </VoidCell>
                  <VoidCell>{row.created_by.display_name}</VoidCell>
                  <VoidCell>{transactionKindMap[row.kind]}</VoidCell>
                  <VoidCell>{paymentMethodMap[row.payment_method]}</VoidCell>
                  <VoidCell>
                    <CurrencyFormat amount={row.total} />
                  </VoidCell>
                  <VoidCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        navigate(`/${locale}/app/transactions/${row.id}`)
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </VoidCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <Moment format="YYYY-MM-DD">{row.created}</Moment>
                  </TableCell>
                  <TableCell>
                    <Moment format="HH:mm">{row.created}</Moment>
                  </TableCell>
                  <TableCell>{row.created_by.display_name}</TableCell>
                  <TableCell>{transactionKindMap[row.kind]}</TableCell>
                  <TableCell>{paymentMethodMap[row.payment_method]}</TableCell>
                  <TableCell>
                    <CurrencyFormat amount={row.total} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        navigate(`/${locale}/app/transactions/${row.id}`)
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsTransactionsList);
