import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  Select,
  Grid,
} from '@material-ui/core';

import red from '@material-ui/core/colors/red';

import { getUsage, voidUsage, getEmployees } from '../../actions';

import DashboardLayout from '../DashboardLayout';
import BaseHOC from '../BaseHOC';
import { Header, SubHeader } from '../Header/styles';

const dangerTheme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  heading: {
    paddingBottom: theme.spacing(1),
  },
  voidHeading: {
    marginTop: theme.spacing(2),
    color: 'red',
  },
});

const UsageDetails = ({
  usageId,
  usageDetails,
  employees,
  classes,
  enqueueSnackbar,
  dispatch,
}) => {
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    dispatch(getUsage(parseInt(usageId, 10), enqueueSnackbar));
    dispatch(getEmployees());
  }, []);

  const getDetails = () => {
    return (
      <div>
        <Typography variant="body1" gutterBottom>
          {usageDetails.product.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {usageDetails.quantity}
        </Typography>
      </div>
    );
  };

  const getVoidDetails = () => {
    if (!usageDetails.voided) {
      return '';
    }
    return (
      <div>
        <Typography variant="h5" gutterBottom className={classes.voidHeading}>
          {`${f({ id: 'transactionDetailsVoided' })} - ${
            usageDetails.voided_by.display_name
          } - ${moment(usageDetails.voided).format('YYYY-MM-DD HH:mm')}`}
        </Typography>
      </div>
    );
  };

  const doVoidUsage = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    if (error) {
      return;
    }

    const voidData = {
      voidedBy: createdBy,
    };
    dispatch(voidUsage(usageDetails.id, voidData, enqueueSnackbar, f));
  };

  return (
    <DashboardLayout>
      <Box pb={4}>
        <Header variant="h2" noWrap>
          {f({ id: 'usageDetailsTitle' })}
        </Header>
      </Box>
      <Box pb={2}>
        <SubHeader variant="h5">
          {usageDetails.created_by.display_name}
        </SubHeader>
      </Box>
      {getDetails()}
      {getVoidDetails()}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => window.history.back()}
      >
        {f({ id: 'buttonBack' })}
      </Button>
      <MuiThemeProvider theme={dangerTheme}>
        <Button
          disabled={usageDetails.voided !== null}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {f({ id: 'buttonVoid' })}
        </Button>
      </MuiThemeProvider>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={dialogOpen}
        onExit={() => {
          setCreatedBy(null);
          setDialogOpen(false);
          setCreatedByError(false);
        }}
      >
        <DialogTitle>{f({ id: 'selectColleague' })}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                required
                error={createdByError}
                className={classes.formControl}
              >
                <InputLabel htmlFor="createdBy">
                  {f({ id: 'createdBy' })}
                </InputLabel>
                <Select
                  value={createdBy}
                  onChange={(event) => setCreatedBy(event.target.value)}
                  error={createdByError}
                  input={<Input name="createdBy" id="createdBy" />}
                >
                  {employees.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreatedBy(null);
              setDialogOpen(false);
              setCreatedByError(false);
            }}
            color="primary"
          >
            {f({ id: 'buttonCancel' })}
          </Button>
          <MuiThemeProvider theme={dangerTheme}>
            <Button onClick={() => doVoidUsage()} color="primary">
              {f({ id: 'buttonVoid' })}
            </Button>
          </MuiThemeProvider>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  usageDetails: state.usageDetails,
  employees: state.employees,
});

export default BaseHOC(UsageDetails, styles, mapStateToProps);
