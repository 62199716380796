import React, { useState } from 'react';

import moment from 'moment-timezone';
import 'moment/locale/hu';

import { Grid, Paper, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import PrimaryButton from '../../PrimaryButton';
import PaperHeader from '../../PaperHeader';
import BaseHOC from '../../BaseHOC';

import SalaryCalculationDeductionsAddDeductionDialog from './SalaryCalculationDeductionsAddDeductionDialog';
import SalaryCalculationDeductionsAddAdvanceDialog from './SalaryCalculationDeductionsAddAdvanceDialog';

const SalaryCalculationRulesButtons = ({
  employees,
  dispatch,
  filterStartDate,
  filterEndDate,
  filterShopIdList,
}) => {
  const [showAddDedcution, setShowAddDedcution] = useState(false);
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Paper>
        <Grid container justify="center">
          <Grid item xs={12}>
            <PaperHeader title={f({ id: 'buttonInput' })} />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" p={1}>
              <Box p={1}>
                <PrimaryButton onClick={() => setShowAddDedcution(true)}>
                  {f({ id: 'salaryDeductionDeduction' })}
                </PrimaryButton>
              </Box>
              <Box p={1}>
                <PrimaryButton onClick={() => setShowAddSalaryAdvance(true)}>
                  {f({ id: 'salaryDeductionAdvance' })}
                </PrimaryButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <SalaryCalculationDeductionsAddDeductionDialog
        showDialog={showAddDedcution}
        setShowDialog={setShowAddDedcution}
        filterStartDate={filterStartDate}
        filterEndDate={filterEndDate}
        filterShopIdList={filterShopIdList}
      />
      <SalaryCalculationDeductionsAddAdvanceDialog
        showDialog={showAddSalaryAdvance}
        setShowDialog={setShowAddSalaryAdvance}
        filterStartDate={filterStartDate}
        filterEndDate={filterEndDate}
        filterShopIdList={filterShopIdList}
      />
    </>
  );
};

export default BaseHOC(SalaryCalculationRulesButtons);
