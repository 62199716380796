import styled from 'styled-components';
import { Link } from '@material-ui/core';

const StyledLink = styled(Link)`
  &:hover {
    color: inherit;
  }
`;

export default StyledLink;
