import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useIntl } from 'react-intl';

import { Grid, Paper, Box } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

import MultiShopSelector from '../MultiShopSelector';
import BaseHOC from '../BaseHOC';
import { getUser, getDateFilterFromUrl } from '../../utils';
import { StyledDatePicker, SearchButton } from './styles';

const StartDateEndDateFilter = ({
  onFilterResults,
  initShopIdList,
  uri,
  singleShopSelect = false,
  allowFutureFilter = false,
  initSameDay = false,
  skipInitCall = false,
  rememberDates = false,
}) => {
  const user = getUser();
  const { shopMap } = user;
  const { formatMessage: f, locale } = useIntl();
  const settings = useSelector((state) => state.settings);

  const getStartDate = () => {
    if (rememberDates) {
      const startFromUri = getDateFilterFromUrl().startDate;
      if (startFromUri) return startFromUri;
    }

    if (initSameDay) {
      return moment()
        .startOf('day')
        .locale(locale)
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    return moment()
      .startOf('month')
      .startOf('day')
      .locale(locale)
      .format('YYYY-MM-DDTHH:mm:ss');
  };

  const getEndDate = () => {
    if (rememberDates) {
      const endFromUri = getDateFilterFromUrl().endDate;
      if (endFromUri) return endFromUri;
    }

    return moment()
      .endOf('day')
      .locale(locale)
      .format('YYYY-MM-DDTHH:mm:ss');
  };

  const [endDate, setEndDate] = useState(getStartDate());
  const [startDate, setStartDate] = useState(getEndDate());
  const [shopIdList, setShopIdList] = useState(
    initShopIdList && initShopIdList.length > 0
      ? initShopIdList
      : shopMap && shopMap.map((x) => x.id),
  );

  useEffect(() => {
    if (settings && settings.timezone) {
      moment.tz.setDefault(settings.timezone);
      setStartDate(getStartDate());
      setEndDate(getEndDate());
      if (!skipInitCall) {
        onFilterResults(getStartDate(), getEndDate(), shopIdList);
      }
    }
  }, [settings, initSameDay]);

  if (!user.canFilterDate) return null;

  return (
    <Box display="flex" justifyContent="flex-start">
      <Paper>
        <Box p="1rem">
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale={moment.locale(locale)}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs={12} sm={4}>
                <StyledDatePicker
                  disableFuture={!allowFutureFilter}
                  label={f({ id: 'startDateEndDateFilterFromLabel' })}
                  cancelLabel={f({ id: 'buttonCancel' })}
                  value={startDate}
                  onChange={(date) =>
                    setStartDate(
                      moment(date)
                        .startOf('day')
                        .locale(locale)
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledDatePicker
                  disableFuture={!allowFutureFilter}
                  label={f({ id: 'startDateEndDateFilterToLabel' })}
                  cancelLabel={f({ id: 'buttonCancel' })}
                  minDate={startDate}
                  minDateMessage={f({
                    id: 'startDateEndDateFilterEarlyDateError',
                  })}
                  value={endDate}
                  onChange={(date) =>
                    setEndDate(
                      moment(date)
                        .endOf('day')
                        .locale(locale)
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    )
                  }
                />
              </Grid>
              {shopMap && shopMap.length > 1 ? (
                <Grid item xs={12} sm={4}>
                  <MultiShopSelector
                    singleShopSelect={singleShopSelect}
                    shopIdList={shopIdList}
                    setShopIdList={setShopIdList}
                  />
                </Grid>
              ) : null}
              <Grid
                container
                item
                xs={10}
                sm={4}
                alignItems="center"
                justifyContent="center"
                alignContent="center"
              >
                <SearchButton
                  onClick={() => {
                    if (rememberDates) {
                      navigate(
                        `${uri}?startDate=${startDate}&endDate=${endDate}&shopIdList[]=${shopIdList.join(
                          '&shopIdList[]=',
                        )}`,
                        {},
                        true,
                      );
                    }
                    return onFilterResults(startDate, endDate, shopIdList);
                  }}
                >
                  {f({ id: 'buttonFilter' })}
                </SearchButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default BaseHOC(StartDateEndDateFilter);
