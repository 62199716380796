import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

import {
  removePurchaseItem,
  setPurchasePreviewTotal,
  getServices,
  getProducts,
} from '../../../actions';
import { getRounding, getSign, percentageToNormal } from '../../../utils';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const SaleItemTable = ({ items, paymentMethod, classes }) => {
  const { formatMessage: f } = useIntl();
  const settings = useSelector((state) => state.settings);
  const services = useSelector((state) => state.services);
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const getServicePriceForBarber = (service, employee) => {
    if (employee) {
      const levelId = (
        (
          employee.service_levels.find(
            (x) => x.service_base_id === service.base_id,
          ) || {}
        ).level || {}
      ).id;

      if (service && levelId) {
        const levelInfo =
          service.service_levels.find((x) => x.level.id === levelId) || {};

        return levelInfo.price;
      }
    }

    return service.price;
  };

  const getDatabaseItemFromArray = (row) => {
    if (row.kind === 'service') {
      const service = services.find((item) => item.id === row.itemId);
      return {
        ...service,
        price: getServicePriceForBarber(service, row.barber),
      };
    }
    if (row.kind === 'giftcard') {
      return services.find((item) => item.id === row.itemId);
    }
    if (row.kind === 'product') {
      return products.find((item) => item.id === row.itemId);
    }
  };

  const getItemPrice = (item) =>
    parseFloat(getDatabaseItemFromArray(item).price);

  const getDiscountedRowPrice = (item) =>
    Math.round(
      100 *
        (item.quantity *
          getItemPrice(item) *
          percentageToNormal(item.discountRate) -
          item.discountAmount +
          parseFloat(item.miscSaleAmount)),
    ) / 100;

  const subtotal = (subitems) =>
    subitems.map(getDiscountedRowPrice).reduce((sum, i) => sum + i, 0);

  useEffect(() => {
    dispatch(getServices());
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    const invoiceSubtotal = paymentMethod === 'giftcard' ? 0 : subtotal(items);
    const invoiceRounding =
      paymentMethod === 'cash'
        ? getRounding(invoiceSubtotal, settings && settings.country.currency) -
          invoiceSubtotal
        : 0;
    const invoiceTotal = invoiceSubtotal + invoiceRounding;
    dispatch(setPurchasePreviewTotal(invoiceTotal));
  }, [paymentMethod, items]);

  const getItemName = (item) =>
    item.kind === 'giftcard'
      ? `${f({ id: 'paymentMethodGiftcard' })}: ${
          getDatabaseItemFromArray(item).title
        }`
      : `${getDatabaseItemFromArray(item).title}`;

  const handleDelete = (row) => {
    dispatch(removePurchaseItem(row.id));
  };

  const invoiceSubtotal = paymentMethod === 'giftcard' ? 0 : subtotal(items);
  const invoiceRounding =
    paymentMethod === 'cash'
      ? getRounding(invoiceSubtotal, settings && settings.country.currency) -
        invoiceSubtotal
      : 0;
  const invoiceTotal = invoiceSubtotal + invoiceRounding;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'columnTitleGoods' })}</TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleUnitPrice' })}
            </TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleQuantity' })}
            </TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleDiscount' })}
            </TableCell>
            <TableCell align="right">{f({ id: 'miscSaleAmount' })}</TableCell>
            <TableCell align="right">
              {f({ id: 'columnTitleRowSum' })}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{getItemName(row)}</TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={getItemPrice(row)} />
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                {row.discountAmount ? (
                  <CurrencyFormat amount={row.discountAmount} />
                ) : row.discountRate ? (
                  `${row.discountRate} %`
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={row.miscSaleAmount} />
                {row.miscSaleComment && ` (${row.miscSaleComment})`}
              </TableCell>
              <TableCell align="right">
                <CurrencyFormat amount={getDiscountedRowPrice(row)} />
              </TableCell>
              <TableCell align="right">
                <IconButton
                  className={classes.button}
                  aria-label="Delete"
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell
              rowSpan={
                settings &&
                settings.country &&
                settings.country.currency &&
                settings.country.currency === 'Ft'
                  ? 5
                  : 4
              }
            />
            <TableCell>{f({ id: 'saleItemTableSum' })}</TableCell>
            <TableCell align="right">
              <CurrencyFormat amount={invoiceSubtotal} />
            </TableCell>
            <TableCell />
          </TableRow>
          {settings &&
          settings.country &&
          settings.country.currency &&
          settings.country.currency === 'Ft' ? (
            <>
              <TableRow>
                <TableCell>{f({ id: 'saleItemTableRounding' })}</TableCell>
                <TableCell align="right">
                  {getSign(invoiceRounding)}
                  <CurrencyFormat amount={invoiceRounding} />
                </TableCell>
                <TableCell />
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell>{f({ id: 'saleItemTableTotal' })}</TableCell>
            <TableCell align="right">
              <CurrencyFormat amount={invoiceTotal} />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BaseHOC(SaleItemTable, styles);
