import React from 'react';

import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AssignSalaryCalculation from './AssignSalaryCalculation';
import SalaryCalculationAssignmentList from './SalaryCalculationAssignmentList';

const SalaryCalculationAssignmentsPage = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'salaryAssignmentsTooltip' })}>
            {f({ id: 'appNavSalaryAssignments' })}
          </Header>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <AssignSalaryCalculation />
        </Grid>
        <Grid item xs={12}>
          <SalaryCalculationAssignmentList />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(SalaryCalculationAssignmentsPage);
