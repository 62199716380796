export default (shopDetails) => `
<h1>ADATVÉDELMI NYILATKOZAT</h1>
<p>A ${shopDetails.name} Időpont Foglaló szolgáltatás üzemeltetője a ${
  shopDetails.companyName
} (${shopDetails.address.postalCode} ${shopDetails.address.town}, ${
  shopDetails.address.addressLine1
}, adószám: ${shopDetails.taxReference}, továbbiakban: mint Szolgáltató) a ${
  shopDetails.bookingUrl
} felületén, valamint az ehhez kapcsolódó tevékenysége során, a
    Felhasználói adatok kezelésében az alábbi adatkezelési szabályokat érvényesíti:</p>
<h2>I. A Tájékoztató célja, hatálya</h2>
<p>1.1. Mi a jelen Tájékoztató célja?</p>
<p>Jelen tájékoztató célja a ${shopDetails.companyName} (${
  shopDetails.address.postalCode
} ${shopDetails.address.town}, ${shopDetails.address.addressLine1}, adószám: ${
  shopDetails.taxReference
}, továbbiakban: mint Szolgáltató)
    által alkalmazott adatvédelmi- és adatkezelési elveknek és politikának a rögzítése és ismertetése.</p>
<p>1.2. Milyen jogszabályok rendelkezései alapján került elfogadásra a jelen Tájékoztató?</p>
<p>Jelen Tájékoztató elfogadásakor a Szolgáltató különösen az alábbi jogszabályokat vette figyelembe:</p>
<ol>
    <li>Magyarország Alaptörvénye;</li>
    <li>a Polgári Törvénykönyvről szóló 2013. évi V. törvény (a továbbiakban: Ptk.);</li>
    <li>az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (a továbbiakban:
        Infotv.);</li>
    <li>az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások
        egyes kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Eker. tv.),</li>
    <li>a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény (a
        továbbiakban: Grt.);</li>
    <li>a számvitelről szóló 2000. évi C. törvény (a továbbiakban: Számviteli tv.);</li>
    <li>az általános forgalmi adóról szóló 2007. évi CXXVII. törvény (a továbbiakban: Áfa tv.);</li>
    <li>a fogyasztóvédelemről szóló 1997. évi CLV. törvény (a továbbiakban: Fgy. tv.);</li>
    <li>a postai szolgáltatásokról szóló 2012. évi CLIX. törvény (a továbbiakban: Postatv.)</li>
    <li>a kutatás és közvetlen üzletszerzés célját szolgáló név- és lakcímadatok kezeléséről szóló 1995. évi CXIX.
        törvény;</li>
    <li>az egyének védelméről a személyes adatok gépi feldolgozása során, Strasbourgban, 1981. január 28. napján kelt
        Egyezmény kihirdetéséről szóló 1998. évi VI. törvény;</li>
    <li>Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) a természetes személyeknek a
        személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
        95/46/EK rendelet hatályon kívül helyezéséről (a továbbiakban: GDPR).</li>
</ol>
<h2>II. Fogalmak</h2>
<p>2.1. Mi az adatkezelés?</p>
<p>A személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy
    műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás,
    lekérdezés, betekintés, felhasználás, közlés, továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel
    útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés.</p>
<p>2.2. Ki az adatkezelő?</p>
<p>Az, aki a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza.</p>
<p>A jelen Tájékoztató tekintetében adatkezelőnek minősül az 1.1. pontban megjelölt Szolgáltató.</p>
<p>2.3 Ki az érintett?</p>
<p>Bármely meghatározott, személyes adat alapján azonosított vagy - akár közvetlenül, akár közvetve - azonosítható
    természetes személy.</p>
<p>2.4. Mi a személyes adat?</p>
<p>Azonosított vagy azonosítható természetes személyre („érintett”) vonatkozó bármely információ. Azonosítható az a
    természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, szám,
    helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi,
    gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható.</p>
<p>2.5. Ki az adatfeldolgozó?</p>
<p>Az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a Szolgáltató, mint
    adatkezelő nevében személyes adatokat kezel.</p>
<p>2.6. Melyik hatóság gyakorol felügyeletet a Szolgáltató által végzett adatkezeléssel kapcsolatosan?</p>
<p>A Nemzeti Adatvédelmi és Információszabadság Hatóság (1125 Budapest, Szilágyi Erzsébet fasor 22/c).</p>
<h2>III. A személyes adatok köre</h2>
<p>3.1. Milyen személyes adatokat gyűjt az Szolgáltató?</p>
<p>A Szolgáltató, mint adatkezelő az általa ${
  shopDetails.bookingUrl
} weboldalon (továbbiakban: Weboldal)
    működtetett foglalási oldalon történő időpont foglalás során az érintett (továbbiakban: Felhasználó) által önkéntes
    módon megadott adatokat kezeli, amelyek a következők:</p>
<p>Természetes személyek esetében:</p>
<p>Kötelező adatok: vezetéknév, keresztnév, e-mail cím, telefonszám</p>
<p>A Felhasználó a fenti adatai megadására nem köteles, azonban azok hiányában nem tudja elvégezni a Weboldalon
    keresztül a szolgáltatásokra történő előzetes foglalást.</p>
<p>A Szolgáltató adatkezelő rendszere regisztráció hiányában is rögzíti a Weboldalt megtekintők IP-címét.</p>
<p>A regisztrációt követően a Szolgáltató a Felhasználó döntésétől függően kezelheti még a Felhasználó utolsó
    belépésének az időpontját és IP-címét.</p>
<p>3.2. Milyen módon gyűjti a személyes adatokat a Szolgáltató?</p>
<p>A Szolgáltató a Felhasználó személyes adatait az általa üzemeltetett Weboldalon történő regisztráció, illetve egyes
    adatok esetében a Weboldal megtekintése útján gyűjti.</p>
<p>3.3. Mit jelent a „cookie”?</p>
<p>Annak érdekében, hogy a Szolgáltató minél testreszabott módon kínálhasson termékeket, illetve szolgáltatásokat, a
    Weboldalát használó személyek számítógépén ún. „cookie”-t vagyis kis adatcsomagot helyez el, amely célja még a
    Weboldal felhasználói élményének növelése.</p>
<p>A Weboldal használójának természetesen lehetősége van arra, hogy a böngészője beállítását akként állítsa be, hogy az
    letiltsa a cookie-k alkalmazását, illetve a már telepített cookie-kat törölheti. Amennyiben a Weboldal használója
    letiltja a cookie-k alkalmazását, akkor azzal azonban tudomásul veszi és elfogadja, hogy a Weboldal nem fog teljes
    értékűen működni, amellyel kapcsolatosan a Szolgáltatót semminemű felelősség nem terheli.</p>
<p>A Szolgáltató a cookie-k alkalmazásával kizárólag a következő adatokat kezeli: a weboldalán felkeresett termékek, a
    weboldalon töltött idő, a Szolgáltató által forgalmazott termékekre vonatkozó vásárlási preferenciák a weboldal
    böngészési előzményei alapján.</p>
<h2>IV. Az adatkezelés célja és jogalapja</h2>
<p>4.1. Milyen célból folytat adatkezelést a Szolgáltató?</p>
<ol>
    <li>az érintett azonosítása;</li>
    <li>az érintett jogosultságainak azonosítása;</li>
    <li>az érintettel való kapcsolattartás;</li>
    <li>az érintett egyedi megkeresése, és ezen megkeresések kezelése, intézése;</li>
    <li>az érintett jogainak védelme;</li>
    <li>statisztikák, elemzések készítése;</li>
    <li>a Szolgáltató üzleti tevékenységének folytatása;</li>
    <li>a Szolgáltató jogos érdekeinek érvényesítése.</li>
</ol>
<p>4.2. Mit jelent a Szolgáltató üzleti tevékenységének folytatása, mint adatkezelési cél?</p>
<p>E körbe tartozó adatkezelési célnak minősül különösen a Szolgáltató által történő termékértékesítés (a vásárlásnak,
    és az ellenérték kifizetésének), illetve a Szolgáltató üzletében nyújtott és igénybe vett szolgáltatás
    megrendelésének dokumentálása, az ahhoz kapcsolódó számviteli kötelezettségek teljesítése, a megvásárolt termék(ek)
    szolgáltatása (kiszállítás vagy átadás), a fizetés teljesítéséhez szükséges lehetőségek biztosítása, illetve az
    online felület alkalmazásával teljesítésre kerülő tranzakciók esetén a visszaélésgyanús ügyletek kiszűrése. A
    megadott személyes adatokat a Szolgáltató emellett a könyvelési-, könyvvizsgálati- és egyéb belső feladatai,
    funkciói ellátása céljából is felhasználja.</p>
<p>4.3. A Szolgáltató egyéb célra is felhasználhatja-e a személyes adatokat?</p>
<p>A Szolgáltató az érintett személyes adatait egyéb módokon is felhasználhatja, mely esetben erről az adatgyűjtés
    időpontjában külön értesítést küld, valamint szükség esetén ahhoz az érintett hozzájárulását kéri.</p>
<p>4.4. Mi a Szolgáltató adatkezelésének a jogalapja?</p>
<p>A Szolgáltató a személyes adatokat kizárólag abban az esetben kezeli, ha az alábbi feltételek közül legalább egy
    teljesül az adatkezeléssel kapcsolatosan:</p>
<ol>
    <li>az érintett hozzájárulását adta személyes adatainak egy vagy több konkrét célból történő kezeléséhez;</li>
    <li>az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a szerződés
        megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges;</li>
    <li>az adatkezelés a Szolgáltatóra vonatkozó jogi kötelezettség teljesítéséhez szükséges;</li>
    <li>az adatkezelés az érintett vagy egy másik természetes személy létfontosságú érdekeinek védelme miatt szükséges;
    </li>
    <li>az adatkezelés közérdekű vagy a Szolgáltatóra ruházott közhatalmi jogosítvány gyakorlásának keretében végzett
        feladat végrehajtásához szükséges;</li>
    <li>az adatkezelés a Szolgáltató vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges, kivéve, ha ezen
        érdekekkel szemben elsőbbséget élveznek az érintett olyan érdekei vagy alapvető jogai és szabadságai, amelyek
        személyes adatok védelmét teszik szükségessé, különösen, ha az érintett gyermek.</li>
</ol>
<p>4.5. Mit jelent az érintett hozzájárulásán alapuló adatkezelés?</p>
<p>Azt jelenti, hogy az adatkezelésre az érintett önkéntes, megfelelő előzetes tájékoztatáson alapuló nyilatkozata
    alapján kerül sor, amely nyilatkozat tartalmazza az érintett kifejezett hozzájárulását ahhoz, hogy az általa közölt
    személyes adatokat a Szolgáltató felhasználja, kezelje.</p>
<p>4.6. Az érintett visszavonhatja-e a hozzájárulását?</p>
<p>Az érintett a hozzájáruláson alapuló adatkezelés esetén jogosult a hozzájárulását bármikor visszavonni, amely azonban
    nem érinti a visszavonás előtti adatkezelés jogszerűségét.</p>
<p>4.7. Mit jelent a szerződés teljesítéséhez szükséges adatkezelés?</p>
<p>Amennyiben az érintett szerződést köt a Szolgáltatóval, akkor a Szolgáltató jogosult a szerződést kötő érintett
    személyes adatait kezelni az így létrejövő szerződés megkötéséhez, illetve annak teljesítéséhez. A Szolgáltató által
    nyújtott szolgáltatás és termékértékesítés is szerződéskötésnek minősül e körben.&nbsp;</p>
<p>4.8. Mit jelent az, hogy az adatkezelés a Szolgáltatóra vonatkozó jogi kötelezettség teljesítéséhez szükséges?</p>
<p>Azt jelenti, hogy a Szolgáltató a jogszabályokban rögzített kötelezettsége teljesítése érdekében végzi az
    adatkezelést. Ilyen kötelezettségek terhelik a Szolgáltató különösen a Számviteli tv., illetve az Áfa. tv. alapján a
    termékértékesítés, szolgáltatásnyújtás dokumentálásával, és ezen dokumentumok megőrzésével kapcsolatosan.</p>
<p>4.9. Mit jelent az Szolgáltató vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges adatkezelés?</p>
<p>A Szolgáltató a GDPR rendelkezéseivel összhangban elvégezte, és el fogja végezni az érdekmérlegelési tesztet,
    amelynek a célja annak megállapítása, hogy a Szolgáltatónak, illetve harmadik személynek az adatkezeléshez fűződő
    jogos érdeke erősebb-e, mint az érintett olyan érdekei vagy alapvető jogai és szabadságai, amelyek a személyes
    adatok védelmét teszik szükségessé.</p>
<p>Ilyen jogos érdeknek minősül különösen az online fizetéssel kapcsolatos azon tranzakciók kiszűrése, amelyek esetében
    visszaélés gyanúsítható.</p>
<h2>V. Az adatkezelés elvei</h2>
<p>5.1. Milyen elveket alkalmaz az Szolgáltató az adatkezelés során?</p>
<p>A személyes adatok</p>
<ol>
    <li>kezelését a Szolgáltató jogszerűen és tisztességesen, valamint az érintett számára átlátható módon végzi
        (jogszerűség, tisztességes eljárás és átláthatóság);</li>
    <li>gyűjtését a Szolgáltató csak meghatározott, egyértelmű és jogszerű célból végzi, és úgy, hogy azokat ne kezelje
        ezekkel a célokkal össze nem egyeztethető módon (célhoz kötöttség);</li>
    <li>az adatkezelés céljai szempontjából megfelelőnek és relevánsak legyenek, és a szükségesre kell korlátozódniuk
        (adattakarékosság);</li>
    <li>pontosak és szükség esetén naprakészek legyenek; e körben a Szolgáltatónak minden ésszerű intézkedést meg kell
        tennie annak érdekében, hogy az adatkezelés céljai szempontjából pontatlan személyes adatokat haladéktalanul
        töröljék vagy helyesbítsék (pontosság);</li>
    <li>tárolását a Szolgáltató olyan formában biztosítja, amely az érintettek azonosítását csak a személyes adatok
        kezelése céljainak eléréséhez szükséges ideig teszi lehetővé (korlátozott tárolhatóság);</li>
    <li>kezelését a Szolgáltató oly módon végzi, hogy a megfelelő technikai vagy szervezési intézkedések alkalmazásával
        biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével,
        azok véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ideértve (integritás és
        bizalmas jelleg);</li>
</ol>
<p>Az Szolgáltató felelősséget vállal az 1)-6) pontban foglalt alapelveknek való megfelelésért, továbbá képes a
    megfelelés igazolására (elszámoltathatóság).</p>
<p>5.2. A Szolgáltató eltérhet-e az adatkezelés eredeti céljától?</p>
<p>Abban az esetben, ha az Szolgáltató a személyes adatokat az eredeti adatfelvétel céljától eltérő célra kívánja
    felhasználni, köteles erről az érintettet tájékoztatni és az előzetes, kifejezett hozzájárulását megszerezni, egyben
    lehetővé tenni számára az eltérő célú felhasználás megtiltását.</p>
<p>5.3. Ellenőrzi-e az Szolgáltató a személyes adatok helyességét?</p>
<p>A Szolgáltató az érintett által megadott személyes adatokat kizárólag a jogszabályban előírt esetben ellenőrzi. Az
    érintett tartozik felelősséggel az általa megadott személyes adatok helyességéért, pontosságáért, megfelelőségéért.
</p>
<p>5.4. A Szolgáltató az adatfeldolgozón kívüli személynek átad-e személyes adatot?</p>
<p>A Szolgáltató személyes adatot - a jelen Tájékoztatóban hivatkozott kivételekkel - nem ad át az adatfeldolgozón
    kívüli harmadik személynek.</p>
<p>A Szolgáltató az alábbi kivételes esetben ad át személyes adatot az adatfeldolgozón kívüli harmadik személynek</p>
<ol>
    <li>a hivatalos bírósági-, rendőrségi megkeresés</li>
    <li>a személyes adatok statisztikailag összesített formában történő felhasználása, mely az érintett beazonosítására
        alkalmas egyéb adatot semmilyen formában nem tartalmazhat, és így annak átadása nem minősülhet adatkezelésnek,
        illetve adattovábbításnak.</li>
</ol>
<p>5.5. Kiket értesít a Szolgáltató a személyes adat helyesbítéséről, korlátozásáról, törléséről?</p>
<p>A Szolgáltató az általa kezelt személyes adatok helyesbítéséről, korlátozásáról, azok törléséről az érintettet,
    valamint mindazon személyeket értesíti, akik számára a személyes adatokat korábban továbbította. Ezen értesítés
    abban az esetben mellőzhető, ha az adatkezelés céljára tekintettel az nem sérti az érintett jogos érdekeit.</p>
<p>5.6. Működik-e adatvédelmi tisztviselő a Szolgáltatónál?</p>
<p>A GDPR rendelkezéseire tekintettel a Szolgáltatónál nem működik adatvédelmi tisztviselő.</p>
<h2>VI. Az adatkezelés időtartama</h2>
<p>6.1. A Szolgáltató az alábbi feltételek bármelyikének a bekövetkeztéig kezeli a személyes adatokat:</p>
<ol>
    <li>a személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy más módon kezelték;</li>
    <li>az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az adatkezelésnek nincs más jogalapja;
    </li>
    <li>az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre;</li>
    <li>a személyes adatokat jogellenesen kezelte a Szolgáltató;</li>
    <li>a személyes adatokat a Szolgáltatóra alkalmazandó jogszabályban előírt jogi kötelezettség teljesítése kapcsán
        törölni kell;</li>
    <li>a személyes adatok tárolásának törvényben meghatározott határideje lejárt;</li>
    <li>a személyes adat hiányos vagy téves - és ez az állapot jogszerűen nem orvosolható -, feltéve, hogy a törlést
        törvény nem zárja ki;</li>
    <li>a felelős adatvédelmi hatóság vagy bíróság elrendelte a személyes adat törlését.</li>
</ol>
<p>6.2. A fentiek figyelembevételével a jogszerűen kezelt személyes adatokat a Szolgáltató a Ptk. 6:22.§-ában
    szabályozott, az utolsó foglalás időpontjától számított 5 (öt) éves elévülési idő figyelembevételével kezeli,
    amennyiben az érintett a hozzájárulását visszavonta, a szerződés már teljesült, és nem áll fenn olyan indok, amely
    ennél hosszabb időtartamú adatkezelést tenne szükségessé.</p>
<p>A Számviteli tv. eltérő rendelkezése alapján megőrzendő adatokat a Szolgáltató az érintett hozzájárulásától
    függetlenül a Felhasználó regisztrált fiókjának a megszűnésétől számított 8 (nyolc) év elteltével törli.</p>
<p>Az érintett által benyújtott panaszról készült jegyzőkönyvet, és az ahhoz kapcsolódó választ a Szolgáltató 5 (öt)
    évig köteles megőrizni.</p>
<h2>VII. Az érintett jogai és annak érvényesülése</h2>
<p>7.1. Milyen jogok illetik meg az érintettet az adatkezeléssel kapcsolatosan?</p>
<p>Az érintett az alábbi jogokkal rendelkezik az adatkezeléssel kapcsolatosan:</p>
<ol>
    <li>tájékoztatáshoz való jog (GDPR 13. és 14. cikk);</li>
    <li>a személyes adatokhoz való hozzáférés joga (GDPR 15. cikk);</li>
    <li>a személyes adatok helyesbítéséhez és kiegészítéséhez való jog (GDPR 16. cikk);</li>
    <li>a személyes adatok törléséhez való jog (GDPR 17. cikk);</li>
    <li>az adatkezelés korlátozásához való jog (GDPR 18. cikk);</li>
    <li>az adathordozhatóság joga (GDPR 20. cikk); és</li>
    <li>a személyes adatok kezelése elleni tiltakozáshoz való jog (GDPR 21. cikk).</li>
</ol>
<p>7.2. Mit jelent a tájékoztatáshoz való jog?</p>
<p>Az adatkezelés ideje alatt az érintett tájékoztatást kérhet a személyes adatai kezeléséről a Szolgáltatótól.</p>
<p>Az érintett a személyes adatai kezeléséről bármikor írásban tájékoztatást kérhet a Szolgáltató székhelyére küldött
    ajánlott, vagy tértivevényes levélben, vagy a Szolgáltató jelen Tájékoztatóban megjelölt elérhetőségére küldött
    elektronikus levélben.</p>
<p>A Szolgáltató a tájékoztatáskérést akkor tekinti hitelesnek és teljesíthetőnek, ha i) a postai úton küldött levél
    esetében az érintett egyértelműen beazonosítható, míg ii) az elektronikus levél esetében akkor, ha a levél az
    érintettnek az általa előzetesen regisztrált e-mail címéről került megküldésre. A Szolgáltató fenntartja azon jogát,
    hogy a tájékoztatási kérelem teljesítését megelőzően az érintett azonosítását más módon is elvégezze.</p>
<p>A Szolgáltató által nyújtott tájékoztatás a GDPR-ban szabályozott információkra terjed ki, amely különösen a
    következő információkat jelenti: a Szolgáltató adatai (beleértve a képviselője neve, elérhetősége), az adatkezelés
    célja és jogalapja, az adatok forrása, a személyes adatok tárolásának időtartama (vagy a meghatározása szempontja),
    az érintett jogai (személyes adatokhoz való hozzáférés, azok helyesbítésének, törlésének vagy kezelésük
    korlátozásának a kérése, vagy tiltakozás a személyes adatok kezelése ellen, illetve az érintett adathordozhatósághoz
    való joga), a hozzájárulás visszavonásának joga, jogorvoslati jog (panasz, bírósági jogorvoslat), a Szolgáltató,
    mint adatkezelő vagy harmadik fél jogos érdeke (ha az adatkezelés ezen alapul), a személyes adatok címzettjei (ha
    van ilyen), annak ténye, hogy a Szolgáltató, mint adatkezelő harmadik országba vagy nemzetközi szervezet részére
    kívánja továbbítani a személyes adatokat (ha van ilyen).</p>
<p>7.3. Mit jelent a személyes adatokhoz való hozzáférés joga?</p>
<p>Az érintett jogosult arra, hogy a Szolgáltatótól visszajelzést kapjon arra vonatkozóan, hogy a személyes adatainak
    kezelése folyamatban van-e. Amennyiben ilyen adatkezelés folyamatban van, akkor az érintett jogosult arra, hogy a
    személyes adatokhoz és az előző pontban részletezett információkhoz hozzáférést kapjon.</p>
<p>7.4. Mit jelent a személyes adatok helyesbítéséhez és kiegészítéséhez való jog?</p>
<p>Az érintett jogosult arra, hogy kérésére a Szolgáltató indokolatlan késedelem nélkül helyesbítse a rá vonatkozó
    pontatlan személyes adatokat.</p>
<p>Az érintett jogosult arra, hogy az adatkezelés célját figyelembe véve kérje a hiányos személyes adatok - egyebek
    mellett kiegészítő nyilatkozat útján történő - kiegészítését.</p>
<p>Ha a Szolgáltató észleli, hogy az általa kezelt személyes adat hibás, akkor a rendelkezésre álló dokumentumok vagy
    közhiteles nyilvántartások alapján, illetve szükség szerint az érintettel történő egyeztetés után helyesbíti az
    érintett adatot.</p>
<p>Amennyiben az adat helyesbítésére nincs mód, akkor azt a Szolgáltató törli. Abban az esetben, ha a helyesbítésnek
    vagy a törlésnek bármilyen akadálya van, akkor azt véglegesen zárolni kell a helyesbítés feltüntetése mellett.</p>
<p>7.5. Mit jelent a személyes adatok törléséhez való jog?</p>
<p>Az érintett - megfelelő azonosítását követően - jogosult arra, hogy kérésére a Szolgáltató indokolatlan késedelem
    nélkül törölje a rá vonatkozó személyes adatokat. A Szolgáltató az érintett törlés iránti kérelmének teljesítését
    megtagadhatja, ha az adatkezelés szükséges</p>
<ol>
    <li>a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából;</li>
    <li>a személyes adatok kezelését előíró, a Szolgáltatóra alkalmazandó jogszabály szerinti kötelezettség teljesítése,
        illetve közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat
        végrehajtása céljából;</li>
    <li>a népegészségügy területét érintő közérdek alapján;</li>
    <li>a közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból, amennyiben a
        törléshez való jog valószínűsíthetően lehetetlenné tenné vagy komolyan veszélyeztetné ezt az adatkezelést; vagy
    </li>
    <li>jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.</li>
</ol>
<p>Amennyiben a Szolgáltató megtagadja az érintett törlés iránti kérelmének teljesítését, akkor arról minden esetben
    tájékoztatja az érintettet a megtagadás indokának megjelölésével, és a jogorvoslatról való tájékoztatással.</p>
<p>A törlési kérelem teljesítése esetén a személyes adatokat olyan módon kell törölni, hogy az már nem legyen
    helyreállítható.</p>
<p>7.6. Milyen esetben került sor a személyes adat zárolására a törlés helyett?</p>
<p>A személyes adatot annak törlése helyett zárolni kell, ha az érintett ezt kéri, vagy ha a rendelkezésére álló
    információk alapján feltételezhető, hogy a törlés sértené az érintett jogos érdekeit.</p>
<p>7.7. Mit jelent az adatkezelés korlátozásához való jog?</p>
<p>Az érintett jogosult arra, hogy kérésére a Szolgáltató korlátozza az adatkezelést, ha</p>
<ol>
    <li>az érintett vitatja a személyes adatok pontosságát (Ez esetben a korlátozás arra az időtartamra vonatkozik,
        amely lehetővé teszi, hogy az adatkezelő ellenőrizze a személyes adatok pontosságát); vagy</li>
    <li>az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és a törlés helyett azok felhasználásának
        korlátozását kéri; vagy</li>
    <li>az adatkezelés célja megvalósult, de az érintett igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez
        vagy védelméhez; vagy</li>
    <li>az érintett tiltakozott az adatkezelés ellen (Ez esetben a korlátozás arra az időtartamra vonatkozik, amíg
        megállapításra nem kerül, hogy a Szolgáltató jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival
        szemben.).</li>
</ol>
<p>7.8. Mit jelent az adathordozhatóság joga?</p>
<p>Az érintettnek joga van arra, hogy a rá vonatkozó és általa a Szolgáltató rendelkezésére bocsátott személyes adatokat
    tagolt, széles körben használt, géppel olvasható formátumban megkapja. Az érintett továbbá jogosult arra, hogy
    ezeket az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná azon Szolgáltató, amelynek a
    személyes adatokat a rendelkezésére bocsátotta, ha:</p>
<ol>
    <li>az adatkezelés az érintett hozzájárulásán, vagy olyan szerződésen alapul, amelyben az érintett az egyik fél,
        vagy az adatkezelés a szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez
        szükséges; és</li>
    <li>az adatkezelés automatizált módon történik.</li>
</ol>
<p>Az érintett adathordozhatósági joga kiterjed arra, hogy az érintett - ha ez technikailag megvalósítható - kérje azt,
    hogy a Szolgáltató a személyes adatokat egy másik adatkezelőnek közvetlenül továbbítása.</p>
<p>7.9. Mit jelent a személyes adatok kezelése elleni tiltakozáshoz való jog?</p>
<p>Az érintett jogosult arra, hogy bármikor tiltakozzon személyes adatainak kezelése ellen - ideértve a profilalkotást
    is – amennyiben</p>
<ol>
    <li>a személyes adatok kezelése közérdekű vagy a Szolgáltatóra ruházott közhatalmi jogosítvány gyakorlásának
        keretében végzett feladat végrehajtásához szükséges; vagy</li>
    <li>az adatkezelés a Szolgáltató vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükséges, kivéve, ha ezen
        érdekekkel szemben elsőbbséget élveznek az érintett olyan érdekei vagy alapvető jogai és szabadságai, amelyek
        személyes adatok védelmét teszik szükségessé; vagy</li>
    <li>ha a személyes adatok kezelése vagy továbbítása kizárólag a Szolgáltatóra vonatkozó jogi kötelezettség
        teljesítéséhez szükséges, kivéve kötelező adatkezelés esetén; vagy</li>
    <li>ha a személyes adatok kezelése közvetlen üzletszerzés, közvélemény-kutatás vagy tudományos kutatás érdekében
        történik; ez esetben az érintett jogosult arra, hogy bármikor tiltakozzon a rá vonatkozó személyes adatok jelen
        alpont szerinti célból történő kezelése ellen, ideértve a profilalkotást is, amennyiben az a közvetlen
        üzletszerzéshez kapcsolódik.</li>
</ol>
<p>Amennyiben az érintett tiltakozását elfogadja az Szolgáltató, akkor az adatkezelést - beleértve a további
    adatfelvételt és adattovábbítást is - megszünteti, és az adatokat zárolja, valamint a tiltakozásról, továbbá az
    annak alapján tett intézkedésekről értesíti mindazokat, akik részére a tiltakozással érintett személyes adatot
    korábban továbbította, és akik kötelesek intézkedni a tiltakozási jog érvényesítése érdekében.</p>
<p>7.10. Miként jár el a Szolgáltató az érintett fenti jogainak gyakorlása esetén?</p>
<p>A Szolgáltató indokolatlan késedelem nélkül, de legkésőbb a megkeresés, kérelem beérkezésétől számított 1 (egy)
    hónapon belül tájékoztatja az érintettet a kérelme alapján hozott intézkedéséről. A kérelem összetettségétől, a
    beérkezett kérelmek számától függően a Szolgáltató ezt a határidőt legfeljebb 2 (kettő) hónappal meghosszabbíthatja,
    amelyről az eredeti határidőn belül a késedelem okainak megjelölése mellett értesíti az érintettet. Elektronikus
    úton benyújtott kérelem esetén – az érintett eltérő kérésének a hiányában – a Szolgáltató elektronikus úton adja meg
    a tájékoztatást.</p>
<p>Amennyiben a Szolgáltató a fenti kötelezettségének nem tesz eleget határidőben, akkor az érintett a jelen
    Tájékoztatóban ismertetésre kerülő jogorvoslati lehetőségeket veheti igénybe.</p>
<p>A Szolgáltató a tájékoztatást díjmentesen biztosítja az érintett számára.</p>
<h2>VIII. A 16. életévét be nem töltött érintettekkel kapcsolatos szabályok</h2>
<p>8.1. A 16. életévüket be nem töltött személyek kizárólag a szülői felügyeletet gyakorló személy által adott írásbeli
    engedéllyel adhatják meg a személyes adataikat.</p>
<p>Ez azt jelenti, hogy a 16. életévét be nem töltött személy a személyes adatainak rendelkezésre bocsátására önállóan
    nem jogosult, és köteles ahhoz a törvényes képviselője hozzájárulását beszerezni. Ilyen hozzájárulás hiányában a 16.
    életévét be nem töltött személy személyes adata kizárólag a hozzájárulástól, mint jogalaptól eltérő másik jogalap
    alapján kezelhető.</p>
<p>Abban az esetben, ha a 16. életévét be nem töltött személy a Szolgáltató szolgáltatásának igénybevételekor nem kerül
    személyes kapcsolatba a Szolgáltatóval, akkor az érintett köteles a jelen pontnak való megfelelést biztosítani,
    amelynek elmulasztásával kapcsolatosan a Szolgáltatót nem terheli felelősség. A személyes adatok megadását a
    Szolgáltató úgy tekinti, hogy az érintett kijelentette, hogy a személyes adatok rendelkezésre bocsátásával
    kapcsolatosan az érintett nem esik korlátozás alá.</p>
<p>A Szolgáltató azonban fenntartja azon jogát, hogy ellenőrizze az adatkezelés jogszerűségét, és az adatkezelés
    jogalapjának a meglétét, beleértve a szülői felügyeletet gyakorló személy hozzájárulásának a meglétét is.</p>
<p>8.2. A Szolgáltató természetesen minden szükséges intézkedést megtesz annak érdekében, hogy a 16. életévüket be nem
    töltött személyeknek a Szolgáltató részére jogosulatlanul átadott, rendelkezésre bocsátott személyes adatait
    törölje, és gondoskodik arról, hogy azok se továbbításra, se feldolgozásra nem kerülhessenek.</p>
<h2>IX. Profilalkotás</h2>
<p>9.1. Mit jelent a profilalkotás?</p>
<p>Profilalkotásnak minősül a személyes adatok automatizált kezelésének bármely olyan formája, amelynek során a
    személyes adatokat a felhasználóhoz fűződő bizonyos személyes jellemzők értékelésére (különösen a munkahelyi
    teljesítményhez, gazdasági helyzethez, egészségi állapothoz, személyes preferenciákhoz, érdeklődéshez,
    megbízhatósághoz, viselkedéshez, tartózkodási helyhez vagy mozgáshoz kapcsolódó jellemzők) elemzésére, vagy
    előrejelzésére használják.</p>
<p>A Szolgáltató azon célból, hogy a Felhasználók számára a Felhasználók igényeit, érdeklődési körét a lehető
    legteljesebb mértékben kiszolgáló ajánlatokat tehessen a Felhasználóknak, hozzájáruláson alapuló profilalkotást
    végez.</p>
<p>A profilalkotás alapján kialakított ajánlatokat a Felhasználók részére a Szolgáltató e-mailben küldött hírlevélben,
    vagy a Weboldalon keresztül juttatja el.</p>
<h2>X. Az adatfeldolgozás</h2>
<p>10.1. A Szolgáltató az általa végzett adatkezeléshez a jelen Tájékoztatóban megjelölt adatfeldolgozókat veszi
    igénybe. A Szolgáltató által igénybevett adatfeldolgozók a személyes adatokon az érintett hozzájárulása nélkül is
    végezhetnek technikai műveleteket, azok során azonban nem hozhatnak önállóan érdemi döntést, és kizárólag a
    Szolgáltatóval kötött szerződés, illetve a Szolgáltató utasításai szerint járhatnak el.</p>
<p>10.2. A Szolgáltató kizárólag olyan adatfeldolgozókat vesz igénybe, akik az adatkezelés során a GDPR és a vonatkozó
    jogszabályok szerint járnak el, és megfelelnek az azokban rögzített követelményeknek.</p>
<p>10.3. A Szolgáltató által igénybevett adatfeldolgozók az Szolgáltató előzetes írásbeli, eseti, vagy általános
    felhatalmazása nélkül további adatfeldolgozó(ka)t nem vehetnek igénybe.</p>
<p>10.4. A Szolgáltató ellenőrzi az adatfeldolgozó(k) által végzett adatkezelést, e körben az adatfeldolgozók
    tevékenységet.</p>
    <p>Az adatfeldolgozó adatai - Az adatfeldolgozó által hozzáférhető adatok köre, a felhasználásuk módja (az adatfeldolgozó által végzett tevékenység)</p>
<ol>
    <li>Barber Cloud LTD. - profilalkotás, marketing hírlevelek kiküldése, elemzések</li>
</ol>
<h2>XI. Az adattovábbítás</h2>
<p>11.1. A Szolgáltató a Felhasználó következő adatait továbbíthatja a jelen pont szerint: vezetéknév, keresztnév,
    lakcím, telefonszám, e-mail cím.</p>
<p>Az adattovábbítás célja: Felhasználók részére történő ügyfélszolgálati segítségnyújtás, a tranzakciók visszaigazolása
    és a Felhasználók védelme érdekében a visszaélésgyanús tranzakciók kiszűrése érdekében végzett ellenőrzés.</p>
<p>11.2. A Szolgáltató a Felhasználó által megadott adatok közül az alábbiakat továbbítja: vezetéknév, keresztnév,
    telefonszám, e-mail cím. A Szolgáltató ezeket az adatokat harmadik személynek, mint adatkezelőnek továbbítja:</p>
<p>Barber Cloud LTD.&nbsp;</p>
<p>11.3. A Szolgáltató jogosult és köteles a rendelkezésére álló, és jogszerűen kezelt adatokat az illetékes hatóságok,
    bíróságok felé továbbítani, amennyiben arra a GDPR, a vonatkozó jogszabály, illetve jogerős és végrehajtható
    határozat kötelezi a Szolgáltatót. A jelen pont szerinti kötelező adattovábbításból eredő következmények miatt a
    Szolgáltatót nem terheli felelősség.</p>
<p>11.4 A Szolgáltató köteles nyilvántartást vezetni az adattovábbításról, amely kiterjed a GDPR-ban, és a vonatkozó
    jogszabályokban megjelölt információkra, amelyek különösen az alábbiak:</p>
<ol>
    <li>az érintettnek és az adatigénylőnek az azonosító adatai;</li>
    <li>az adattovábbítás célja, és annak jogalapja,</li>
    <li>a továbbításra került adatfajták megjelölése, és</li>
    <li>az adattovábbítás időpontja.</li>
</ol>
<p>11.5 A nyilvántartásba a saját adatai tekintetében betekinthet, és arról adatokat igényelhet az érintett, ha azt
    törvény nem zárja ki.</p>
<p>Adattovábbítás címzettje A továbbított adatok jellege, hozzáférhető adatok köre, a felhasználásuk módja (az
    adatfeldolgozó által végzett tevékenység)</p>
<h2>XII. A személyes adatok megismerésére jogosultak</h2>
<p>12.1. Kik férhetnek hozzá a Szolgáltató által kezelt személyes adatokhoz?</p>
<p>A Szolgáltató által kezelt adatokhoz kizárólag a feladatuk ellátása érdekében a Szolgáltatónak az arra kijelölt, és
    megfelelő jogosultsággal rendelkező munkatársai, valamint a jelen Tájékoztatóban megjelölt adatfeldolgozók, illetve
    az adattovábbítás címzettjei férhetnek hozzá.</p>
<h2>XIII. Adatvédelem, adatbiztonság</h2>
<p>13.1. Miként gondoskodik a Szolgáltató a személyes adatok védelméről?</p>
<p>A Szolgáltató a tudomány és technológia állása és a megvalósítás költségei, továbbá az adatkezelés jellege, hatóköre,
    körülményei és céljai, valamint a természetes személyek jogaira és szabadságaira jelentett, változó valószínűségű és
    súlyosságú kockázat figyelembevételével megfelelő technikai és szervezési intézkedéseket hajt végre annak érdekében,
    hogy a kockázat mértékének megfelelő szintű adatbiztonságot garantálja.</p>
<p>A Szolgáltató ezen technikai és szervezési intézkedéseinek a célja, hogy a Szolgáltató által kezelt személyes adatok
    védettek legyenek, és a Szolgáltató megakadályozza azok véletlen elvesztését, jogtalan megsemmisítését, az azokhoz
    való jogosulatlan hozzáférést, azok jogosulatlan felhasználását, megváltoztatását, illetve terjesztését.</p>
<p>A Szolgáltató a fenti célok megvalósítása érdekében a személyes adatok kezeléséhez használt számítógépeken,
    szervereken jelszavas védelmet alkalmaz.</p>
<p>A Szolgáltató továbbá mindazon harmadik személyt felhívja az adatbiztonsági követelmények betartására, illetve
    betartatására, akik részére a Szolgáltató által kezelt személyes adatok továbbításra kerülnek. A Szolgáltató
    emellett az adatkezelési tevékenységében résztvevő munkatársai, valamint az adatfeldolgozók részére is
    kötelezettségként írja elő az adatvédelmi-, adatbiztonsági követelmények teljesítését.</p>
<h2>XIV. Adatvédelmi incidens</h2>
<p>14.1. Mi minősül adatvédelmi incidensnek?</p>
<p>A biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy
    jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan
    hozzáférést eredményezi.</p>
<p>14.2. Mikor köteles a Szolgáltató az érintettet értesíteni az adatvédelmi incidensről?</p>
<p>Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár a természetes személyek jogaira és szabadságaira
    nézve, a Szolgáltató indokolatlan késedelem nélkül tájékoztatja az érintettet az adatvédelmi incidensről. Az
    érintett részére a tájékoztatásban világosan és közérthetően ismertetni kell az adatvédelmi incidens jellegét, és
    közölni kell a legfontosabb információkat és intézkedéseket.</p>
<p>14.3. Milyen esetben nem terheli értesítési kötelezettség a Szolgáltatót?</p>
<p>Az érintettet nem kell tájékoztatni, ha a következő feltételek bármelyike teljesül:</p>
<ol>
    <li>a Szolgáltató megfelelő technikai és szervezési védelmi intézkedéseket hajtott végre, és ezeket az
        intézkedéseket az adatvédelmi incidens által érintett adatok tekintetében alkalmazták, különösen azokat az
        intézkedéseket – mint például a titkosítás alkalmazása –, amelyek a személyes adatokhoz való hozzáférésre fel
        nem jogosított személyek számára értelmezhetetlenné teszik az adatokat;</li>
    <li>a Szolgáltató az adatvédelmi incidenst követően olyan további intézkedéseket tett, amelyek biztosítják, hogy az
        érintett jogaira és szabadságaira jelentett magas kockázat a továbbiakban valószínűsíthetően nem valósul meg;
    </li>
    <li>a tájékoztatás aránytalan erőfeszítést tenne szükségessé. Ilyen esetekben az érintetteket nyilvánosan közzétett
        információk útján kell tájékoztatni, vagy olyan hasonló intézkedést kell hozni, amely biztosítja az érintettek
        hasonlóan hatékony tájékoztatását.</li>
</ol>
<h2>XV. Észrevételek, jogorvoslatok</h2>
<p>15.1. A Szolgáltató az általa végzett adatkezeléssel kapcsolatosan bármilyen kérdéssel, észrevétellel a Szolgáltató
    az alábbi elérhetőségeken kereshető meg.</p>
<p>${shopDetails.companyName}</p>
<p>Postacím: ${shopDetails.postalAddress?.postalCode ||
  shopDetails.address?.postalCode} ${shopDetails.postalAddress?.town ||
  shopDetails.address?.town}, ${shopDetails.postalAddress?.addressLine1 ||
  shopDetails.address?.addressLine1}
  </p>
<p>E-mail: ${shopDetails.contactEmail}</p>
<p>15.2. Az adatkezeléssel kapcsolatos panasz közvetlenül a Nemzeti Adatvédelmi és Információszabadság Hatósághoz
    nyújtható be az alábbi elérhetőségeken:</p>
<p>Nemzeti Adatvédelmi és Információszabadság Hatóság</p>
<p>Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.</p>
<p>Telefon: +36 (1) 391-1400</p>
<p>Honlap: <a href="http://www.naih.hu" rel="noopener noreferrer" target="_blank">www.naih.hu</a></p>
<p>E-mail: <a href="mailto:ugyfelszolgalat@naih.hu" rel="noopener noreferrer"
        target="_blank">ugyfelszolgalat@naih.hu</a></p>
<p>15.3. Az érintett közvetlenül az illetékes bírósághoz is fordulhat a jogai megsértése miatt. Az érintett igényének
    elbírálására – az érintett választásától függően – az érintett lakóhelye vagy tartózkodási helye szerint illetékes
    törvényszék rendelkezik hatáskörrel és illetékességgel.</p>
<p>15.4. Az érintett megkeresésére az Szolgáltató tájékoztatja az érintettet a jogorvoslati lehetőségekről és azok
    eszközeiről.</p>
<h2>XVI. A Tájékoztató módosítása, új Tájékoztató bevezetése</h2>
<p>16.1. A Szolgáltató fenntartja azon jogát, hogy a jelen Tájékoztatót egyoldalúan módosítsa, vagy azt visszavonja, és
    új Tájékoztatót fogadjon, illetve tegyen közzé.</p>
<p>16.2 Az érintett a hozzájáruló nyilatkozat megtételével, illetve a Szolgáltatóval történő szerződéskötéssel elfogadja
    a Tájékoztató mindenkor hatályos rendelkezéseit.</p>
<p>***</p>
<p>${shopDetails.companyName} - ${shopDetails.policyCity}, ${
  shopDetails.policyDate
}</p>
`;
