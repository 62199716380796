import React from 'react';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';

import DashboardLayout from '../../DashboardLayout';
import AdminBookingSettingsForm from './AdminBookingSettingsForm';

const AdminBookingSettingsPage = () => {
  const { formatMessage: f } = useIntl();
  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'bookingSettingsTooltip' })}>
            {f({ id: 'appNavAdminBookingSettings' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <AdminBookingSettingsForm />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(AdminBookingSettingsPage);
