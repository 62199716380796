import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import moment from 'moment';
import { useIntl } from 'react-intl';

import { getTillClosing } from '../../../actions';

import Header from '../../Header';
import BaseHOC from '../../BaseHOC';
import DashboardLayout from '../../DashboardLayout';

import CloseTillNewCloseForm from './CloseTillNewCloseForm';
import TillCloseDetailsTable from '../Shared/TillCloseDetailsTable';
import TillCloseNotesLeftInTillTable from '../Shared/TillCloseNotesLeftInTillTable';
import TillCloseSplitMissingTable from '../Shared/TillCloseSplitMissingTable';

const CloseTillPage = ({ enqueueSnackbar }) => {
  const CLOSE_DATE = moment().format(moment.HTML5_FMT.DATE);
  const { formatMessage: f } = useIntl();

  const dailyTillClose = useSelector((state) => state.dailyTillClose);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dailyTillClose) {
      dispatch(getTillClosing({ closeDate: CLOSE_DATE, enqueueSnackbar }));
    }
  }, [dailyTillClose]);

  return (
    <DashboardLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header subtitle={f({ id: 'tooltipCloseTill' })}>
            {f({ id: 'appNavInputCloseTill' })}
          </Header>
        </Grid>
        <Grid item xs={12}>
          <TillCloseDetailsTable />
        </Grid>
        {dailyTillClose && dailyTillClose.created ? (
          <>
            <Grid item xs={12} md={6}>
              <TillCloseNotesLeftInTillTable />
            </Grid>
            <Grid item xs={12} md={6}>
              <TillCloseSplitMissingTable />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <CloseTillNewCloseForm />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default BaseHOC(CloseTillPage);
