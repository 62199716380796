import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { BarMetric } from 'salad-ui.chart';

import BaseHOC from '../../BaseHOC';

const AdminStatsServices = ({ bookingStats }) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <Box pt={2} display="flex" alignItems="center" flexDirection="column">
        <Box>
          <Typography variant="subtitle1">
            {f({ id: 'appNavAdminShopServices' })}
          </Typography>
        </Box>
        <Box px={4} width="100%" maxWidth={1400}>
          {bookingStats.services.map((serviceData) => (
            <Box pt={2} key={serviceData.label}>
              <BarMetric
                label={serviceData.label || 'n/a'}
                percent={(serviceData.rate || 0) * 100}
                value={String(serviceData.value || 0)}
                metricName=""
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bookingStats: state.bookingStats,
});

export default BaseHOC(AdminStatsServices, null, mapStateToProps);
