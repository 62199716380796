import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { navigate } from 'gatsby';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import { resetTillClosingList } from '../../../actions';

import Table from '../../Table';
import Loader from '../../Loader';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const AdminTillClosingsList = ({ singleDay }) => {
  const { formatMessage: f, locale } = useIntl();
  const dispatch = useDispatch();
  const tillClosings = useSelector((state) => state.tillClosings);
  return (
    (tillClosings && (
      <Table
        title={
          singleDay
            ? f({ id: 'adminTillListDailyTitle' })
            : f({ id: 'adminTillListTitle' })
        }
      >
        <TableHead>
          <TableRow>
            {singleDay ? (
              <TableCell>{f({ id: 'inputDatetime' })}</TableCell>
            ) : null}
            {singleDay ? null : (
              <TableCell>{f({ id: 'adminTillListCloseDay' })}</TableCell>
            )}
            <TableCell>{f({ id: 'createdBy' })}</TableCell>
            <TableCell>{f({ id: 'adminTillAmountTaken' })}</TableCell>
            <TableCell>{f({ id: 'adminTillChangeAmount' })}</TableCell>
            <TableCell>{f({ id: 'columnTitleBalance' })}</TableCell>
            {singleDay ? null : (
              <TableCell>{f({ id: 'adminTillCloseCount' })}</TableCell>
            )}
            {singleDay ? null : <TableCell>{f({ id: 'details' })}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {(tillClosings.results || []).map((row) => (
            <TableRow key={row.id}>
              {singleDay ? (
                <TableCell>
                  <Moment format="YYYY-MM-DD HH:mm">{row.created}</Moment>
                </TableCell>
              ) : null}
              {singleDay ? null : (
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.close_date}</Moment>
                </TableCell>
              )}
              <TableCell>{row.created_by.display_name}</TableCell>
              <TableCell>
                <CurrencyFormat amount={row.cash_out} />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.cash_in_till} />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.balance} />
              </TableCell>
              {singleDay ? null : <TableCell>{row.closings_on_day}</TableCell>}
              {singleDay ? null : (
                <TableCell>
                  <IconButton
                    aria-label={f({ id: 'details' })}
                    onClick={() => {
                      dispatch(resetTillClosingList());
                      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                      navigate(
                        `/${locale}/app/till-closings/${row.close_date}/${window.location.search}`,
                      );
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )) || <Loader />
  );
};

export default BaseHOC(AdminTillClosingsList);
