import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Box,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { navigate } from 'gatsby';

import DashboardLayout from '../../DashboardLayout';
import Table from '../../Table';
import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';

import {
  getProducts,
  getEmployees,
  getProductInventory,
  addMultiProductInventory,
} from '../../../actions';
import { isInt, getUser } from '../../../utils';

const AdminProductInventoryCheckInputPage = ({
  products,
  employees,
  productInventory,
  dispatch,
  enqueueSnackbar,
}) => {
  const [quantityList, setQuantityList] = useState({});
  const [quantityErrorList, setQuantityErrorList] = useState({});
  const [inventorySumList, setInventorySumList] = useState({});
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [filter, setFilter] = useState('');
  const { formatMessage: f, locale } = useIntl();

  useEffect(() => {
    if (products.length === 0) dispatch(getProducts());
    if (employees.length === 0) dispatch(getEmployees());
    dispatch(
      getProductInventory({
        startDate: moment
          .unix(0)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment()
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        enqueueSnackbar,
      }),
    );
  }, []);

  useEffect(() => {
    const updatedList = {};
    productInventory.forEach((x) => {
      updatedList[x.base_id] = x.inventory_sum;
    });
    setInventorySumList(updatedList);
  }, [productInventory]);

  const updateQuantityList = (id, base_id, value) => {
    setQuantityList({
      ...quantityList,
      [id]: value
        ? parseInt(value, 10) - (inventorySumList[base_id] || 0)
        : -(inventorySumList[base_id] || 0),
    });
  };

  const submitData = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    Object.entries(quantityList).forEach(([key, val]) => {
      if (val && !isInt(val)) {
        setQuantityErrorList({ ...quantityErrorList, [key]: true });
        error = true;
      } else {
        setQuantityErrorList({ ...quantityErrorList, [key]: false });
      }
    });

    if (error) {
      return;
    }

    const tmpList = [];
    Object.entries(quantityList).forEach(([productId, quantity]) => {
      if (quantity && parseInt(quantity, 10)) {
        tmpList.push({
          createdBy,
          productId,
          quantity,
          shopId: getUser().shopId,
        });
      }
    });
    setInventoryDataList(tmpList);
    setShowConfirmDialog(true);
  };

  const handleFilterChange = ({ search }) => setFilter(search);

  return (
    <DashboardLayout>
      <Table
        title={f({ id: 'inventoryDoInventory' })}
        onChange={handleFilterChange}
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {f({ id: 'inventoryProductName' })}
            </TableCell>
            <TableCell align="left">
              {f({ id: 'inventoryCurrentQuantity' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            products
              .filter(
                (x) =>
                  !filter ||
                  `${x.title}${x.barcode || ''}`.toLowerCase().indexOf(filter) >
                    -1,
              )
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <Input
                      key={`input-${row.id}`}
                      error={quantityErrorList[row.id]}
                      id="quantity"
                      name="quantity"
                      value={`${(inventorySumList[row.base_id] || 0) +
                        parseInt(quantityList[row.id] || '0', 10)}`}
                      onChange={(event) =>
                        updateQuantityList(
                          row.id,
                          row.base_id,
                          event.target.value,
                        )
                      }
                      type="number"
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Box m={1}>
        <FormControl required error={createdByError}>
          <InputLabel htmlFor="createdBy">{f({ id: 'createdBy' })}</InputLabel>
          <Select
            value={createdBy}
            onChange={(event) => setCreatedBy(event.target.value)}
            input={<Input name="createdBy" id="createdBy" />}
          >
            {employees.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box m={1}>
        <PrimaryButton onClick={() => submitData()}>
          {f({ id: 'buttonInput' })}
        </PrimaryButton>
      </Box>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        open={showConfirmDialog}
      >
        <DialogTitle>{f({ id: 'inventoryConfirmInput' })}</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {f({ id: 'inventoryProductName' })}
                </TableCell>
                <TableCell align="center">
                  {f({ id: 'inventoryPreviousQuantity' })}
                </TableCell>
                <TableCell align="center">
                  {f({ id: 'inventoryNewQuantity' })}
                </TableCell>
                <TableCell align="center">
                  {f({ id: 'inventoryChangeQuantity' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryDataList.map((row) => {
                const product =
                  products &&
                  products.length > 0 &&
                  products.find((x) => x.id === parseInt(row.productId, 10));
                return (
                  <TableRow key={row.productId}>
                    <TableCell>{product.title}</TableCell>
                    <TableCell>
                      {inventorySumList[product.base_id] || 0}
                    </TableCell>
                    <TableCell>
                      {(inventorySumList[product.base_id] || 0) + row.quantity}
                    </TableCell>
                    <TableCell
                      style={{ color: row.quantity > 0 ? 'green' : 'red' }}
                    >
                      {row.quantity > 0 ? '+' : null}
                      {row.quantity}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            withmargin="true"
            onClick={() => {
              dispatch(
                addMultiProductInventory(inventoryDataList, enqueueSnackbar, f),
              ).then(() => {
                navigate(`/${locale}/app/product-inventory`);
              });
            }}
          >
            {f({ id: 'buttonInput' })}
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              setShowConfirmDialog(false);
            }}
            color="primary"
          >
            {f({ id: 'buttonCancel' })}
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  employees: state.employees,
  productInventory: state.productInventory,
});

export default BaseHOC(
  AdminProductInventoryCheckInputPage,
  null,
  mapStateToProps,
);
