import React, { useState } from 'react';

import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  TextField,
  Box,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import StarRating from '../../StarRating';

import { approveReview, respondReview } from '../../../actions';

const ReviewDetailsDialog = ({
  open,
  booking,
  onApprove,
  onDeny,
  onCancel,
  dispatch,
  enqueueSnackbar,
}) => {
  const [respondOpen, setRespondOpen] = useState(false);
  const [respondConfirmOpen, setRespondConfirmOpen] = useState(false);
  const [response, setResponse] = useState('');
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const { formatMessage: f } = useIntl();

  return (
    booking && (
      <>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
          <DialogTitle>{f({ id: 'reviewDetails' })}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">{`${f({
                id: 'reviewClientDetails',
              })}:`}</Typography>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="name"
                  name="name"
                  label={f({ id: 'name' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_name}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="email"
                  name="email"
                  label={f({ id: 'clientsClientEmail' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_email}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="email"
                  name="email"
                  label={f({ id: 'bookingSettingsPhoneNumber' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_mobile}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="bookingCount"
                  name="bookingCount"
                  label={f({ id: 'reviewAllBookingCount' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_booking_count}
                />
              </FormControl>
              <Box mt={2}>
                <Typography variant="h6">{`${f({
                  id: 'reviewBookingDetails',
                })}:`}</Typography>
              </Box>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="barber"
                  name="barber"
                  label={f({ id: 'reviewToWho' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.employee.display_name}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="start"
                  name="start"
                  label={f({ id: 'reviewToWhen' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(booking.start).format('YYYY-MM-DD HH:mm')}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="service"
                  name="service"
                  label={f({ id: 'reviewToWhatService' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.service.title}
                />
              </FormControl>
              <Box mt={2}>
                <Typography variant="h6">{`${f({
                  id: 'reviewReviewDetails',
                })}:`}</Typography>
              </Box>
              <Box>
                <StarRating rating={booking.review_stars} />
              </Box>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  multiline
                  margin="dense"
                  id="feedback"
                  name="feedback"
                  label={f({ id: 'review' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.review_feedback}
                />
              </FormControl>
              <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                  withmargin="true"
                  onClick={() => {
                    setRespondOpen(true);
                  }}
                  disabled={!booking.customer_email}
                >
                  {f({ id: 'reviewSendResponse' })}
                </PrimaryButton>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={() => {
                onCancel();
              }}
            >
              {f({ id: 'buttonCancel' })}
            </SecondaryButton>
            <PrimaryButton
              withmargin="true"
              onClick={() => {
                dispatch(
                  approveReview({
                    bookingId: booking.id,
                    approve: true,
                    enqueueSnackbar,
                  }),
                ).then(() => onApprove());
              }}
              disabled={booking.review_processed && booking.review_approved}
            >
              {f({ id: 'reviewAccept' })}
            </PrimaryButton>
            <PrimaryButton
              danger
              withmargin="true"
              onClick={() => {
                dispatch(
                  approveReview({
                    bookingId: booking.id,
                    approve: false,
                    enqueueSnackbar,
                  }),
                ).then(() => onDeny());
              }}
              disabled={booking.review_processed && !booking.review_approved}
            >
              {f({ id: 'reviewDeny' })}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <Dialog disableBackdropClick disableEscapeKeyDown open={respondOpen}>
          <DialogTitle>{f({ id: 'reviewSendResponse' })}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  multiline
                  margin="dense"
                  id="response"
                  name="response"
                  label={f({ id: 'response' })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={4}
                  value={response}
                  onChange={(e) => setResponse(e.target.value)}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={() => {
                setRespondOpen(false);
                setResponse('');
              }}
            >
              {f({ id: 'buttonCancel' })}
            </SecondaryButton>
            <PrimaryButton
              withmargin="true"
              onClick={() => {
                setRespondConfirmOpen(true);
              }}
              disabled={!response}
            >
              {f({ id: 'send' })}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={respondConfirmOpen}
        >
          <DialogTitle>{f({ id: 'reviewSendResponseConfirm' })}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box mt={2}>
                <Typography variant="h6">
                  {`${f({ id: 'reviewSendResponsePreview' })}:`}
                </Typography>
              </Box>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="name"
                  name="name"
                  label={f({ id: 'name' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_name}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  // disabled
                  margin="dense"
                  id="email"
                  name="email"
                  label={f({ id: 'clientsClientEmail' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={booking.customer_email}
                />
              </FormControl>
              <FormControl style={{ minWidth: '260px' }}>
                <TextField
                  multiline
                  margin="dense"
                  id="feedbackConfirm"
                  name="feedbackConfirm"
                  label={f({ id: 'reviewSendResponseConfirmMessage' })}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={response}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              disabled={sendButtonDisabled}
              onClick={() => {
                setRespondConfirmOpen(false);
              }}
            >
              {f({ id: 'buttonCancel' })}
            </SecondaryButton>
            <PrimaryButton
              withmargin="true"
              disabled={sendButtonDisabled}
              onClick={() => {
                setSendButtonDisabled(true);
                dispatch(
                  respondReview({
                    bookingId: booking.id,
                    response,
                    enqueueSnackbar,
                  }),
                )
                  .then(() => {
                    setRespondConfirmOpen(false);
                    setRespondOpen(false);
                    setResponse('');
                    setSendButtonDisabled(false);
                    onCancel();
                  })
                  .catch(() => {
                    setSendButtonDisabled(false);
                  });
              }}
            >
              {f({ id: 'send' })}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default BaseHOC(ReviewDetailsDialog);
