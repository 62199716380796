import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const ReportsPerformanceServiceSalesPerformance = ({ onChange }) => {
  const serviceSalesPerformance = useSelector(
    (state) => state.serviceSalesPerformance,
  );
  const getDiscount = (row) => {
    if (parseInt(row.discount_amount, 10)) {
      return <CurrencyFormat amount={row.discount_amount} />;
    }
    if (parseFloat(row.discount_rate)) return `${row.discount_rate}%`;
    return '-';
  };
  const { formatMessage: f } = useIntl();

  return (
    <Table
      title={f({ id: 'performanceServiceSaleTitle' })}
      onChange={onChange}
      count={serviceSalesPerformance && serviceSalesPerformance.count}
    >
      <TableHead>
        <TableRow>
          <TableCell align="center">{f({ id: 'createdBy' })}</TableCell>
          <TableCell align="center">{f({ id: 'kindService' })}</TableCell>
          <TableCell align="center">{f({ id: 'quantity' })}</TableCell>
          <TableCell align="center">{f({ id: 'discount' })}</TableCell>
          <TableCell align="center">
            {f({ id: 'columnTitleUnitPrice' })}
          </TableCell>
          <TableCell align="center">{f({ id: 'total' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {serviceSalesPerformance &&
          serviceSalesPerformance.results &&
          serviceSalesPerformance.results.map((row) => (
            <TableRow
              key={`sales-perf-${row.name}-${row.title}-${
                row.count
              }-${getDiscount(row)}-${row.sum}`}
            >
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.count}</TableCell>
              <TableCell>{getDiscount(row)}</TableCell>
              <TableCell>
                <CurrencyFormat amount={row.price} />
              </TableCell>
              <TableCell>
                <CurrencyFormat amount={row.sum} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default BaseHOC(ReportsPerformanceServiceSalesPerformance);
