import React, { useEffect, useState } from 'react';

import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import {
  listSalaryAssignments,
  getSalaryRule,
  resetSalaryRule,
  unAssignSalaryRule,
} from '../../../actions';
import { getUser } from '../../../utils';

import Loader from '../../Loader';
import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

import FixSalaryRuleDialog from '../Shared/FixSalaryRuleDialog';
import PerformanceBasedSalaryRuleDialog from '../Shared/PerformanceBasedSalaryRuleDialog';
import TieredSalaryRuleDialog from '../Shared/TieredSalaryRuleDialog';
import TieredProgressiveSalaryRuleDialog from '../Shared/TieredProgressiveSalaryRuleDialog';
import UnassignConfirmationDialog from '../Shared/UnassignConfirmationDialog';
import DeductCardPaymentFromSalaryRuleDialog from '../Shared/DeductCardPaymentFromSalaryRuleDialog';

// Add link to show details of the assigned rule (... in the last column). This should show the
// modal used to create the specific rule type. Also the dialog should have a de-assign button
// to remove assignment

const SalaryCalculationAssignmentList = ({
  listSalaryAssignments,
  enqueueSnackbar,
  salaryAssignments,
  getSalaryRule,
  resetSalaryRule,
  salaryRule,
  unAssignSalaryRule,
}) => {
  const { shopMap } = getUser();
  const [fixSalaryRuleDialogVisible, setFixSalaryRuleDialogVisible] = useState(
    false,
  );
  const [
    performanceBasedSalaryRuleDialogVisible,
    setPerformanceBasedSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredSalaryRuleDialogVisible,
    setTieredSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredProgressiveSalaryRuleDialog,
    setTieredProgressiveSalaryRuleDialog,
  ] = useState(false);
  const [
    unassignConfirmationDialogVisible,
    setUnassignConfirmationDialogVisible,
  ] = useState(false);
  const [
    deductCardPaymentFromSalaryRuleDialog,
    setDeductCardPaymentFromSalaryRuleDialog,
  ] = useState(false);
  const [unassignParams, setUnassignParams] = useState(null);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    listSalaryAssignments({ enqueueSnackbar });
  }, []);

  if (salaryRule && salaryRule !== {}) {
    if (
      salaryRule.rule_class === 'fixsalaryrule' &&
      !fixSalaryRuleDialogVisible
    ) {
      setFixSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'performancebasedsalaryrule' &&
      !performanceBasedSalaryRuleDialogVisible
    ) {
      setPerformanceBasedSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredsalaryrule' &&
      !tieredSalaryRuleDialogVisible
    ) {
      setTieredSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
      !tieredProgressiveSalaryRuleDialog
    ) {
      setTieredProgressiveSalaryRuleDialog(true);
    } else if (
      salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
      !deductCardPaymentFromSalaryRuleDialog
    ) {
      setDeductCardPaymentFromSalaryRuleDialog(true);
    }
  }

  return (
    <>
      <Table title={f({ id: 'appNavSalaryAssignments' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'createdBy' })}</TableCell>
            <TableCell>{f({ id: 'salaryAssignmentsAssignedRules' })}</TableCell>
            {shopMap.length > 1 ? (
              <TableCell>{f({ id: 'shop' })}</TableCell>
            ) : null}
            <TableCell>{f({ id: 'details' })}</TableCell>
            <TableCell>{f({ id: 'buttonDelete' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salaryAssignments.results &&
            salaryAssignments.results.map((employee) =>
              employee.assigned_salary_rules.map((assignment) => (
                <TableRow key={assignment.id}>
                  {employee.assigned_salary_rules.indexOf(assignment) === 0 ? (
                    <TableCell rowSpan={employee.assigned_salary_rules.length}>
                      {employee.display_name}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    {assignment.salary_calculation_rule.name}
                  </TableCell>
                  {shopMap.length > 1 ? (
                    <TableCell>
                      {assignment.shops
                        .map(
                          (shopId) => shopMap.find((x) => x.id === shopId).name,
                        )
                        .join(', ')}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() =>
                        getSalaryRule(
                          assignment.salary_calculation_rule.id,
                          enqueueSnackbar,
                        )
                      }
                    >
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {/* TODO: Add confirmation dialog */}
                    <IconButton
                      aria-label={f({ id: 'details' })}
                      onClick={() => {
                        setUnassignParams({
                          ruleId: assignment.salary_calculation_rule.id,
                          employeeId: employee.id,
                        });
                        setUnassignConfirmationDialogVisible(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )),
            )}
        </TableBody>
      </Table>
      {salaryAssignments && _.isEmpty(salaryAssignments) ? <Loader /> : null}
      <FixSalaryRuleDialog
        onCancel={() => {
          setFixSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setFixSalaryRuleDialogVisible(false);
        }}
        dialogOpen={fixSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'fixsalaryrule' &&
          salaryRule.params
        }
      />
      <PerformanceBasedSalaryRuleDialog
        onCancel={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
        }}
        dialogOpen={performanceBasedSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'performancebasedsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredSalaryRuleDialog
        onCancel={() => {
          setTieredSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setTieredSalaryRuleDialogVisible(false);
        }}
        dialogOpen={tieredSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredProgressiveSalaryRuleDialog
        onCancel={() => {
          setTieredProgressiveSalaryRuleDialog(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setTieredProgressiveSalaryRuleDialog(false);
        }}
        dialogOpen={tieredProgressiveSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
          salaryRule.params
        }
      />
      <DeductCardPaymentFromSalaryRuleDialog
        onCancel={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
        }}
        dialogOpen={deductCardPaymentFromSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
          salaryRule.params
        }
      />
      <UnassignConfirmationDialog
        onCancel={() => {
          setUnassignParams(null);
          setUnassignConfirmationDialogVisible(false);
        }}
        onSubmit={() => {
          unAssignSalaryRule(
            unassignParams.ruleId,
            unassignParams.employeeId,
            undefined,
            enqueueSnackbar,
            f,
          );
          setUnassignConfirmationDialogVisible(false);
        }}
        dialogOpen={unassignConfirmationDialogVisible}
        title={f({ id: 'buttonConfirm' })}
        message={f({ id: 'confirmDeleteConfirmSalaryRule' })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  salaryAssignments: state.salaryAssignments,
  salaryRule: state.salaryRule,
});

const mapDispatchToProps = (dispatch) => ({
  listSalaryAssignments: (...args) => dispatch(listSalaryAssignments(...args)),
  getSalaryRule: (...args) => dispatch(getSalaryRule(...args)),
  resetSalaryRule: (...args) => dispatch(resetSalaryRule(...args)),
  unAssignSalaryRule: (...args) => dispatch(unAssignSalaryRule(...args)),
});

export default BaseHOC(
  SalaryCalculationAssignmentList,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
