import React, { useState, useEffect } from 'react';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Box,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { navigate } from 'gatsby';

import DashboardLayout from '../../DashboardLayout';
import Table from '../../Table';
import BaseHOC from '../../BaseHOC';
import PrimaryButton from '../../PrimaryButton';
import FormControl from '../../FormControl';

import {
  getProducts,
  getEmployees,
  addMultiProductInventory,
} from '../../../actions';
import { isPositiveInt, getUser } from '../../../utils';

const AdminProductInventoryMultiInputPage = ({
  products,
  employees,
  dispatch,
  enqueueSnackbar,
}) => {
  const [quantityList, setQuantityList] = useState({});
  const [quantityErrorList, setQuantityErrorList] = useState({});
  const [createdBy, setCreatedBy] = useState('');
  const [createdByError, setCreatedByError] = useState(false);
  const [filter, setFilter] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const { formatMessage: f, locale } = useIntl();

  useEffect(() => {
    if (products.length === 0) dispatch(getProducts());
    if (employees.length === 0) dispatch(getEmployees());
  }, []);

  const updateQuantityList = (id, value) => {
    setQuantityList({ ...quantityList, [id]: value });
  };

  const submitData = () => {
    let error = false;

    if (createdBy === '') {
      setCreatedByError(true);
      error = true;
    } else {
      setCreatedByError(false);
    }

    Object.entries(quantityList).forEach(([key, val]) => {
      if (val && !isPositiveInt(val)) {
        setQuantityErrorList({ ...quantityErrorList, [key]: true });
        error = true;
      } else {
        setQuantityErrorList({ ...quantityErrorList, [key]: false });
      }
    });

    if (error) {
      setSubmitEnabled(true);
      return;
    }
    setSubmitEnabled(false);

    const inventoryDataList = [];
    Object.entries(quantityList).forEach(([productId, quantity]) => {
      if (quantity && parseInt(quantity, 10)) {
        inventoryDataList.push({
          createdBy,
          productId,
          quantity,
          shopId: getUser().shopId,
        });
      }
    });

    dispatch(
      addMultiProductInventory(inventoryDataList, enqueueSnackbar, f),
    ).then(() => {
      navigate(`/${locale}/app/product-inventory`);
    });
  };

  const handleFilterChange = ({ search }) => setFilter(search);

  return (
    <DashboardLayout>
      <Table
        title={f({ id: 'inventoryAddProducts' })}
        onChange={handleFilterChange}
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {f({ id: 'inventoryProductName' })}
            </TableCell>
            <TableCell align="left">
              {f({ id: 'inventoryInputQuantityToAdd' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            products
              .filter(
                (x) =>
                  !filter ||
                  `${x.title}${x.barcode || ''}`.toLowerCase().indexOf(filter) >
                    -1,
              )
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <Input
                      key={`input-${row.id}`}
                      error={quantityErrorList[row.id]}
                      id="quantity"
                      name="quantity"
                      value={quantityList[row.id] || ''}
                      onChange={(event) =>
                        updateQuantityList(row.id, event.target.value)
                      }
                      type="number"
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Box m={1}>
        <FormControl required error={createdByError}>
          <InputLabel htmlFor="createdBy">{f({ id: 'createdBy' })}</InputLabel>
          <Select
            value={createdBy}
            onChange={(event) => setCreatedBy(event.target.value)}
            input={<Input name="createdBy" id="createdBy" />}
          >
            {employees.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box m={1}>
        <PrimaryButton disabled={!submitEnabled} onClick={() => submitData()}>
          {f({ id: 'buttonInput' })}
        </PrimaryButton>
      </Box>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  employees: state.employees,
});

export default BaseHOC(
  AdminProductInventoryMultiInputPage,
  null,
  mapStateToProps,
);
