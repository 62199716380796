import React, { useEffect, useState } from 'react';

import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

import * as _ from 'lodash';

import {
  listSalaryRules,
  getSalaryRule,
  resetSalaryRule,
} from '../../../actions';
import { getUser } from '../../../utils';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

import FixSalaryRuleDialog from '../Shared/FixSalaryRuleDialog';
import PerformanceBasedSalaryRuleDialog from '../Shared/PerformanceBasedSalaryRuleDialog';
import TieredSalaryRuleDialog from '../Shared/TieredSalaryRuleDialog';
import TieredProgressiveSalaryRuleDialog from '../Shared/TieredProgressiveSalaryRuleDialog';
import DeductCardPaymentFromSalaryRuleDialog from '../Shared/DeductCardPaymentFromSalaryRuleDialog';

// Add link to show details of the assigned rule (... in the last column). This should show the
// modal used to create the specific rule type. Also the dialog should have a de-assign button
// to remove assignment

const SalaryCalculationRulesList = ({
  listSalaryRules,
  enqueueSnackbar,
  salaryRules,
  getSalaryRule,
  resetSalaryRule,
  salaryRule,
}) => {
  const { shopMap } = getUser();
  const [fixSalaryRuleDialogVisible, setFixSalaryRuleDialogVisible] = useState(
    false,
  );
  const [
    performanceBasedSalaryRuleDialogVisible,
    setPerformanceBasedSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredSalaryRuleDialogVisible,
    setTieredSalaryRuleDialogVisible,
  ] = useState(false);
  const [
    tieredProgressiveSalaryRuleDialog,
    setTieredProgressiveSalaryRuleDialog,
  ] = useState(false);
  const [
    deductCardPaymentFromSalaryRuleDialog,
    setDeductCardPaymentFromSalaryRuleDialog,
  ] = useState(false);

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    listSalaryRules({ enqueueSnackbar });
  }, []);

  if (salaryRule && salaryRule !== {}) {
    if (
      salaryRule.rule_class === 'fixsalaryrule' &&
      !fixSalaryRuleDialogVisible
    ) {
      setFixSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'performancebasedsalaryrule' &&
      !performanceBasedSalaryRuleDialogVisible
    ) {
      setPerformanceBasedSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredsalaryrule' &&
      !tieredSalaryRuleDialogVisible
    ) {
      setTieredSalaryRuleDialogVisible(true);
    } else if (
      salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
      !tieredProgressiveSalaryRuleDialog
    ) {
      setTieredProgressiveSalaryRuleDialog(true);
    } else if (
      salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
      !deductCardPaymentFromSalaryRuleDialog
    ) {
      setDeductCardPaymentFromSalaryRuleDialog(true);
    }
  }

  return (
    <>
      <Table title={f({ id: 'salaryRules' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'salaryName' })}</TableCell>
            <TableCell>{f({ id: 'salaryType' })}</TableCell>
            {shopMap.length > 1 ? (
              <TableCell>{f({ id: 'shop' })}</TableCell>
            ) : null}
            <TableCell>{f({ id: 'details' })}</TableCell>
          </TableRow>
        </TableHead>
        {_.isEmpty(salaryRules) ? null : (
          <TableBody>
            {salaryRules.results.map((rule) => (
              <TableRow key={rule.id}>
                <TableCell>{rule.name}</TableCell>
                <TableCell>{f({ id: rule.rule_class })}</TableCell>
                {shopMap.length > 1 ? (
                  <TableCell>
                    {rule.shops
                      .map(
                        (shopId) => shopMap.find((x) => x.id === shopId).name,
                      )
                      .join(', ')}
                  </TableCell>
                ) : null}
                <TableCell>
                  <IconButton
                    aria-label={f({ id: 'details' })}
                    onClick={() => getSalaryRule(rule.id, enqueueSnackbar)}
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <FixSalaryRuleDialog
        onCancel={() => {
          setFixSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setFixSalaryRuleDialogVisible(false);
        }}
        dialogOpen={fixSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'fixsalaryrule' &&
          salaryRule.params
        }
      />
      <PerformanceBasedSalaryRuleDialog
        onCancel={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setPerformanceBasedSalaryRuleDialogVisible(false);
        }}
        dialogOpen={performanceBasedSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'performancebasedsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredSalaryRuleDialog
        onCancel={() => {
          setTieredSalaryRuleDialogVisible(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setTieredSalaryRuleDialogVisible(false);
        }}
        dialogOpen={tieredSalaryRuleDialogVisible}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredsalaryrule' &&
          salaryRule.params
        }
      />
      <TieredProgressiveSalaryRuleDialog
        onCancel={() => {
          setTieredProgressiveSalaryRuleDialog(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setTieredProgressiveSalaryRuleDialog(false);
        }}
        dialogOpen={tieredProgressiveSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'tieredprogressivesalaryrule' &&
          salaryRule.params
        }
      />
      <DeductCardPaymentFromSalaryRuleDialog
        onCancel={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
          resetSalaryRule();
        }}
        onSubmit={() => {
          setDeductCardPaymentFromSalaryRuleDialog(false);
        }}
        dialogOpen={deductCardPaymentFromSalaryRuleDialog}
        params={
          salaryRule &&
          salaryRule.rule_class === 'deductcardpaymentfromsalaryrule' &&
          salaryRule.params
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  salaryRules: state.salaryRules,
  salaryRule: state.salaryRule,
});

const mapDispatchToProps = (dispatch) => ({
  listSalaryRules: (...args) => dispatch(listSalaryRules(...args)),
  getSalaryRule: (...args) => dispatch(getSalaryRule(...args)),
  resetSalaryRule: (...args) => dispatch(resetSalaryRule(...args)),
});

export default BaseHOC(
  SalaryCalculationRulesList,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
