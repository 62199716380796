import React from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

const ReportsSummaryUsage = ({ usageSummary, onChange }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Table
      title={f({ id: 'summaryUsageTitle' })}
      onChange={onChange}
      count={usageSummary && usageSummary.count}
    >
      <TableHead>
        <TableRow>
          <TableCell>{f({ id: 'date' })}</TableCell>
          <TableCell>{f({ id: 'inputTime' })}</TableCell>
          <TableCell>{f({ id: 'createdBy' })}</TableCell>
          <TableCell>{f({ id: 'kindProduct' })}</TableCell>
          <TableCell>{f({ id: 'quantity' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usageSummary &&
          usageSummary.results &&
          usageSummary.results.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Moment format="YYYY-MM-DD">{row.created}</Moment>
              </TableCell>
              <TableCell>
                <Moment format="HH:mm:ss">{row.created}</Moment>
              </TableCell>
              <TableCell>{row.created_by.display_name}</TableCell>
              <TableCell>{row.product.title}</TableCell>
              <TableCell>{row.quantity}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  usageSummary: state.usageSummary,
});

export default BaseHOC(ReportsSummaryUsage, null, mapStateToProps, null);
