import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import { Paper } from '@material-ui/core';
import PrimaryButton from '../PrimaryButton';

export const Wrapper = styled(Paper)`
  && {
    max-width: 500px;
    padding: 1rem 0rem;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  && {
    max-width: 100%;
  }
`;

export const SearchButton = styled(PrimaryButton)`
  && {
    width: 100%;
  }
`;
