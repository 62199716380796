import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Box,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { useIntl } from 'react-intl';
import { getUser } from '../utils';
import { saveSettings, getOnboarding, getSettings } from '../actions';

import { Capitalized } from './Pages/AdminBookingSettings/styles';

const OnboardingBarberDialog = ({ open, onClose }) => {
  const shopId = getUser().shopMap[0].id;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { formatMessage: f, locale } = useIntl();
  const settings = useSelector((state) => state.settings);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const emptyOpeningHours = {
    mon: { open: null, close: null },
    tue: { open: null, close: null },
    wed: { open: null, close: null },
    thu: { open: null, close: null },
    fri: { open: null, close: null },
    sat: { open: null, close: null },
    sun: { open: null, close: null },
  };
  const emptyOpeningDays = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };
  const weekdayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const getLocalWeekday = (momentInstance, i) =>
    momentInstance
      .weekdays(true)
      .map((day) => weekdayKeys[momentInstance.weekdays().indexOf(day)])[i];

  const [openingHours, setOpeningHours] = useState({ ...emptyOpeningHours });
  const [openingDays, setOpeningDays] = useState({ ...emptyOpeningDays });

  useEffect(() => {
    moment.locale(locale);
    moment.updateLocale(locale, {
      week: {
        dow: 1, // First day of week is Monday
      },
    });
    getSettings({ shopId, enqueueSnackbar });
  }, []);

  useEffect(() => {
    if (settings) {
      const openingHoursValue = settings.opening_hours || {
        ...emptyOpeningHours,
      };
      setOpeningHours(openingHoursValue);
      setOpeningDays(
        Object.fromEntries(
          Object.keys(openingHoursValue).map((key) => {
            return [
              key,
              !!(openingHoursValue[key].open && openingHoursValue[key].close),
            ];
          }),
        ),
      );
    }
  }, [settings]);

  const updateOpeningHours = (momentInstance, dayIndex, openClose, value) => {
    // value hour is the one user selected, ie. user clicked 14:00, value will
    // be 14:00, but the TZ can change according to the user's browser

    const newOpeningHours = { ...openingHours };
    // TODO: Format to only hours
    newOpeningHours[getLocalWeekday(momentInstance, dayIndex)][
      openClose
    ] = value ? value.format('HH:mm') : null;
    setOpeningHours(newOpeningHours);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {f({ id: 'onboardingOpeningHourDialogTitle' })}
      </DialogTitle>
      <DialogContent>
        <FormHelperText margin="dense">
          {f({ id: 'onboardingOpeningHourDialogHelper' })}
        </FormHelperText>
        <Box m={1} maxWidth={400} flexDirection="row" margin="auto">
          <Box>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              libInstance={moment}
              locale={moment.locale(locale)}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                {moment.weekdays(true).map((weekday, i) => (
                  <Box
                    mb={2}
                    key={`weekday-${weekday}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={openingDays[getLocalWeekday(moment, i)]}
                          onChange={(event) => {
                            const openingDaysNew = { ...openingDays };
                            openingDaysNew[getLocalWeekday(moment, i)] =
                              event.target.checked;
                            setOpeningDays(openingDaysNew);
                          }}
                          id={`${weekday}-day-open`}
                          name={`${weekday}-day-open`}
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                    />
                    <Box width={100}>
                      <Capitalized>{weekday}:</Capitalized>
                    </Box>
                    <Box m={1} display="flex" flexDirection="row">
                      <Box m={1} width={100}>
                        <KeyboardTimePicker
                          cancelLabel={f({ id: 'buttonCancel' })}
                          disabled={!openingDays[getLocalWeekday(moment, i)]}
                          clearable="true"
                          autoOk
                          initialFocusedDate={moment(
                            openingHours[getLocalWeekday(moment, i)].open,
                            'HH:mm',
                          )}
                          mask="__:__"
                          ampm={false}
                          minutesStep={15}
                          variant="inline"
                          value={
                            openingHours[getLocalWeekday(moment, i)].open
                              ? moment(
                                  openingHours[getLocalWeekday(moment, i)].open,
                                  'HH:mm',
                                )
                              : null
                          }
                          onChange={(selectedDate) =>
                            updateOpeningHours(moment, i, 'open', selectedDate)
                          }
                        />
                      </Box>
                      <Box m={1} width={100}>
                        <KeyboardTimePicker
                          cancelLabel={f({ id: 'buttonCancel' })}
                          disabled={!openingDays[getLocalWeekday(moment, i)]}
                          clearable="true"
                          autoOk
                          initialFocusedDate={moment(
                            openingHours[getLocalWeekday(moment, i)].close,
                            'HH:mm',
                          )}
                          mask="__:__"
                          ampm={false}
                          minutesStep={15}
                          variant="inline"
                          value={
                            openingHours[getLocalWeekday(moment, i)].close
                              ? moment(
                                  openingHours[getLocalWeekday(moment, i)]
                                    .close,
                                  'HH:mm',
                                )
                              : null
                          }
                          onChange={(selectedDate) =>
                            updateOpeningHours(moment, i, 'close', selectedDate)
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {f({ id: 'buttonCancel' })}
        </Button>
        <Button
          onClick={() => {
            const data = new FormData();
            let error = false;

            Object.keys(openingHours || {}).forEach((key) => {
              if (
                openingHours[key].open &&
                openingHours[key].close &&
                openingHours[key].open >= openingHours[key].close
              ) {
                enqueueSnackbar(f({ id: 'bookingSettingsErrorOpeningHours' }), {
                  variant: 'warning',
                });
                error = true;
              }
            });

            if (error) return;

            const openingHoursWithClosings = { ...openingHours };

            Object.keys(openingDays || {}).forEach((key) => {
              openingHoursWithClosings[key] = (openingDays[key] &&
                openingHours[key]) || { open: null, close: null };
            });

            data.append(
              'openingHours',
              JSON.stringify(openingHoursWithClosings),
            );

            dispatch(
              saveSettings({ f, shopId, settingsData: data, enqueueSnackbar }),
            ).then(() => {
              Promise.all([
                dispatch(getOnboarding()),
                dispatch(getSettings({ shopId, enqueueSnackbar })),
              ]);
              onClose();
            });
          }}
          color="primary"
          variant="contained"
        >
          {f({ id: 'buttonInput' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingBarberDialog;
