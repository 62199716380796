import React from 'react';

import {
  Button,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import BaseHOC from '../../BaseHOC';

const UnassignConfirmationDialog = ({
  onSubmit,
  onCancel,
  dialogOpen,
  title,
  message,
}) => {
  const { formatMessage: f } = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={dialogOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {f({ id: 'buttonCancel' })}
        </Button>
        <Button onClick={onSubmit} color="primary">
          {f({ id: 'buttonDelete' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default BaseHOC(
  UnassignConfirmationDialog,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
