import React from 'react';
import { useSelector } from 'react-redux';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

const AdminMiscTransactionsSummaryList = () => {
  const { formatMessage: f } = useIntl();
  const miscCategories = useSelector((state) => state.miscCategories);
  const miscTransactionStats = useSelector(
    (state) => state.miscTransactionStats,
  );

  return (
    <Box width="400px">
      <Table title={f({ id: 'miscTransactionTableTitle' })}>
        <TableHead>
          <TableRow>
            <TableCell align="center">{f({ id: 'category' })}</TableCell>
            <TableCell align="center">{f({ id: 'amount' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {miscCategories &&
            miscTransactionStats &&
            miscCategories.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <CurrencyFormat
                    amount={
                      (miscTransactionStats.find(
                        (x) => x.misc_category === row.id,
                      ) &&
                        miscTransactionStats.find(
                          (x) => x.misc_category === row.id,
                        ).total_price) ||
                      0
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default BaseHOC(AdminMiscTransactionsSummaryList);
