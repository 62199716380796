import React, { useState } from 'react';
import Moment from 'react-moment';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import MoreHoriz from '@material-ui/icons/MoreHoriz';

import Table from '../../Table';
import BaseHOC from '../../BaseHOC';

import { getClient } from '../../../actions';

import DeleteClientDialog from './DeleteClientDialog';
import ClientDetailsDialog from './ClientDetailsDialog';

const AdminClientsList = ({
  clients,
  clientDetails,
  dispatch,
  onChange,
  onDownload,
  onDelete,
  initLimit,
  enqueueSnackbar,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState('');
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Table
        title=""
        onChange={onChange}
        onDownload={onDownload}
        count={clients && clients.count}
        initLimit={initLimit}
      >
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'clientsClientName' })}</TableCell>
            <TableCell>{f({ id: 'email' })}</TableCell>
            <TableCell>{f({ id: 'mobile' })}</TableCell>
            <TableCell>{f({ id: 'statsAllBooking' })}</TableCell>
            <TableCell>{f({ id: 'clientsLastBookingDate' })}</TableCell>
            <TableCell>{f({ id: 'clientsLastBookingService' })}</TableCell>
            <TableCell>{f({ id: 'clientsLastBookingBarber' })}</TableCell>
            <TableCell>{f({ id: 'details' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients &&
            clients.results &&
            clients.results.map((row) => (
              <TableRow key={row.email}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.number_of_bookings}</TableCell>
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.latest_booking_date}</Moment>
                </TableCell>
                <TableCell>{row.latest_service_title}</TableCell>
                <TableCell>
                  {row.latest_selected_barber ||
                    f({ id: 'bookingBarberInitialsUnassigned' })}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label={f({ id: 'details' })}
                    onClick={() => {
                      dispatch(
                        getClient({ enqueueSnackbar, email: btoa(row.email) }),
                      );
                      setShowDetailsDialog(true);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ClientDetailsDialog
        open={showDetailsDialog}
        onCancel={() => {
          setShowDetailsDialog(false);
        }}
        onDelete={() => {
          setEmailToDelete(clientDetails.email);
          setShowDeleteConfirmation(true);
        }}
        clientDetails={clientDetails}
      />
      <DeleteClientDialog
        open={showDeleteConfirmation}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirm={() => {
          onDelete(emailToDelete);
          setShowDeleteConfirmation(false);
          setShowDetailsDialog(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  clientDetails: state.clientDetails,
});

export default BaseHOC(AdminClientsList, null, mapStateToProps);
