import React, { useState, useEffect, Fragment } from 'react';

import * as _ from 'lodash';

import {
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Input,
  Select,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Box,
  IconButton,
  Avatar,
  InputAdornment,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/hu';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import {
  getEmployee,
  saveEmployee,
  deleteEmployee,
  addEmployee,
  getEmployees,
  getServices,
  deletePortfolioImage,
} from '../../../actions';
import { getUser, isSalonzone } from '../../../utils';

import MultiShopSelector from '../../MultiShopSelector';
import PaddedGrid from '../../PaddedGrid';
import PrimaryButton from '../../PrimaryButton';
import PaperWithTitle from '../../PaperWithTitle';
import SecondaryButton from '../../SecondaryButton';
import FormControl from '../../FormControl';
import TextField from '../../TextField';
import BaseHOC from '../../BaseHOC';

// moment.locale('hu'); // it is required to select default locale manually

// moment.updateLocale('hu', {
//   week: {
//     dow: 1,
//   },
// });

const AdminEmployeeEditForm = ({
  enqueueSnackbar,
  getEmployee,
  getEmployees,
  getServices,
  saveEmployee,
  deleteEmployee,
  addEmployee,
  deletePortfolioImage,
  employeeDetails,
  employees,
  employee,
  services,
}) => {
  const { shopMap } = getUser();
  const [id, setId] = useState(0);
  const [mode, setMode] = useState('add');
  const [disableButtons, setDisableButtons] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [portfolioImagesPreview, setPortfolioImagesPreview] = useState([]);
  const [existingPortfolioImages, setExistingPortfolioImages] = useState([]);
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [employment, setEmployment] = useState('');
  const [employmentError, setEmploymentError] = useState(false);
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [shopIdList, setShopIdList] = useState(
    (employee && employee.shops) || shopMap.map((x) => x.id),
  );
  const [availabilityList, setAvailabilityList] = useState([]);
  const [availabilityItemsToUpdate, setAvailabilityItemsToUpdate] = useState(
    [],
  );
  const [exludeServices, setExludeServices] = useState([]);
  const [breakConfigs, setBreakConfigs] = useState([]);

  const [openBreakDialog, setOpenBreakDialog] = useState(false);
  const [newBreakConfigStart, setNewBreakConfigStart] = useState(
    moment('2020-01-01 10:00:00'),
  );
  const [newBreakConfigDuration, setNewBreakConfigDuration] = useState('');
  const [newBreakConfigTitle, setNewBreakConfigTitle] = useState('');
  const [disableBooking, setDisableBooking] = useState(false);
  const [serviceLevels, setServiceLevels] = useState([]);
  const { formatMessage: f, locale } = useIntl();

  const employmentKindMap = [
    {
      value: 'employee',
      label: f({ id: 'employmentKindEmployee' }),
    },
    {
      value: 'contractor',
      label: f({ id: 'employmentKindContractor' }),
    },
  ];

  const roleMap = [
    {
      value: 'barber',
      label: f({ id: 'employmentRoleBarber' }),
      active: true,
    },
    {
      value: 'manager',
      label: f({ id: 'employmentRoleManager' }),
      active: true,
    },
    {
      value: 'manager',
      label: f({ id: 'employmentRoleManager' }), // TODO: Fix in database
      active: false,
    },
  ];

  useEffect(() => {
    if (employee) {
      setId(employee.id);
      setEmail(employee.email || '');
      setMode('edit');
      getEmployee(
        employee.id,
        shopMap.map((x) => x.id),
        enqueueSnackbar,
      );
      setExludeServices(employee.exclude_service_ids);
      setExistingPortfolioImages(employee.portfolio_images);
    } else {
      setMode('add');
    }
  }, [employee]);

  useEffect(() => {
    getEmployees();
    getServices();
  }, []);

  useEffect(() => {
    if (profileImage) {
      var reader = new FileReader();
      reader.readAsDataURL(profileImage);

      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };
    }
  }, [profileImage]);

  useEffect(() => {
    if (portfolioImages) {
      const readPromises = Array.from(portfolioImages).map((x) => {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.readAsDataURL(x);

          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      });

      Promise.all(readPromises).then((values) => {
        setPortfolioImagesPreview(values);
      });
    }
  }, [portfolioImages]);

  useEffect(() => {
    if (employee && !_.isEmpty(employeeDetails)) {
      setDisplayName(employeeDetails.display_name || '');
      setEmail(employeeDetails.email || '');
      setDisableBooking(employee.disable_booking || false);
      setInstagramLink(employeeDetails.instagram_link || '');
      setAvailabilityList(employeeDetails.availabilities);
      // TODO: Use value instead of label
      setRole(employeeDetails.role);
      setEmployment(employeeDetails.employment_kind);
      setBreakConfigs(employeeDetails.break_configs || []);
      setServiceLevels(employee.service_levels || []);
    }
  }, [employeeDetails, employee]);

  const handleSave = () => {
    let error = false;

    if (displayName === '') {
      setDisplayNameErrorMessage(f({ id: 'employmentErrorNameMissing' }));
      error = true;
    } else if (
      !id &&
      employees.filter((x) => x.display_name === displayName).length > 0
    ) {
      setDisplayNameErrorMessage(f({ id: 'employmentErrorAlreadyExists' }));
      error = true;
    } else {
      setDisplayNameErrorMessage('');
    }

    if (role === '') {
      setRoleError(true);
      error = true;
    } else {
      setRoleError(false);
    }

    if (employment === '') {
      setEmploymentError(true);
      error = true;
    } else {
      setEmploymentError(false);
    }

    availabilityList.forEach((x) => {
      if ((x.start && !x.end) || (!x.start && x.end) || x.start > x.end) {
        enqueueSnackbar(
          `${f({ id: 'employmentErrorAvailability' })}: ${x.date}`,
          { variant: 'warning' },
        );
        error = true;
      }
    });

    if (error) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      return;
    }

    const data = new FormData();

    if (profileImage) {
      data.append('profileImage', profileImage, profileImage.name);
    }
    [...Array(portfolioImages.length).keys()].forEach((x) => {
      data.append('portfolioImages', portfolioImages[x]);
    });

    data.append('displayName', displayName);
    data.append('email', email);
    data.append('instagramLink', instagramLink);
    data.append('role', role);
    data.append('breakConfigs', JSON.stringify(breakConfigs));
    data.append('exludeServices', exludeServices);
    data.append('employmentKind', employment);
    data.append('availabilityList', availabilityItemsToUpdate);
    data.append('disableBooking', disableBooking ? 'True' : 'False');

    if (serviceLevels.length > 0) {
      data.append('serviceLevels', JSON.stringify(serviceLevels));
    }

    setDisableButtons(true);
    if (mode === 'edit') {
      data.append('id', id);
      saveEmployee(id, data, enqueueSnackbar, f).then(() =>
        setDisableButtons(false),
      );
    } else {
      [...Array(shopIdList.length).keys()].forEach((x) => {
        data.append('shopIdList', shopIdList[x]);
      });
      addEmployee(data, enqueueSnackbar, false, f).then(() =>
        setDisableButtons(false),
      );
    }
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box m={1} maxWidth={350}>
          <Box mb={2}>
            <PaperWithTitle
              title={f({ id: 'employmentBaseDetails' })}
              helpText={f({ id: 'employmentBaseDetailsTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box mb={2}>
                  <FormControl
                    component="fieldset"
                    style={{ minWidth: '260px' }}
                  >
                    <FormGroup>
                      <TextField
                        helperText={displayNameErrorMessage}
                        error={!!displayNameErrorMessage}
                        required
                        id="displayName"
                        name="displayName"
                        label={f({ id: 'name' })}
                        margin="dense"
                        value={displayName}
                        onChange={(event) => setDisplayName(event.target.value)}
                      />
                      {shopMap.length > 1 ? (
                        <PaddedGrid item xs={12}>
                          <MultiShopSelector
                            disabled={mode === 'edit'}
                            shopIdList={shopIdList}
                            setShopIdList={setShopIdList}
                          />
                        </PaddedGrid>
                      ) : null}
                      <FormControl required error={roleError}>
                        <InputLabel htmlFor="role">
                          {f({ id: 'employeeRole' })}
                        </InputLabel>
                        <Select
                          value={role}
                          onChange={(event) => setRole(event.target.value)}
                          input={<Input name="role" id="role" />}
                        >
                          {roleMap.map(
                            (option) =>
                              (option.active && (
                                <MenuItem
                                  key={`role-${option.value}`}
                                  value={option.value}
                                  name={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              )) ||
                              null,
                          )}
                        </Select>
                      </FormControl>
                      <FormControl required error={employmentError}>
                        <InputLabel htmlFor="employment">
                          {f({ id: 'employmentKind' })}
                        </InputLabel>
                        <Select
                          value={employment}
                          onChange={(event) =>
                            setEmployment(event.target.value)
                          }
                          input={<Input name="employment" id="employment" />}
                        >
                          {employmentKindMap.map((option) => (
                            <MenuItem
                              key={`employment-kind-${option.value}`}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id="email"
                        type="email"
                        name="email"
                        label="Email"
                        margin="dense"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
          <Box mb={2}>
            <PaperWithTitle
              title={f({ id: 'employmentBreaks' })}
              helpText={f({ id: 'employmentBreaksTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box mb={2}>
                  <Box display="flex" flexDirection="column">
                    {breakConfigs.map((breakConfig) => {
                      const startTime = moment(
                        `2020-01-01 ${String(breakConfig.start_hour).padStart(
                          2,
                          '0',
                        )}:${String(breakConfig.start_minute).padStart(
                          2,
                          '0',
                        )}`,
                      );
                      const endTime = startTime
                        .clone()
                        .add(breakConfig.duration, 'minute');
                      return (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          key={`${breakConfig.id}-${
                            breakConfig.title
                          }-${startTime.format(moment.HTML5_FMT.TIME)}`}
                          mb={1}
                        >
                          <Box>
                            {`${breakConfig.title} (${startTime.format(
                              moment.HTML5_FMT.TIME,
                            )} - ${endTime.format(moment.HTML5_FMT.TIME)})`}
                          </Box>
                          <Box>
                            <IconButton
                              size="small"
                              aria-label={f({ id: 'buttonDelete' })}
                              onClick={() =>
                                setBreakConfigs(
                                  [...breakConfigs].filter(
                                    (x) => x.id !== breakConfig.id,
                                  ),
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <PrimaryButton onClick={() => setOpenBreakDialog(true)}>
                    {f({ id: 'buttonAdd' })}
                  </PrimaryButton>
                  <Dialog
                    open={openBreakDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {f({ id: 'employmentAddBreak' })}
                    </DialogTitle>
                    <DialogContent>
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        libInstance={moment}
                        locale={moment.locale(locale)}
                      >
                        <Box mb={3}>
                          <KeyboardTimePicker
                            label={f({ id: 'employmentAddBreakChooseTime' })}
                            clearable="true"
                            autoOk
                            mask="__:__"
                            ampm={false}
                            minutesStep={15}
                            variant="inline"
                            format="HH:mm"
                            cancelLabel={f({ id: 'buttonCancel' })}
                            value={newBreakConfigStart}
                            onChange={(selectedDate) =>
                              setNewBreakConfigStart(selectedDate)
                            }
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            fullWidth
                            // helperText={titleOverrideError}
                            // error={!!titleOverrideError}
                            id="newBreakConfigTitle"
                            name="newBreakConfigTitle"
                            label={f({ id: 'title' })}
                            value={newBreakConfigTitle}
                            onChange={(e) =>
                              setNewBreakConfigTitle(e.target.value)
                            }
                            autoComplete="newBreakConfigTitle"
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            fullWidth
                            // helperText={durationOverrideError}
                            // error={!!durationOverrideError}
                            id="newBreakConfigDuration"
                            name="newBreakConfigDuration"
                            label={f({ id: 'duration' })}
                            value={newBreakConfigDuration}
                            onChange={(e) =>
                              setNewBreakConfigDuration(e.target.value)
                            }
                            autoComplete="newBreakConfigDuration"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {f({ id: 'minute' })}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                      <SecondaryButton
                        onClick={() => setOpenBreakDialog(false)}
                      >
                        {f({ id: 'buttonCancel' })}
                      </SecondaryButton>
                      <PrimaryButton
                        onClick={() => {
                          setBreakConfigs([
                            ...breakConfigs,
                            {
                              start_hour: newBreakConfigStart.hour(),
                              start_minute: newBreakConfigStart.minute(),
                              duration: newBreakConfigDuration,
                              title: newBreakConfigTitle,
                            },
                          ]);
                          setNewBreakConfigStart(moment('2020-01-01 10:00:00'));
                          setNewBreakConfigDuration('');
                          setNewBreakConfigTitle('');
                          setOpenBreakDialog(false);
                        }}
                      >
                        {f({ id: 'buttonAdd' })}
                      </PrimaryButton>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
          <Box mb={2}>
            <PaperWithTitle
              title={f({ id: 'employmentProfileTitle' })}
              helpText={f({ id: 'employmentProfileTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box mb={2}>
                  <Avatar
                    aria-label="recipe"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      width: 128,
                      height: 128,
                    }}
                    src={
                      profileImagePreview ||
                      (employee &&
                        employee.profile_image &&
                        `https://agrddpxdbm.cloudimg.io/v7/${employee.profile_image}?w=300&h=300`)
                    }
                  >
                    {(employee && employee.profile_image) || profileImagePreview
                      ? null
                      : (employee &&
                          employee.display_name
                            .split(' ')
                            .map((x) => x[0])
                            .join('')) ||
                        ''}
                  </Avatar>
                </Box>
                <Box mb={2}>
                  <FormControlLabel
                    control={
                      <Input
                        id="profileImage"
                        name="profileImage"
                        type="file"
                        onChange={(event) =>
                          setProfileImage(event.target.files[0])
                        }
                        disabled={disableButtons}
                        style={{
                          width: '0.1px',
                          height: '0.1px',
                          opacity: '0',
                          overflow: 'hidden',
                          position: 'absolute',
                          zIndex: '-1',
                        }}
                      />
                    }
                    label={f({ id: 'employmentNewProfilePicture' })}
                    labelPlacement="start"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSalonzone ? '#230928' : '#212121',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '6px 16px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '0.875rem',
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    id="instagramLink"
                    name="instagramLink"
                    label={f({ id: 'employmentInstagramLink' })}
                    value={instagramLink}
                    onChange={(event) => setInstagramLink(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          instagram.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <FormControl style={{ minWidth: '260px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!disableBooking}
                          onChange={(event) =>
                            setDisableBooking(!event.target.checked)
                          }
                          id="disableBooking"
                          name="disableBooking"
                          color="primary"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      }
                      label={f({ id: 'employmentShowProfile' })}
                    />
                  </FormControl>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
        </Box>
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'appNavAdminShopServices' })}
            helpText={f({ id: 'employmentServicesTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <FormControl component="fieldset" style={{ minWidth: '260px' }}>
                  {services.map((service) => (
                    <Fragment key={service.base_id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              exludeServices.indexOf(service.base_id) === -1
                            }
                            onChange={(event) => {
                              // if checked, remove from exludeServices
                              if (event.target.checked) {
                                setExludeServices(
                                  [...exludeServices].filter(
                                    (x) => x !== service.base_id,
                                  ),
                                );
                              } else {
                                setExludeServices([
                                  ...exludeServices,
                                  service.base_id,
                                ]);
                                setServiceLevels(
                                  [...serviceLevels].filter(
                                    (x) =>
                                      x.service_base_id !== service.base_id,
                                  ),
                                );
                              }
                            }}
                            color="primary"
                            inputProps={{
                              'aria-label': 'primary checkbox',
                            }}
                          />
                        }
                        label={service.title}
                      />
                      {exludeServices.indexOf(service.base_id) === -1 &&
                        service.service_levels &&
                        service.service_levels.length > 0 && (
                          <Box pl={2}>
                            <RadioGroup
                              name={`service-level-${service.id}`}
                              value={
                                (
                                  (
                                    serviceLevels.find(
                                      (x) =>
                                        x.service_base_id === service.base_id,
                                    ) || {}
                                  ).level || {}
                                ).id || -1
                              }
                              // Todo: use useState to store service_levels and update them
                              onChange={(event) => {
                                const newServiceLevels = [...serviceLevels];
                                const levelId = parseInt(
                                  event.target.value,
                                  10,
                                );

                                if (levelId === -1) {
                                  setServiceLevels(
                                    newServiceLevels.filter(
                                      (x) =>
                                        x.service_base_id !== service.base_id,
                                    ),
                                  );
                                } else {
                                  setServiceLevels([
                                    ...newServiceLevels.filter(
                                      (x) =>
                                        x.service_base_id !== service.base_id,
                                    ),
                                    {
                                      service_base_id: service.base_id,
                                      level: {
                                        id: levelId,
                                      },
                                    },
                                  ]);
                                }
                              }}
                            >
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                value={-1}
                                label={f({ id: 'employeeDefaultServiceLevel' })}
                              />
                              {service.service_levels
                                .filter(
                                  (x) => x.service_base_id === service.base_id,
                                )
                                .map((serviceLevel) => (
                                  <FormControlLabel
                                    key={`radio-${service.base_id}-${serviceLevel.level.id}`}
                                    value={serviceLevel.level.id}
                                    control={<Radio color="primary" />}
                                    label={serviceLevel.level.title}
                                  />
                                ))}
                            </RadioGroup>
                          </Box>
                        )}
                    </Fragment>
                  ))}
                </FormControl>
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
        <Box m={1} maxWidth={500}>
          <PaperWithTitle
            title={f({ id: 'employmentPortfolioImagesTitle' })}
            helpText={f({ id: 'employmentPortfolioImagesTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" width="100%" flexWrap="wrap">
                    {existingPortfolioImages.map((x) => (
                      <Box
                        m={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        key={`portfolio-image-${x.id}`}
                      >
                        <Box>
                          <img
                            src={`https://agrddpxdbm.cloudimg.io/v7/${x.image}?w=200&h=200&func=bound`}
                          />
                        </Box>
                        <Box>
                          <IconButton
                            aria-label={f({ id: 'buttonDelete' })}
                            onClick={() =>
                              deletePortfolioImage({
                                f,
                                imageId: x.id,
                                enqueueSnackbar,
                              }).then(() =>
                                setExistingPortfolioImages(
                                  [...existingPortfolioImages].filter(
                                    (y) => x.id !== y.id,
                                  ),
                                ),
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                    {portfolioImagesPreview.map((x, i) => (
                      <Box
                        m={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        key={`portfolio-${i}`}
                      >
                        <Box maxWidth="200px" maxHeight="200px">
                          <img
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            src={x}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Input
                          id="portfolioImages"
                          name="portfolioImages"
                          type="file"
                          onChange={(event) => {
                            setPortfolioImagesPreview([]);
                            setPortfolioImages(event.target.files);
                          }}
                          disabled={disableButtons}
                          style={{
                            width: '0.1px',
                            height: '0.1px',
                            opacity: '0',
                            overflow: 'hidden',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          inputProps={{ multiple: true }}
                        />
                      }
                      label={f({ id: 'employmentPortfolioImagesUpload' })}
                      labelPlacement="start"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: isSalonzone ? '#230928' : '#212121',
                        color: '#fff',
                        borderRadius: '4px',
                        padding: '6px 16px',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '0.875rem',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
      </Box>
      <Box mt={2}>
        <PrimaryButton
          withmargin="true"
          disabled={disableButtons}
          onClick={() => window.history.back()}
        >
          {f({ id: 'buttonBack' })}
        </PrimaryButton>
        <PrimaryButton
          withmargin="true"
          disabled={disableButtons}
          onClick={() => handleSave()}
        >
          {f({ id: 'buttonSave' })}
        </PrimaryButton>
        {mode === 'edit' ? (
          <PrimaryButton
            withmargin="true"
            danger
            disabled={disableButtons}
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : (
          ''
        )}
      </Box>
      <Dialog
        open={deleteConfirmationOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {f({ id: 'confirmDeleteTitle' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {f({ id: 'confirmDeleteConfirmEmployee' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setDeleteConfirmationOpen(false)}>
            {f({ id: 'buttonCancel' })}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => deleteEmployee(id, enqueueSnackbar, f)}
            danger
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  services: state.services,
  employees: state.employees,
  employeeDetails: state.employeeDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (...args) => dispatch(getEmployee(...args)),
  getServices: (...args) => dispatch(getServices(...args)),
  addEmployee: (...args) => dispatch(addEmployee(...args)),
  saveEmployee: (...args) => dispatch(saveEmployee(...args)),
  deleteEmployee: (...args) => dispatch(deleteEmployee(...args)),
  getEmployees: (...args) => dispatch(getEmployees(...args)),
  deletePortfolioImage: (...args) => dispatch(deletePortfolioImage(...args)),
});

export default BaseHOC(
  AdminEmployeeEditForm,
  null,
  mapStateToProps,
  mapDispatchToProps,
);
