import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Table from '../../Table';
import Loader from '../../Loader';
import CurrencyFormat from '../../CurrencyFormat';
import BaseHOC from '../../BaseHOC';

import { Heading } from './styles';

const TillCloseSplitMissingTable = () => {
  const { formatMessage: f } = useIntl();
  const dailyTillClose = useSelector((state) => state.dailyTillClose);
  return (
    (dailyTillClose && (
      <Table title={f({ id: 'tillMissingTitle' })}>
        <TableHead>
          <TableRow>
            <TableCell>{f({ id: 'createdBy' })}</TableCell>
            <TableCell>{f({ id: 'columnTitleBalance' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parseFloat(dailyTillClose.balance) < 0 ||
          parseFloat(dailyTillClose.balance) > 0 ? (
            dailyTillClose.employees_on_day.map((row) => (
              <TableRow key={row}>
                <TableCell>{row.display_name}</TableCell>
                <TableCell>
                  {parseFloat(dailyTillClose.balance) > 0 ? '+' : null}
                  <CurrencyFormat
                    amount={
                      parseFloat(dailyTillClose.balance) /
                      dailyTillClose.employees_on_day.length
                    }
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Heading variant="h6" gutterBottom>
              {f({ id: 'tillMissingNoMissing' })}
            </Heading>
          )}
        </TableBody>
      </Table>
    )) || <Loader />
  );
};

export default BaseHOC(TillCloseSplitMissingTable);
