import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import {
  Box,
  FormGroup,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useIntl } from 'react-intl';

import {
  saveService,
  deleteService,
  addService,
  getServices,
  getSettings,
  saveNextServiceBaseIDs,
  saveDisableServiceBaseIDs,
} from '../../../actions';
import { isPositiveInt, isPositiveIntOrZero, getUser } from '../../../utils';

import MultiShopSelector from '../../MultiShopSelector';
import PaperWithTitle from '../../PaperWithTitle';
import PrimaryButton from '../../PrimaryButton';
import TextField from '../../TextField';
import FormControl from '../../FormControl';
import ColorPicker from '../../ColorPicker';
import BaseHOC from '../../BaseHOC';

const DEFAULT_COLOR = '#2196f3';

const ServiceEditForm = ({ enqueueSnackbar, service }) => {
  const { shopMap } = getUser();

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const services = useSelector((state) => state.services);

  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState('');
  const [displayOrder, setDisplayOrder] = useState(services.length + 1);
  const [durationError, setDurationError] = useState(false);
  const [durationErrorMessage, setDurationErrorMessage] = useState('');
  const [description, setDescription] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = useState('');
  const [price, setPrice] = useState(0);
  const [priceError, setPriceError] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [mode, setMode] = useState('add');
  const [shopIdList, setShopIdList] = useState(
    (service && [service.shop_id]) || shopMap.map((x) => x.id),
  );
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [serviceLevels, setServiceLevels] = useState([]);
  const [existingServiceLevels, setExistingServiceLevels] = useState([]);
  const [updateNextBaseIds, setUpdateNextBaseIds] = useState([]);
  const [updateDisabledBaseIds, setUpdateDisabledBaseIds] = useState([]);
  const [fixPrice, setFixPrice] = useState(true);
  const [saving, setSaving] = useState(false);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (service && settings?.country?.currency) {
      setId(service.id);
      setTitle(service.title || '');
      setShopIdList([service.shop_id]);
      if (settings?.country?.currency === 'Ft' && service.price) {
        setPrice(parseInt(service.price, 10) || '');
      } else {
        setPrice(service.price || '');
      }
      setDuration(service.duration || '');
      setDisplayOrder(service.display_order || '1');
      setDescription(service.description || '');
      setColor(service.color || DEFAULT_COLOR);
      setBarcode(service.barcode || '');

      const newServiceLevels = [...(service.service_levels || [])].map((x) => ({
        ...x,
        price:
          settings?.country?.currency === 'Ft'
            ? parseInt(x.price, 10)
            : x.price,
      }));

      setServiceLevels(newServiceLevels);

      setExistingServiceLevels(service.service_levels || []);
      setFixPrice(service.fix_price);
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [service, settings]);

  useEffect(() => {
    if (service) {
      dispatch(getSettings({ shopId: service.shop_id, enqueueSnackbar }));
    }
  }, [service]);

  useEffect(() => {
    dispatch(getServices());
  }, []);

  const sucessfulSave = () => {
    // dispatch(getServices());
    navigate('/app/services');
    enqueueSnackbar(f({ id: 'snackbarModifySuccess' }), {
      variant: 'success',
    });
    setSaving(false);
  };

  const handleSave = () => {
    setSaving(true);
    let error = false;
    let titleError = false;

    if (title === '') {
      setTitleError(true);
      titleError = true;
      error = true;
    } else {
      setTitleError(false);
    }

    if (shopMap[0].language_key === 'hu') {
      if (!isPositiveIntOrZero(price)) {
        setPriceError(true);
        error = true;
      } else {
        setPriceError(false);
      }
    }

    if (!isPositiveInt(duration) || parseInt(duration, 10) % 5 !== 0) {
      setDurationError(true);
      setDurationErrorMessage(f({ id: 'serviceMultipleOf15Error' }));
      error = true;
    } else {
      setDurationError(false);
      setDurationErrorMessage('');
    }

    if (
      !id &&
      services.filter((x) => x.title === title && x.shop_id === shopIdList[0])
        .length > 0
    ) {
      setTitleError(true);
      setTitleErrorMessage(f({ id: 'serviceAlreadyExists' }));
      error = true;
    } else {
      setTitleError(titleError);
    }

    // TODO: Validate service level overrides as well

    if (error) {
      enqueueSnackbar(f({ id: 'allFieldsRequiredErrorMessage' }), {
        variant: 'warning',
      });
      setSaving(false);
      return;
    }

    const serviceData = {
      title,
      price,
      description,
      duration,
      color,
      displayOrder,
      barcode,
      serviceLevels,
      fixPrice,
      shopId: shopIdList[0],
    };

    if (mode === 'edit') {
      dispatch(saveService(id, serviceData, enqueueSnackbar, f)).then(() => {
        if (updateNextBaseIds.length > 0) {
          dispatch(
            saveNextServiceBaseIDs({ f, enqueueSnackbar, updateNextBaseIds }),
          ).then(() => {
            if (updateDisabledBaseIds.length > 0) {
              dispatch(
                saveDisableServiceBaseIDs({
                  f,
                  enqueueSnackbar,
                  updateDisabledBaseIds,
                }),
              ).then(() => {
                sucessfulSave();
              });
            } else {
              sucessfulSave();
            }
          });
        } else if (updateDisabledBaseIds.length > 0) {
          dispatch(
            saveDisableServiceBaseIDs({
              f,
              enqueueSnackbar,
              updateDisabledBaseIds,
            }),
          ).then(() => {
            sucessfulSave();
          });
        } else {
          sucessfulSave();
        }
      });
    } else {
      dispatch(addService(serviceData, enqueueSnackbar, false, f)).then(
        (newService) => {
          if (updateNextBaseIds.length > 0) {
            dispatch(
              saveNextServiceBaseIDs({
                f,
                enqueueSnackbar,
                updateNextBaseIds: updateNextBaseIds.map((x) => {
                  if (x.baseId === undefined) {
                    return { ...x, baseId: newService.base_id };
                  }
                  if (x.nextId === undefined) {
                    return { ...x, nextId: newService.base_id };
                  }
                }),
              }),
            ).then(() => setSaving(false));
          }
          if (updateDisabledBaseIds.length > 0) {
            dispatch(
              saveDisableServiceBaseIDs({
                f,
                enqueueSnackbar,
                updateDisabledBaseIds: updateDisabledBaseIds.map((x) => {
                  if (x.baseId === undefined) {
                    return { ...x, baseId: newService.base_id };
                  }
                }),
              }),
            ).then(() => setSaving(false));
          }
        },
      );
    }
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'serviceNameDescription' })}
            helpText={f({ id: 'serviceNameDescriptionTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <FormControl component="fieldset" style={{ minWidth: '260px' }}>
                  <FormGroup>
                    <TextField
                      helperText={titleErrorMessage}
                      error={titleError}
                      margin="dense"
                      required
                      id="title"
                      name="title"
                      label={f({ id: 'name' })}
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />

                    <TextField
                      id="serviceDescription"
                      name="serviceDescription"
                      label={f({ id: 'description' })}
                      value={description}
                      multiline
                      rows="4"
                      onChange={(event) => setDescription(event.target.value)}
                    />
                    {shopMap.length > 1 ? (
                      <MultiShopSelector
                        disabled={mode === 'edit'}
                        shopIdList={shopIdList}
                        setShopIdList={setShopIdList}
                        singleShopSelect
                      />
                    ) : null}
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      pt={2}
                    >
                      <TextField
                        id="barcode"
                        name="barcode"
                        label={f({ id: 'barcode' })}
                        value={barcode}
                        onChange={(event) => setBarcode(event.target.value)}
                      />
                      <Tooltip
                        title={f({ id: 'barcodeTooltip' })}
                        enterTouchDelay={1}
                        leaveTouchDelay={2000}
                      >
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
          </PaperWithTitle>
        </Box>
        <Box m={1} maxWidth={350}>
          <PaperWithTitle
            title={f({ id: 'servicePriceDuration' })}
            helpText={f({ id: 'servicePriceDurationTooltip' })}
          >
            <Box display="flex" flexDirection="column" ml={2} mt={1}>
              <Box mb={2}>
                <Box>
                  <Typography variant="body2">
                    {f({ id: 'serviceDefaultLevel' })}:
                  </Typography>
                </Box>
                <FormControl component="fieldset" style={{ minWidth: '260px' }}>
                  <FormGroup>
                    <TextField
                      error={priceError}
                      required
                      margin="dense"
                      id="price"
                      name="price"
                      label={f({ id: 'price' })}
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                      type="number"
                      InputProps={
                        settings?.country?.currency &&
                        ((settings.country.currency === 'Ft' && {
                          endAdornment: (
                            <InputAdornment position="end">Ft</InputAdornment>
                          ),
                        }) ||
                          (settings.country.currency === 'GBP' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'EUR' && {
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }) ||
                          (settings.country.currency === 'CZK' && {
                            startAdornment: (
                              <InputAdornment position="end">Kč</InputAdornment>
                            ),
                          }))
                      }
                    />

                    <TextField
                      helperText={durationErrorMessage}
                      error={durationError}
                      required
                      id="duration"
                      name="duration"
                      margin="dense"
                      label={f({ id: 'calendarEditDialogDuration' })}
                      type="number"
                      value={duration}
                      onChange={(event) => setDuration(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {f({ id: 'minute' })}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormGroup>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fixPrice}
                      onChange={(event) => setFixPrice(event.target.checked)}
                      id="chkbox-fix-price"
                      name="chkbox-fix-price"
                      color="primary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                  }
                  label={f({ id: 'servicePriceExact' })}
                />
              </Box>

              {service &&
                settings &&
                settings.barber_levels &&
                settings.barber_levels.map((level) => {
                  const levelAdded = serviceLevels.find(
                    (x) => x.level.id === level.id,
                  );
                  return (
                    <Box mb={2} key={`level-${level.id}`}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!levelAdded}
                              onChange={(event) => {
                                const newServiceLevels = [...serviceLevels];
                                if (event.target.checked) {
                                  const existingLevel =
                                    (existingServiceLevels || []).find(
                                      (x) => x.level.id === level.id,
                                    ) || null;

                                  if (existingLevel) {
                                    newServiceLevels.push(existingLevel);
                                  } else {
                                    newServiceLevels.push({
                                      service_base_id: service.base_id,
                                      duration,
                                      price,
                                      level: {
                                        id: level.id,
                                      },
                                    });
                                  }

                                  setServiceLevels(newServiceLevels);
                                } else {
                                  setServiceLevels(
                                    newServiceLevels.filter(
                                      (x) => x.level.id !== level.id,
                                    ),
                                  );
                                }
                              }}
                              id={`chkbox-level-${level.id}`}
                              name={`chkbox-level-${level.id}`}
                              color="primary"
                              inputProps={{
                                'aria-label': 'primary checkbox',
                              }}
                            />
                          }
                          label={level.title}
                        />
                      </Box>
                      {levelAdded && (
                        <FormControl
                          component="fieldset"
                          style={{ minWidth: '260px' }}
                        >
                          <FormGroup>
                            <TextField
                              error={priceError}
                              required
                              margin="dense"
                              id="price"
                              name="price"
                              label={f({ id: 'price' })}
                              value={levelAdded && levelAdded.price}
                              onChange={(event) => {
                                const newServiceLevels = [...serviceLevels];
                                const editLevel = newServiceLevels.find(
                                  (x) =>
                                    x.service_base_id ===
                                      levelAdded.service_base_id &&
                                    x.level.id === levelAdded.level.id,
                                );

                                if (editLevel) {
                                  editLevel.price = event.target.value;
                                }

                                setServiceLevels(newServiceLevels);
                              }}
                              type="number"
                              InputProps={
                                (settings.country.currency === 'Ft' && {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      Ft
                                    </InputAdornment>
                                  ),
                                }) ||
                                (settings.country.currency === 'GBP' && {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      £
                                    </InputAdornment>
                                  ),
                                }) ||
                                (settings.country.currency === 'EUR' && {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      €
                                    </InputAdornment>
                                  ),
                                }) ||
                                (settings.country.currency === 'CZK' && {
                                  startAdornment: (
                                    <InputAdornment position="end">
                                      Kč
                                    </InputAdornment>
                                  ),
                                })
                              }
                            />

                            <TextField
                              helperText={durationErrorMessage}
                              error={durationError}
                              id="duration"
                              name="duration"
                              margin="dense"
                              label={f({ id: 'calendarEditDialogDuration' })}
                              type="number"
                              value={levelAdded && levelAdded.duration}
                              onChange={(event) => {
                                const newServiceLevels = [...serviceLevels];
                                const editLevel = newServiceLevels.find(
                                  (x) =>
                                    x.service_base_id ===
                                      levelAdded.service_base_id &&
                                    x.level.id === levelAdded.level.id,
                                );

                                if (editLevel) {
                                  editLevel.duration = event.target.value;
                                }

                                setServiceLevels(newServiceLevels);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {f({ id: 'minute' })}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormGroup>
                        </FormControl>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </PaperWithTitle>
        </Box>
        {shopMap.filter((x) => x.booking).length > 0 && (
          <Box m={1} maxWidth={350}>
            <PaperWithTitle
              title={f({ id: 'serviceDisplay' })}
              helpText={f({ id: 'serviceDisplayTooltip' })}
            >
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Box mb={2}>
                  <FormControl
                    component="fieldset"
                    style={{ minWidth: '260px' }}
                  >
                    <FormGroup>
                      <TextField
                        id="displayOrder"
                        name="displayOrder"
                        label={f({ id: 'serviceDisplayOrder' })}
                        type="number"
                        value={displayOrder}
                        onChange={(event) =>
                          setDisplayOrder(event.target.value)
                        }
                      />
                      <Box mt={1}>
                        <ColorPicker
                          required
                          id="color"
                          name="color"
                          value={color}
                          onChange={(selectedColor) => setColor(selectedColor)}
                        />
                      </Box>
                    </FormGroup>
                  </FormControl>
                </Box>
              </Box>
            </PaperWithTitle>
          </Box>
        )}
        {shopMap.filter((x) => x.booking).length > 0 && (
          <Box m={1} maxWidth={350}>
            <PaperWithTitle
              title={f({ id: 'serviceNextServicesTitle' })}
              helpText={f({ id: 'serviceNextServicesTooltip' })}
            >
              <Box px={2}>
                <Box
                  pl={2}
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={2}
                >
                  {f({ id: 'serviceNextServicesBeforeAfter' })}
                </Box>
                {services
                  ?.filter((x) => x.base_id !== service?.base_id)
                  ?.map((x) => {
                    const nextChecked = !!(
                      (updateNextBaseIds.find(
                        (y) =>
                          y.baseId === service?.base_id &&
                          y.nextId === x.base_id,
                      ) &&
                        updateNextBaseIds.find(
                          (y) =>
                            y.baseId === service?.base_id &&
                            y.nextId === x.base_id,
                        ).add) ||
                      (!updateNextBaseIds.find(
                        (y) =>
                          y.baseId === service?.base_id &&
                          y.nextId === x.base_id,
                      ) &&
                        x.previous_services_base_ids.find(
                          (y) => y === service?.base_id,
                        ))
                    );
                    const previousChecked = !!(
                      (updateNextBaseIds.find(
                        (y) =>
                          y.baseId === x.base_id &&
                          y.nextId === service?.base_id,
                      ) &&
                        updateNextBaseIds.find(
                          (y) =>
                            y.baseId === x.base_id &&
                            y.nextId === service?.base_id,
                        ).add) ||
                      (!updateNextBaseIds.find(
                        (y) =>
                          y.baseId === x.base_id &&
                          y.nextId === service?.base_id,
                      ) &&
                        x.next_services_base_ids.find(
                          (y) => y === service?.base_id,
                        ))
                    );
                    const disabledChecked = !!(
                      (updateDisabledBaseIds.find(
                        (y) =>
                          y.baseId === service?.base_id &&
                          y.disabledId === x.base_id,
                      ) &&
                        updateDisabledBaseIds.find(
                          (y) =>
                            y.baseId === service?.base_id &&
                            y.disabledId === x.base_id,
                        )?.disable) ||
                      (!updateDisabledBaseIds.find(
                        (y) =>
                          y.baseId === service?.base_id &&
                          y.disabledId === x.base_id,
                      ) &&
                        x.disabled_services_base_ids.find(
                          (y) => y === service?.base_id,
                        ))
                    );

                    return (
                      <Box
                        key={`base-${x.base_id}-box`}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {x.title}
                        </FormLabel>

                        <RadioGroup
                          value={
                            (previousChecked && 'previous') ||
                            (nextChecked && 'next') ||
                            (disabledChecked && 'disabled')
                          }
                          onChange={(event) => {
                            if (event.target.value == 'disabled') {
                              setUpdateNextBaseIds([
                                ...updateNextBaseIds.filter(
                                  (y) =>
                                    !(
                                      (y.baseId === x.base_id &&
                                        y.nextId === service?.base_id) ||
                                      (y.baseId === service?.base_id &&
                                        y.nextId === x.base_id)
                                    ),
                                ),
                                {
                                  baseId: x.base_id,
                                  nextId: service?.base_id,
                                  add: false,
                                },
                                {
                                  baseId: service?.base_id,
                                  nextId: x.base_id,
                                  add: false,
                                },
                              ]);
                              setUpdateDisabledBaseIds([
                                ...updateDisabledBaseIds.filter(
                                  (y) =>
                                    !(
                                      y.baseId === service?.base_id &&
                                      y.disabledId === x.base_id
                                    ),
                                ),
                                {
                                  baseId: service?.base_id,
                                  disabledId: x.base_id,
                                  disable: true,
                                },
                              ]);
                            } else if (event.target.value == 'next') {
                              setUpdateNextBaseIds([
                                ...updateNextBaseIds.filter(
                                  (y) =>
                                    !(
                                      (y.baseId === x.base_id &&
                                        y.nextId === service?.base_id) ||
                                      (y.baseId === service?.base_id &&
                                        y.nextId === x.base_id)
                                    ),
                                ),
                                {
                                  baseId: x.base_id,
                                  nextId: service?.base_id,
                                  add: false,
                                },
                                {
                                  baseId: service?.base_id,
                                  nextId: x.base_id,
                                  add: true,
                                },
                              ]);
                              setUpdateDisabledBaseIds([
                                ...updateDisabledBaseIds.filter(
                                  (y) =>
                                    !(
                                      y.baseId === service?.base_id &&
                                      y.disabledId === x.base_id
                                    ),
                                ),
                                {
                                  baseId: service?.base_id,
                                  disabledId: x.base_id,
                                  disable: false,
                                },
                              ]);
                            } else if (event.target.value == 'previous') {
                              setUpdateNextBaseIds([
                                ...updateNextBaseIds.filter(
                                  (y) =>
                                    !(
                                      (y.baseId === x.base_id &&
                                        y.nextId === service?.base_id) ||
                                      (y.baseId === service?.base_id &&
                                        y.nextId === x.base_id)
                                    ),
                                ),
                                {
                                  baseId: x.base_id,
                                  nextId: service?.base_id,
                                  add: true,
                                },
                                {
                                  baseId: service?.base_id,
                                  nextId: x.base_id,
                                  add: false,
                                },
                              ]);
                              setUpdateDisabledBaseIds([
                                ...updateDisabledBaseIds.filter(
                                  (y) =>
                                    !(
                                      y.baseId === service?.base_id &&
                                      y.disabledId === x.base_id
                                    ),
                                ),
                                {
                                  baseId: service?.base_id,
                                  disabledId: x.base_id,
                                  disable: false,
                                },
                              ]);
                            }
                          }}
                        >
                          <Box display="flex" flexDirection="row">
                            <FormControlLabel
                              value="previous"
                              control={<Radio color="primary" />}
                            />
                            <FormControlLabel
                              value="next"
                              control={<Radio color="primary" />}
                            />
                            <FormControlLabel
                              value="disabled"
                              control={<Radio color="primary" />}
                            />
                          </Box>
                        </RadioGroup>
                      </Box>
                    );
                  })}
              </Box>
            </PaperWithTitle>
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <PrimaryButton
          withmargin="true"
          disabled={saving}
          onClick={() => window.history.back()}
        >
          {f({ id: 'buttonBack' })}
        </PrimaryButton>
        <PrimaryButton
          withmargin="true"
          disabled={saving}
          onClick={() => handleSave()}
        >
          {f({ id: 'buttonSave' })}
        </PrimaryButton>
        {mode === 'edit' ? (
          <PrimaryButton
            withmargin="true"
            danger
            disabled={saving}
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        ) : (
          ''
        )}
      </Box>
      <Dialog
        open={deleteConfirmationOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {f({ id: 'confirmDeleteTitle' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {f({ id: 'confirmDeleteConfirmService' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setDeleteConfirmationOpen(false)}>
            {f({ id: 'buttonCancel' })}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => dispatch(deleteService(id, enqueueSnackbar, f))}
            danger
          >
            {f({ id: 'buttonDelete' })}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BaseHOC(ServiceEditForm);
